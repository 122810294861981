import _ from "lodash";

function callbackEnhancer() {
    return (createStore) =>
        (...args) => {
            const store = createStore(...args);
            function dispatch(action, options) {
                if (_.isPlainObject(options) && options.successCallback) {
                    const value = store.dispatch(action);
                    if (_.isObject(value) && typeof value.then === "function") {
                        value
                            .then(() => options.successCallback())
                            .catch(() => {
                                if (_.isFunction(options.failureCallback)) {
                                    options.failureCallback();
                                }
                            });
                        return value;
                    } else {
                        options.successCallback();
                    }
                } else {
                    return store.dispatch(action);
                }
            }
            return {
                ...store,
                dispatch,
            };
        };
}

export default callbackEnhancer;
