import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { FieldArray, useFormikContext } from 'formik'
import Element from './Form/Element'
import Text from '../../commonComponent/Text'
import { omit } from 'lodash'

const CustomFieldArray = ({
  label,
  inputProps,
  values,
  errors,
  touched,
  elements,
  handleChange,
  handleBlur,
  wrapperStyles,
  addNewText,
  errorStyle = "",
  addNewBtnDisableCondition,
  maxLength,
  deleteBtnStyle,
  showDeleteForIdx,
  showClearBtn = false
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const inputRefs = useRef([])

  const onHandleChange = (e, el, idx) => {
    if (el.inputProps.onInputChange) {
      el.inputProps.onInputChange(e, values, idx, setFieldValue, setFieldTouched)
    }
    handleChange(e)
  }

  const isAddNewBtnEnabled = () => {
    let addNew = true;
    if (addNewBtnDisableCondition) {
      addNew = !addNewBtnDisableCondition(values)
    }
    if (maxLength) {
      addNew = addNew && (values[inputProps.name].length < maxLength)
    }
    return addNew
  }

  const checkIfInputsEmpty = (idx) => {
    const obj = values[inputProps.name][idx]
    if (obj) {
      let emptyInput = true
      Object.keys(obj).forEach(key => {
        if (typeof obj[key] === "string" && obj[key].length > 0) {
          emptyInput = false
          return
        }
      })
      return emptyInput
    }
    return true
  }

  return (
    <FieldArray
      name={inputProps.name}
      render={({ remove, push, replace }) => {
        return <>
          <div className='flex justify-between col-span-2'>
            <div className='relative w-1/2 mb-3'>
              <Text
                tag="p"
                scale={true}
                text={label}
                fontweight="bold"
                alignment="left"
                styles="capitalize"
              />
              <Text
                tag="small"
                scale={true}
                styles={`text-red-600 absolute top-6 2xl:top-5 xl:top-4 lg:top-3 md:top-3 ${errorStyle}`}
                text={typeof errors[inputProps.name] === "string" ? errors[inputProps.name] : ""}
                alignment="left"
              />
            </div>
            <div onClick={() => {
              if (isAddNewBtnEnabled()) {
                push(
                  elements.reduce((acc, el) => {
                    return {
                      ...acc,
                      [el.inputProps.name]: el.initialValue
                    }
                  }, {})
                )
              }
            }}
            >
              <Text
                tag="p"
                scale={true}
                text={addNewText}
                fontweight="bold"
                alignment="left"
                styles={`capitalize ${isAddNewBtnEnabled() ? "text-blue-500 cursor-pointer" : "text-gray-500 cursor-default"}`}
              />
            </div>
          </div>
          {values[inputProps.name]?.map((el, idx) => {
            return <div className={wrapperStyles} key={idx}>
              {elements.map(elem => <Element
                key={elem.key + idx}
                {...elem}
                handleChange={(e) => onHandleChange(e, elem, idx)}
                handleBlur={handleBlur}
                value={el[elem.inputProps.name]}
                errorText={
                  typeof errors[inputProps.name] === "object" ?
                    touched[inputProps.name] &&
                    touched[inputProps.name][idx] &&
                    touched[inputProps.name][idx][elem.inputProps.name] &&
                    errors[inputProps.name][idx] &&
                    errors[inputProps.name][idx][elem.inputProps.name]
                    : ""
                }
                errorStyle={elem.errorStyle ? elem.errorStyle : ""}
                inputProps={{
                  ...omit(elem.inputProps,"onInputChange"),
                  name: `${inputProps.name}.${idx}.${elem.inputProps.name}`,
                  disabled: elem.disableCondition ? elem.disableCondition(values, idx) : false,
                  index: idx
                }}
                disabled={elem.disableCondition ? elem.disableCondition(values, idx) : false}
                inputRefHandler={(inputRef) => {
                  if (inputRef && inputRef.current) {
                    const inpRef = inputRef.current
                    const jdx = inputRefs.current.findIndex(el => (el.row === idx && el.name === elem.inputProps.name));
                    if (jdx === -1) {
                      inputRefs.current.push({ row: idx, name: elem.inputProps.name, ref: inpRef })
                    }
                  }
                }}
              />)}
              <div className={`flex justify-end items-center ${deleteBtnStyle}`}>
                {showClearBtn && <div className={`${checkIfInputsEmpty(idx) ? "text-gray-500 cursor-default" : "text-black cursor-pointer"}`} onClick={() => {
                  if (!checkIfInputsEmpty(idx)) {
                    replace(idx,
                      elements.reduce((acc, el) => {
                        return {
                          ...acc,
                          [el.inputProps.name]: el.initialValue
                        }
                      }, {})
                    )
                    inputRefs.current.filter(el => el.row === idx)?.forEach(el => {
                      el.ref.value = ""
                    })
                  }
                }}>
                  <Text
                    tag="p"
                    scale={true}
                    text={"Reset"}
                    fontweight="bold"
                    alignment="left"
                    styles={`capitalize`}
                  />
                </div>}
                {showDeleteForIdx(values, idx) === idx && <div onClick={() => remove(idx)} className="ml-5">
                  <Text
                    tag="p"
                    scale={true}
                    text={"Delete"}
                    fontweight="bold"
                    alignment="left"
                    styles="capitalize text-red-500 cursor-pointer text-right"
                  />
                </div>
                }
              </div>
            </div>
          })}
        </>
      }}
    />
  )
}

CustomFieldArray.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  elements: PropTypes.array,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  wrapperStyles: PropTypes.string,
  addNewText: PropTypes.string,
  errorStyle: PropTypes.string,
  addNewBtnDisableCondition: PropTypes.func,
  maxLength: PropTypes.number,
  deleteBtnStyle: PropTypes.string,
  showDeleteForIdx: PropTypes.func,
  showClearBtn: PropTypes.bool,
}

export default CustomFieldArray