import escapeHTML from "escape-html";
import _ from "lodash";
import { toQuery } from "lodash-contrib";
import { DateTime } from "luxon";
import { Editor, Node, Text, Transforms } from "slate";
import { addMemberType, memberSubType, notesTypes, someSpecialDaysTypes } from "./enum";
import config from "../config/Config";
import { SessionStorage } from "./Storage";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const COUNT_FORMATS = [
    {
        // 0 - 999
        letter: "",
        limit: 1e3,
    },
    {
        // 1,000 - 999,999
        letter: "K",
        limit: 1e6,
    },
    {
        // 1,000,000 - 999,999,999
        letter: "M",
        limit: 1e9,
    },
    {
        // 1,000,000,000 - 999,999,999,999
        letter: "B",
        limit: 1e12,
    },
    {
        // 1,000,000,000,000 - 999,999,999,999,999
        letter: "T",
        limit: 1e15,
    },
];
export function setActiveItem(arr, index) {
    const myArr = [...arr].map((item, i) => {
        item.active = i === index ? true : false;
        return item;
    });
    return myArr;
}
export const setActiveTab = (arr, index) => {
    return (
        arr &&
        arr.length > 0 &&
        [...arr].map((item, i) => ({
            ...item,
            active: index === i,
        }))
    );
};

export function getUniqueArray(arr) {
    return [...new Set(arr)];
}

export function getActiveItem(arr) {
    return arr.find((item) => item.active);
}

export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const stringInject = (str, arr) => {
    if (typeof str !== "string" || !(arr instanceof Array)) {
        return false;
    }

    // Here just concatenated the url = 'https://abc.com' and arr = ['val1', 'val2'] ====>  https://abc.com/val1/val2
    return str + "/" + arr.join("/");
};

export const urlToQuery = (str, object) => {
    return `${str}?${toQuery(object)}`;
};

export const bgColorTag = (tagname) => {
    let color =
        tagname === "Under Review"
            ? "blue-500"
            : tagname === "Expired"
                ? "red-500"
                : tagname === "Approval Required"
                    ? "orange"
                    : tagname === "Requested Back"
                        ? "maroon"
                        : tagname === "Expiring Soon"
                            ? "yellow-400"
                            : "blue-400";

    return color;
};

export const dateFormat = (date) => {
    const valueDate = new Date(date);
    const newDate = `${valueDate.getDate().toString.length == 1
            ? "0" + valueDate.getDate()
            : valueDate.getDate()
        }/${valueDate.getMonth().toString().length == 1
            ? "0" + valueDate.getMonth()
            : valueDate.getMonth()
        }/${valueDate.getFullYear()}`;
    return newDate;
};

export const getSelectedFolderPath = (folderBreadCrumbs) => {
    const isThreeDots = folderBreadCrumbs.length > 6;
    const isDot = folderBreadCrumbs.length > 4 ? true : false;
    let breadCrumb = [
        {
            folderName: "",
        },
        ...folderBreadCrumbs,
    ];

    if (isThreeDots) {
        breadCrumb = [
            "...",
            ...breadCrumb.slice(-2).map((item) => item.folderName),
        ];
    } else if (isDot) {
        breadCrumb = breadCrumb.map((item, index) => {
            if (index < 3) {
                return "..";
            }
            return item.folderName;
        });
    } else {
        breadCrumb = breadCrumb.map((item) => {
            return item.folderName;
        });
    }
    return folderBreadCrumbs.length > 4
        ? " Root/" + breadCrumb.join("/")
        : " Root" + breadCrumb.join("/");
};

export function messageString(message) {
    return _.isArray(message) ? message.join(".") : message;
}
export const renderAlertStatus = (status, isAdmin) => {
    try {
        if (status === "UNDER_REVIEW") {
            if (isAdmin) return "Approval Required";
            return "Under Review";
        } else {
            if (status) {
                var strArr = status.split("_");
                return strArr
                    .map((status) => {
                        let lower = status.toLowerCase();
                        return lower[0].toUpperCase() + lower.substring(1);
                    })
                    .join(" ");
            }

            return "";
        }
    } catch (err) {
        return status;
    }
};

export const calculateTotalPage = (totalRecord, perPageRecord) => {
    return Math.ceil(totalRecord / perPageRecord);
};

export const activityStatus = {
    UPCOMING: "Upcoming",
    UPDATE_STATUS: "Update Status",
    HELD: "Held",
    CANCELLED: "Cancelled",
    POSTPONED: "Postponed",
    DELETE: "Delete",
};

export const getInputElement = (args) => {
    var input = document.createElement("input");
    if (args.type) {
        input.type = args.type;
    }
    input.name = args.name;
    input.value = args.value || "";
    input.onchange = args.onChange;
    input.onblur = args.onBlur;

    return {
        setValue: (value) => {
            input.value = value;
            const event = new Event("change");
            input.dispatchEvent(event);
        },
        resetValue: () => {
            input.value = "";
            const event = new Event("change");
            input.dispatchEvent(event);
        },
        blurElement: () => {
            const event = new Event("blur");
            input.dispatchEvent(event);
        },
        getValue: () => {
            return input.value;
        },
    };
};

export const getRelativeCalenderDate = (timestamp) => {
    var relativeCalender = DateTime.fromISO(timestamp).toRelativeCalendar();
    if (relativeCalender) {
        return (
            relativeCalender[0].toUpperCase() +
            relativeCalender.substring(1) +
            " " +
            DateTime.fromISO(timestamp).toFormat("hh:mm a")
            // DateTime.fromISO(timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)
        );
    }
    return "";
};

export const formatPhoneNumber = (value) => {
    //Filter only numbers from the input
    if (value === "") return ["-"];
    if (value === "+1 ") return ["", ""];
    if (value?.length === 1) return ["+1 " + value, value];
    if (value && value[0] === "+") {
        value = value.substring(3);
    }
    let cleaned = ("" + value).replace(/\D/g, ""); //remove any other character expect numbers
    let formatPart1 = "";
    if (cleaned.length > 3) {
        const part1 = cleaned.substring(0, 3);
        formatPart1 = `(${part1}) `;
        if (cleaned.length <= 6) {
            const formattedLabel1 = "+1 " + formatPart1 + cleaned.substring(3);
            return [formattedLabel1, cleaned];
        } else {
            let part2 = cleaned.substring(3, 6);
            let formatPart2 = part2 + "-" + cleaned.substring(6, 10);
            const formattedLabel2 = "+1 " + formatPart1 + formatPart2;
            return [formattedLabel2, cleaned];
        }
    }
    const formattedLabel3 = "+1 " + cleaned;
    return [formattedLabel3, cleaned];
};


export const numberFormatter = (label, value) => {
    if (value == "$") return ["", ""];
    // /^[A-Z]+$/i regex expression to match all alphanumeric characters in string
    if (value?.length === 1) return ["$" + value.replace(/^[A-Z]+$/i, ''), value.replace(/^[A-Z]+$/i, '')];
    let cleaned = ("" + value).replace(/\D/g, "");
    let chunk = _.chunk(cleaned.split("").reverse(), 3);
    let str = "";
    chunk.forEach((c) => {
        let arr = c.reverse().join("");
        str = "," + arr + str;
    });
    return ["$" + str?.substring(1), cleaned];
};

export const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
};

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const insertMention = (editor, character, id, type) => {
    const mention = {
        type: "mention",
        character,
        children: [{ text: "" }],
        id,
        ...(type === notesTypes.dashboard ? { clientId: id } : { userId: id })
    };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
};

export const serializeFunc = (node) => {
    const clientIds = [];
    return {
        html: serialize(node, clientIds),
        clientIds,
    };
};

const serialize = (node, clientIds) => {
    if (Text.isText(node)) {
        let string = escapeHTML(node.text);
        if (node.underline) {
            string = `<u>${string}</u>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }

        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        return string;
    }

    if (Array.isArray(node.children)) {
        if (node.type === "mention") {
            const userId = node.userId
            const clientId = node.clientId
            clientIds.push(userId ? userId : clientId);
            return `<a ${userId ? "user" : "client"}-id="${userId ? userId : clientId}">@${node.character} </a>`;
        }
        const children = node.children
            .map((n) => serialize(n, clientIds))
            .join("");
        switch (node.type) {
            case "paragraph":
                return `<p>${children}</p>`;
            default:
                return children;
        }
    }
};

export const plainSerialize = (nodes) => {
    return nodes.map((n) => Node.string(n)).join("\n");
};

export const deserialize = (el, state = {}) => {
    if (el.nodeType === 3) {
        if (state.mention) return el.textContent;
        return {
            text: el.textContent,
            ...state,
        };
    } else if (el.nodeType !== 1) {
        return null;
    }

    let newState;
    if (el.nodeName === "STRONG") {
        newState = { ...state, bold: true };
    }

    if (el.nodeName === "EM") {
        newState = { ...state, italic: true };
    }

    if (el.nodeName === "U") {
        newState = { ...state, underline: true };
    }

    if (el.nodeName === "A") {
        newState = { ...state, mention: true };
    }

    let children = _.flattenDeep(
        Array.from(el.childNodes).map((ch) => {
            return deserialize(ch, newState);
        })
    );

    if (children.length === 0) {
        children = [{ text: "" }];
    }

    switch (el.nodeName) {
        case "BODY":
            return children;
        case "P":
            return {
                type: "paragraph",
                children: children,
            };
        case "STRONG":
        case "EM":
        case "U":
            return children;
        case "A": {
            const clientId = el.hasAttribute("client-id") ? el.getAttribute("client-id") : ""
            const userId = el.hasAttribute("user-id") ? el.getAttribute("user-id") : ""

            return {
                type: "mention",
                character: children[0]?.slice(1),
                children: [{ text: "" }],
                id: el.id && el.id !== "undefined" ? el.id : "",
                ...(clientId ? { clientId: clientId } : {}),
                ...(userId ? { userId: userId } : {}),
            };
        }
        default:
            return el.textContent;
    }
};

export const getVirtualElement = function (args) {
    var { name, handleChange, handleBlur, type } = args;

    function getEvent() {
        let event = {};
        event.target = {
            type,
            name,
        };
        return event;
    }

    function setValue(value) {
        const event = getEvent();
        event.target.value = value;
        handleChange(event);
    }

    function blurElement() {
        const event = getEvent();
        if (handleBlur) {
            handleBlur(event);
        }
    }

    function resetValue() {
        const event = getEvent();
        event.target.value = "";
        handleChange(event, "");
    }

    return {
        setValue,
        blurElement,
        resetValue,
    };
};

export const encodeSSN = (ssn) => {
    if (!ssn) return "";
    var length = ssn.length;

    var starting = length - 4;
    return Array(starting).fill("X").join("") + ssn.substring(starting);
};

export const getDiffYears = (timestamp) => {
    var current = DateTime.now();
    var diff = current.diff(DateTime.fromISO(timestamp), ["days", "years"]);
    return diff.toObject();
};

export const getFilterRange = () => { };

export const getTasksAccordingToRange = (tasks) => {
    let overdueTask = [];
    let todaysTask = [];
    let tomorrowTask = [];
    let upcomingTask = [];
    let completedLast14Days = [];

    var currentDateTime = DateTime.now().toFormat("yyyy-MM-dd");
    var currentDate = DateTime.fromISO(currentDateTime);
    var tomorrowDate = currentDate.plus({ days: 1 });
    var last14DaysDate = currentDate.minus({ days: 14 });

    tasks.forEach((task) => {
        let comparedDate = DateTime.fromISO(task.date);
        if (task.isCompleted) {
            completedLast14Days.push(task);
        } else if (comparedDate < currentDate) {
            if (!task.isCompleted) overdueTask.push(task);
            else if (comparedDate >= last14DaysDate) {
                //fix for not showing tasks after 14 days completion
                completedLast14Days.push(task);
            }
            /*else {
                   completedLast14Days.push(task);
               }*/
        } else if (comparedDate > currentDate) {
            if (comparedDate > tomorrowDate) {
                //upcomingTask.push(task);
                if (!task.isCompleted) upcomingTask.push(task);
                else if (comparedDate <= last14DaysDate || task.isCompleted) {
                    completedLast14Days.push(task);
                }
            } else {
                //tomorrowTask.push(task);
                if (!task.isCompleted) tomorrowTask.push(task);
                else if (comparedDate <= last14DaysDate || task.isCompleted) {
                    completedLast14Days.push(task);
                }
            }
        } else {
            //todaysTask.push(task);
            if (!task.isCompleted) todaysTask.push(task);
            else if (comparedDate <= last14DaysDate || task.isCompleted) {
                completedLast14Days.push(task);
            }
        }
    });

    // upcomingTask.sort((a, b) => {
    //     let date1 = DateTime.fromISO(a.createdAt);
    //     let date2 = DateTime.fromISO(b.createdAt);
    //     if (date1.startOf("millisecond") <= date2.startOf("millisecond")) return 1;
    //     return -1;
    // });

    return {
        overdueTask,
        todaysTask,
        tomorrowTask,
        upcomingTask,
        completedLast14Days,
    };
};

export function getTransformedObject(obj) {
    return Object.keys(obj).reduce((acc, key) => {
        var value = obj[key];
        if (_.isPlainObject(value)) {
            return { ...acc, [key]: getTransformedObject(value) };
        }
        if (value === "") {
            value = null;
        }
        return { ...acc, [key]: value };
    }, {});
}

export const memberSorting = (arr) => {
    var type = [
        addMemberType.PERSON,
        addMemberType.PET,
        addMemberType.TRUST,
        addMemberType.BUSINESS,
    ];

    var subType = [
        memberSubType.Primary,
        memberSubType.Secondary,
        memberSubType.None,
    ];

    arr.sort((obj1, obj2) => {
        var type1 = obj1.type;
        var type2 = obj2.type;

        return type.indexOf(type1) - type.indexOf(type2);
    });

    arr.sort((obj1, obj2) => {
        var subType1 = obj1.subType;
        var subType2 = obj2.subType;

        var subTypeIndex1 = subType.indexOf(subType1);
        var subTypeIndex2 = subType.indexOf(subType2);

        if (subTypeIndex1 !== -1 && subTypeIndex2 !== -1) {
            return subTypeIndex1 - subTypeIndex2;
        }
        if (subTypeIndex1 !== -1) return -1;
        if (subTypeIndex2 !== -1) return 1;

        if (obj1.type === "PET") return -1;
    });

    return arr;
};

// function to format client name (adding Family text)
export const formatClientName = (name) => {
    let clientTitle;
    const nameArray = name.split(/(\s+)/);
    if (nameArray.length > 1) {
        clientTitle = nameArray[nameArray.length - 1] + " Family- " + name;
    } else {
        clientTitle = name + " Family- " + name;
    }

    return clientTitle;
};

// Format client address
export const formatClientAddress = (addressInfo) => {
    let clientAddress = "";
    let stateToDisplay;
    if (
        (addressInfo.address && addressInfo.address.trim().length > 0) ||
        (addressInfo.addressLine2 &&
            addressInfo.addressLine2.trim().length > 0) ||
        (addressInfo.addressLine3 &&
            addressInfo.addressLine3.trim().length > 0) ||
        (addressInfo.city && addressInfo.city.trim().length > 0)
    )
        stateToDisplay = `, ${addressInfo.state?.value === undefined ? addressInfo.state : addressInfo.state?.abbrevation  //state.value??? issue fix
            }`;
    else stateToDisplay = addressInfo.state?.value === undefined ? addressInfo.state : addressInfo.state?.value;  //state.value??? 

    clientAddress =
        (addressInfo.address ? addressInfo.address : "") +
        (addressInfo.addressLine2 ? ` ${addressInfo.addressLine2}` : "") +
        (addressInfo.addressLine3 ? ` ${addressInfo.addressLine3}` : "") +
        (addressInfo.city ? ` ${addressInfo.city}` : "") +
        (addressInfo.state ? `${stateToDisplay} ` : "") +
        (addressInfo.zip ? addressInfo.zip : "");

    return clientAddress;
};

// Format SSN Number
export const formatSSN = (ssn, format = "xxx-xx-xxxx") => {
    // let validateRegex = new RegExp(
    //     "^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$"
    // );
    if (!ssn) return "";
    var val = ssn.replace(/[^\d-]/g, "");
    if (format === "xx-xxxxxxx") {
        val = val.replace(/^(\d{2})-?(\d{1,2})/, "$1-$2");
        val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, "$1-$2-$3");
        // let myRegex = validateRegex.test(val);
        // if (myRegex) {
        val = val
            .split("")
            .filter((val, idx) => {
                return val !== "-" || idx === 2 || idx === 7;
            })
            .join("");
        // }
    } else {
        // val = val.replace(/^(\d{3})-?(\d{1,2})/, "$1-$2");
        val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, "$1-$2-$3");
        // let myRegex = validateRegex.test(val);
        // if (myRegex) {
        val = val
            .split("")
            .filter((val, idx) => {
                return val !== "-" || idx === 3 || idx === 6;
            })
            .join("");
        // }
    }
    return val.substring(0, 11);
};

// Replace underscore ("_") with space (" ")
export const RemovedUnderscore = (str) => {
    var ele = str.split("_");
    for (var i = 0; i < ele.length; i++) {
        ele[i] = ele[i].charAt(0).toUpperCase() + ele[i].slice(1);
    }
    return ele.join(" ");
};

// Get position of hamburger menu
export const getPosDropdown = (
    eleId,
    containerId,
    menuHeight,
    headerHeight
) => {
    const main = document.getElementById(eleId)?.offsetTop;
    const containerHeight = document.getElementById(containerId).scrollHeight;
    let pos = 0;
    if (menuHeight > containerHeight + headerHeight - main) {
        pos = -1 * (menuHeight) + "px";
    }
    return pos;
};


// Get Count Format in ("K, B, M, T") eg. 1000 = 1K

export const formatTotal = (value = 0) => {
    const format = COUNT_FORMATS.find(
        (format) => Math.abs(value) < format.limit
    );
    value = (1000 * value) / format.limit;
    //value = Math.round(value * 100) / 100; // keep one decimal number, only if needed
    value = Math.floor(value * 100) / 100;
    return value + format.letter;
}

// Only for number type in input 

export const onlyNumbers = (evt) => {
    if (evt.keyCode != 8) {
        var regex = new RegExp("^[0-9]+$");
        if (!regex.test(evt.key)) {
            evt.preventDefault();
            return false;
        }
    }
}

// Dollor formatter

export const formatDollar = (num, isDollar = true) => {
    const p = num.toFixed(2).split(".");
    const dollarText = "$";
    const formatter = p[0].split("").reverse().reduce(function (acc, num, i, orig) {
        return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
    return isDollar ? (`${dollarText} ${formatter}`) : formatter;
}

export const nullCheck = (value) => ['', undefined, null].includes(value)

// First Uppercase Letter and rest Lowercase

export const capitalizeStr = (str) => str[0].toUpperCase() + str.slice(1).toLowerCase()

export const capitalizeFirstCharOfEveryWord = (str) => {
    return str?.split(" ").map(st => {
        if (st && st.length > 0) {
            return capitalizeStr(st)
        }
        return st
    }).join(" ")
}

export const formatNumber = (value) => {
    if (!value) { return "" } else {
        let specialCh = value.replace(/[^0-9.,]/g, '')
        if (!specialCh) {
            return ""
        }

    }

    if (value.includes('$') && value.length > 1) {
        value = value.substring(1)

    } else if (value.includes('$') && value.length === 1) {
        return value
    }
    if (value.includes(".")) {
        let splittedArray = value.split(".")
        let clean = splittedArray[0].replace(/,/g, "")
        let leftFormatted = new Intl.NumberFormat().format(parseFloat(clean));

        let rightFormatted = "";
        if (splittedArray[1] !== "") {
            if (splittedArray[1].length > 2) {
                let getTwo = splittedArray[1].substring(0, 2)
                splittedArray[1] = getTwo
            }
            rightFormatted = '.' + splittedArray[1]
        } else {
            rightFormatted = '.'
        }
        return "$" + leftFormatted + rightFormatted
    }
    else {
        let clean = value.replace(/[,$]/g, "")
        if (clean === "") {

            return value
        }
        return "$" + new Intl.NumberFormat().format(parseFloat(clean));
    }
};
// Round off number

export const roundOff = (value, precision) => {
    const x = Math.pow(10, precision);
    return '$' + (Math.round(value * x) / x).toLocaleString("en-US");
};

// Locale Number Formatter

export const localeNumberFormatter = (style = 'currency', currency = 'USD', minimumFractionDigits = 0, value) => (new Intl.NumberFormat('en-US', { style, currency, minimumFractionDigits }).format(Number(value)))

// Check if setSelectionInput
export const setSelectionNotAvailable = (value) => ['email', 'color', 'number'].includes(value)

export const toFixed2Decimal = (value) => {
    value = value.replace(/[^0-9.]/g, '')
    let splittedArray = ("" + value).split(".")

    if (splittedArray.length <= 1) {
        return value
    }

    let rightFormatted = "";
    if (splittedArray[1] !== "") {
        if (splittedArray[1].length > 2) {
            let getTwo = splittedArray[1].substring(0, 2)
            splittedArray[1] = getTwo
        }
        rightFormatted = '.' + splittedArray[1]
    } else {
        rightFormatted = '.'
    }
    return splittedArray[0] + rightFormatted
}

export const generateColorShades = () => {
    const sMin = 45;
    const sMax = 90;

    const lMin = 30;
    const lMax = 75;

    const step = 5;

    const arr = []
    const tmpArr = []

    for (let s = sMin; s <= sMax; s += step) {
        const tmp = []
        for (let l = lMin; l <= lMax; l += step) {
            tmp.push({
                saturation: s,
                lightness: l
            })
        }
        tmpArr.push(tmp)
    }

    let x = tmpArr.length - 1
    while (x >= 0) {
        let i = x, j = 0
        while (i < tmpArr.length) {
            arr.push(tmpArr[i][j])
            i++;
            j++;
        }
        x--;
    }

    let y = 1;
    while (y < tmpArr[0].length) {
        let j = y, i = 0
        while (j < tmpArr[0].length) {
            arr.push(tmpArr[i][j])
            i++;
            j++
        }
        y++
    }

    return arr
}
export const getDeepClone = (param) => JSON.parse(JSON.stringify(param))

export function replaceAllObjValues(obj, valueToAssign) {
    return Object.keys(obj).reduce((acc, key) => {
        var value = obj[key];
        if (Array.isArray(value)) {
            return {
                ...acc,
                [key]: value.map(val => replaceAllObjValues(val, valueToAssign))
            }
        }
        if (_.isPlainObject(value)) {
            return { ...acc, [key]: replaceAllObjValues(value, valueToAssign) };
        }

        value = valueToAssign;
        return { ...acc, [key]: value };
    }, {});
}

export const memberName = (members = []) => {
    const primaryMember = members?.find(el => el.subType === memberSubType.Primary)
    const secondaryMember = members?.find(el => el.subType === memberSubType.Secondary)
    const pName = primaryMember ? `${_.upperFirst(primaryMember.lastName)}: ${_.upperFirst((primaryMember.nickName ? primaryMember.nickName : primaryMember.firstName))}` : ""
    const sName = secondaryMember ? " & " + _.upperFirst((secondaryMember.nickName ? secondaryMember.nickName : secondaryMember.firstName)) : ""
    let clientMemberName = pName + sName;
    return clientMemberName
}

export const parseAndChangeNameInNote = (noteObj) => {
    const parser = new DOMParser();
    const note = noteObj.note;
    const doc = parser.parseFromString(note, "text/html");
    const body = deserialize(doc.body);
    const mentionedClients = body[0].children.filter(c => (c.type === "mention" && c.clientId))
    mentionedClients?.map((c, idx) => {
        const index = noteObj.clients.findIndex(cl => cl.id === c.clientId)
        if (index >= 0) {
            const members = noteObj.clients[index]?.members
            const newName = memberName(members)
            c.character = newName;
            noteObj.clients[index].tagIndex = (idx + 1)
        }
        return c;
    })
    const idx = noteObj.clients.findIndex(cl => !cl.tagIndex)
    if (idx >= 0) {
        noteObj.clients[idx].tagIndex = 0
    }
    const { html } = serializeFunc({
        children: body,
    })
    noteObj.note = html
    return noteObj
}

export const fetchUsers = (searchParam, successFn, errorFn, fetchRequest) => {
    let roleId = [3];
    const search = {
        limit: 10,
        page: 1,
        sortBy: "createdAt",
        order: "DESC",
        invitationAccepted: true,
        exceptRoleId: JSON.stringify(roleId),
        ...searchParam,
    };
    fetchRequest({}, "get", "user", null, search).then(successFn).catch(errorFn)
}
export const checkDuplicateSpecialDate = (data, specialDays) => {
    let idxOfType = specialDays.findIndex(el => {
        if ((data.id && el.id !== data.id) || !data.id) {
            return (el.dateType === data.dateType && el.member.id === data.member)
        }
        return false
    })
    if (idxOfType > -1) {
        return true
    }
    return false
}

export const detectFormDataChange = (values, initialValues) => {
    let formChanged = false;
    Object.keys(values).forEach(key => {
        const val1 = initialValues[key]
        const val2 = values[key]
        if (typeof val2 === "string") {
            if (val1 && val2) {
                if (typeof val1 === "object") {
                    if (val1.value != val2) {
                        formChanged = true
                        return
                    }
                } else if (val1 != val2) {
                    formChanged = true
                    return
                }
            }
        } else if (typeof val2 === "boolean" && typeof val1 === "boolean" && val2 != val1) {
            formChanged = true
            return
        } else if (typeof val2 === "number") {
            if (typeof val1 === "object") {
                if (val1 && val1.value != val2) {
                    formChanged = true
                    return
                }
            } else if (val1 != val2) {
                formChanged = true
                return
            }
        }
    })
    return formChanged
}


export const fetchNumber = (phoneNumber, setFieldError, name, fetchRequest, setIsNumber, dHToast, faExclamationCircle, index) => {
    fetchRequest({ phoneNumber }, "post", "addClientPhone").then(res => {   
      if(res.error){
        setIsNumber(prev => {
            return {
              ...prev,
                [index]: false,
              
            };
          });
        return;
      }
        setFieldError(name, "This phone number is already in use. Enter another number.")
        setIsNumber(prev => {
            return {
              ...prev,
              [index]: true,
            };
          });
        dHToast(true, "This phone number is already in use. Enter another number.", "rt", "error", faExclamationCircle);
    },
    (err)=>{
        dHToast(true, err?.message, "rt", "error", faExclamationCircle);
    })
    .catch((err) => {
        dHToast(true, err?.message, "rt", "error", faExclamationCircle);
  })
  }

export const generateClientDetailURL = ({ clientId, tab = "highlights", leftPanel = "notes" }) => {
    return `/client-detail/${clientId}?tab=${tab}${tab === "highlights" ? "&leftPanel=" + leftPanel : ""}`
}
export const generateUnitsDetailURL = ({ clientId, tab = "0"}) => {
    return `/billing/households/${clientId}?tab=${tab}`
}
export const generateAccountDetailURL = ({ clientId, tab = "0", accountId }) => {
    return `/billing/accounts/${accountId}?clientId=${clientId}&tab=${tab}`
}
export const checkClientAnniversary = (type) => {
    if ([someSpecialDaysTypes[type]].includes(someSpecialDaysTypes.CLIENT_ANNIVERSARY)) {
        return true
    }
    return false
}

export const createResizableColumn = (col, nextCol, resizer, setColWidths, idx, setDragging, colMinWidth = 5) => {
    let x = 0;
    let currColWidth = 0
    let nextColWidth = 0

    const mouseDownHandler = function (e) {
        e.preventDefault()
        e.stopPropagation()
        x = e.clientX;

        currColWidth = col.style.width;
        nextColWidth = nextCol.style.width;

        currColWidth = parseFloat(currColWidth);
        nextColWidth = parseFloat(nextColWidth);

        resizer.classList.add('resizing');

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
        const dx = (e.clientX - x);

        const newCurrWidth = currColWidth + dx
        const newNextWidth = nextColWidth - dx

        if (setDragging) {
            setDragging(true)
        }

        if (newCurrWidth > colMinWidth && newNextWidth > colMinWidth) {
            setColWidths(prev => {
                const newArr = [...prev]
                newArr[idx] = +newCurrWidth.toFixed(3)
                newArr[idx + 1] = +newNextWidth.toFixed(3)
                return newArr;
            })
        }
        document.querySelector("body").classList.add("ew-resize")
    };

    const mouseUpHandler = function (e) {
        e.preventDefault()
        e.stopPropagation()
        document.querySelector("body").classList.remove("ew-resize")

        resizer.classList.remove('resizing');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    resizer.addEventListener('mousedown', mouseDownHandler);
    resizer.addEventListener('click', e => {
        e.stopPropagation()
        e.preventDefault()
    });
}

export const getUpdatedMemberDisplaySeq = (currMemberNewDisplaySeq, memberDisplaySeq, subType, selectedMember) => {
    const updatedMemberDisplaySeq = _.cloneDeep(memberDisplaySeq)
    switch (currMemberNewDisplaySeq) {
        case 1: {
            const prevPrimaryIdx = updatedMemberDisplaySeq.findIndex(el => el.subType === memberSubType.Primary)
            if (!selectedMember) {
                // move previous primary member to last position
                if (prevPrimaryIdx > -1) {
                    updatedMemberDisplaySeq[prevPrimaryIdx].displaySequence = updatedMemberDisplaySeq.length + 1
                }
            }
            else {
                const currMemIdx = updatedMemberDisplaySeq.findIndex(el => el.id === selectedMember.id)
                // move all members after this member by one position up
                for (let i = currMemIdx + 1; i < updatedMemberDisplaySeq.length; i++) {
                    updatedMemberDisplaySeq[i].displaySequence -= 1
                }
                if (prevPrimaryIdx > -1) {
                    updatedMemberDisplaySeq[prevPrimaryIdx].displaySequence = updatedMemberDisplaySeq.length
                }
                updatedMemberDisplaySeq[currMemIdx].displaySequence = 1
            }
            break;
        }
        case 2: {
            if (updatedMemberDisplaySeq.length < 2) {
                break
            }
            if (subType !== memberSubType.Secondary) {
                for (let i = 2; i < updatedMemberDisplaySeq.length; i++) {
                    updatedMemberDisplaySeq[i].displaySequence -= 1
                }
                updatedMemberDisplaySeq[1].displaySequence = updatedMemberDisplaySeq.length
                break
            }
            const prevSecondaryIdx = updatedMemberDisplaySeq.findIndex(el => el.subType === memberSubType.Secondary)
            if (prevSecondaryIdx === -1) {
                if (!selectedMember) {
                    for (let i = 1; i < updatedMemberDisplaySeq.length; i++) {
                        updatedMemberDisplaySeq[i].displaySequence += 1
                    }
                }
                else {
                    const currMemIdx = updatedMemberDisplaySeq.findIndex(el => el.id === selectedMember.id)
                    // move all members before this member by one position down
                    for (let i = 1; i < currMemIdx; i++) {
                        updatedMemberDisplaySeq[i].displaySequence += 1
                    }
                    updatedMemberDisplaySeq[currMemIdx].displaySequence = 2
                }
            }
            else {
                if (!selectedMember) {
                    updatedMemberDisplaySeq[prevSecondaryIdx].displaySequence = updatedMemberDisplaySeq.length + 1
                }
                else {
                    const currMemIdx = updatedMemberDisplaySeq.findIndex(el => el.id === selectedMember.id)
                    // move all members after this member by one position up
                    for (let i = currMemIdx + 1; i < updatedMemberDisplaySeq.length; i++) {
                        updatedMemberDisplaySeq[i].displaySequence -= 1
                    }
                    updatedMemberDisplaySeq[prevSecondaryIdx].displaySequence = updatedMemberDisplaySeq.length
                    updatedMemberDisplaySeq[currMemIdx].displaySequence = 2
                }
            }
            break
        }

        default:
            break
    }

    return updatedMemberDisplaySeq
}

export const getInitialTabs = (arr, memberType) => {
    if (memberType === addMemberType.TRUST) {
        arr = arr.filter(el => (el.key !== "identity" && el.key !== "employment"))
    }
    else if (memberType === addMemberType.BUSINESS) {
        arr = arr.filter(el => (el.key !== "identity"))
    }
    return setActiveItem(arr, 0)
}

export const checkForEmptyAddresses = (addrArr) => {
    return addrArr.filter(addr => {
        if (addr.addressLine1 === ""
            && addr.addressLine2 === ""
            && addr.city === ""
            && addr.state === ""
            && addr.zip === ""
        ) {
            return false
        }
        return true
    })
}

export const checkForEmptyPhone = (phoneArr) => {
    return phoneArr.filter(ph => {
        if (ph.phone === "") {
            return false
        }
        return true
    })
}

export const checkForEmptyEmail = (emailArr) => {
    return emailArr.filter(em => {
        if (em.email === "") {
            return false
        }
        return true
    })
}

export const uploadBlobFile = (blob, fileName,  dHToast, fetchRequest) => {
    const formData = new FormData();
    formData.append("file", blob, fileName);

  return  fetchRequest(formData, "file", "fileAttachment")
        .then((res) => {
            // setFieldValue("profileImageUrl", res?.result?.attachmentUrl);
            return res?.result?.attachmentUrl
        })
        .catch((err) => {
            dHToast(true, err.message, "rt", "error", faExclamationCircle);
            return null
        });
};

export const getClientLinkedinUrl = async (url, setFieldValue, dHToast, setBlobObject) => {
    try {
        const params = {
            profileUrl: url,
        };

        let urlNew = `${config.get("base")}${config.get("clientLinkedinImage")}`;
        urlNew = urlToQuery(urlNew, params);

        const blobFileName = url.substring(url.lastIndexOf("/in/") + 4).replace('/', '');

        const user = SessionStorage.getObj("user");
        const response = await fetch(urlNew, {
            headers: {
                Authorization: `Bearer ${user.accessToken}`,
            },
        });
        if (response.status === 200) {
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);            
            setFieldValue("profileImageUrl", objectURL);
            const newBlobObject = {
                blob: blob,
                blobFileName: blobFileName
              };
            
              setBlobObject(newBlobObject);
        } else {
            const errorResponse = await response.json();
            const errorMessage = errorResponse.message;
            dHToast(true, errorMessage, "rt", "error", faExclamationCircle);
        }
    } catch (error) {
        dHToast(true, error?.message, "rt", "error", faExclamationCircle);
    }
};

export const getTurningText = (date) => {
    const { years, days } = getDiffYears(date)
    if (years == 0) {
        return "Turning 1"
    } else if (days <= 30) {
        return "Turned " + (years)
    }
    return "Turning " + (years + 1)
}

export const extractDateRange = (str, dHToast) => {
    const rangeRegex = /(\d{1,2}[-/]\d{1,2}[-/]\d{4})\s*-\s*(\d{1,2}[-/]\d{1,2}[-/]\d{4})/g;
    const dateRegex = /(\d{1,2}[-/]\d{1,2}[-/]\d{4})/g;
    const arr = str?.match(rangeRegex)
    if (arr?.length) {
        const dateArr = arr[0].match(dateRegex)
        if (dateArr?.length > 1) {
            const stD = new Date(dateArr[0])
            const enD = new Date(dateArr[1])
            if (stD.toString() != "Invalid Date" && enD.toString() != "Invalid Date" && stD < enD) {
                return [stD, enD]
            } else {
                dHToast(true, "Please enter a valid date range.", "rt", "error", faExclamationCircle);
            }
        }
    }
    return [null, null]
}
