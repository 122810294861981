import { UPDATE_MASTER_DATA, UPDATE_SPECIFIC_MASTER_DATA } from "./master-data.constant"

export const updateMasterData = (data) => {
  return {
    type: UPDATE_MASTER_DATA,
    data: data,
  }
}

export const updateSpecificKeyInMasterData = (key, data) => {
  return {
    type: UPDATE_SPECIFIC_MASTER_DATA,
    key,
    data,
  }
}