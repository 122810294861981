import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import AutoLogoutTimer from "../hooks/AutoLagoutTimer";
import { useSelector } from "react-redux";
import Unauthorized from "../components/Unauthorized";

const pathPermissionKeyMap = {
  '/waprofile/:id': 'PERSONAL_DETAILS',
  '/dashboard': 'DASHBOARD',
  '/clients': 'GENERAL',
  '/client-detail/:id': 'GENERAL',
  '/prospects': 'PROSPECTS',
  '/alerts': 'ALERT',
  '/open-requests': 'OPEN_WORKFLOWS',
  '/create-request-manage-workflows': 'MANAGE_WORKFLOWS',
  '/products': 'PRODUCTS',
  '/billing': 'UNITS',
  '/billing/households': 'UNITS',
  '/billing/households/:id': 'UNITS',
  '/billing/accounts': 'ACCOUNTS',
  '/billing/accounts/:id': 'ACCOUNTS',
  '/billing/fee': 'FEE_SCHEDULES',
  '/billing/cashflows': 'CASHFLOWS',
  '/billing/billingInstances': 'BILLING_INSTANCES',
  '/billing/receivables': 'RECEIVABLES',
  '/billing/alllogs': 'LOGS',
  '/users': 'USERS',
  '/roles': 'ROLES',
  '/teams': 'TEAMS',
  '/master-data/receivable-products': "Receivable_Products",
  '/master-data/states': "STATES",
  '/master-data/strategy-types': "STRATEGY_TYPES",
  '/master-data/workflow-types': "WORKFLOW_TYPES",
  '/master-data/security-type': "SECURITY_TYPES",
  '/master-data/registration-type': "REGISTRATION_TYPE",
  '/master-data/country':"COUNTRY",
  '/master-data/sexual-orientation':"SEXUAL_ORIENTATION",
  '/master-data/political-party':"POLITICAL_PARTY",
  '/master-data/sources':"SOURCES",
  '/master-data/specific-interest':"SPECIFIC_INTEREST",
  '/master-data/gender':"GENDER",
  '/master-data/marriage-status':"MARRIAGE_STATUS",
  '/master-data/:type':"COMMON_MASTER_DATA",
  '/master-data/religious-affiliations':"Religious_Affiliations",
  '/master-data/industry':"industry",
  '/master-data/providers':"providers",
  '/otherProfessionals':"OTHER_PROFESSIONAL",
}

const ProtectedRoute = ({ component: Component, user, path, ...rest }) => {
  const permission = useSelector(
    ({ loggedInUser }) => loggedInUser.loggedInUser.userPermission, _.isEqual);

  const checkRouteAuth = () => {
    let featureVal = permission?.find(obj => {
      return obj.permission.feature.toUpperCase() === pathPermissionKeyMap[path].toUpperCase();
    });
    if (featureVal) {
      return featureVal.read
    }
    return true
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!_.isEmpty(user)) {
          return (checkRouteAuth() ?
            <AutoLogoutTimer
              ComposedClass={Component} {...rest} {...props} /> :
            <Unauthorized />)
        } else {
          return (
            <Redirect
              to="/"
            />
          );
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  user: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};



export default ProtectedRoute;

