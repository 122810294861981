import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import getRequest from "../../../../network/getRequest";
import { UserContext } from "../../../../contextAPI";
import { isEmpty } from "lodash";
import Button from "../../../../commonComponent/Button";
import InputText from "../../../../commonComponent/InputText";
import Image from "../../../../commonComponent/Image";
import { Document, Page, pdfjs } from "react-pdf";
import Text from "../../../../commonComponent/Text";
import { messageString } from "../../../../utils/UtilsFunc";
import { Tooltip } from "react-tippy";
import { faCheckCircle, faExclamationCircle, faSearchPlus, faSearchMinus, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FilePriview = ({
    file = null,
    updateList = null,
    close = null,
    showActions = false,
    userId = null,
    hasFileUrl = false,
}) => {
    const { fetchRequest } = getRequest();
    const [fileUrl, setFileUrl] = useState();
    const [note, setNote] = useState(file?.note);
    const [error, setError] = useState("");
    const { dHToast, userDetails } = useContext(UserContext);
    const [blob, setBlob] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scalePdf, setScalePdf] = useState(1.4)

    var isAdmin = userDetails?.role?.role === "ADMIN";

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const getFileUrl = () => {
        let param = [userId];
        let search = {
            id: file.documentId,
        };
        fetchRequest(null, "get", "filePreviewUrl", param, search).then(
            (res) => {
                if (!isEmpty(res.result)) {
                    fetch(res.result.signedUrl)
                        .then((res) => res.blob())
                        .then((data) => {
                            setFileUrl(res.result);
                            setBlob(URL.createObjectURL(data));
                        });
                }
            }
        ).catch((err) => {
            dHToast(true, err?.message, "rt", "error", faExclamationCircle);
        });
    };
    const fileAction = (status) => {
        if (!isAdmin || !showActions) return;
        const body = {
            id: file.documentId,
            note: note,
        };
        if (status) {
            body.status = status;
        }
        fetchRequest(body, "put", "approve").then(
            (res) => {
                if (!isEmpty(res.result) && res.statusCode !== "400") {
                    dHToast(true, messageString(res.message), "rt", "success", faCheckCircle);
                    updateList();
                    close(false);
                } else {
                    dHToast(true, res.message, "rt", "error", faExclamationCircle);
                }
            },
            (err) => {
                dHToast(true, err.message, "rt", "error", faExclamationCircle);

            }
        ).catch((err) => {
            dHToast(true, err?.message, "rt", "error", faExclamationCircle);
        });
    };

    useEffect(() => {
        if (hasFileUrl) {
            fetch(file.signedUrl)
                .then((res) => res.blob())
                .then((data) => {
                    setFileUrl(file);
                    setBlob(URL.createObjectURL(data));
                });
        }
        else {
            getFileUrl();
        }
    }, []);

    const handleChange = (name, value) => {
        setNote(value);
    };

    function Loader() {
        return (
            <div className="h-96 flex justify-center items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (!blob) {
        return <Loader />;
    }

    return (
        <div className="p-6">
            <div className="border-b-2 border-gray-200 flex justify-between w-full">


                <Tooltip
                    position="top"
                    trigger="mouseenter"
                    html={
                        <p className="w-full break-all px-2 pb-2 pt-0.5">
                            {fileUrl.documentName}
                        </p>
                    }
                    className="w-full pr-8"
                >
                    <Text
                        tag="span"
                        scale={true}
                        //text={item.title}
                        text={
                            <p className="text-base text-black-900 font-bold pb-4 truncate ">
                                Compliance Document: {fileUrl.documentName}

                            </p>
                        }

                        fontweight="bold"
                        alignment="left"
                        truncate={true}
                    />
                </Tooltip>

                <a href={fileUrl.signedUrl}><FontAwesomeIcon className="mt-0.5 absolute right-7 cursor-pointer" icon={faDownload} /></a>

            </div>
            <div className="flex flex-col mt-5 bg-white h-96 overflow-y-auto">
                {fileUrl.documentType === "pdf" ? (
                    <Tooltip
                        position="top"
                        trigger="mouseenter"
                        multiple={true}
                        html={
                            <div className="px-3 py-2 flex">
                                <FontAwesomeIcon
                                    onClick={() => {
                                        setScalePdf(prevState => prevState <= 1 ? 1.0 : +((prevState - 0.1).toFixed(1)))
                                    }}
                                    className={`mx-3 ${scalePdf <= 1 ? "text-gray-400 cursor-auto" : "text-white cursor-pointer"}`}
                                    size="lg"
                                    icon={faSearchMinus}
                                />
                                <FontAwesomeIcon
                                    onClick={() => {
                                        setScalePdf(prevState => prevState >= 2.0 ? 2.0 : +((prevState + 0.1).toFixed(1)))
                                    }}
                                    className={`mx-3 ${scalePdf >= 2 ? "text-gray-400 cursor-auto" : "text-white cursor-pointer"}`}
                                    size="lg"
                                    icon={faSearchPlus}
                                />
                            </div>
                        }
                        hideOnClick={"persistent"}
                        interactive
                        distance={-10}
                        className="h-full"
                    >
                        <div className="h-full overflow-y-auto">
                            <Document
                                file={blob}
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={Loader}
                                renderMode="canvas"
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} scale={scalePdf} pageNumber={index + 1} />
                                ))}
                            </Document>
                        </div>
                    </Tooltip>
                ) : (
                    <Image img={blob} alt="file" imgClass="w-full" />
                )}
            </div>

            {
                ((isAdmin && showActions) || note) && (
                    <div>
                        <InputText
                            name="note"
                            placeholder="Add Note"
                            type="text"
                            width="36"
                            classStyle="pl-10"
                            bgColor="gray-100"
                            handleChange={handleChange}
                            value={note}
                            required={true}
                            errorStyle="-bottom-3"
                            validatorType="all"
                        />
                    </div>
                )
            }

            {
                isAdmin && showActions && (
                    <React.Fragment>
                        <div className="flex items-center my-2 justify-end">
                            <div className="w-1/2">
                                {error && (
                                    <Text
                                        tag="small"
                                        scale={true}
                                        text={error}
                                        alignment="left"
                                        styles="my-2 text-red-400"
                                    />
                                )}
                            </div>
                            <div className="flex flex-row justify-end w-1/2 w-full">
                                <Button
                                    text="Request Again"
                                    type="button"
                                    width="32"
                                    btnColor="bg-white"
                                    rounded="rounded"
                                    color="black"
                                    action={() => fileAction("REQUESTED_AGAIN")}
                                    styles="active:bg-blue-900 mx-2 flex items-center justify-around p-2"
                                    disabled={!note}
                                />
                                <Button
                                    text="Approve"
                                    type="button"
                                    width="32"
                                    btnColor="bg-white"
                                    rounded="rounded"
                                    color="black"
                                    action={() => fileAction("APPROVED")}
                                    styles="active:bg-blue-900 mx-2 flex items-center justify-around p-2 text-blue-900"
                                    disabled={!note}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        </div >
    );
};

FilePriview.propTypes = {
    file: PropTypes.object,
    updateList: PropTypes.func,
    close: PropTypes.func,
    showActions: PropTypes.bool,
    userId: PropTypes.string,
    hasFileUrl: PropTypes.bool
};

export default FilePriview;
