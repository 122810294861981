import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import _, { isEmpty, isFunction } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { getVirtualElement } from "../utils/UtilsFunc";
import OptionsMenu from "./OptionsMenu";
import defaultUserIcon from "../assets/img/icons/user_icon.svg";
import AlertConfirmation from "./AlertConfirmation";
import AccessCheck from "./AccessCheck";

function MultiSelectChips({
    option = [],
    handleChange = () => { },
    handleBlur = () => { },
    inputProps,
    value = '',
    transformOptions,
    userIcon = defaultUserIcon,
    disabled = false,
    errorText = "",
    width = "",
    hasIcon = "false",
    margin = "false",
    readOnly = false,
    height = 12,
    showLists,
    autoComplete = 'off',
    inputWrapperClassName = "bg-gray-100",
    tagClassName = "bg-white-200",
    compactView = false,
    multiSelect = true,
    showChipsImg = true,
    crossIconStyle = null,
    materialLabel,
    conditionalSelect = null,
    confirmationMsg = "",
    confirmBeforeRemove = false,
    onRemoveTag = null,
    onRemoveTagAsync = null,
    deletePermissionRequired = false,
    checkPermissionRequired = false,
    permissionFeature = null,
}) {
    const [dropdown, setDropdown] = useState(false);
    const [items, setItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedItemsArray, setSelectedItemsArray] = useState([]);
    const inputRef = useRef();
    const dropdownRef = useRef();
    const wrapperDivRef = useRef()
    const prevSearchValue = useRef("")
    const [labelActive, setLabelActive] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [tagToRemove, setTagToRemove] = useState(null);
    const [isRemoving, setIsRemoving] = useState(false)

    const { ...rest } = inputProps;

    var refElement = useMemo(() => {
        return getVirtualElement({
            name: inputProps.name,
            type: "chipsinput",
            value: value,
            handleChange: function (e) {
                handleChange(e);
            },
            handleBlur,
        });
    }, []);

    useEffect(() => {
        setSelectedItemsArray(value);
    }, [value]);

    useEffect(() => {
        if (_.isFunction(inputProps.defaultValue)) {
            let defaultValue = inputProps.defaultValue(value);
            refElement.setValue(defaultValue);
        }

        return () => {
            setSelectedItemsArray([])
            refElement.setValue([])
        }
    }, []);


    const removeTag = (ele) => {
        const updatedState = selectedItemsArray.map(st => ({ ...st }))
        const filteredItem = updatedState.filter((tag) => tag.value !== ele.value);
        setSelectedItemsArray(filteredItem);
        refElement.setValue(filteredItem);
        setSearchResults(prevState => prevState.map(item => ({ ...item, checked: filteredItem.some(selected => selected.value === item.value) })));
        setTagToRemove(null)
        if (onRemoveTag && isFunction(onRemoveTag)) {
            onRemoveTag(ele)
        }
    };

    useOnClickOutside(wrapperDivRef, () => {
        if (selectedItemsArray.length === 0) {
            setLabelActive(false)
        }
        setDropdown(false);
        prevSearchValue.current = searchTerm
        setSearchTerm("")
    });

    let inputClass = classNames({
        "bg-transparent appearance-none outline-none h-full w-full text-gray-800": true,
        [`${inputProps.styles}`]: true,
        "pl-10": hasIcon === "true",
        "mb-3": margin === "true",
        "border-red-600": errorText.length > 0,
        "opacity-40 cursor-not-allowed pointer-events-none": disabled === true,
        [`w-${width}`]: true,
    });

    let dropdownInputClass = classNames({
        "bg-gray-100 mx-5 p-1 px-2 rounded appearance-none outline-none h-full text-gray-800": true,
        [`${inputProps.styles}`]: true,

    });

    useEffect(() => {
        (async function () {
            if (_.isArray(option)) {
                return setItems(option);
            }
            option("", (data) => {
                const transformedData = transformOptions(data)
                setItems(transformedData);
                setSearchResults(transformedData.map(item => ({ ...item, checked: selectedItemsArray.some(selected => selected.value === item.value) })));
            });
        })();
    }, [option]);

    useEffect(() => {
        (async function () {
            if (_.isArray(option)) {
                return setItems(option);
            }
            if (dropdown && prevSearchValue.current !== searchTerm) {
                option(searchTerm, (data) => {
                    const transformedData = transformOptions(data)
                    setItems(transformedData);
                    setSearchResults(transformedData.map(item => ({ ...item, checked: selectedItemsArray.some(selected => selected.value === item.value) })));
                });
            }
        })();
    }, [option, dropdown, searchTerm, prevSearchValue.current]);

    function selectClick(e, item) {
        if (confirmBeforeRemove && !e.target.checked) {
            setTagToRemove(item)
            setShowConfirmationDialog(true)
            return
        }
        if (conditionalSelect && isFunction(conditionalSelect)) {
            conditionalSelect(e, item)
        }
        const { checked, value } = e.target;
        let updatedState = selectedItemsArray.map(st => ({ ...st }))
        if (checked) {
            if (multiSelect) {
                updatedState.push({ ...item, checked: true, })
            }
            else {
                updatedState = [{ ...item, checked: true, }]
            }
        } else if (!checked) {
            updatedState = updatedState.filter(filteredItem => filteredItem.value !== value)
        }
        setSelectedItemsArray(updatedState);
        inputRef.current.focus();
        prevSearchValue.current = searchTerm
        setSearchTerm("");
        setDropdown(true);
        refElement.setValue(updatedState);
        setSearchResults(prevState => prevState.map(item => ({ ...item, checked: updatedState.some(selected => selected.value === item.value) })));
    }


    const handleSearchChange = (event) => {
        prevSearchValue.current = searchTerm
        setSearchTerm(event.target.value);
    };

    const handleFocus = () => {
        // if (!_.isArray(option)) {
        setLabelActive(true)
        setSearchResults(items.map(item => ({ ...item, checked: selectedItemsArray.some(selected => selected.value === item.value) })))
        setDropdown(true);
        // }
    }


    useEffect(() => {

        if (!isEmpty(searchTerm) && _.isArray(option)) {
            const results = items.filter((ele) => ele.title.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item) => ({ ...item, checked: false }));
            setDropdown(true);
            if (!isEmpty(selectedItemsArray)) {
                setSearchResults(results.map(item => ({ ...item, checked: selectedItemsArray.some(selected => selected.value === item.value) })));
            } else {
                setSearchResults(results);
            }
        }

    }, [searchTerm]);

    useEffect(() => {
        if (!dropdown && selectedItemsArray.length === 0) {
            setLabelActive(false)
        }
        else {
            setLabelActive(true)
        }
    }, [dropdown, selectedItemsArray])

    const renderCloseBtn = (tag) => {
        return <button
            onClick={(e) => {
                e.stopPropagation();
                if (confirmBeforeRemove) {
                    setTagToRemove(tag)
                    setShowConfirmationDialog(true)
                } else {
                    removeTag(tag)
                }
            }}
            className={`bg-transparent hover focus:outline-none ${disabled && "cursor-default"} `}
            type="button"
            disabled={disabled}
        >
            <FontAwesomeIcon
                icon={faTimes}
                className={`w-4 h-4 mr-2  ${materialLabel ? "text-gray-500" : "text-gray-400"}`}
            />
        </button>
    }


    return (
        <div ref={wrapperDivRef} className="flex flex-col items-center relative w-full">
            {showLists && selectedItemsArray.length > 0 && (
                <div className="">
                    <div className="px-6 py-4 w-1/2 absolute right-0 top-0 bg-white z-50 shadow rounded max-h-select">
                        {selectedItemsArray.map((item) => {
                            return <div key={item.id} className="pb-2">{item.title}</div>;
                        })}
                    </div>
                </div>
            )}
            <div className="w-full">
                <div className={`flex h-${height} ${materialLabel && 'border-b-2'}`}>
                    {compactView ?
                        <div className={`flex flex-auto flex-col no-scrollbar overflow-x-auto overflow-y-hidden`}>
                            <div className="flex">
                                <input
                                    ref={inputRef}
                                    readOnly={true}
                                    value={selectedItemsArray.length > 0 ?
                                        selectedItemsArray.length === 1 ? selectedItemsArray[0].name :
                                            selectedItemsArray.length === 2 ? selectedItemsArray[0].name + " & " + (selectedItemsArray.length - 1) + " other" :
                                                selectedItemsArray[0].name + " & " + (selectedItemsArray.length - 1) + " others" : ""
                                    }
                                    autoComplete={autoComplete}
                                    onClick={handleFocus}
                                    {...rest}
                                    className={inputClass}
                                />
                            </div>
                        </div> :
                        <div onClick={() => inputRef.current?.focus()} className={`flex flex-auto no-scrollbar overflow-x-auto overflow-y-hidden  ${inputWrapperClassName} ${materialLabel && 'mr-8'}`}>
                            {selectedItemsArray && selectedItemsArray.map((tag) => {
                                return (
                                    <React.Fragment key={tag.id}  >
                                        <div
                                            className={`flex justify-center items-center m-1 font-medium ${materialLabel ? "bg-gray-200" : "bg-white"} rounded-full text-teal-700 ${crossIconStyle ? crossIconStyle.bg : 'bg-teal-100'}`}
                                        >
                                            <span className={`rounded-full text-black-400 font-normal text-sm flex items-center ${disabled ? "cursor-default" : "cursor-pointer"} active:bg-gray-300 transition duration-300 ease w-max ${tagClassName}`}>
                                                {tag.title && showChipsImg && (
                                                    <img
                                                        className="rounded-full w-8 h-8 max-w-none object-cover"
                                                        alt={userIcon}
                                                        src={tag.profileImageUrl ? tag.profileImageUrl : userIcon}
                                                    />
                                                )}
                                                <span className="flex items-center px-3 py-2">
                                                    {tag.title}
                                                </span>
                                                {
                                                    (deletePermissionRequired && permissionFeature) ?
                                                        <AccessCheck feature={permissionFeature} permissionType="delete" elementType="Img" noDiv>
                                                            {renderCloseBtn(tag)}
                                                        </AccessCheck> :
                                                        renderCloseBtn(tag)
                                                }
                                            </span>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                            <div style={{ minWidth: '6rem' }} className={`flex-1 ${materialLabel && "material-field input-field"}`}>
                                <input
                                    ref={inputRef}
                                    placeholder=""
                                    readOnly={readOnly}
                                    value={searchTerm}
                                    autoComplete={autoComplete}
                                    onChange={handleSearchChange}
                                    onFocus={handleFocus}
                                    {...rest}
                                    className={inputClass}
                                    onMouseLeave={() => {
                                        inputRef.current.blur();
                                        refElement.blurElement();
                                    }}
                                />

                                {materialLabel && (
                                    <>
                                        <i className="select-icon">
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </i>
                                        <label
                                            htmlFor={inputProps.id}
                                            className={`renderAutocompletelabel ${labelActive ? "active  -mt-1" : ""
                                                }`}
                                        >
                                            {materialLabel}
                                        </label>
                                    </>
                                )}
                            </div>
                        </div>}
                </div>
            </div>
            {dropdown ? (
                <OptionsMenu
                    dropdownRef={dropdownRef}
                    searchResults={searchResults}
                    selectClick={selectClick}
                    autoComplete={autoComplete}
                    compactView={compactView}
                    handleSearchChange={handleSearchChange}
                    inputRef={inputRef}
                    searchTerm={searchTerm}
                    dropdownInputClass={dropdownInputClass}
                    disabled={disabled}
                    permissionFeature={permissionFeature}
                    deletePermissionRequired={deletePermissionRequired}
                    checkPermissionRequired={checkPermissionRequired}
                />
            ) : null}
            <AlertConfirmation
                title="Please Confirm?"
                showConfirm={showConfirmationDialog}
                message={confirmationMsg}
                firstButtonText={inputProps.confirmBoxFirstBtnText ? "Cancel" : "No"}
                secondButtonText="Yes"
                secondButtonHandler={() => {
                    if (onRemoveTagAsync && isFunction(onRemoveTagAsync)) {
                        setIsRemoving(true)
                        onRemoveTagAsync(tagToRemove).then(res => {
                            if (res) {
                                removeTag(tagToRemove)
                            } else {
                                setTagToRemove(null)
                            }
                            setShowConfirmationDialog(false);
                            setIsRemoving(false)
                        })
                    } else {
                        removeTag(tagToRemove)
                        setShowConfirmationDialog(false);
                    }
                }}
                firstButtonHandler={() => {
                    setShowConfirmationDialog(false);
                    setTagToRemove(null)
                }}
                disableSecondButton={isRemoving}
            />
        </div>
    );
}

MultiSelectChips.propTypes = {
    option: PropTypes.array,
    handleChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    handleBlur: PropTypes.func,
    disabled: PropTypes.string,
    inputProps: PropTypes.object,
    value: PropTypes.array,
    transformOptions: PropTypes.func,
    userIcon: PropTypes.string,
    errorText: PropTypes.string,
    width: PropTypes.string,
    hasIcon: PropTypes.string,
    margin: PropTypes.string,
    readOnly: PropTypes.bool,
    height: PropTypes.number,
    showLists: PropTypes.bool,
    autoComplete: PropTypes.string,
    inputWrapperClassName: PropTypes.string,
    tagClassName: PropTypes.string,
    compactView: PropTypes.bool,
    multiSelect: PropTypes.bool,
    showChipsImg: PropTypes.bool,
    crossIconStyle: PropTypes.oneOf(PropTypes.object, null),
    materialLabel: PropTypes.string,
    conditionalSelect: PropTypes.arrayOf(PropTypes.func, null),
    confirmationMsg: PropTypes.string,
    confirmBeforeRemove: PropTypes.bool,
    onRemoveTag: PropTypes.func,
    onRemoveTagAsync: PropTypes.func,
    deletePermissionRequired: PropTypes.bool,
    permissionFeature: PropTypes.string,
    checkPermissionRequired: PropTypes.bool,
};

export default MultiSelectChips;
