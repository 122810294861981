import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Text from "../commonComponent/Text";
import _ from "lodash";

const Tooltip = ({ icon, content, children, styles }) => {
  const [show, setShow] = useState(false);
  const element = useRef(null);
  const [height, setHeight] = useState(0);
  const showTooltip = () => {
    setShow(true);
  };
  const hideTooltip = () => {
    setShow(false);
  };
  let tooltipClass = classNames(
    `tooltip-content absolute bg-gray-400 p-2 rounded z-10 left-6 -top-${height} ${styles}`,
    {
      invisible: !show
    }
  );
  useEffect(() => {
    let h = Math.round((element.current.clientHeight - 8) / 8);
    h = h % 2 === 0 ? h : h - 1;
    setHeight(h);
  });
  return (
    <div className="inline relative cursor-pointer">
      {icon && (
        <span
          // className="tooltip"
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <FontAwesomeIcon icon={icon} className="w-3 h-3 m-auto" />
        </span>
      )}
      {children && (
        <span
          // className="tooltip"
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          {children}
        </span>
      )}
      <div className={tooltipClass} ref={element}>
        {_.isArray(content) && content.length > 0 ? (
          <ul className="list-disc pl-4">
            {content.map((item, i) => (
              <li key={i}>
                <Text
                  tag="small"
                  scale={true}
                  styles="mt-4 mb-4"
                  text={item}
                  alignment="center"
                />
              </li>
            ))}
          </ul>
        ) : (
          <Text
            tag="small"
            scale={true}
            styles="mt-4 mb-4"
            text={content}
            alignment="center"
          />
        )}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  icon: PropTypes.object.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.element,
  styles: PropTypes.string
};

export default Tooltip;
