import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Image = ({ img, alt = "", title = "", imgClass = "", icon, change, style }) => {
    if (!icon) {
        return img.length > 0 ? (
            <img src={img} alt={alt} title={title} className={imgClass} onChange={change} style={style} />
        ) : (
            ""
        );
    }

    return <FontAwesomeIcon className={imgClass} icon={icon} size="s" />;
};

Image.propTypes = {
    img: PropTypes.string.isRequired,
    alt: PropTypes.string,
    imgClass: PropTypes.string,
    icon: PropTypes.element,
    change: PropTypes.func,
    title: PropTypes.string,
    style: PropTypes.object,
};

export default Image;
