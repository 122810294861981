import config from "react-global-configuration";

config.set(
    {
        base: process.env.REACT_APP_REST_ENDPOINT,
        // base: "https://dev-api.centec.io",
        ping: "/ping",
        register: "/auth/register",
        login: "/auth/login",
        forgot: "/auth/password/forgot",
        recover: "/auth/password/recover",
        reset: "/auth/password/reset",
        waprofile: "/user/profile",
        msLogin: "/auth/ms",
        user: "/user",
        income: "/billing/incomes",
        incomeGraph:"/billing/income/graph",
        verifyEmail: "/auth/verifyEmail",
        resendEmail:"/user/resendEmail",
        resendInviteEmail: "/user/resendInviteEmail",
        
        teams: "/user/team",
        teamLogo: "/user/team/logo",
        createuser: "/user/create",
        personal: "/user/personal",
        commission: "/user/commissions",
        partnership:"/user/partnership",
        targets: "/user/prospect/targets",
        changePassword: "/user/password/change",
        upload: "/user/compliance/asset/upload",
        annualGoals: "/user/annualgoal",

        /* compliance api  start*/
        complianceFolder: "/user/compliance/folder",
        complianceFile:"/user/compliance/file",
        filePreviewUrl: "/user/compliance/document",
        approve: "/user/compliance/document",
        search: "/user/compliance/document/search",
        reUpload: "/user/compliance/asset/re-upload",
        profileImage: "/user/profile/image",
        profileUploadProspect: "/prospect/profile/image",
        fileAttachment: "/client/attachment",
        /* compliance api end*/
        alertList: "/alerts",
        acknowledged: "/alert",
        // Prospects API
        prospects: "/prospect",
        prospectsStats: "/prospect/statistics",
        saveProspect: "/prospect/create",
        deleteActivity: "/prospect/activity",
        fetchProspect: "/prospect/id",

        //Clients API
        clients: "/client",
        clientStats:"/client/stats",
        addOtherProfessionals: "/client/other-professional/add",

        // Highlights Api
        clientMember: "/client/member",
        milestone: "/client/milestone",
        specialDates: "/client/special/dates",
        addClientMember: "/client/member/add",
        addClientPhone: "/client/member/phone",
        clientLinkedinImage: "/client/member/linkedin-profile-image-binary",
        clientNotes: "/client/note",
        taskNotes: '/client/task',
        addClientNote: "/client/note/add",
        addSpecialDay: "/client/special/dates/add",
        addMilestone: "/client/milestone/create",
        clientTask: "/client/task",
        addClientTask: "/client/task/add",
        getWorkflowTypes:"/workflow/type",
        createWorkflowTemplate: "/workflow/createTemplate",
        updateWorkflowTemplate: "/workflow/workflowTemplate",
        getWorkflowTemplate: "/workflow",
        createOpenWorkflowRequest: "/workflow/open/request/create",
        getWorkflowForClient: "/workflow/open/request/client",
        openWorkflowRequestNotes: "/workflow/open/request/notes",
        updateOpenWorkflowRequestStep: "/workflow/open/request/step",
        getMemberIdentity: "/client/member/identity",
        deleteWorkflowStep: "/workflow/workflowTemplateSteps",
        getOpenWorkflowRequestById: "/workflow/open/request",
        getWorkflowTemplateById: "/workflow",
        getTemplatesGroupByType: "/workflow/byType",

        //Accounts graph API 
        getAccountStrategyVariations:"/accounts/strategies/variation",
        getAccountVariationGraph:"/accounts/variation",
        
        //Accounts Api
        accounts: "/accounts",
        accountsUpdate: '/accounts/update',
        getAccountPayFor: '/accounts/paysFor',
        addAccountPaysFor: '/accounts/paysFor/add',
        updateAccountPaysFor: '/accounts/paysFor/update',
        deleteAccountPaysFor: '/accounts/paysFor',

        //Strategy Setup Api
        getStrategies:"/accounts/strategies",
        addStrategies:"/accounts/strategies/add",
        updateStrategies:"/accounts/strategies/update",
        getAllStrategyTypes:"/accounts/strategyType",
        markAsReviewTransactions:"/accounts/bookKeeping/update",
        getAccountsStrategies:"/accounts/accountStrategies",
        addAccountStrategies:"/accounts/accountStrategies/add",

        //transactions
        getAllTransactions:"/accounts/bookKeeping",
        addTransactions:"/accounts/bookkeeping-strategization/add",
        getReviewedTransaction:"/accounts/bookkeeping-strategization",
        getTransactionAlerts:"/accounts/transaction-alerts",

        // Services Api
        addFLMGoal: "/client/services/create/goal",
        getFLMGoals: "/client/services/goals",
        goals: "/client/services/goal",
        clientServices: "/client/services",
        addRMData: "/client/services/create/riskManagement",
        riskManagement: "/client/services/riskManagement",
        legacyPlanning: "/client/services/legacyPlanning",
        createLegacyData: "/client/services/create/legacyPlanning",

        //product 
        getProduct:'/product/all',
        getHoldingProduct:'/product/holdings',
        getAssetCategory:'/product/assetCategory',
        getAssetClass:'/product/assetClass',
        getProductType:'/product/type',
        getAvdAssetCategory:'/product/advAssetCategory',
        editProduct:'/product/update',

        // Billing
        getHouseholdBilling: "/client/billing/households",
        getClientGeneralBilling: "/client/billing/general",
        feeSchedules: "/billing/fee-schedule",
        feeScheduleStructure: "/billing/fee-schedule/structure",
        billingStats: '/billing/stats',
        billingGenerate: '/billing/bill/generate',
        billings: '/billing/bills',
        billingsDelete: '/billing/bill',
        billingReport: '/billing/bill/report',
        billingRetry: '/billing/bills/retry',
        /* compliance api end*/

        // Billing Household Apis
        householdGeneral: "/client/billing/general",
        householdFinance: "/client/billing/planning-fee",
        householdBilling: "/client/billing/billing-info",

        //Billing Payout Block
        payoutBlock: "/client/billing/payoutBlock",
        
        //Exclude Assets
        getExcludedAssets: '/accounts/excludedAssets',
        addExcludedAssets: '/accounts/excludedAssets/add',
        updateExcludedAssets: '/accounts/excludedAssets/update',
        deleteExcludedAssets: '/accounts/excludedAssets',

        //Accounts Payout Block
        payoutBlockAccounts:"/accounts/payoutBlock",
        payoutBlockAccountsAdd:"/accounts/payoutBlock/add",
        payoutBlockAccountsUpdate:"/accounts/payoutBlock/update",
        
        //Investment Fees 
        getInvestmentFees: '/client/billing/investment/fee',
        postInvestmentFees: '/client/billing/investment/fee',
             // Account Billing
        addAccountBilling:'/accounts/billingInfo/add',
        getAccountBilling: '/accounts/billingInfo',
        
        //Live Bills
        getLiveBills: '/billing/bill/units',

        //Receivables
        receivablesProduct:"/receivables/product",
        receivablesPayoutBlock:"/receivables/payoutBlock",
        receivableProductList:"/product",
        receivableProviderList:"/provider",
        receivableOriginals:"/receivables/originals",
        receivablesProjectedMonthly:"/receivables/projected/monthly",
        receivablesPastDue:"/receivables/pastdue/total",
        receivablesProjectedData:"/receivables",
        receivablesHeader:"/receivables/projected/header",
        receivablesPagination:"/receivables/pagination",
        myCompensation:"/receivables/myCompensation/header",
        receivablesMyCompensationProjectedMonthly:"/receivables/projected/myCompensation/monthly",
        receivablesMyCompensationPagination:"/receivables/myCompensation/pagination",
        receivablesMyCompensationProjectedData:"/receivables/myCompensation",
        receivablesBasicChartData: "/receivables/basic-chartData",
        receivablesDrilldownChartData: "/receivables/drilldown-chartData",


        //Roles and Permissions
        role: "/user/role",
        rolesPermissions: "/rolespermission",
        userPermissions: "/userpermission",
        roleAndPermission: "/user/role/roleId",

        //Dashboard
        dashboardStats:"/user/dashboard/statistics",
        dashboardOpportunities: "/dashboard/opportunities",
        dashboardInfluencers:"/client/top-influencers",
        servicePricing: "/dashboard/service-pricing",
        dashboardGoals: "/dashboard/goals",
        dashboardPathwayToPartnership: "/dashboard/pathway-to-partnership",
        getLeaderboardStats:"/dashboard/leaderboard",

        // Accounts & Cashflows
        getAccounts:'/accounts',
        cashflows: "/accounts/cashflows",
        updateCashflows: "/accounts/cashflows/update",

        //Mfa
        setupMfa:"/auth/generateMFAQRCode",
        validateMfaToken:"/auth/validateMFAToken",
        updateMfa:"/user/updateMFA",

        //logs
        logsBySearchFields:"/logs/search/fields",
        getLogsBySearchText:"/logs/search",
        getAllLogs:"/logs/search/all",

        
        registrationType: "/accounts/registrationType",
        registrationTypeUpdate: "/accounts/registrationType/update",
        registrationTypeAdd: "/accounts/registrationType/add",

        
        workflowTypeCreate:"/workflow/type/create",

        strategyTypeUpdate: "/accounts/strategyType/update",
        strategyTypeCreate: "/accounts/strategyType/add",

        securityType: "/product/securityType",
        securityTypeUpdate: "/product/securityType/update",
        securityTypeAdd: "/product/securityType/add",

        addStatesType:"/master-data/state/add",
        statesTypeUpdate:"/master-data/state",
        statesType:"/master-data/states",

        countryTypeAdd:"/master-data/country/add",
        countryTypeUpdate:"/master-data/country",
        getCountryType:"/master-data/countries",
        
        addCommisionType:"/master-data/receivableproductlist/add",
        getCommisionType:"/master-data/receivableproductlist",

        addSexualOrientation:"/master-data/sexualOrientation/add",
        getSexualOrientation:"/master-data/sexualOrientation",

        addPoliticalParty:"/master-data/politicalParty/add",
        getPoliticalParty:"/master-data/politicalParty",

        addSources:"/master-data/sources/add",
        getSources:"/master-data/sources",

        addSpecificInterest:"/master-data/specificInterest/add",
        getSpecificInterest:"/master-data/specificInterest",
        
        addGender:"/master-data/gender/add",
        getGender:"/master-data/gender",

        addMarriageStatus:"/master-data/marriageStatus/add",
        getMarriageStatus:"/master-data/marriageStatus",

        addReligiousAffiliations:"/master-data/religiousAffiliations/add",
        getReligiousAffiliations:"/master-data/religiousAffiliations",
        addSubReligiousAffiliations:"/master-data/subreligiousAffiliations/add",
        updateSubReligiousAffiliations:"/master-data/subreligiousAffiliations",

        addIndustry:"/master-data/industry/add",
        getIndustry:"/master-data/industry",
        addIndustryJob:"/master-data/industryJobs/add",
        updateIndustryJob:"/master-data/industryJobs",
        
        addAgeRange:"/master-data/ageRange/add",
        getAgeRange:"/master-data/ageRange",

        addMarketingSegment:"/master-data/marketingSegment/add",
        getMarketingSegment:"/master-data/marketingSegment",

        addEmploymentStatus:"/master-data/employmentStatus/add",
        getEmploymentStatus: "/master-data/employmentStatus",

        addAccountObjective:"/master-data/accountObjective/add",
        getAccountObjective:"/master-data/accountObjective",

        // Suitability
        getSuitability:"/client/services/suitability",
        addSuitability: "/client/services/suitability/add",
        
        // systemconfig
        sysconfig: "/sysconfig",

        otherProfessionals: "/client/other-professional",
        linkOtherProfessional: "/client/other-professional/link",

        introducedBy: "/client/introducedby",
        introductions: "/client/introductions",
        family: "/client/member/family",

        //global serach
        globalSearch:"/client/global-search",
        clientSearch: "/client/search-all",

        notesPin: "/client/note/dashboard/pin",

        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        //mskey: "57759fd5-0815-40e9-be73-5f2f17612125",
        mskey:"2e88400d-3388-44f2-9767-23dea887dab9",
        errorCode: [400, 401, 405, 412, 500],
        successCode: [200, 201, 202, 203, 204, 205, 206, 207],
    },
    { freeze: false }
);

export default config;
