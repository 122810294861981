import React, { useContext, useEffect } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import Loadable from "react-loadable";
import Header from "./subComponents/Header";
import Sidebar from "./subComponents/Sidebar";
import cx from "classnames";
import ProtectedRoute from "./ProtectedRoute";
import { isEmpty } from "lodash";
import { UserContext } from "./contextAPI";
import "./App.css";
import Toast from "./commonComponent/Toast";
import Loader from "./commonComponent/Loader";
import { SessionStorage } from "./utils/Storage";
import ErrorBoundary from "./commonComponent/ErrorBoundary";

const Signin = Loadable({
    loader: () =>
        import(/* webpackChunkName: "chunk-signin" */ "./components/Signin"),
    loading: Loader,
});
const Mfa = Loadable({
    loader: () =>
        import(/* webpackChunkName: "chunk-signin" */ "./components/Mfa"),
    loading: Loader,
});

const WAProfile = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-waprofile" */ "./components/WAProfile"
        ),
    loading: Loader,
});
const Dashboard = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-dashboard" */ "./components/Dashboard"
        ),
    loading: Loader,
});
const Clients = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-clients" */ "./components/Clients/Listing"
        ),
    loading: Loader,
});
const ClientsDetails = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-clients" */ "./components/Clients/Details"
        ),
    loading: Loader,
});
const Prospects = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-prospects" */ "./components/Prospects"
        ),
    loading: Loader,
});
const Alert = Loadable({
    loader: () =>
        import(/* webpackChunkName: "chunk-alert" */ "./components/Alert"),
    loading: Loader,
});
const OpenRequests = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-openrequests" */ "./components/OpenRequests"
        ),
    loading: Loader,
});
const CreateRequest = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-createrequest" */ "./components/CreateRequest"
        ),
    loading: Loader,
});
const ManageWorkflow = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-createrequest" */ "./components/Workflows/Componants/ManageWorkflow"
        ),
    loading: Loader,
});

const Products = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-products" */ "./components/Products/Products"
        ),
    loading: Loader,
});
const HouseholdInfo = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/Households/Info"
        ),
    loading: Loader,
});

const HouseholdDetails = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/Households/Details"
        ),
    loading: Loader,
});

const Cashflows = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/Cashflows"
        ),
    loading: Loader,
});
const FeeScheduler = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/FeeSchedules"
        ),
    loading: Loader,
});
const AccountsInfo = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/Accounts/Info"
        ),
    loading: Loader,
});
const AccountsDetails = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/Accounts/Details"
        ),
    loading: Loader,
});
const BillInstance = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/BillingInstances"
        ),
    loading: Loader,
});
const Receivables = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/Receivables"
        ),
    loading: Loader,
});
const BillingLogs = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-billing" */ "./components/Billing/AllLogs"
        ),
    loading: Loader,
});

const Users = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-products" */ "./components/IAM/Users"
        ),
    loading: Loader,
});
const Roles = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-products" */ "./components/IAM/Roles"
        ),
    loading: Loader,
});
const Teams = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-products" */ "./components/IAM/Teams"
        ),
    loading: Loader,
});
const Unauthorized = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-products" */ "./components/Unauthorized"
        ),
    loading: Loader,
});
const CommissionType = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/CommissionType"
        ),
    loading: Loader,
});
const States = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/States"
        ),
    loading: Loader,
});
const StrategyTypes = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/StrategyTypes"
        ),
    loading: Loader,
});
const WorkflowTypes = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/WorkflowTypes"
        ),
    loading: Loader,
});
const SecurityType = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/SecurityType"
        ),
    loading: Loader,
});
const RegistrationType = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/RegistrationType"
        ),
    loading: Loader,
});

const Country = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/Country"
        ),
    loading: Loader,
});
const SexualOrientation = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/SexualOrientation"
        ),
    loading: Loader,
});
const Sources = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/Sources"
        ),
    loading: Loader,
});
const PoliticalParty = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/PoliticalParty"
        ),
    loading: Loader,
});
const SpecificInterest = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/SpecificInterest"
        ),
    loading: Loader,
});
const Gender = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/Gender"
        ),
    loading: Loader,
});
const MarriageStatus = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/MarriageStatus"
        ),
    loading: Loader,
});
const ReligiousAffiliations = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/ReligiousAffiliations"
        ),
    loading: Loader,
});
const CommonMasterData = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/CommonMasterData"
        ),
    loading: Loader,
});
const Industry = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/Industry"
        ),
    loading: Loader,
});
const Providers = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/MasterData/Providers"
        ),
    loading: Loader,
});
const OtherProfessionals = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "chunk-master" */ "./components/OtherProfessionals/OtherProfessionals"
        ),
    loading: Loader,
});

function App() {
    const location = useLocation();
    const history = useHistory();
    const { userDetails: user, showToast, clearUser } = useContext(UserContext);
    const isSignIn = !isEmpty(user);
    let bodyClass = cx("flex w-full min-h-full", {
        "text-center items-center": ["/"].includes(
            location.pathname
        ),
        "flex-col": ["/"].includes(location.pathname),
    });
    let parentContainerClass = cx("flex w-full h-full overflow-hidden", {
        "mt-20": isSignIn,
    });

    // On before load / refresh

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        if (isSignIn && location.pathname === "/") {
            let loginType = SessionStorage.getObj("loginType");
            if (loginType === "msSignIn") {
                clearUser();
                localStorage.clear()
            } else {
                //history.push("/dashboard");
                history.push("/mfa");
            }
        }
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className={bodyClass}>
            {isSignIn && <Header />}
            <div className={parentContainerClass}>
                {isSignIn && <Sidebar />}
                <ErrorBoundary key={location.pathname}>
                    <Switch>
                        <Route exact path="/" component={!isSignIn && Signin} />
                        <Route
                            exact path='/mfa'
                            component={Mfa}
                        />
                        <ProtectedRoute
                            exact path='/unauthorized'
                            component={Unauthorized}
                            user={user} />
                        <ProtectedRoute
                            exact
                            path="/waprofile/:id"
                            component={WAProfile}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/dashboard"
                            component={Dashboard}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/clients"
                            component={Clients}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/client-detail/:id"
                            component={ClientsDetails}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/prospects"
                            component={Prospects}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/alerts"
                            component={Alert}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/otherProfessionals"
                            component={OtherProfessionals}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/open-requests"
                            component={OpenRequests}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/create-request"
                            component={CreateRequest}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/create-request-manage-workflows"
                            component={ManageWorkflow}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/products"
                            component={Products}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/billing/households"
                            component={HouseholdInfo}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/billing/households/:id"
                            component={HouseholdDetails}
                            user={user}
                        />

                        <ProtectedRoute
                            exact
                            path="/billing/accounts"
                            component={AccountsInfo}
                            user={user}
                        />

                        <ProtectedRoute
                            exact
                            path="/billing/accounts/:id"
                            component={AccountsDetails}
                            user={user}
                        />

                        <ProtectedRoute
                            exact
                            path="/billing/fee"
                            component={FeeScheduler}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/billing/cashflows"
                            component={Cashflows}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/billing/billingInstances"
                            component={BillInstance}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/billing/receivables"
                            component={Receivables}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/billing/alllogs"
                            component={BillingLogs}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/users"
                            component={Users}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/roles"
                            component={Roles}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/teams"
                            component={Teams}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/receivable-products"
                            component={CommissionType}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/states"
                            component={States}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/strategy-types"
                            component={StrategyTypes}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/workflow-types"
                            component={WorkflowTypes}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/security-type"
                            component={SecurityType}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/registration-type"
                            component={RegistrationType}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/country"
                            component={Country}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/sexual-orientation"
                            component={SexualOrientation}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/gender"
                            component={Gender}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/political-party"
                            component={PoliticalParty}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/sources"
                            component={Sources}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/specific-interest"
                            component={SpecificInterest}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/marriage-status"
                            component={MarriageStatus}
                            user={user}
                        />
                         <ProtectedRoute
                            exact
                            path="/master-data/religious-affiliations"
                            component={ReligiousAffiliations}
                            user={user}
                            />
                        <ProtectedRoute
                            exact
                            path="/master-data/industry"
                            component={Industry}
                            user={user}
                        />
                        <ProtectedRoute
                            exact
                            path="/master-data/providers"
                            component={Providers}
                            user={user}
                        />
                         <ProtectedRoute
                            exact
                            path="/master-data/:type"
                            component={CommonMasterData}
                            user={user}
                        />
                       
                    </Switch>
                </ErrorBoundary>
            </div>
            {showToast.status === true && (
                <Toast
                    {...{
                        icon: showToast.icon,
                        alignment: showToast.alignment,
                        text: showToast.message,
                        type: showToast.type,
                        fontweight: "normal",
                    }}
                />
            )}
        </div>
    );
}

export default App;
