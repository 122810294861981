export const mfaVerificationCode = [
  {
    key: "vcode",
    eletype: "input",
    initialValue: "",
    inputProps: {
      type: "text",
      placeholder: "MFA Code",
      name: "vcode",
      onInput: (e) => e.target.value = e.target.value.slice(0, 6),
      className: "px-3 py-3 placeholder-blueGray-300 text-black-900 relative bg-gray-100 rounded-none text-sm border focus:ring outline-none w-full",
    },
    borderFlag: false,
  },
];
