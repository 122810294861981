import React, { useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as tweenFunctions from "tween-functions";
import PropTypes from "prop-types";

const DraggableItems = ({
  data,
  render,
  onDragEnd,
  onBeforeDragStart,
  isDropDisabled = false,
  withContext = true,
  placeholderClassName = "",
  draggableWrapper = null,
}) => {

  const draggRefs = useRef(data.map(el => null))

  const animateRow = (currentIdx, finalIdx) => {
    const id = data[currentIdx].id

    const preDrag = api.tryGetLock("drag-" + id);
    if (!preDrag) {
      return;
    }

    const startY = draggRefs.current[currentIdx] && draggRefs.current[currentIdx].getBoundingClientRect().y;

    const endY = draggRefs.current[finalIdx] && draggRefs.current[finalIdx].getBoundingClientRect().y;

    const start = { x: 0, y: startY };
    const end = { x: 0, y: endY };
    const drag = preDrag.fluidLift(start);

    const points = [];

    for (let i = 0; i < 20; i++) {
      points.push({
        x: tweenFunctions.easeInOutSine(i, start.x, end.x, 20),
        y: tweenFunctions.easeInOutSine(i, start.y, end.y, 20)
      });
    }

    moveStepByStep(drag, points);
  }

  function moveStepByStep(drag, values) {
    requestAnimationFrame(() => {
      const newPosition = values.shift();
      drag.move(newPosition);

      if (values.length) {
        moveStepByStep(drag, values);
      } else {
        drag.drop();
      }
    });
  }

  let api
  const useMyCoolSensor = (value) => {
    api = value;
  };

  const getDraggable = (item, index) => (
    <Draggable key={item.id} draggableId={"" + item.id} index={index}>
      {(provided) => {
        return (
          <div
            className=""
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            <div
              className='flex justify-between'
              ref={el => draggRefs.current[index] = el}
            >
              {render(item, index, animateRow)}
            </div>
          </div>
        )
      }}
    </Draggable>
  )

  const droppableEle = (
    <Droppable
      droppableId="droppable"
      direction='vertical'
      isDropDisabled={isDropDisabled}
    >
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} className="">
            {data.map((item, index) => {
              if (draggableWrapper) {
                return draggableWrapper(getDraggable(item, index))
              }
              return getDraggable(item, index)
            })}
            <div className={placeholderClassName}>{provided.placeholder}</div>
          </div>
        )
      }}
    </Droppable>
  )

  return withContext ? (
    <div className=''>
      <DragDropContext onDragEnd={onDragEnd}
        enableDefaultSensors={true}
        sensors={[useMyCoolSensor]}
        onBeforeDragStart={onBeforeDragStart}
      // onBeforeDragStart={t => {
      //   setDraggableItems(prevState => {
      //     let updatedState = _.cloneDeep(prevState).map(el => ({ ...el, showFull: false }))
      //     return updatedState
      //   })
      // }}
      >
        {droppableEle}
      </DragDropContext>
    </div>
  ) : (
    droppableEle
  )
}

DraggableItems.propTypes = {
  data: PropTypes.array.isRequired,
  render: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onBeforeDragStart: PropTypes.func,
  isDropDisabled: PropTypes.bool,
  withContext: PropTypes.bool,
  placeholderClassName: PropTypes.string,
  draggableWrapper: PropTypes.func,
};

export default DraggableItems