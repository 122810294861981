import React from 'react'
import PropTypes from "prop-types";

const UnAuthorized = ({
  message = "You do not have permission to view this page.",
  className = "bg-gray-100 content-container"
}) => {
  return (
    <div className={`flex flex-col items-center justify-center ${className}`}>
      <p>Unauthorized</p>
      <p>{message}</p>
    </div>
  )
}

UnAuthorized.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default UnAuthorized