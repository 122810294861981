import {
    CHANGE_ACCOUNTS_SELECTED_TAB,
    CREATE_PAYOUT_BLOCK_INFO,
    ADD_NEW_PAYOUT_BLOCK_INFO,
    DELETE_NEW_PAYOUT_BLOCK_INFO,
    GET_GENERAL_ACCOUNT,
    GET_BILLING_ACCOUNT,
    GET_MEMBER_LIST,
    GET_ACCOUNT_PAY_FOR,
    ADD_PAY_FOR,
    CLEAR_STATE,
    UPDATE_SELECTED_STATE,
    REMOVE_SELECTED_STATE,
    GET_INVESTMENT_FEE
} from "../../actions/billing/accounts/accounts.constant";
import { setActiveItem } from "../../../utils/UtilsFunc";

function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case CHANGE_ACCOUNTS_SELECTED_TAB:
            return {
                ...state,
                tabs: setActiveItem(state.tabs, action.index),
                selectedTabIndex: action.index,
            };
        case CREATE_PAYOUT_BLOCK_INFO:
            return {
                ...state,
                payoutBlock: {
                    payoutBlockList: action.data.payoutBlock,
                    totalCreditTo: action.data.creditToTotal,
                    payoutPercentageTotal: action.data.payoutPercentageTotal
                },
            };
        case ADD_NEW_PAYOUT_BLOCK_INFO:
            return {
                ...state,
                payoutBlock: {
                    payoutBlockList: [
                        ...state.payoutBlock.payoutBlockList,
                        action.data,
                    ],
                    totalCreditTo: state.payoutBlock.totalCreditTo,
                    payoutPercentageTotal: state.payoutBlock.payoutPercentageTotal
                },
            };
        case DELETE_NEW_PAYOUT_BLOCK_INFO:
            return {
                ...state,
                payoutBlock: {
                    payoutBlockList: state.payoutBlock.payoutBlockList.filter(
                        (item) => item.payee !== ""
                    ),
                    totalCreditTo: state.payoutBlock.totalCreditTo,
                    payoutPercentageTotal: state.payoutBlock.payoutPercentageTotal
                },
            };
        case GET_GENERAL_ACCOUNT:
            return {
                ...state,
                accountInfo: {
                    generalInfo: action.data
                }
            }
        case GET_BILLING_ACCOUNT:
            return {
                ...state,
                billingData: action.data
            }

        case GET_MEMBER_LIST:
            return {
                ...state,
                membersList: action.data
            }
        case GET_ACCOUNT_PAY_FOR: {
            return {
                ...state,
                payForList: [...state.payForList.filter(payFor => !isNaN(payFor.id)), ...action.data]
            }
        }
        case ADD_PAY_FOR: {
            return {
                ...state,
                payForList: [...state.payForList, action.data]
            }
        }
        case UPDATE_SELECTED_STATE: {
            return {
                ...state,
                payForList: [
                    ...state.payForList.map(item => (item.id == action.data.id) ?
                        {
                            ...item,
                            submitCancelVisible: action.data.submitCancelVisible,
                            updateDeleteVisible: action.data.updateDeleteVisible,
                            updateField: action.data.updateField
                        }
                        :
                        item)
                ]
            }
        }
        case REMOVE_SELECTED_STATE: {
            return {
                ...state,
                payForList: [
                    ...state.payForList.filter(item => item.id !== action.data)
                ]
            }
        }
        case GET_INVESTMENT_FEE: {
            return {
                ...state,
                investmentFees: action.data
            }
        }
        case CLEAR_STATE: {
            return {
                ...state,
                accountInfo: {
                    generalInfo: {}
                },
                payForList: [],
                membersList: []
            }
        }
        default:
            return state;
    }
}

var INIT_STATE = {
    selectedTabIndex: 0,
    tabs: [
        {
            id: "tab1",
            title: "General",
            key: "active",
            allow: true,
            grid: 1,
            active: true,
        },
        {
            id: "tab2",
            title: "Billing",
            key: "billing",
            allow: true,
            grid: 1,
        },
        {
            id: "tab3",
            title: "This Account Pays For",
            key: "paysFor",
            allow: true,
            grid: 1,
        },
        {
            id: "tab4",
            title: "Payout Blocks",
            key: "payoutBlocks",
            allow: true,
            grid: 1,
        },
        {
            id: "tab5",
            title: "Account Live Bill",
            key: "accountLiveBill",
            allow: true,
            grid: 1,
        },
        {
            id: "tab6",
            title: "Logs",
            key: "logs",
            allow: true,
            grid: 1,
        },
        {
            id: "tab7",
            title: "Account Holdings",
            key: "productHoldings",
            allow: true,
            grid: 1,
        },
    ],
    payoutBlock: {
        payoutBlockList: [],
        totalCreditTo: 0,
        payoutPercentageTotal: 0,
    },
    payForList: [],
    membersList: [],
    accountInfo: {
        generalInfo: {},
        contact: {},
        other: {},
    },
    investmentFees: {}
};

export default reducer;
