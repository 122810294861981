import React, { useState, useEffect, useContext } from "react";
import SidebarData from "../assets/database/sidebarData.json";
import SidebarListItem from "../commonComponent/SidebarListItem";
import { useLocation } from "react-router-dom";
import { UserContext } from "../contextAPI";
import getRequest from "../network/getRequest";
import _ from "lodash";
import { SessionStorage } from "../utils/Storage";
import { useDispatch, useSelector } from "react-redux";
import { fetchHouseHoldBilling } from "../store/actions/billing/household/household.action";
import {
    setLoggedInUserDetails, setLoggedInUserPermissions
} from "../store/actions/loggedInUser/loggedInUser.action";
import { setLoggedOutUser } from '../store/actions/loggedOutUser/loggedOutUser.action';
import { updateMasterData } from "../store/actions/masterData/master-data.action";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
    const location = useLocation();
    const { pathname } = location;
    const [sidebar, setSidebar] = useState([]);
    const [sidebarHeight, setSidebarHeight] = useState(0);
    const { fetchRequest } = getRequest();
    const userData = SessionStorage.getObj("user");
    const { setUser, userDetails, clearUser, updateTotalAlerts, dHToast } = useContext(UserContext);
    const [totalAlerts, setTotalAlerts] = useState(userData.alertCount);
    const [openWorkflowReqcount, setOpenWorkflowReqcount] = useState(userData.openWorkflowReqcount);
    const dispatch = useDispatch()
    const { householdStats: unitStats } = useSelector(({ household }) => household);
    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => {
        let pageSidebar = SidebarData.filter(el => (!el.visibleToRole || el.visibleToRole === userDetails.role?.id)).map((item) => {
            item.subMenu = item.subMenu.map((element) => {
                if (element.key === "alerts") {
                    element.badge = totalAlerts;
                }
                if (element.key === "open-requests") {
                    element.badge = openWorkflowReqcount;
                }
                if (element.link) {
                    //element.active = element.link === pathname ? true : false;
                    element.active =
                        element.key && pathname.includes(element.key)
                            ? true
                            : false;
                } else {
                    if (element.subList) {
                        // If unit data are not yet updated call the API in order to update unit count in sidebar
                        if (element.title === 'Billing' && _.isEmpty(unitStats)) {
                            dispatch(
                                fetchHouseHoldBilling('ACTIVE', undefined, 1, 10, {
                                    order: "ASC",
                                    sortBy: "unitId"
                                })
                            )
                        }
                        let flag = false;
                        element.subList.map((item) => {
                            if (item.key === "units" && !_.isEmpty(unitStats)) {
                                item.badge = +(unitStats.requiresAttention);
                            }
                            if (flag) return;
                            const itemLink = item.link?.split("?").length > 0 ? item.link.split("?")[0] : item.link
                            flag = pathname.includes(itemLink) ? true : false;
                        });
                        element.active = flag;
                    }
                }
                return element;
            });
            return item;
        });
        setSidebar(pageSidebar);
        setSidebarHeight(window.innerHeight - 80);
    }, [pathname, totalAlerts, unitStats, openWorkflowReqcount]);

    function handleSubListOpenClick(title, active) {
        setSidebar(prevState => {
            return prevState.map((item) => {
                return {
                    ...item,
                    subMenu: item.subMenu.map((element) => {
                        return {
                            ...element,
                            active: element.title === title ? active : false,
                        };
                    }),
                };
            })
        })
    }

    function fetchProfileDetails(firstTimeCall = false) {
        fetchRequest({}, "get", "waprofile").then(
            (res) => {
                if (res && _.has(res, "result")) {
                    let currentUser = { ...userDetails, ...res.result };
                    if (!_.has(res.result, "fullName")) {
                        res.result.fullName = "Centric";
                    }
                    if (firstTimeCall) {
                        SessionStorage.setObj("user", currentUser);
                        currentUser.userPermission = res.result.userPermission;
                        dispatch(setLoggedInUserDetails(currentUser));
                        setUser(res.result.alertCount);
                    } else {
                        SessionStorage.setObj("user", currentUser);
                        updateTotalAlerts(res.result.alertCount);
                        dispatch(setLoggedInUserPermissions(res.result.userPermission))
                    }
                    setTotalAlerts(res.result.alertCount);
                    setOpenWorkflowReqcount(res.result.openWorkflowReqcount);
                }
            },
            (err) => {
                if (err.message === "Unauthorized") {
                    clearUser();
                    localStorage.clear()
                    dispatch(setLoggedOutUser())
                    //history.push("/");
                }
            }
        ).catch((err) => {
            //dHToast(true, err?.message, "rt", "error", faExclamationCircle);
            if (err.message === "Unauthorized") {
                clearUser();
                localStorage.clear()
                dispatch(setLoggedOutUser())
                //history.push("/");
            }

        });
    }

    useEffect(() => {
        //All API calling with alphabetically sorted
        Promise.all([
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getGender").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getSexualOrientation").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getMarketingSegment").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "createdAt",
                    order: "ASC"
                }
            }, "post", "getAgeRange").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getPoliticalParty").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "statesType").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getSources").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getSpecificInterest").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getReligiousAffiliations").then(res => {
                const arr = res.result
                const idx = arr.findIndex(r => r.name?.toLowerCase() === "other")
                if (idx >= 0) {
                    const other = arr[idx]
                    arr.splice(idx, 1)
                    arr.push(other)
                }
                arr.forEach(el => {
                    const subArr = el.subReligions
                    const jdx = subArr.findIndex(r => r.name?.toLowerCase() === "other")
                    if (jdx >= 0) {
                        const other = subArr[jdx]
                        subArr.splice(jdx, 1)
                        subArr.push(other)
                    }
                    el.subReligions = subArr
                })
                return arr
            }),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getMarriageStatus").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getEmploymentStatus").then(res => res.result),
            fetchRequest({
                sorting: {
                    sortBy: "name",
                    order: "ASC"
                }
            }, "post", "getIndustry").then(res => {
                const arr = res.result
                const idx = arr.findIndex(r => r.name?.toLowerCase() === "other")
                if (idx >= 0) {
                    const other = arr[idx]
                    arr.splice(idx, 1)
                    arr.push(other)
                }
                arr.forEach(el => {
                    const subArr = el.industryJobs
                    const jdx = subArr.findIndex(r => r.name?.toLowerCase() === "other")
                    if (jdx >= 0) {
                        const other = subArr[jdx]
                        subArr.splice(jdx, 1)
                        subArr.push(other)
                    }
                    el.industryJobs = subArr
                })
                return arr
            }),
            fetchRequest({}, "get", "receivableProviderList", null, {
                sortBy: "name",
                order: "ASC",
            }).then(res => res.result),
            fetchRequest({}, "get", "sysconfig").then(res => res.result),
            fetchRequest({}, "post", "getAccountObjective", null, {
                sortBy: "createdAt",
                order: "ASC",
            }).then(res => res.result),
        ]).then((values) => {
            dispatch(updateMasterData({
                gender: values[0],
                sexualOrientation: values[1],
                marketingSegments: values[2],
                ageRange: values[3],
                politicalParty: values[4],
                state: values[5],
                sources: values[6],
                specificInterests: values[7],
                religiousAffiliations: values[8],
                marriageStatus: values[9],
                employmentStatus: values[10],
                industry: values[11],
                providers: values[12],
                sysconfig: values[13],
                accountObjective: values[14]
            }))
        }).catch((err) => {
            dHToast(true, err?.message, "rt", "error", faExclamationCircle);
        })

        fetchProfileDetails(true);
        const intervalId = setInterval(fetchProfileDetails, 20000);
        return () => clearInterval(intervalId);
    }, []);

    const classes = `relative w-full flex flex-row items-center h-11 focus:outline-none text-gray-600 hover:text-sidebarActive border-l-4 border-transparent ${isExpanded ? "pr-6 mr-2" : ""} rounded-tr-lg rounded-br-lg hover:bg-blue-100`;
    const SidebarList = sidebar && sidebar.length > 0 ? sidebar.map((item) => {
        return item.subMenu.map((element, i) => {
            return (
                <SidebarListItem
                    type={element.type}
                    item={element}
                    key={i}
                    classes={classes}
                    handleSubListOpenClick={handleSubListOpenClick}
                    isExpanded={isExpanded}
                />
            );
        });
    }) : [];

    const showSideBar = () => {
        let sidebarEle = document.getElementsByClassName("hidden sideBarStyle");
        const totalSideBarElements = 21;
        if (sidebarEle?.length == totalSideBarElements) {
            return false
        }
        return true
    }

    const [sidebarIsExpanded, setSidebarIsExpanded] = useState(false)

    useEffect(() => {
        let interVal
        if (!sidebarIsExpanded) {
            interVal = setTimeout(() => {
                setIsExpanded(false)
            }, 300)
        }

        return () => {
            interVal && clearTimeout(interVal);
        }
    }, [sidebarIsExpanded])

    useEffect(() => {
        if (!isExpanded) {
            setSidebar(prevState => {
                return prevState.map((item) => {
                    return {
                        ...item,
                        subMenu: item.subMenu.map((element) => {
                            const arr = element.link?.split("?")
                            let active = false;
                            if (arr && arr.length > 0 && arr[0] === pathname) {
                                active = true
                            } else if (element.subList && element.subList.length > 0) {
                                element.subList.forEach(sb => {
                                    const arr1 = sb.link?.split("?")
                                    if (arr1 && arr1.length > 0 && arr1[0] === pathname) {
                                        active = true
                                    }
                                })
                            }
                            return {
                                ...element,
                                active: active
                            };
                        }),
                    };
                })
            })
        }
    }, [isExpanded, pathname])

    return (
        <div
            onMouseEnter={(e) => {
                setSidebarIsExpanded(true)
                setIsExpanded(true)
            }}
            onMouseLeave={(e) => {
                setSidebarIsExpanded(false)
            }}
            id="sideBarElement"
            className={`custom-width-transition flex flex-col ${showSideBar() ? 'border-r' : 'w-0'} ${sidebarIsExpanded ? "w-80" : "w-20"} fixed bg-white h-full z-20`}
        >
            <div className="w-full flex-grow overflow-hidden">
                <ul
                    className="flex flex-col py-4 space-y-1 overflow-y-auto"
                    style={{ height: sidebarHeight }}
                >
                    {SidebarList}
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
