import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Text from "../commonComponent/Text";

const MultiSelectOptionMenu = ({
    dropdownRef,
    searchResults,
    selectClick,
    text,
    autoComplete = 'off',
    height = "h-32",
    selectLabel = "",
    optionHasMoreInfo = true,
    checkBoxStyle,
}) => {

    let dropdownClass = classNames({
        [`absolute shadow bg-white top-14 z-40 w-full lef-0 rounded max-h-select overflow-y-auto ${height}`]: true,
    });
    const menuItemClassesForDisabledOption = classNames(
        "text-gray-300 pointer-events-none cursor-not-allowed"
    );

    return (
        <div id="dropdown" ref={dropdownRef} className={dropdownClass}>
            <div className="flex flex-col w-full py-1">
                {searchResults && searchResults.length === 0 && (
                    <div className="text-center text-sm mb-4">
                        No Data Available
                    </div>
                )}
                <Text text={selectLabel ? selectLabel : "SELECT FEE PAYING ACCOUNT"} tag="label" autoComplete={autoComplete}
                                    scale={true}
                                    styles="text-100 pl-5 text-gray-400"></Text>
                {searchResults && searchResults.map((item, key) => {
                    return (

                        <div
                            key={key}
                            className={(item.disabled?menuItemClassesForDisabledOption:' cursor-pointer ')+"  w-full rounded-t pl-2 pr-2 text-sm"}
                        >
                           { key !== 0 && <hr />} 
                            <label htmlFor={item.value} className="flex w-full items-end px-5 py-3 relative hover:bg-gray-200">
                                
                                <input
                                    type="checkbox"
                                    checked={item.checked}
                                    id={item.value}
                                    value={item.value}
                                    onChange={(e) => {
                                        selectClick(e, item);
                                    }}
                                    autoComplete={autoComplete}
                                    className="mt-2"
                                    style={checkBoxStyle}
                                    disabled={item.disabled?true:false}
                                />
                                <div className="flex flex-col pl-3.5 w-full">
                                    <div className={(item.disabled?menuItemClassesForDisabledOption:'')+" w-full cursor-pointer"}>{item.name}</div>
                                    {optionHasMoreInfo && <span>
                                        {text} - <label className={(item.disabled?menuItemClassesForDisabledOption:'')+" w-full cursor-pointer"} htmlFor={item.value}>{item.title}</label>
                                    </span>}
                                </div>
                            </label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

MultiSelectOptionMenu.propTypes = {
    selectClick: PropTypes.func,
    searchResults: PropTypes.array,
    dropdownRef: PropTypes.node,
    text: PropTypes.string,
    autoComplete: PropTypes.string,
    height: PropTypes.string,
    selectLabel: PropTypes.string,
    optionHasMoreInfo: PropTypes.bool,
    checkBoxStyle: PropTypes.object,
};

export default MultiSelectOptionMenu;
