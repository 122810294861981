import React from "react";
import PropTypes from "prop-types";

import Mention from "./Mention";

function Element(props) {
    const { attributes, children, element } = props;
    switch (element.type) {
        case "mention":
            return <Mention {...props} />;
        default:
            return <p {...attributes}>{children}</p>;
    }
}

Element.propTypes = {
    attributes: PropTypes.object,
    element: PropTypes.object,
    children: PropTypes.array,
};

export default Element;
