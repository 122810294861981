const message = {
  login: {
    success: "Login successfully.",
    error: "Invalid Credentials.",
    mserror: "You are not a centric authorised user.",
    validation: "Please enter Email and Password",
    emailValidation: 'Email should not be empty',
    passwordValidation: 'Password should not be empty'
  },
  forgotpass: {
    success: "A password reset link has been sent to",
    error: "Email does not exist",
    validation: "Please enter a valid email address.",
  },
  changepass: {
    keymismatch: "token or email mismatched",
    success: "Password reset!",
    error: "Email does not exist",
    validation: "Please enter password",
    passwordnotmatch: "New Password and confirm password must be the same",
    formValidation: "Please check all required fields"
  },
  addnewuser: {
    success: "New user added.",
    error: "Something went wrong, please try again",
    validation: "Please fill all required field",
  },
  tooltips: {
    passwordInfo: [
      "The password Length should be Min 8 and Max 14 Characters.",
      "It should be a mixture of uppercase and lowercase letters.",
      "At least one number and one special character required e.g. @ # ? ].",
      "The new password shouldn't be the same as the last 3 passwords.",
      "Password should not contain  < ,  > special characters]",
    ]
  },
};

const getMessage = (key) => {
  return message[key];
};

export default getMessage;
