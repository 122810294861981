import React from 'react'
import PropTypes from "prop-types";
import MembersDefaultImages from './MembersDefaultImages';
import { generateClientDetailURL, memberName } from '../utils/UtilsFunc';
import { activitystatusClientKey, memberSubType } from '../utils/enum';
import { useHistory } from 'react-router-dom';

const ClientsDefaultImages = ({ clients = [], leftPanelView = "notes" }) => {
  const [firstClient, ...restClients] = clients.sort((a, b) => {
    if (a.tagIndex < b.tagIndex) return -1;
    return 1
  })
  const history = useHistory();

  const countOfSecondaryMembers = restClients.reduce((count, client) => {
    const idx = client.members.findIndex(el => el.subType === memberSubType.Secondary)
    if (idx !== -1) {
      return count + 1
    }
    return count
  }, 0)

  return (
    <div className='flex-shrink-0 cursor-pointer' onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      const newLocation = `/client-detail/${firstClient.id}`
      if (history.location.pathname !== newLocation &&
        ![activitystatusClientKey.ACTIVE, activitystatusClientKey.INACTIVE].includes(firstClient.activityStatus)
      ) {
        history.push(generateClientDetailURL({ clientId: firstClient.id, leftPanel: leftPanelView }))
      }
    }}>
      <MembersDefaultImages
        members={firstClient?.members}
        showImageByGender
        showNameOnHover
        showRemaining={(restClients.length > 0)}
        countToShow={restClients.length}
        tooltipHtml={
          <div className='py-0.5 px-3'>
            {restClients.map(client => {
              return (
                <div key={client.id} className="my-2 flex items-center cursor-pointer" onClick={e => {
                  e.stopPropagation();
                  const newLocation = `/client-detail/${client.id}`
                  if (history.location.pathname !== newLocation &&
                    ![activitystatusClientKey.ACTIVE, activitystatusClientKey.INACTIVE].includes(client.activityStatus)
                  ) {
                    history.push(generateClientDetailURL({ clientId: client.id, leftPanel: leftPanelView }))
                  }
                }}>
                  <MembersDefaultImages members={client.members} showImageByGender />
                  <p className={`${countOfSecondaryMembers > 0 ? (client.members.findIndex(el => el.subType === memberSubType.Secondary) === -1 ? "ml-8" : "ml-2") : "ml-2"}`}>{memberName(client.members)}</p>
                </div>
              )
            })}
          </div>
        }
      />
    </div>
  )
}

ClientsDefaultImages.propTypes = {
  clients: PropTypes.array,
  leftPanelView: PropTypes.string,
};

export default ClientsDefaultImages