const CLIENT = 'CLIENT';
const SINGLE_UNIT = 'SINGLE_UNIT';
const SINGLE_ACCOUNT = 'SINGLE_ACCOUNT';
const WEALTH_ADVISOR = 'WEALTH_ADVISOR';
const FINANCIAL_PLANNING_ = 'FINANCIAL_PLANNING';
const INVESTMENT_MANAGEMENT_ = 'INVESTMENT_MANAGEMENT';
const ALL_UNITS = 'ALL_UNITS';
const ACTIVE = 'ACTIVE'
const PRIMARY = 'PRIMARY';
const SECONDARY = 'SECONDARY';
const LIVE_BILL = 'LIVE_BILL';
const FORECAST_BILL = 'FORECAST_BILL';
const EXPIRING_SOON = 'Expiring Soon';
const EXPIRED = 'Expired';
const FEE_SCHEDULE_MIN_ERROR = 'Fee schedule structure must consist of atleast 1 entry';
const FEE_SCHEDULE_MAX_ERROR = 'Fee schedule structure must consist of at most 7 entries';
const FEE_SCHEDULE_MESSAGE = 'Please enter the structure fields';
const IN_PROGRESS = 'in_progress';
const FAILED = 'Failed';
const COMPLETED = 'completed';
const FINANCIAL_PLANNING = 'Financial Planning';
const INVESTMENT_MANAGEMENT = 'Investment / Management Fees';
const SINGLE_UNIT_ = 'Single Unit';
const SINGLE_ACCOUNT_ = 'Single Account';
const ALL_UNITS_ = 'All Units';
const WEALTH_ADVISOR_ = 'WA';
const LIVE = 'LIVE';
const FORECAST = 'FORECAST';
const ALL = 'ALL';
const PROD_DATE = '2022-03-01';//yyyy-mm-dd
const NOTES_UPDATED_SUCCESSFULLY = 'Note updated successfully';
const TASK_REQUIRED = 'Task type is required for creating task';
const DATE_REQUIRED = 'Due date is required for creating task';
const CONTACT_ADMIN = ' Contact your admin to get updated invite.';
const FEE_SCHEDULE_MAX_VALUE_ERROR = 'Final structure must include Maximum($) value of $10,000,000,000 (10 billion).';

const INCOME_FILTER_OPTIONS = [
  {
    name: "Primary",
    value: "PRIMARY_ADVISORY"
  },
  {
    name: "Advisory - Receivable",
    value: "RECEIVABLES_ADVISORY"
  },
  {
    name: "Risk - Receivable",
    value: "RECEIVABLES_RISK"
  }
]
const SEPARATOR = "|#|"

const CLIENT_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_1-min.jpeg"
const PROSPECT_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_3-min.png"
const DASHBOARD_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_4-min.png"
const CLIENTLISTING_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_6-min.png"
const WORKFLOWS_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_8-min.png"
const ALERTS_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_10-min.png.png"
const BILLINGS_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_7-min.png"
const RECEIVABLES_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_5-min.png"
const MYPROFILE_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_9-min.png.png"
const IAM_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_12-min.png"
const MASTER_DATA_HEADER_BACKGROUND = "https://assets.centec.io/web-images/centec_header_image_13-min.jpeg"
const MAX_COUNT_FOR_CONTACT_INFO = 5

const ASSETS_AND_LIABILITIES_COLORS = {
  "managedAccounts": "#B99A52",
  "riskManagedAsset": "#1581ED",
  "privateFund": "#ff6c10",
  "outsideAssets": "#00ff00",
  "liabilities": "#ff0000"
}

export {
  CLIENT,
  SINGLE_UNIT,
  SINGLE_ACCOUNT,
  WEALTH_ADVISOR,
  ALL_UNITS,
  ACTIVE,
  PRIMARY,
  SECONDARY,
  LIVE_BILL,
  FORECAST_BILL,
  EXPIRING_SOON,
  EXPIRED,
  FEE_SCHEDULE_MIN_ERROR,
  FEE_SCHEDULE_MAX_ERROR,
  FEE_SCHEDULE_MESSAGE,
  IN_PROGRESS,
  FAILED,
  COMPLETED,
  FINANCIAL_PLANNING,
  INVESTMENT_MANAGEMENT,
  SINGLE_UNIT_,
  SINGLE_ACCOUNT_,
  ALL_UNITS_,
  WEALTH_ADVISOR_,
  LIVE,
  FORECAST,
  ALL,
  PROD_DATE,
  NOTES_UPDATED_SUCCESSFULLY,
  TASK_REQUIRED,
  DATE_REQUIRED,
  FINANCIAL_PLANNING_,
  INVESTMENT_MANAGEMENT_,
  INCOME_FILTER_OPTIONS,
  CONTACT_ADMIN,
  SEPARATOR,
  CLIENT_HEADER_BACKGROUND,
  PROSPECT_HEADER_BACKGROUND,
  DASHBOARD_HEADER_BACKGROUND,
  CLIENTLISTING_HEADER_BACKGROUND,
  WORKFLOWS_HEADER_BACKGROUND,
  ALERTS_HEADER_BACKGROUND,
  BILLINGS_HEADER_BACKGROUND,
  RECEIVABLES_HEADER_BACKGROUND,
  MYPROFILE_HEADER_BACKGROUND,
  IAM_HEADER_BACKGROUND,
  MAX_COUNT_FOR_CONTACT_INFO,
  FEE_SCHEDULE_MAX_VALUE_ERROR,
  ASSETS_AND_LIABILITIES_COLORS,
  MASTER_DATA_HEADER_BACKGROUND
};