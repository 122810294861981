import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Text from "./Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { getVirtualElement } from "../utils/UtilsFunc";
import DraggableItems from "./DraggableItems";
import _ from "lodash";
import addIcon from "../assets/img/icons/add_circle_outline.svg"
import addIconGrey from "../assets/img/icons/add_circle_outline_grey.svg"
import deleteIcon from "../assets/img/icons/delete_red.svg"
import { SEPARATOR } from "../constants/constant";
import { useFormikContext } from "formik";

function DynamicFields({
    label = "",
    errorText = "",
    inputProps = {},
    errorStyle = "",
    disabled = false,
    bgColor = "",
    labelStyles = "",
    containerfull = false,
    handleChange,
    handleBlur,
    value,
    autoComplete = 'off',
    isDraggable = false,
}) {
    const { errors } = useFormikContext();
    let inputClass = cx(
        "p-3 text-black-900 font-semibold relative text-md outline-none flex-1 mr-4",
        {
            "border-red-600": errorText,
            "focus:ring-blue-100": errorText,
            "focus:ring-red-100": errorText,
            "opacity-40 cursor-not-allowed pointer-events-none":
                disabled === true,
            [`${inputProps.styles}`]: true,
        }
    );
    let labelClass = cx("text-gray-500 mb-2 pb-5", {
        [`${labelStyles}`]: true,
    });

    var refElement = useMemo(() => {
        return getVirtualElement({
            name: inputProps.name,
            handleChange,
            handleBlur,
            type: "dynamic",
        });
    }, []);

    function handleInputChange(e, currId, isNote = false) {
        var inpValue = e.target.value;
        if (inputProps.name === "beneficiaries" && inpValue.indexOf(SEPARATOR) !== -1) {
            return
        }
        var newState = value.map(el => ({ ...el }));
        const idx = value.findIndex(el => el.id === currId);
        if (isNote) {
            newState[idx].note = inpValue;
        }
        else {
            newState[idx].val = inpValue;
        }
        refElement.setValue(newState);
    }

    function handleAdd() {
        var newState = value.map(el => ({ ...el }));
        if (inputProps.max) {
            if (newState.length >= inputProps.max) return;
        }
        let isFieldAddable = true;
        newState.forEach((val) => {
            if (!val.val) isFieldAddable = false;
        });
        if (isFieldAddable) {
            const maxId = Math.max(...newState.map(el => el.id));
            const newObj = { id: maxId + 1, val: "", }
            if (inputProps.name === "beneficiaries") {
                newObj.note = ""
            }
            newState.push(newObj);
            refElement.setValue(newState);
        }
    }

    function handleDelete(id) {
        const newState = value.filter(el => el.id !== id).map(el => ({ ...el }));
        refElement.setValue(newState);
    }

    function getContent(arrEle, idx) {
        return (
            <div className="relative pb-1 text-center flex flex-col items-center w-full">
                <div
                    className="relative text-center flex items-center w-full"
                    key={arrEle.id}
                >
                    {isDraggable && <FontAwesomeIcon
                        icon={faBars}
                        style={{ cursor: "grabbing" }}
                        className="w-3 h-3 m-auto mr-1.5"
                    />}
                    <input
                        className={inputClass}
                        onChange={(e) => handleInputChange(e, arrEle.id)}
                        onBlur={handleBlur}
                        {...inputProps}
                        name={`${inputProps.name}.${arrEle.id}`}
                        value={arrEle.val}
                        autoComplete={autoComplete}
                        style={inputProps.name === "beneficiaries" ? {
                            marginRight: 0
                        } : {}}
                    />
                    {inputProps.name === "beneficiaries" &&
                        <div className=" w-1/2 pl-5 flex items-center">
                            <textarea
                                className={inputClass}
                                onChange={(e) => handleInputChange(e, arrEle.id, true)}
                                onBlur={handleBlur}
                                {...inputProps}
                                name={`${inputProps.name}.note.${arrEle.id}`}
                                value={arrEle.note}
                                autoComplete={autoComplete}
                                rows={1}
                            />
                        </div>
                    }
                    {!isDraggable &&
                        <div className={`mr-2 w-5 h-5 flex-shrink-0 flex justify-center items-center`}>
                            <img
                                src={idx === 0 ? addIcon : deleteIcon}
                                className={`${idx === 0 ? "w-full h-full" : "w-4 h-4"} cursor-pointer`}
                                onClick={idx === 0 ? handleAdd : () => handleDelete(arrEle.id)}
                            />
                        </div>
                    }
                </div>
                <div className={"relative h-5 w-full"}>
                    {errors[inputProps.name] && errors[inputProps.name][idx]?.val && <Text
                        tag="small"
                        scale={true}
                        styles={`text-red-600 absolute w-full left-5`}
                        text={errors[inputProps.name][idx].val}
                    />}
                    {errors[inputProps.name] && errors[inputProps.name][idx]?.note && <Text
                        tag="small"
                        scale={true}
                        styles={`text-red-600 absolute left-1/2 pl-5`}
                        text={errors[inputProps.name][idx].note}
                    />}
                </div>
            </div>
        );
    }
    const reorder = (arr, start, end) => {
        arr = _.cloneDeep(arr)
        const [removed] = arr.splice(start, 1)
        arr.splice(end, 0, removed);
        return arr
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }

        const newVal = reorder(
            value,
            result.source.index,
            result.destination.index
        );
        refElement.setValue(newVal);
    }

    function renderInput() {
        if (isDraggable) {
            return <DraggableItems
                data={value}
                onDragEnd={onDragEnd}
                render={(item, index) => {
                    return getContent(item, index)
                }}
            />
        }
        let childrens = [getContent(value[0], 0)];


        return [
            ...childrens,
            value.slice(1).map((v, idx) => getContent(v, idx + 1)),
        ];
    }

    return (
        <div
            className={`text-left relative pb-4 ${containerfull ? "w-full" : ""}`}
        >
            {label && (
                <Text
                    tag="label"
                    scale={true}
                    styles={labelClass}
                    text={label}
                    alignment="left"
                    autoComplete={autoComplete}
                />
            )}
            {isDraggable
                ? (
                    <div className="flex">
                        <div className="flex-1">{renderInput()}</div>
                        <div className="w-5 mr-2 flex flex-col justify-around items-center">
                            {value.map((el, idx) => <img
                                key={el.id}
                                src={idx === 0 ? (value.length < inputProps.max ? addIcon : addIconGrey) : deleteIcon}
                                className={`${idx === 0 ? (value.length < inputProps.max ? "cursor-pointer" : "cursor-default") : "w-4 h-4 cursor-pointer"} -mt-5`}
                                onClick={idx === 0 ? handleAdd : () => handleDelete(el.id)}
                            />)}
                        </div>
                    </div>
                )
                : renderInput()}
            {errorText && (
                <Text
                    tag="small"
                    scale={true}
                    styles={`bottom-0 text-red-600 absolute w-full pt-3  ${errorStyle}`}
                    text={errorText}
                    autoComplete={autoComplete}
                />
            )}
        </div>
    );
}

DynamicFields.propTypes = {
    label: PropTypes.string,
    errorText: PropTypes.string,
    inputProps: PropTypes.object,
    errorStyle: PropTypes.string,
    disabled: PropTypes.bool,
    bgColor: PropTypes.string,
    labelStyles: PropTypes.string,
    containerfull: PropTypes.bool,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    value: PropTypes.array,
    autoComplete: PropTypes.string,
    isDraggable: PropTypes.bool,
};

export default DynamicFields;
