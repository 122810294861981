import * as yup from "yup";
import { addMemberType, memberSubType } from "./enum";
import { DateTime } from "luxon";

import getRequest from "../network/getRequest";
import { MAX_COUNT_FOR_CONTACT_INFO } from "../constants/constant";

const { fetchRequest } = getRequest()

const cacheTest = (asyncValidate) => {
    let _valid = false;
    let _value = '';

    return async (value) => {
        if (value !== _value) {
            _value = value;
            const response = await asyncValidate(value);
            _valid = response;
            return response;
        }
        return _valid;
    };
};

const actualValidityTest = Array(MAX_COUNT_FOR_CONTACT_INFO).fill(0).map(() => cacheTest(
    (value) => searchPhoneNumbers(value)
));


const searchPhoneNumbers = val => {
    return fetchRequest({ phoneNumber: val }, 'post', 'addClientPhone').then(res => {
        if (res?.error) {
            return true
        }
        return false;
    }).catch(err => {
        return true
    })
}

export const prospectsValidationSchema = yup.object().shape({
    info: yup.object({
        name: yup
            .string()
            .min(3, "Minimum of 3 characters are allowed")
            .max(30, "Maximum of 30 characters are allowed")
            .required("Name is required"),
            // .matches(/^[A-Za-z._ ]*$/, "Only text characters are allowed"),
        type: yup.string().required("Type is required"),
        worksAt: yup
        .string()
        .nullable()
        .min(3, "Minimum of 3 characters are allowed")
        .max(30, "Maximum of 30 characters are allowed"),
        activityStatus: yup
            .string()
            .required("Please select any one activity status"),
        leadStatus: yup.string().required("Please select any one lead status"),
        businessDeveloper: yup
            .string(),
            //.required("Please select any one Business Developer name"),
        wealthAdvisor: yup
            .string()
            .required("Please select any one Primary Advisor name"),
    }),
    prospectInterests: yup.object({
        financialLifeManagement: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        riskManagement: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        assetManagement: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        legacyPlanning: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        combineInterests: yup
            .string()
            .required("Please add any one of the service(s) values"),
    }),
    contact: yup.object().when("info", (val) => {
        if (val?.activityStatus === "CONVERTED") {
            return yup.object({
                phone: yup
                    .string()
                    .matches(
                        /^.{10,}$/,
                        "Please add valid phone number with area code"
                    ),
                source: yup.string().required("Please add Source Name"),
                address:yup.string().max(50, "Maximum of 50 characters are allowed"),
                other:yup.string().max(30, "Maximum of 30 characters are allowed"),
                email: yup
                    .string()
                    .required("Please add email Address")
                    .max(255, "Email can be up to 255 characters in length.")
                    .matches(
                        /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
                        "Please enter a valid email address."
                    )
            });
        }
        return yup.object({
            phone: yup
                .string()
                .matches(
                    /^.{10,}$/,
                    "Please add valid phone number with area code"
                ),
            source: yup.string().required("Please add Source Name"),
            address:yup.string().max(50, "Maximum of 50 characters are allowed"),
            other:yup.string().max(30, "Maximum of 30 characters are allowed"),
            email: yup.string().max(255, "Email can be up to 255 characters in length.").matches(
                /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
                "Please enter a valid email address."
            ),
        });
    }),
    activityError: yup.string().required("Please add at least one activity"),
});

export const activityValidationSchema = yup.object().shape({
    activityType: yup.string().required("Required"),
    activityDate: yup
    .string()
    .required("Required"),
    // .matches(
    //     /^\d{2}\/\d{2}\/\d{4}$/,
    //     "Please add valid date"
    // ),
    minutes: yup
        .string()
        .required("Required")
        .matches(/^[0-9]*$/, "Please Enter valid value"),
});

export const mfaVerificationCodeSchema = yup.object().shape({
        vcode: yup.string()
              .required("MFA code is required.")
              //.test('length', 'Code must be 6 digits.', (val) => { if(val){ return val.length === 6;}})
             // .matches(/^[0-9]+$/, "Please enter only numbers.")
});

export const specialDatesValidations = yup.object().shape({
    dateType: yup.string().required("Required"),
    date: yup
    .string()
    .required("Required")
    .test(
        'dateIsValid', 
        'Enter a valid Date', 
        (value) => {
            if(value)
            {
                let date = DateTime.fromFormat(value, 'yyyy-MM-dd');
                if (date.invalid === null) { return true}
                return false
            }
        }
    ),
});

export const specialDaySchema = yup.object({
    member: yup.string().required("Required"),
    dateType: yup.string().required("Required"),
    // .max(25, "Maximum of 25 characters are allowed"),
    date: yup.string().required("Required"),
    description: yup.string().max(75, "Description can be upto 75 characters. "),
});

export const clientSourcechema = yup.object({
    sources: yup.string().required("Required"),
    other: yup.string().max(30, "Maximum of 30 characters are allowed"),
});

export const milestoneSchema = yup.object({
    member: yup.string().required("Required"),
    fullName: yup
        .string()
        .required("Required")
        .max(60, "Maximum of 60 characters are allowed")
        .min(1, "Minimum of 1 characters are allowed"),
    description: yup.string().max(300, "Maximum of 300 characters are allowed"),
    dob: yup.string().required("Required"),
});

export const assetsLiabilitiesSchema = yup.object({
    assets: yup
    .string()
    .required("Required")
    .ensure()
    .test(
        "maxValueCheck",
        "Max limit should be 100 billion.",
        (value) => {
            let splitValueArray = []
            if(value?.includes('.'))
            {
                splitValueArray = value.split('.')
            }
            if(splitValueArray.length >0){ value = splitValueArray[0] }
            const updateVal = ("" + value).replace(/\D/g, "");
            if (Number(updateVal) > 100_000_000_000 ) return false;
            return true;
        }
    ),
    liabilities: yup
    .string()
    .required("Required")
    .ensure()
    .test(
        "maxValueCheck",
        "Max limit should be 100 billion.",
        (value) => {
            let splitValueArray = []
            if(value?.includes('.'))
            {
                splitValueArray = value.split('.')
            }
            if(splitValueArray.length >0){ value = splitValueArray[0] }
            const updateVal = ("" + value).replace(/\D/g, "");
            if (Number(updateVal) > 100_000_000_000 ) return false;
            return true;
        }
    ),
    // assets: yup
    // .string()
    // .required("Required")
    // .matches(/^([^0].*)([0-9]*)$/, "Please Enter Valid Value")
    //     .test(
    //         "maxValueCheck",
    //         "Max limit should be $100,000,000,000",
    //         (value) => {
    //             const updateVal = ("" + value).replace(/\D/g, "");
    //             if (Number(updateVal) > 100000000000) return false;
    //             return true;
    //         }
    //     ),
    // liabilities: yup.string()
    // .required("Required")
    // .matches(/^([^0].*)([0-9]*)$/, "Please Enter Valid Value")
    //     .test(
    //         "maxValueCheck",
    //         "Max limit should be $100,000,000,000",
    //         (value) => {
    //             const updateVal = ("" + value).replace(/\D/g, "");
    //             if (Number(updateVal) > 100000000000) return false;
    //             return true;
    //         }
    //     ),
});

export const addGoalSchema = yup.object({
    name: yup
        .string()
        .required("Please enter the goal name")
        .max(50, "Maximum of 50 characters are allowed"),
    // .matches(/^[A-Za-z ]*$/, "Only text characters are allowed"),
    goalPercentage: yup
        .number()
        .required("Please enter goal value achieved %")
        .min(0, "Only numbers are allowed up to 0-100")
        .max(100, "Only numbers are allowed up to 0-100"),
    projectedTime: yup
        .string()
        .required("Please select the goal projected month and year"),
});

export const addCustomSnoozeDateSchema = yup.object({
    date: yup.string().required("Please select the review snooze date"),
});

export const clientSinceDateSchema = yup.object({
    clientSince: yup.string().required("Date is required."),
});

export const dateSchema = yup.object({
    date: yup.string().required("Date is required."),
});

export const addCustomReceivedSchema = yup.object({
    receivedDate: yup.string().required("Please select the received date"),
});

export const addManagedAccountRiskSchema = yup.object({
    risk: yup
        .number()
        .required("Please enter the risk number")
        .min(1, "Only numbers are allowed up to 1-99")
        .max(99, "Only numbers are allowed up to 1-99"),

});

export const addClientMemberSchema = (memberType) =>
    yup.object({
        fullName: yup
            .string()
            .required("Name is required.")
            .min(3, "Name should be of minimum of 3 characters")
            .max(50, "Maximum of 50 characters are allowed")
            .matches(
                /^(?!\s+$).*/,
                "Name cannot contain only spaces."
            ),
        subType: yup.string().nullable(),
        date: yup
        .string()
        .when("subType", (subType) =>{
            if ( [memberSubType.Primary, memberSubType.Secondary].includes(subType)) {
                return yup.string().
                    required("Date is Required").typeError("")
            }
        }).notRequired().nullable().typeError(""),
        email: yup
            .string()
            .when("subType",(subType)=> {
                if( [memberSubType.Primary, memberSubType.Secondary].includes(subType) ) {
                    return yup.string().
                        required("Email is Required").max(255, "Email can be up to 255 characters in length.")
                        .typeError("")
                        .matches(
                        /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
                        "Please enter a valid email address."
                    );
                }
            }).notRequired().max(255, "Email can be up to 255 characters in length.").nullable().typeError(""),
        phone: yup
        .string()
        .nullable()
        //.required("Required")
        .matches(
          /^.{17}$/,
          "Please add valid phone number with area code"
        )
        .typeError(""),
        // identityNumber: yup
        //     .string()
        //     .nullable()
        //     .required("Enter up to 9-digit alphanumeric values")
        //     // .matches(
        //     //     memberType !== addMemberType.PERSON ? /^(?!0{2})(?!6{2})\d{2}-(?!0{7})\d{7}$/ : /^(?!0{3})(?!6{3})[0-8]\d{2}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
        //     //     memberType !== addMemberType.PERSON ? "Please enter valid TIN#" : "Please enter valid SS#"
        //     //   )
        //     .length(
        //         memberType !== addMemberType.PERSON ? 10 : 11,
        //         "Enter up to 9-digit Numbers"
        //     )
        //     .typeError(""),
        employer: yup
            .string()
            .nullable()
            /* .required("Enter the employer name in text format")
            .test(
                "requiredCheck",
                "Enter the employer name in text format",
                (value) => {
                    if (memberType === addMemberType.PERSON && !value)
                        return false;
                    return true;
                }
            )*/
            .max(30, "Maximum of 30 characters are allowed")
            // .matches(/^[A-Za-z ]*$/, "Enter the employer name in text format")
            .typeError(""),
        jobTitle: yup
            .string()
            .nullable()
            /*.required("Enter the Job Title in text and space format")
            .test(
                "requiredCheck",
                "Enter the employer name in text format",
                (value) => {
                    if (memberType === addMemberType.PERSON && !value)
                        return false;
                    return true;
                }
            )*/
            .max(30, "Maximum of 30 characters are allowed")
            .matches(
                /^[A-Za-z ]*$/,
                "Enter the Job Title in text and space format"
            )
            .typeError(""),
        address: yup
            .string()
            .nullable()
            //.required("Address is Required")
            .max(255, "Maximum of 255 characters are allowed")
            .typeError(""),
        riskScore: yup
            .number()
            .nullable()
            //.required("Risk Score is Required")
            .min(1, "Enter the Risk Score up to 1-100")
            .max(100, "Enter the Risk Score up to 1-100")
            .typeError(""),
    });

export const addClientMemberPetSchema = yup.object({
    firstName: yup.string().required("Required")
        .min(1, "First name should be of minimum of 1 character.")
        .max(50, "Maximum of 50 characters are allowed.")
        .matches(
            /^(?!\s+$).*/,
            "Name cannot contain only spaces."
        ),
    middleName: yup.string().max(50, "Maximum of 50 characters are allowed.").nullable(),
    lastName: yup.string().required("Required")
        .min(1, "Last Name should be of minimum of 1 character.")
        .max(50, "Maximum of 50 characters are allowed.")
        .matches(
            /^(?!\s+$).*/,
            "Name cannot contain only spaces."
        ),
    // age: yup
    // .number()
    // .max(150, "Age should not be greater than 150"),
    nickName: yup.string().max(50, "Maximum of 50 characters are allowed.").nullable(),
    suffix: yup.string().max(20, "Maximum of 20 characters are allowed.").nullable(),
    date: yup
        .string()
        .required("Date of Birth is required.")
        .test(
            'dateIsValid',
            'Enter a valid Date',
            (value) => {
                if (value) {
                    let date = DateTime.fromFormat(value, 'yyyy-MM-dd');
                    if (date.invalid === null) { return true }
                    return false
                }
            }
        ),
});

export const addWorkflowSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .max(50, "Maximum of 50 characters are allowed"),
    type: yup.string().required("Required"),
    description: yup.string().max(100, "Maximum of 100 characters are allowed"),
});

export const initiateWorkflowSchema = yup.object({
    client: yup
        .string()
        .required("Please select Client to initiate with Workflow request"),
    workflow: yup
        .string()
        .required("Please select a Workflow to initiate with Workflow request"),
});

export const addRiskManagementSchema = yup.object({
    member: yup.string().required("Required"),
    benefits: yup
        .string()
        .required("Required")
        .matches(/^([^0].*)([0-9]*)$/, "Please Enter Valid Value")
        .test(
            "maxValueCheck",
            "Max limit should be $1,000,000,000",
            (value) => {
                const updateVal = ("" + value).replace(/\D/g, "");
                if (Number(updateVal) > 1000000000) return false;
                return true;
            }
        ),
    value: yup
        .string()
        .required("Required")
        .matches(/^([^0].*)([0-9]*)$/, "Please Enter Valid Value")
        .test("maxValueCheck", "Max limit should be $20,000,000", (value) => {
            const updateVal = ("" + value).replace(/\D/g, "");
            if (Number(updateVal) > 20000000) return false;
            return true;
        }),
    annualPremium: yup
        .string()
        .required("Required")
        .matches(/^([^0].*)([0-9]{0,10})$/, "Please Enter Valid Value")
        .test(
            "maxValueCheck",
            "Max limit should be $1,000,000,000",
            (value) => {
                const updateVal = ("" + value).replace(/\D/g, "");
                if (Number(updateVal) > 1000000000) return false;
                return true;
            }
        ),
    paymentFrequency: yup.string().required("Required"),
    purchasedDate: yup.string().required("Required"),
    policyPeriod: yup.string().required("Required"),
    policyNumber: yup
        .string()
        .required("Required")
        .max(20, "Maximum of 20 characters are allowed"),
    policyType: yup.string().required("Required"),
    company: yup
        .string()
        .required("Required")
        .typeError(""),
    productName: yup.string().trim().required("Required").min(1, "Maximum of 1 character is allowed.").max(75, "Maximum of 75 characters are allowed."),
    commentBox: yup.string().max(500, "Maximum of 500 characters are allowed."),
    companyName: yup.string().when("companyTitle", (val) => {
        if (val && val.toLowerCase().trim() === "other") {
            return yup.string().trim().required("Company name is required.").max(50, "Maximum of 50 characters are allowed.")
        }
        return yup.string()
    }),
});

export const addLegacyPersonSchema = yup.object({
    member: yup.string().required("Please Select a Member"),
    financialPOA: yup.array().of(
        yup.object().shape({
            val: yup.string().max(50, "Maximum of 50 characters are allowed.")
        })
    ),
    healthcarePOA: yup.array().of(
        yup.object().shape({
            val: yup.string().max(50, "Maximum of 50 characters are allowed.")
        })
    ),
    guardian: yup.array().of(
        yup.object().shape({
            val: yup.string().max(50, "Maximum of 50 characters are allowed.")
        })
    ),
});

export const addLegacyPetSchema = yup.object({
    accountName: yup.string().required("Required"),
    taxId: yup.string().required("Required"),
    // .matches(/^[0-9]*$/, "Tax Id must only contains numbers"),
    trustors: yup.array().of(
        yup.object().shape({
            val: yup.string().max(50, "Maximum of 50 characters are allowed.")
        })
    ),
    trustees: yup.array().of(
        yup.object().shape({
            val: yup.string().max(50, "Maximum of 50 characters are allowed.")
        })
    ),
    beneficiaries: yup.array().of(
        yup.object().shape({
            val: yup.string().when("note", (note) => {
                if (note?.length > 0) {
                    return yup.string().trim().required("Required.").max(50, "Maximum of 50 characters are allowed.")
                }
                return yup.string().max(50, "Maximum of 50 characters are allowed.")
            }),
            note: yup.string().max(500, "Maximum of 500 characters are allowed."),
        })
    ),
});

export const addHouseholdGeneralInfoSchema = yup.object({
    contact: yup.object({
        address: yup
            .string()
            .required("Address is required")
            .max(50, "Maximum of 50 characters are allowed."),
        addressLine2: yup.string().max(50, "Maximum of 50 characters are allowed."),
        addressLine3: yup.string().max(50, "Maximum of 50 characters are allowed."),
        zip: yup
            .string()
            .required("Please enter Zip")
            .matches(/^[0-9]*$/, "Please Enter Valid Zip")
            .min(4, "Minimum 4 characters are required.")
            .max(5, "Maximum Characters allowed are 5"),
        city: yup
            .string()
            .required("City is required")
            .max(50, "Maximum of 50 characters are allowed."),
        state: yup.string().required("State is Required"),
    }),
    
 
    // wealthAdvisor: yup
    //     .string()
    //     .required("Please select wealth advisor"),
    // businessDeveloper: yup
    //     .string(),
    //     //.required("Please select business developer"),
});

export const addPayoutBlocksSchema = yup.object({
    type: yup.string().required("Required"),
    payee: yup.string().required("Required"),
    payoutPercentage: yup
        .number()
        .required("Please enter payout %")
        .min(0, "Enter only 0-100 numbers")
        .max(100, "Enter only 1-100 numbers"),
    startDate: yup.string().required("Required"),
    payoutBlockPaymentType: yup.string().required("Required"),
    numberOfPayments: yup.number().when("payoutBlockPaymentType", {
        is: "Number_Of_Payments",
        then: yup
            .number()
            .required("Please enter # of Payments")
            .min(1, "Enter only 1-60 numbers")
            .max(60, "Enter only 1-60 numbers")
            .typeError(""),
        otherwise: yup.number().notRequired().nullable(true).typeError(""),
    }),
    creditTo: yup
        .number()
        .required("Credit to % required")
        .min(0, "Enter only 0-100 numbers")
        .max(100, "Enter only 1-100 numbers"),
});

export const addReceivablePayoutBlocksSchema = (maxNumOfPayments) => yup.object({
    type: yup.string().required("Required"),
    payee: yup.string().required("Required"),
    appPercentage: yup
        .number()
        .required("Please enter % of App")
        .min(0, "Enter only 0-100 numbers")
        .max(100, "Enter only 0-100 numbers"),
    commissionPercentage: yup
    .number()
    .required("Please enter commission %")
    .min(0, "Commission % ranges from 0-300%.")
    .max(300, "Commission % ranges from 0-300%."),
    /*commission:yup
    .number()
    .required("Please enter commission"),*/
    startDate: yup.string().required("Required"),
    numberOfPayments: yup.number()
        // .when("payoutBlockPaymentType", {
        //     is: "Number_Of_Payments",
        //     then: yup
        //         .number()
        //         .required("Please enter # of Payments")
        //         .min(1, "Enter only 1-120 numbers")
        //         .max(120, "Enter only 1-120 numbers")
        //         .typeError(""),
        //     otherwise: yup.number().notRequired().nullable(true).typeError(""),
        // }),
        .required("Please enter # of Payments")
        .min(1, `Enter only 1-${maxNumOfPayments} numbers`)
        .max(maxNumOfPayments, `The number of payments cannot exceed the total product payment.`),
    creditTo: yup
        .number()
        .required("Credit to % required")
        .min(0, "Enter only 0-100 numbers")
        .max(100, "Enter only 0-100 numbers"),
});

export const addReceivablePayoutBlocksSchemaWithoutAppPercent = (maxNumOfPayments) => yup.object({
    type: yup.string().required("Required"),
    payee: yup.string().required("Required"),
    commissionPercentage: yup
    .number()
    .required("Please enter commission %")
    .min(0, "Commission % ranges from 0-300%.")
    .max(300, "Commission % ranges from 0-300%. "),
    /*commission:yup
    .number()
    .required("Please enter commission"),*/
    startDate: yup.string().required("Required"),
    numberOfPayments: yup.number()
        // .when("payoutBlockPaymentType", {
        //     is: "Number_Of_Payments",
        //     then: yup
        //         .number()
        //         .required("Please enter # of Payments")
        //         .min(1, "Enter only 1-120 numbers")
        //         .max(120, "Enter only 1-120 numbers")
        //         .typeError(""),
        //     otherwise: yup.number().notRequired().nullable(true).typeError(""),
        // }),
        .required("Please enter # of Payments")
        .min(1, `Enter only 1-${maxNumOfPayments} numbers`)
        .max(maxNumOfPayments, `The number of payments cannot exceed the total product payment.`),
    creditTo: yup
        .number()
        .required("Credit to % required")
        .min(0, "Enter only 0-100 numbers")
        .max(100, "Enter only 0-100 numbers"),
});


export const addReceivablePayoutBlocksSchemaAdmin = yup.object({
    totalAppPercentage: yup
        .number()
        .required("Please enter % of App")
        .min(1, "Enter a number between 1-100.")
        .max(100, "Enter a number between 1-100."),
    totalCommissionPercentage: yup
    .number()
    .required("Please enter commission %")
    .min(0, "Enter a number between 0-300.")
    .max(300, "Enter a number between 0-300."),
});

export const addReceivablePayoutBlocksSchemaWithoutAppPercentAdmin = yup.object({
 
    totalCommissionPercentage: yup
    .number()
    .required("Please enter commission %")
    .min(0, "Enter a number between 0-300")
    .max(300, "Enter a number between 0-300"),
});


export const addHouseholdFinancialPlanningSchema = yup.object({
    feeSchedule: yup.string().max(12, "Max limit should be $1,000,000,000"),
    payMethod: yup.string().required("Pay Method is required"),
    frequency: yup
        .string()
        .required("Frequency is required")
        .matches(/^([^0].*)([0-9]{0,10})$/, "Please Enter Valid Value")
        .test(
            "maxValueCheck",
            "Max limit should be $1,000,000,000",
            (value) => {
                const updateVal = ("" + value).replace(/\D/g, "");
                if (Number(updateVal) > 1000000000) return false;
                return true;
            }
        ),
    startDate: yup.string().required("Start Date is required"),
    feePayingAccount:yup.string().when("payMethod", {
        is: "INDIRECT",
        then: yup
            .string()
            .required("Please select an account")
            .typeError(""),
        otherwise: yup.string().notRequired().nullable(true).typeError(""),
    })
});

export const addHouseholdBillingInfoSchema = yup.object({
    achAccountType: yup.string().required("ACH Account Type is required"),
    accountNumber: yup
        .string()
        .required("Account Number is required")
        .test(
            'Is positive?', 
            'Account Number should be number', 
            (value) => value > 0
        )
        .max(17, "Should not be more than 17 digits"),
    routingNumber: yup
        .string()
        .required("Routing Number is required")
        .test(
            'Is positive?', 
            'Routing Number should be number', 
            (value) => value > 0
        )
        .max(9, "Should contain 9 digits only")
        .min(9, "Should contain 9 digits only")
        .typeError("Routing should be number"),
    firstName: yup
        .string()
        .required("First Name is required")
        .max(15, "Maximum characters allowed are 15"),
    lastName: yup
        .string()
        .required("Last Name is required")
        .max(15, "Maximum Characters allowed are 15"),
    billingAddressLine1: yup
        .string()
        .required("Address is required")
        .max(50, "Maximum Characters allowed are 50"),
    billingAddressLine2: yup
        .string()
        .max(50, "Maximum Characters allowed are 50"),
    city: yup
        .string()
        .required("City is required")
        .max(30, "Maximum Characters allowed are 30"),
    state: yup.string().required("State is Required"),
    country: yup
        .string()
        .required("Country is required")
        .max(50, "Maximum Characters allowed are 50"),
    emailAddress: yup
        .string()
        .required("Email Address is required")
        .max(255, "Email can be up to 255 characters in length.")
        .matches(
            /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
            "Please enter a valid email address."
        ),

    phoneNumber: yup
        .string()
        .required("Phone Number is required")
        .test('is-ten-digits', 'Invalid phone number. Please provide a 10-digit number.', (value) => {
            if (!value) return true; 
            return value.replace(/\D/g, '').length === 11; 
          }),
    zip: yup
        .string()
        .required("Please enter Zip")
        .matches(/^[0-9]*$/, "Please Enter Valid Zip")
        .min(4, "Zip Should be minimum of 4 Characters")
        .max(5, "Maximum Characters allowed are 5"),
});

export const pathwayToPartnershipSchema = yup.object({
    totalRisk1: yup
        .number()
        .required("Required")
        .min(1, "Minimum is 1")
        .max(2000000, "Maximum is 2000000"),
    totalRisk2: yup
        .number()
        .required("Required")
        .min(1, "Minimum is 1")
        .max(2000000, "Maximum is 2000000"),
    averageRisk1: yup
        .number()
        .required("Required")
        .min(1, "Minimum is 1")
        .max(2000000, "Maximum is 2000000"),
    averageRisk2: yup
        .number()
        .required("Required")
        .min(1, "Minimum is 1")
        .max(2000000, "Maximum is 2000000"),
    averageAdvisory1: yup
        .number()
        .required("Required")
        .min(1, "Minimum is 1")
        .max(2000000, "Maximum is 2000000"),
    averageAdvisory2: yup
        .number()
        .required("Required")
        .min(1, "Minimum is 1")
        .max(2000000, "Maximum is 2000000"),
    eligibility: yup
        .number()
        .required("Required")
        .min(1, "Minimum is 1")
        .max(2000000, "Maximum is 2000000"),
});

export const AddNewRoleGeneralSchema = yup.object({
    role_name: yup
        .string()
        .required("Required")
        .max(60, "Maximum of 60 characters are allowed"),
    description: yup.string().max(300, "Maximum of 300 characters are allowed"),
});

export const addFeeStructureSchema = (rows, editId) => yup.object({
    minimum: yup.string().required("Required")
    // .min(0, "Minimum limit should be $0")
        .ensure()
        .test(
            "minValueCheck",
            "Minimum limit should be $0",
            (value) => {
                const updateVal = parseFloat(("" + value).replace(/[$,]/g, ""));
                if (Number(updateVal) < 0) return false;
                return true;
            }
        )
        .test(
            "maxValueCheck",
            "Max limit should be $10,000,000,000",
            (value) => {
                let splitValueArray = []
                if (value?.includes('.')) {
                    splitValueArray = value.split('.')
                }
                if (splitValueArray.length > 0) { value = splitValueArray[0] }
                const updateVal = parseFloat(("" + value).replace(/[$,]/g, ""));
                if (Number(updateVal) > 10_000_000_000) return false;
                return true;
            }
        ).test("minRangeCheck", "This value is already added in the structure.", function (value) {
            const updateMinVal = parseFloat(("" + value).replace(/[$,]/g, ""));
            let presentInRange = false
            for (const rl of rows) {
                if (rl.id && (!editId || (editId && editId !== rl.id))) {
                    const currMin = +rl.minimum
                    const currMax = +rl.maximum
                    if (!isNaN(updateMinVal) && currMin <= updateMinVal && updateMinVal <= currMax) {
                        presentInRange = true
                    }
                }
            }
            return !presentInRange
        }).test("rangeCheck", "This value is already added in the structure.", function (value) {
            const updateMinVal = parseFloat(("" + value).replace(/[$,]/g, ""));
            const updateMaxVal = parseFloat(this.parent['maximum'].replace(/[$,]/g, ""));
            let presentInRange = false
            for (const rl of rows) {
                if (rl.id && (!editId || (editId && editId !== rl.id))) {
                    const currMin = +rl.minimum
                    const currMax = +rl.maximum
                    if (!isNaN(updateMinVal) &&
                        !isNaN(updateMaxVal) &&
                        updateMinVal < currMin &&
                        currMax < updateMaxVal
                    ) {
                        presentInRange = true
                        break
                    }
                }
            }
            return !presentInRange
        }),
    maximum: yup.string().required("Required")
        // .min(0, "Min limit should be $0")
        .ensure()
        .test(
            "minValueCheck",
            "Minimum limit should be $0",
            (value) => {
                const updateVal = parseFloat(("" + value).replace(/[$,]/g, ""));
                if (Number(updateVal) < 0) return false;
                return true;
            }
        )
        // .matches(/^[\d]*(\.[\d]{0,2})?$/, "Please Enter Valid Value")
        .test('greaterValue', 'Maximum ($) Value can never be equal to or less than Min ($) value',
            function () {
                let minimum =  parseFloat(this.parent['minimum'].replace(/[$,]/g, ""));
                let maximum =  parseFloat(this.parent['maximum'].replace(/[$,]/g, ""));
                return +maximum <= +minimum ? false : true;
        })
        // .matches(/^[\d]*(\.[\d]{0,2})?$/, "Please Enter Valid Value")
        .test(
            "maxValueCheck",
            "Max limit should be $10,000,000,000",
            (value) => {
                let splitValueArray = []
                if (value?.includes('.')) {
                    splitValueArray = value.split('.')
                }
                if (splitValueArray.length > 0) { value = splitValueArray[0] }
                const updateVal = parseFloat(("" + value).replace(/[$,]/g, ""));
                if (Number(updateVal) > 10_000_000_000) return false;
                return true;
            }
        ).test("maxRangeCheck", "This value is already added in the structure.", function (value) {
            const updateMaxVal = parseFloat(("" + value).replace(/[$,]/g, ""));
            let presentInRange = false
            for (const rl of rows) {
                if (rl.id && (!editId || (editId && editId !== rl.id))) {
                    const currMin = +rl.minimum
                    const currMax = +rl.maximum
                    if (!isNaN(updateMaxVal) && currMin <= updateMaxVal && updateMaxVal <= currMax) {
                        presentInRange = true
                    }
                }
            }
            return !presentInRange
        }).test("rangeCheck", "This value is already added in the structure.", function (value) {
            const updateMaxVal = parseFloat(("" + value).replace(/[$,]/g, ""));
            const updateMinVal = parseFloat(this.parent['minimum'].replace(/[$,]/g, ""));
            let presentInRange = false
            for (const rl of rows) {
                if (rl.id && (!editId || (editId && editId !== rl.id))) {
                    const currMin = +rl.minimum
                    const currMax = +rl.maximum
                    if (!isNaN(updateMinVal) &&
                        !isNaN(updateMaxVal) &&
                        updateMinVal < currMin &&
                        currMax < updateMaxVal
                    ) {
                        presentInRange = true
                        break
                    }
                }
            }
            return !presentInRange
        }),
    rate: yup.string()
        .required("Please enter rate")
        .min(1, "Min limit should be 1")
        .matches(/^[\d]*(\.[\d]{0,2})?$/, "Please Enter Valid Value")
        .test(
            "maxValueCheck",
            "Max limit should be $1,000,000",
            (value) => {
                const updateVal = ("" + value);
                if (Number(updateVal) > 10_00_000) return false;
                return true;
            }
        ),
    payById: yup.string().required("Required"),
});

export const feeSchedulesGeneralSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .max(50, "Name should be upto 50 characters in length"),
    type: yup.string()
        .required("Required"),
    description: yup.string()
        .max(50, "Maximum of 50 characters are allowed"),
    annualMinimum: yup
        .string()
        .required("Please enter annual minimum")
        .matches(/^([^0].*)([\d]{0,10})$/, "Please Enter Valid Value")
        .test("maxValueCheck", "Max limit should be $100,000", (value) => {
            const updateVal = ("" + value).replace(/\D/g, "");
            if (Number(updateVal) > 1_000_00) return false;
            return true;
        }),
   // basis: yup.string().required("Required"),
});

export const receivableSchema = yup.object({
    productType: yup.string().required("Product Type is required"),
    receivableProductList: yup
        .string()
        .required("Product is required")
        .typeError(""),
    accountHolder: yup
        .string()
        .required("Account Holder is required")
        .min(1, "Minimum Character is 1")
        .max(30, "Maximum Characters allowed are 30")
        .typeError(""),
    description: yup.string().max(50, "Maximum Characters allowed are 50"),
    amount: yup
        .string()
        .required("Amount/Premium value is required")
        //.matches(/^([^0].*)([0-9]{0,10})$/, "Please Enter Valid Value")
        .test(
            "maxValueCheck",
            "Max limit should be $50,000,000",
            (value) => {
                let splitValueArray = []
                if(value?.includes('.'))
                {
                    splitValueArray = value.split('.')
                }
                if(splitValueArray.length >0){ value = splitValueArray[0] }
                const updateVal = ("" + value).replace(/\D/g, "");
                if (Number(updateVal) > 50000000 ) return false;
                return true;
            }
        )
        .test(
            "minValueCheck",
            "Min Limit should be $1",
            (value) => {
                const updateVal = ("" + value).replace(/\D/g, "");
                if (Number(updateVal) < 1 ) return false;
                return true;
            }
        ),

    paymentFrequency: yup
        .string()
        .required("Payment Frequency is required"),
    numberOfPayments:yup
            .number()
            .required("Please enter # of Payments")
            .min(1, "Enter only 1-120 numbers")
            .max(120, "Enter only 1-120 numbers")
            .typeError(""),
    receivableProviderList: yup
        .string()
        .required("Provider is required")
        .typeError(""),
    startDate: yup.string().required("Start Date is required"),
});

export const AddNewTeamFormSchema = yup.object({
    name: yup
        .string()
        .required("Team Name is required")
        .max(30, "Maximum of 30 characters are allowed"),
    location: yup
    .string()
    .required("Location should not be empty")
    .max(30, "Maximum of 30 characters are allowed"),
    emailAlias: yup
        .string()
        .required("Email Alias is required")
        .max(255, "Email can be up to 255 characters in length.")
        .matches(
            /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
            "Please enter alias a valid email address."
        )
});

export const generateBillSchema = yup.object({
    billType: yup.string().required("Please select Bill Type"),
    forecastLiveBill: yup.string().required('Please select Forecast Bill / Live Bill'),
    billFor: yup.string().required('Please select Pay For'),
    billedUser: yup.string().when('billFor', {
        is: (billFor => (billFor === 'ALL_UNITS') ? false : true),
        then: yup.string().required('Please select Unit / WA1 / Account')
    }).nullable()
})

export const productSchema = yup.object({
    assetClass: yup
        .string()
        .required("Asset Class is required"),
    assetCategory: yup
        .string(),
    productType: yup
        .string()
        .required("Product Type is required"),
    addAssetCategory: yup
        .string()
        .required("ADV Asset Category is required"),
    avdReportable: yup.boolean(),
    fReportable: yup.boolean()
})

export const addAccountsGeneralSchema = yup.object({
    clients: yup.string().required('Please select household members'),
    member: yup.string().required('Please select member'),
    registrationName: yup.string().required('Required')
})

export const billingAccountFormSchema = yup.object({
    frequency: yup.string().required("Required"),
    payMethod: yup.string().required("Required"),
    feeSchedule: yup.string().required("Required"),
    startDate: yup.string().required("Required"),
    accountObjective: yup.string().required("Required"),
})

export const paysForFormSchema = yup.object({
    accountPayee: yup.string().required('Please select account'),
    payPercent: yup.number('Only numbers are allowed.')
        .required('Please enter pay %')
        .min(1, 'Enter only 1-100 numbers')
        .max(100, 'Enter only 1-100 numbers')
})

export const addStrategySetupSchema = yup.object({
    strategyType: yup.string().required("Required"),
    target: yup
        .number()
        .required("Please enter target %")
        .min(1, "Enter only 1-100 numbers")
        .max(100, "Enter only 1-100 numbers"),
    lowerTolerance: yup
        .number()
        .required("Please enter lower tol %")
        .min(0, "Enter only 0-25 numbers")
        .max(25, "Enter only 0-25 numbers"),
    upperTolerance: yup
        .number()
        .required("Please enter upper tol %")
        .min(0, "Enter only 0-25 numbers")
        .max(25, "Enter only 0-25 numbers"),
    viewableByClient: yup.string().required("Required"),

})

export const excludeAssetFormSchema = yup.object({
    assignmentType: yup.string().required("Please select assignment type"),
    category: yup.string().required("Please select category"),
    applyToAllAccounts: yup.boolean(),
    account:yup.array().when('applyToAllAccounts', {
        is: (applyToAllAccounts => (applyToAllAccounts) ? false : true),
        then: yup.array().required('Please select Account')
    }).nullable()
})

export const investmentSchema = yup.object({
    // minAnnualFee: yup.string()
    //     .ensure()
    //     .required('Please enter investment fees')
    //     .matches(/^(\$)?([\w ]+)[[,\d]]*(\.[\d]{0,2})?$/, "Please enter valid value")
    minAnnualFee: yup.string()
        .required('Please enter investment fees')
        .matches(/^([^0].*)([0-9]{0,10})$/, "Please enter valid value")
        .test(
            "maxValueCheck",
            "Min annual investment fee cannot exceed 1 billion.",
            (value) => {
                const updateVal = ("" + value).replace(/[$,]/g, "");
                if (Number(updateVal) > 1000_000_000) return false;
                return true;
            })
})

export const forReviewTransactionSchema = yup.object({
  
    transaction: yup.array()?.of(
        yup.object()?.shape({
          strategies: yup.string().required("Please select a strategy"),
          percent: yup.number()
            .required("Please enter percentage")
            .min(0.01, "Value should be 0-100")
            .max(100, "Value should be 0-100"),
                })
      )

})

export const userCommissionSchema = yup.object({
    name: yup
        .string()
        .required("Required"),
    type: yup.string()
        .required("Required"),
    value: yup.number()
        .required("Required"),
});
export const userWorkflowSchema = yup.object({
    name: yup.string()
    .required("Required")
    .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
        if((val==undefined)||val.trim().length===0){
            return false
        }
        return true
    }),
    description: yup.string()
    .required("Required")
    .test('lengthCheck','Description cannot contain only spaces.',(val)=>{
        if((val==undefined)||val.trim().length===0){
            return false
        }
        return true
    }),
});
export const registrationTypeSchema = yup.object({
    possibleValue: yup.string()
    .required("Required")
     .test('lengthCheck','Possible Value cannot contain only spaces.',(val)=>{
        if((val==undefined)||val.trim().length===0){
            return false
        }
        return true
    }),
possibleValueDescription: yup.string()
    .required("Required")
     .test('lengthCheck','Possible Value Description cannot contain only spaces.',(val)=>{
        if((val==undefined)||val.trim().length===0){
            return false
        }
        return true
    }),
});
export const securityTypeSchema = yup.object({
    possibleValue: yup.string()
        .required("Required")
         .test('lengthCheck','Possible Value cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
    possibleValueDescription: yup.string()
        .required("Required")
         .test('lengthCheck','Possible Value Description cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});
export const strategyTypesSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});

export const politicalPartySchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});

export const profilePersonalDetailsSchema = yup.object({
    dob: yup.string()
        .required('Date of Birth is required.'),
    designation: yup.string()
        .required('Occupation is required.'),
    address: yup.string()
        .required('Mailing Address is required.'),
})

export const addEditProspectSchema = yup.object({
    info: yup.object({
        firstName: yup
            .string()
            .min(1, "Minimum of 1 characters are allowed.")
            .max(50, "Maximum of 50 characters are allowed.")
            .required("First name is required."),
        lastName: yup
            .string()
            .min(1, "Minimum of 1 characters are allowed.")
            .max(50, "Maximum of 50 characters are allowed.")
            .required("Last name is required."),
        middleName: yup
            .string()
            .max(50, "Maximum of 50 characters are allowed."),
        suffix: yup
            .string()
            .max(20, "Maximum of 20 characters are allowed."),
        nickName: yup
            .string()
            .max(50, "Maximum of 50 characters are allowed."),
        gender: yup.string().required("Gender is required."),
        type: yup.string().required("Type is required."),
        worksAt: yup
            .string()
            .nullable()
            .max(75, "Maximum of 75 characters are allowed."),
        activityStatus: yup
            .string()
            .required("Please select any one activity status"),
        leadStatus: yup.string().required("Please select any one lead status"),
        businessDeveloper: yup
            .string(),
            //.required("Please select any one Business Developer name"),
        wealthAdvisor: yup
            .string()
            .required("Please select any one Primary Advisor name"),
    }),
    services: yup.object({
        financialLifeManagement: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        riskManagement: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        assetManagement: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        legacyPlanning: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        privateFund: yup
            .string()
            .nullable()
            .matches(/^([^0].*)([0-9]{0,12})$/, "Please Enter Valid Value")
            .test(
                "maxValueCheck",
                "Max limit should be 100 billion.",
                (value) => {
                    const updateVal = ("" + value).replace(/\D/g, "");
                    if (Number(updateVal) > 100000000000) return false;
                    return true;
                }
            ),
        // combineInterests: yup
        //     .string()
        //     .required("Please add any one of the service(s) values"),
    }),
    contact: yup.object().when("info", (val) => {
        if (val?.activityStatus === "CONVERTED") {
            return yup.object({
                addressLine1: yup.string().required("Address line 1 is required.").max(50, "Maximum of 50 characters are allowed."),
                addressLine2: yup.string().max(50, "Maximum of 50 characters are allowed."),
                city: yup.string().required("City is required.").min(1, "Minimum of 1 character is allowed.").max(30, "Maximum of 30 characters are allowed."),
                state: yup.string().required("State is required."),
                zip: yup.string().required("Zip is required.").min(4, "Minimum 4 characters are required.").max(5, "Maximum of 5 characters are allowed."),
                sources: yup.string().required("Source is required."),
                phone: yup
                    .string()
                    .required("Phone is required.")
                    .matches(
                        /^.{10,}$/,
                        "Please add valid phone number with area code"
                    ).test('is-ten-digits', 'Invalid phone number. Please provide a 10-digit number.', (value) => {
                        if (!value) return true; 
                        return value.replace(/\D/g, '').length === 11; // Check if value contains 10 digits
                      }),
                other: yup.string().max(30, "Maximum of 30 characters are allowed"),
                email: yup
                    .string()
                    .required("Please add email Address")
                    .max(255, "Email can be up to 255 characters in length.")
                    .matches(
                        /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
                        "Please enter a valid email address."
                    )
            });
        }
        return yup.object({
            addressLine1: yup.string().required("Address line 1 is required.").max(50, "Maximum of 50 characters are allowed."),
            addressLine2: yup.string().max(50, "Maximum of 50 characters are allowed."),
            city: yup.string().required("City is required.").min(1, "Minimum of 1 character is allowed.").max(30, "Maximum of 30 characters are allowed."),
            state: yup.string().required("State is required."),
            zip: yup.string().required("Zip is required.").min(4, "Minimum 4 characters are required.").max(5, "Maximum of 5 characters are allowed."),
            sources: yup.string().required("Source is required."),
            phone: yup
                .string()
                .required("Phone is required.")
                .matches(
                    /^.{10,}$/,
                    "Please add valid phone number with area code"
                ).test('is-ten-digits', 'Invalid phone number. Please provide a 10-digit number.', (value) => {
                    if (!value) return true; 
                    return value.replace(/\D/g, '').length === 11; // Check if value contains 10 digits
                  }),
            other:yup.string().max(30, "Maximum of 30 characters are allowed"),
            email: yup.string().required("Email is required.").max(255, "Email can be up to 255 characters in length.").matches(
                /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
                "Please enter a valid email address."
            ),
        });
    }),
    activities: yup.array().min(1, "Please add at least one activity.").required("Please add at least one activity."),
})

export const statesTypesSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck', 'Name cannot contain only spaces.', (val) => {
            if ((val == undefined) || val.trim().length === 0) {
                return false
            }
            return true
        }),
    countryId: yup
        .string()
        .required("Required"),
    abbrevation: yup
        .string()
        .required("Required"),
});
export const countryTypesSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck', 'Name cannot contain only spaces.', (val) => {
            if ((val == undefined) || val.trim().length === 0) {
                return false
            }
            return true
        }),
    abbrevation: yup
        .string()
        .required("Required"),
});
export const religiousSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});
export const sourcesSchema = yup.object({
    name: yup
        .string()
        .required("Required")   
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});
export const specificInterestSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});

export const sexualOrientationSchema = yup.object({
    name: yup
    .string()
    .required("Required")
    .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
        if((val==undefined)||val.trim().length===0){
            return false
        }
        return true
    }),
});
export const commonDataSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});
export const commisionTypeSchema = yup.object({
    name: yup.string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
        productType: yup.string()
        .required("Required")
        .test('lengthCheck','Receivable Type cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});
export const providerSchema = yup.object({
    name: yup.string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
    file: yup.mixed().test('required', 'File is required', function(value) {
        return value && value.size > 0;
    }).test("fileSize", "The file is greater than 512kb", (value) => {        
        if(typeof value==='string'){
            return true
        }
            return value && value.size <= 524288;
        }).test("fileType", "Only jpg, jpeg, and png files are allowed", (value) => {
        if(typeof value==='string'){
            return true
        }
            if (!value) {
              return true; 
            }
            const supportedFormats = ["image/jpg", "image/jpeg", "image/png"];
            return supportedFormats.includes(value.type);
          })
});

export const otherProffSchema = yup.object({
    type: yup.string().nullable().required("Type is required."),
    fullName: yup.string().nullable().required("Full name is required.").min(3, "Name should be between 3-75 chars in length").max(75,"Name should be between 3-75 chars in length"),
    phoneNumber: yup.string().test('is-ten-digits', 'Invalid phone number. Please provide a 10-digit number.', (value) => {
        if (!value) return true; 
        return value.replace(/\D/g, '').length === 11; // Check if value contains 10 digits
      }),
    address: yup
    .string()
    .required("Address is required.")
    .min(3, "Address should be between 3-150 chars in length")
    .max(150, "Address should be between 3-150 chars in length"),
    email: yup.string().required("Email is required.")
    .min(3, "Email should be between 3-255 chars in length.")
    .max(255, "Email should be between 3-255 chars in length. ")
    .matches(
        /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
        "Enter a valid Email. "
    ),
    note: yup.string().max(150)
});

export const addMemberBasicSchema = (subType, memberType) => yup.object({
    firstName: yup
        .string()
        .required("First name is required.")
        .min(1, "First name should be of minimum of 1 character.")
        .max(50, "Maximum of 50 characters are allowed.")
        .matches(
            /^(?!\s+$).*/,
            "Name cannot contain only spaces."
        ),
    middleName: yup.string().max(50, "Maximum of 50 characters are allowed.").nullable(),
    lastName: yup
        .string()
        .required("Last name is required.")
        .min(1, "Last Name should be of minimum of 1 character.")
        .max(50, "Maximum of 50 characters are allowed.")
        .matches(
            /^(?!\s+$).*/,
            "Name cannot contain only spaces."
        ),
    subType: yup.string().nullable(),
    suffix: yup.string().max(20, "Maximum of 20 characters are allowed.").nullable(),
    nickName: yup.string().max(50, "Maximum of 50 characters are allowed.").nullable(),
    date: [addMemberType.BUSINESS, addMemberType.TRUST].includes(memberType) && subType && [memberSubType.Primary, memberSubType.Secondary].includes(subType)
        ? yup.string().required("Date is Required")
        : yup.string().nullable(),
    ...(memberType !== addMemberType.PERSON ? {
        identityNumber: yup
            .string()
            .test("length-check", "TIN# should be between 10 to 11 characters", (val) => {
                if (val) {
                    if ([10, 11].includes(val.length)) {
                        return true
                    }
                    return false
                }
                return true
            })
            .nullable(),
    } : {}),
    annualIncome:yup
    .string()
    .nullable()
    .matches(/^([^0].*)([0-9]{0,10})$/, "Please Enter Valid Value")
    .test(
        "maxValueCheck",
        "Max limit should be 1 billion.",
        (value) => {
            const updateVal = ("" + value).replace(/\D/g, "");
            if (Number(updateVal) > 1000_000_000) return false;
            return true;
        }
    ),
    linkedinUrl: yup
    .string()
    .nullable()
    .test(
        "linkedinUrlCheck",
        "Enter a valid LinkedIn profile URL",
        (value) => {
        if (!value) {
            return true;
        }
        if (value.includes("linkedin.com/in/")) {
            return true;
        }
        return false;
        }
    ),

})

export const addMemberPreferenceSchema = (memberType) => yup.object().shape({
    riskScore: yup.number()
        .nullable()
        .min(1, "Risk Score ranges from 1 to 100.")
        .max(100, "Risk Score ranges from 1 to 100."),
    ...(memberType === addMemberType.PERSON ? {
        gender: yup
            .string()
            .required("Gender is required."),
        marriageStatus: yup
            .string()
            .required("Marriage status is required.")
    } : {})
})

export const addMemberContactSchema = (subType, contactInfo) => yup.object().shape({
    phoneNumbers: yup.array()
        .of(
            yup.object().shape({
                type: yup.string().when("phone", (phone) => {
                    if (phone && phone.length > 0) {
                        return yup.string().required('Required')
                    }
                }),
                ...((subType && memberSubType.Primary === subType)?{}:{
                    linkedToPrimary:yup.bool()
                }),
                phone: ((subType && memberSubType.Primary === subType) ?
                    yup.string().test("required-check", "Phone number is required.", function (val, context) {
                        const phoneArr = context.from[1].value.phoneNumbers.filter(ph => (ph.phone && ph.phone.length > 0))
                        return phoneArr.length > 0
                    })
                    : yup.string()
                ).matches(/^.{10,}$/, "Please add valid phone number with area code")
                .test('is-ten-digits', 'Invalid phone number. Please provide a 10-digit number.', (value) => {
                    if (!value) return true; 
                    return value.replace(/\D/g, '').length === 11; // Check if value contains 10 digits
                  })
                    .test("duplicate-phone", "This phone number is already added. Enter another phone number.", function (val, context) {
                        const currIdx = +context.path.replace(/\D/g, '')
                        const phoneArr = context.from[1].value.phoneNumbers
                        let retVal = true
                        phoneArr.forEach((el, idx) => {
                            if (idx !== currIdx) {
                                if (el.phone === val) {
                                    retVal = false
                                    return
                                }
                            }
                        })
                        return retVal
                    }).test('check-phone-availability', 'This phone number is already in use.', function (value, context) {
                        const linkedToPrimary = context.parent?.linkedToPrimary
                        if (linkedToPrimary) {
                            return true
                        }
                        const phoneNumber = value?.replace(/\D/g, '').slice(1);
                        const idx = context.options.index;
                        // let initialValue = contactInfo?.phoneNumbers[idx]?.phone
                        let initialValue = ""
                        let initialLinkToPrimary=false;
                        const parentId = context.parent?.id;
                        if (parentId) {
                            initialLinkToPrimary=contactInfo?.phoneNumbers.find(el => el.id === parentId)?.linkedToPrimary
                            initialValue = contactInfo?.phoneNumbers.find(el => el.id === parentId)?.phone
                        }
                        if (value &&( initialValue !== value||initialLinkToPrimary)) {
                            return actualValidityTest[idx](phoneNumber).then(res => res)
                        } else {
                            return true;
                        }
                    })
            })
        ).test("primary-checked", "You need to add 1 primary phone number for this member.", function (val) {
            const filledVal = val.filter(v => v.phone && v.phone.length > 0)
            if (filledVal.length > 0) {
                const isPrimaryChecked = filledVal.some(v => v.isPrimary)
                return isPrimaryChecked
            }
            return true
        }).notRequired(),
    emailAddresses: yup.array().of(
        yup.object().shape({
            type: yup.string().when("email", (email) => {
                if (email && email.length > 0) {
                    return yup.string().required('Required')
                }
            }),
            email: (subType && [memberSubType.Primary, memberSubType.Secondary].includes(subType) ?
                yup.string().test("required-check", "Email is required.", function (val, context) {
                    const emailArr = context.from[1].value.emailAddresses.filter(ph => (ph.email && ph.email.length > 0))
                    return emailArr.length > 0
                })
                    .max(255, "Email can be up to 255 characters in length.")
                    .typeError("")
                    .matches(
                        /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/,
                        "Please enter a valid email address."
                    )
                : yup
                    .string()
                    .notRequired().max(255, "Email can be up to 255 characters in length.").nullable().typeError("")
            )
            .test("duplicate-email", "This email is already added. Enter another email.", function (val, context) {
                const currIdx = +context.path.replace(/\D/g, '')
                const phoneArr = context.from[1].value.emailAddresses
                let retVal = true
                phoneArr.forEach((el, idx) => {
                    if (idx !== currIdx) {
                        if (el.email === val) {
                            retVal = false
                            return
                        }
                    }
                })
                return retVal
            }),
        })
    ).test("primary-checked", "You need to add 1 primary email for this member.", function (val) {
        const filledVal = val.filter(v => v.email && v.email.length > 0)
        if (filledVal.length > 0) {
            const isPrimaryChecked = filledVal.some(v => v.isPrimary)
            return isPrimaryChecked
        }
        return true
    }).notRequired(),
    addresses: yup.array().of(
        yup.object().shape({
            addressLine1: ((subType && memberSubType.Primary === subType)
                ? yup.string().required("Address Line 1 is required.")
                : yup.string().when(["city", "state", "zip", "addressLine2", "type"], {
                    is: (val1, val2, val3, val4, val5) => val1 !== undefined || val2 !== undefined || val3 !== undefined || val4 !== undefined || val5 !== undefined,
                    then: yup.string().required("Address Line 1 is required."),
                    otherwise: yup.string()
                })).max(50, "Maximum of 50 characters are allowed."),
            addressLine2: yup.string().max(50, "Maximum of 50 characters are allowed.").nullable(),
            city: ((subType && memberSubType.Primary === subType)
                ? yup.string().required("City is required.")
                : yup.string().when(["addressLine1", "state", "zip", "addressLine2", "type"], {
                    is: (val1, val2, val3, val4, val5) => val1 !== undefined || val2 !== undefined || val3 !== undefined || val4 !== undefined || val5 !== undefined,
                    then: yup.string().required("City is required."),
                    otherwise: yup.string()
                })).max(30, "Maximum of 30 characters are allowed."),
            state: (subType && memberSubType.Primary === subType)
                ? yup.string().required("State is required.")
                : yup.string().when(["addressLine1", "city", "zip", "addressLine2", "type"], {
                    is: (val1, val2, val3, val4, val5) => val1 !== undefined || val2 !== undefined || val3 !== undefined || val4 !== undefined || val5 !== undefined,
                    then: yup.string().required("State is required."),
                    otherwise: yup.string()
                }),
            zip: (subType && memberSubType.Primary === subType)
                ? yup.string().required("Zip is required.").min(4, "Minimum 4 characters are required.").max(5, "Maximum of 5 characters are allowed.")
                : yup.string().when(["addressLine1", "city", "state", "addressLine2", "type"], {
                    is: (val1, val2, val3, val4, val5) => val1 !== undefined || val2 !== undefined || val3 !== undefined || val4 !== undefined || val5 !== undefined,
                    then: yup.string().required("Zip is required.").min(4, "Minimum 4 characters are required.").max(5, "Maximum of 5 characters are allowed."),
                    otherwise: yup.string()
                }),
            type: yup.string().when(["addressLine1", "addressLine2", "city", "state", "zip"], {
                is: (val1, val2, val3, val4, val5) => val1 !== undefined || val2 !== undefined || val3 !== undefined || val4 !== undefined || val5 !== undefined,
                then: yup.string().required("Required."),
                otherwise: yup.string()
            }),
        }, [
            ["addressLine1", "city"],
            ["addressLine1", "state"],
            ["addressLine1", "zip"],
            ["addressLine1", "type"],
            ["city", "addressLine1"],
            ["city", "state"],
            ["city", "zip"],
            ["city", "type"],
            ["state", "addressLine1"],
            ["state", "city"],
            ["state", "zip"],
            ["state", "type"],
            ["zip", "addressLine1"],
            ["zip", "city"],
            ["zip", "state"],
            ["zip", "type"],
            ["type", "addressLine1"],
            ["type", "city"],
            ["type", "state"],
            ["type", "zip"],
        ])
    ).test("primary-checked", "You need to add 1 primary address for this member.", function (val) {
        const filledVal = val.filter(v => v.addressLine1 && v.addressLine1.length > 0)
        if (filledVal.length > 0) {
            const isPrimaryChecked = filledVal.some(v => v.isPrimary)
            return isPrimaryChecked
        }
        return true
    }).notRequired(),
})

export const addMemberIdentitySchema = (subType, memberType, showSsIdentity) => yup.object().shape({
    date: subType && [memberSubType.Primary, memberSubType.Secondary].includes(subType)
        ? yup.string().required("DOB is required.")
        : yup.string().notRequired(),
    ...(memberType === addMemberType.PERSON ? {
        identityNumber: yup
            .string()
            .test("length-check", "SSN should be 9 digits.", (val) => {
                if (val) {
                    const actualSSN = val.replace(/[^0-9]/g, '');
                    if (actualSSN.length === 9 || actualSSN.length === 4 && !showSsIdentity) {
                        return true
                    }
                    return false
                }
                return true
            })
            .nullable(),
    } : {})
})

export const addMemberEmploymentSchema = (memberType) => yup.object().shape(
    memberType === addMemberType.PERSON ? {
        employer: yup.string().max(30, "Maximum of 30 characters are allowed.").nullable(),
        jobTitle: yup.string().max(30, "Maximum of 30 characters are allowed.").nullable(),
        employmentStatus: yup.array().min(1, "Employment status is required.").required("Employment status is required.")
    } : {}
)

export const genderSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});
export const marriagestatusSchema = yup.object({
    name: yup
        .string()
        .required("Required")
        .test('lengthCheck','Name cannot contain only spaces.',(val)=>{
            if((val==undefined)||val.trim().length===0){
                return false
            }
            return true
        }),
});
export const ageRangeSchema = yup.object({
    minAge: yup
        .string()
        .required("Required"),
    maxAge: yup
        .string()
        .test("maxValueCheck",
        "Max age should be greater than Min age required",
        function (val){
            if(val){
                let minimum = Number(this.parent['minAge']);
                let maximum = Number(this.parent['maxAge']);
                return +maximum <= +minimum ? false : true;
            }
            return true
        })
});


export const addMemberDeceasedDateSchema = yup.object({
    deceased: yup.boolean().oneOf([true], "Required."),
});
export const privateFundSchema=yup.object({
    privateFund:yup.string()
.nullable()
.matches(/^([^0].*)([0-9]{0,10})$/, "Please Enter Valid Value")
.test(
    "maxValueCheck",
    "Max limit should be 1 billion.",
    (value) => {
        const updateVal = ("" + value).replace(/\D/g, "");
        if (Number(updateVal) > 1000_000_000) return false;
        return true;
    }
)
})

export const addSuitabilitySchema = yup.object({
    annualIncome: yup.string().required("Required."),
    estNetWorth: yup.string().required("Required."),
    liquidNetWorth: yup.string().required("Required."),
    taxStatus: yup.string().required("Required."),
    liquidityNeeds: yup.string().required("Required."),
    riskScore: yup.number().nullable().min(1, "Risk Score ranges from 1 to 100.").max(100, "Risk Score ranges from 1 to 100."),
    timeHorizon: yup.string().required("Required."),
    notes: yup.string().max(500, "Maximum of 500 characters are allowed."),
    stocks: yup.number().required("Required.").min(0, "Stocks Experience ranges from 0 to 21.").max(21, "Stocks Experience ranges from 0 to 21."),
    bonds: yup.number().required("Required.").min(0, "Bonds Experience ranges from 0 to 21.").max(21, "Bonds Experience ranges from 0 to 21."),
    commodities: yup.number().required("Required.").min(0, "Commodities Experience ranges from 0 to 21.").max(21, "Commodities Experience ranges from 0 to 21."),
    options: yup.number().required("Required.").min(0, "Options Experience ranges from 0 to 21.").max(21, "Options Experience ranges from 0 to 21."),
    mutualFunds: yup.number().required("Required.").min(0, "Mutual Funds Experience ranges from 0 to 21.").max(21, "Mutual Funds Experience ranges from 0 to 21."),
    realEstate: yup.number().required("Required.").min(0, "Real Estate Experience ranges from 0 to 21.").max(21, "Real Estate Experience ranges from 0 to 21."),
})
export const introducedBySchema = yup.object({
    introducedBy: yup.string().required("Required"),
});

export const familySchema = yup.object({
    memberId: yup.string().required("Required"),
    relation: yup.string().required("Required"),
    relativeId: yup.string().required("Required"),
});

export const introductionSchema = yup.object({
    introduction: yup.string().required("Required"),
});


export const manageTeamBillingUnit = yup.object({
    wealthAdvisor: yup.string().required("Please select wealth advisor"),
    businessDeveloper: yup.string(),
})
