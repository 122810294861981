import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
const Text = ({
    autocomplete= false,
    styles = "",
    tag = "",
    scale = true,
    text = "",
    fontweight = "normal",
    truncate = false,
    alignment = "left",
    width = "",
    onMouseOver = () => { },
    onMouseLeave = () => { },
}) => {
    const Tag = `${tag}`;
    const tagClass = cx({
        truncate: truncate === true,
        [`text-${alignment}`]: true,
        "2xs:text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl 2xl:text-6xl h1":
            tag === "h1" && scale === true,
        "2xs:text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl 2xl:text-5xl h2":
            tag === "h2" && scale === true,
        "2xs:text-lg xs:text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl h3":
            tag === "h3" && scale === true,
        "2xs:text-base xs:text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl 2xl:text-3xl h4":
            tag === "h4" && scale === true,
        "2xs:text-sm xs:text-base sm:text-lg md:text-xl lg:text-xl xl:text-xl 2xl:text-xl h5":
            tag === "h5" && scale === true,
        "2xs:text-xs xs:text-sm sm:text-base md:text-base lg:text-base xl:text-base 2xl:text-base h6":
            tag === "h6" && scale === true,
        "2xs:text-2xs xs:text-xs sm:text-sm md:text-sm lg:text-sm xl:text-sm 2xl:text-sm span":
            tag === "span" && scale === true,
        "2xs:text-2xs xs:text-xs sm:text-xs md:text-sm lg:text-sm xl:text-md 2xl:text-md label":
            tag === "label" && scale === true,
        "2xs:text-3xs xs:text-2xs sm:text-2xs md:text-2xs lg:text-xs xl:text-xs 2xl:text-xs small":
            tag === "small" && scale === true,
        "2xs:text-2xs xs:text-xs sm:text-2xs md:text-2xs lg:text-2xs xl:text-xs 2xl:text-sm p":
            tag === "p" && scale === true,
        "2xs:text-2xs xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md li":
            tag === "li" && scale === true,
        "text-4xl": tag === "h1" && scale === false,
        "text-3xl": tag === "h2" && scale === false,
        "text-2xl": tag === "h3" && scale === false,
        "text-xl": tag === "h4" && scale === false,
        "text-lg": tag === "h5" && scale === false,
        "text-base": tag === "h6" && scale === false,
        "text-sm inb": tag === "span" && scale === false,
        "text-sm label": tag === "label" && scale === false,
        "text-xs": tag === "small" && scale === false,
        "text-sm": tag === "p" && scale === false,
        "text-sm list": tag === "li" && scale === false,
        [`font-${fontweight}`]: true,
        [styles]: true,
    });
    return <Tag onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} style={{ width: width }} className={tagClass} autoComplete={"" + autocomplete}>{text}</Tag>;
};

Text.propTypes = {
    tag: PropTypes.string,
    scale: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alignment: PropTypes.string,
    fontweight: PropTypes.string,
    styles: PropTypes.string,
    truncate: PropTypes.bool,
    autocomplete: PropTypes.bool,
    width: PropTypes.string,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

export default Text;
