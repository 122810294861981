import React, { useState } from 'react'
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { useFormikContext } from "formik";
import MaterialInput from './MaterialField';
import Text from '../../commonComponent/Text';
import Button from '../../commonComponent/Button';
import Section from '../Pages/Prospects/Section';
import _ from 'lodash';
import calendar from "../../assets/img/icons/calendar.svg";
import { useEffect } from 'react';
import useDatePickerRef from '../../hooks/useDatePickerRef';

const SubForm = ({ name, subForm, wrapperStyles, sectionTitle, listRenderer, transformOnSave, subFormWrapperStyles, isSubmitting, handleCancelSubForm }) => {
  const [showSubForm, setShowSubForm] = useState(false)
  const { values, setFieldValue, errors, setFieldError } = useFormikContext();
  const [editId, setEditId] = useState("")
  const [saveClicked, setSaveClicked] = useState(false)
  const [showError, setShowError] = useState(false)
  const{datepickerRef,handleClickDatepickerIcon}=useDatePickerRef()

  const getInitialValue = () => {
    return subForm.reduce((acc, val) => {
      let name = val.inputProps.name;
      if (name?.split(".").length === 1) {
        return {
          ...acc,
          [val.inputProps.name]: val.initialValue ?? "",
        };
      }

      var [main, subMain] = name.split(".");
      return {
        ...acc,
        [main]: {
          ...acc[main],
          [subMain]: val.initialValue ?? "",
        },
      };
    }, {});
  }

  const getInitialTouchedValue = () => {
    return subForm.reduce((acc, val) => {
      if (val.required) {
        let name = val.inputProps.name;
        if (name?.split(".").length === 1) {
          return {
            ...acc,
            [val.inputProps.name]: false
          };
        }

        var [main, subMain] = name.split(".");
        return {
          ...acc,
          [main]: {
            ...acc[main],
            [subMain]: false
          },
        };
      }
      return acc
    }, {});
  }

  const [formData, setFormData] = useState(getInitialValue());

  const [touched, setTouched] = useState(getInitialTouchedValue());

  useEffect(() => {
    if (isSubmitting) {
      setSaveClicked(true)
    }
  }, [isSubmitting])

  const handleSubmit = () => {
    const updatedTouched = {}
    let errorExists = false
    Object.keys(touched).forEach(key => {
      errorExists = errorExists || !formData[key]
      updatedTouched[key] = true
    })
    setTouched(updatedTouched)
    if (errorExists) return;

    const arr = values[name] ?? []
    let formToAdd = {}
    subForm.forEach(sf => {
      if (sf.inputProps.transformValue) {
        formToAdd[sf.inputProps.name] = sf.inputProps.transformValue(formData[sf.inputProps.name])
      }
      else {
        formToAdd[sf.inputProps.name] = formData[sf.inputProps.name]
      }
    })
    if (editId) {
      const idx = values[name].findIndex(el => el.id === editId)
      const updatedArr = _.cloneDeep(values[name])

      formToAdd = {
        ...updatedArr[idx],
        ...formToAdd,
      }
      if (transformOnSave) {
        const [transFormedData, hasError] = transformOnSave(formToAdd, name, values, setFieldError)
        formToAdd = transFormedData
        if (hasError) {
          setShowError(true)
          return
        }
      }
      updatedArr[idx] = formToAdd

      setFieldValue(name, updatedArr)
    }
    else {
      if (transformOnSave) {
        const [transFormedData, hasError] = transformOnSave(formToAdd, name, values, setFieldError)
        formToAdd = transFormedData
        if (hasError) {
          setShowError(true)
          return
        }
      }
      setFieldValue(name, arr.concat(formToAdd))
    }
    setShowSubForm(false)
    setFormData(getInitialValue())
    setEditId("")
  }

  const onEditHandler = (initialValue, id) => {
    setEditId(id)
    setFormData(initialValue)
    setShowSubForm(true)
  }

  const onDeleteHandler = (id) => {
    const updatedArr = values[name].filter(el => el.id !== id)
    setFieldValue(name, updatedArr)
  }

  const updateStateHandler = (id, nameState, val) => {
    const idx = values[name].findIndex(el => el.id === id)
    const updatedArr = _.cloneDeep(values[name])

    updatedArr[idx][nameState] = val
    setFieldValue(name, updatedArr)
  }

  const renderSubForm = () => {
    return <div className={subFormWrapperStyles}>
      {subForm.map(sf => {
        return <div key={sf.key} className={"pb-4"}>
          {sf.outerLabel && <Text
            tag="label"
            scale={true}
            styles={"text-xs text-gray-500"}
            text={sf.outerLabel}
            alignment="left"
          />}
          {sf.eletype === "date" ?<>
            <div className='relative'>
              <DatePicker
                autoComplete='off'
                dateFormat="MM/dd/yyyy"
                // required="required"
                onChange={(e) => { setFormData(prevState => ({ ...prevState, [sf.inputProps.name]: e })) }}
                onBlur={() => setTouched(prevState => ({ ...prevState, [sf.inputProps.name]: true }))}
                peekNextMonth
                showMonthDropdown
                selected={formData[sf.inputProps.name]}
                showYearDropdown
                adjustDateOnChange
                excludeDates={[new Date('12/31/1899')]}
                // onChangeRaw={e => e?.preventDefault()}
                dropdownMode="select"
                placeholderText="MM/dd/yyyy"
                className={`border bg-gray-100 border-solid mt-2 mb-4 px-3 py-3 text-black-900 font-semibold relative rounded text-md outline-none w-full ${touched[sf.inputProps.name] && !formData[sf.inputProps.name]
                  ? "border-red-600"
                  : "border-gray-200"
                  }`}
                {...sf.inputProps}
                ref={datepickerRef}
              />
              <span className="absolute bottom-7 right-4" onClick={() => handleClickDatepickerIcon()}>
              <img
                  src={calendar}
                  className="w-7 h-7 m-auto "
                />
              </span>
            </div>
              <Text
                tag="p"
                scale={true}
                styles={`text-red-600 -mt-4 text-sm small`}
                text={touched[sf.inputProps.name] && formData[sf.inputProps.name].length === 0 && sf.errorText}
              />
              </>
            :
            <MaterialInput
              key={sf.key}
              {...sf}
              inputProps={{
                ...sf.inputProps,
                onChange: (e) => {
                  const val = e.target.value
                  setFormData(prevState => ({ ...prevState, [sf.inputProps.name]: val }))
                },
                onBlur: () => setTouched(prevState => ({ ...prevState, [sf.inputProps.name]: true })),
                value: formData[sf.inputProps.name]
              }}
              errorText={touched[sf.inputProps.name] && !formData[sf.inputProps.name] && sf.errorText}
            />
          }
        </div>
      })}
      <div className="flex justify-end items-center">
        <Button
          text="Cancel"
          styles="mr-6"
          action={() => {
            if (_.isFunction(handleCancelSubForm)) {
              handleCancelSubForm(setFieldError);
            }
            setShowSubForm(false)
          }}
        />
        <Button
          text="Save"
          type="button"
          action={handleSubmit}
          btnColor="text-blue-800"
        />
      </div>
    </div>
  }

  return <div className={`${wrapperStyles}`}>
    <Section
      title={sectionTitle}
      action={
        <p
          className="text-blue-700 text-xs cursor-pointer"
          onClick={() => {
            setShowSubForm(true);
            setFormData(getInitialValue())
            setTouched(getInitialTouchedValue())
          }}
        >
          <span className="text-sm">+</span>ADD
        </p>
      }
    />
    {((saveClicked || showError) && errors[name]) && <div>
      <Text
        tag="small"
        scale={true}
        styles={`text-red-600`}
        text={errors[name]}
        alignment="left"
      />
    </div>}
    {showSubForm && renderSubForm()}
    <div className={subFormWrapperStyles}>
      {listRenderer(values[name], name, onEditHandler, onDeleteHandler, updateStateHandler)}
    </div>
  </div>

}

SubForm.propTypes = {
  name: PropTypes.string,
  subForm: PropTypes.array,
  wrapperStyles: PropTypes.string,
  sectionTitle: PropTypes.string,
  listRenderer: PropTypes.func,
  transformOnSave: PropTypes.func,
  subFormWrapperStyles: PropTypes.string,
  isSubmitting: PropTypes.bool,
  handleCancelSubForm: PropTypes.func,
}

export default SubForm