import React from 'react';
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom";
import Button from './Button';
import errorIcon from "../assets/img/icons/error.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    return (this.state.errorInfo) ?
      (
        <div className='content-container p-5 flex justify-center items-center flex-col'>
          <img src={errorIcon} alt={"Error"} className="w-20 h-20" />
          <h2 className='mt-2'>Oops! Something went wrong.</h2>
          <details className='mb-2' style={{ whiteSpace: 'pre-wrap', textAlign: "center" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ? this.state.errorInfo.componentStack : null}
          </details>
          <div>
            <Button
              text={"Back"}
              color="white"
              btnColor="bg-blue-200 "
              styles="p-2 min-w-60 rounded-lg cursor-pointer text-sm text-blue-500 m-2"
              width="32"
              action={() => {
                this.props.history.goBack();
              }}
            />
          </div>
        </div>
      ) : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
  history: PropTypes.object
};

export default withRouter(ErrorBoundary);
