import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import Image from "./Image";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPencilAlt,
    faEye,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Text from "./Text";

const Anchor = ({
    isFull = false,
    isActive = false,
    redirectPath = "",
    classes = "",
    item = {},
    separator = false,
    clickEvent = null,
    menuTitle = "",
    bagdeClasses = "",
    subLinkActive = false,
    imageStyle="",
    disabled=false
}) => {
    let linkClass = cx(classes, {
        "w-full": isFull === true,
        "text-sidebarActive bg-blue-100": isActive === true,
        "text-sidebarActive bg-blue-50": subLinkActive === true,
        "flex items-center": _.has(item, "iconAction"),
        "text-gray-300 cursor-not-allowed bg-gray-300":disabled===true,
    });
    let badgeClass = cx(
        "ml-auto text-xs font-medium tracking-wide rounded-full h-7 w-7 pb-0.5 flex items-center justify-center",
        {
            [`${bagdeClasses}`]: true,
            "bg-red-500": bagdeClasses?.length === 0,
            "text-white": bagdeClasses?.length === 0,
        }
    );
    const icon = _.has(item, "icon") && item.icon!== null && item.icon!== undefined && (
        <span className={`inline-flex justify-center items-center ${item.iconStyle ? item.iconStyle : "mx-4"}`}>
            {_.has(item, "icon") && (
                <Image
                    img={(_.has(item, "iconUrl") && item.iconUrl)? item.icon : require(`../assets/img/icons/${item.icon}`).default}
                    alt={item.title}
                    imgClass= {imageStyle === "" ? "h-4 w-4": imageStyle}
                />
            )}
        </span>
    );
    const iconAction = _.has(item, "iconAction") && (
        <FontAwesomeIcon
            icon={
                item.iconAction === "delete"
                    ? faPencilAlt
                    : item.iconAction === "back"
                    ? faArrowLeft
                    : faEye
            }
            className="w-3 h-3 m-auto"
        />
    );
    const title = _.has(item, "title") && (
        <span className={item.itemStyle ? item.itemStyle : "ml-2 mr-2 truncate font-bold"}>
            {item.title}
        </span>
    );

    const badge = _.has(item, "badge") &&  item?.badge?.toString().trim().length > 0 && (
        <span className={badgeClass}>{(item?.badge || 0)}</span>
    );
    const handleClick = (e) => {
        if (typeof clickEvent === "function" && redirectPath.length > 0) {
            clickEvent(e);
        } else if (
            typeof clickEvent === "function" &&
            redirectPath.length <= 0
        ) {
            e.preventDefault();
            if(disabled){
                return;
            }
            clickEvent(e);
        }
    };

    const renderAnchorTag = () => {
        if (separator) {
            return <hr />;
        } else if (menuTitle !== "") {
            return (
                <Text
                    tag="small"
                    scale={true}
                    styles="text-100 text-left"
                    text={menuTitle}
                    // alignment="left"
                />
            );
        } else {
            return (
                <Link
                    className={linkClass}
                    to={redirectPath}
                    onClick={handleClick}
                >
                    {icon}
                    {iconAction}
                    {title}
                    {badge}
                </Link>
            );
        }
    };

    return renderAnchorTag();
};

Anchor.propTypes = {
    isFull: PropTypes.bool,
    isActive: PropTypes.bool,
    redirectPath: PropTypes.string,
    classes: PropTypes.string,
    item: PropTypes.object,
    clickEvent: PropTypes.func,
    separator: PropTypes.bool,
    menuTitle: PropTypes.string,
    bagdeClasses: PropTypes.string,
    subLinkActive: PropTypes.bool,
    imageStyle: PropTypes.string,
    disabled:PropTypes.bool
};

export default Anchor;
