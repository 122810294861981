import React from "react";
import PropTypes from "prop-types";
import Image from "./Image";
import cx from "classnames";
import Text from "../commonComponent/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Button = ({
    text = "",
    type = "button",
    width = "auto",
    img = "",
    btnColor = "",
    color = "gray",
    action = null,
    disabled = false,
    styles = "",
    icon = {},
    rounded = "none",
    passRef = null,
    fweight = "bold",
    iconStyles = "",
    updateStyle = false
}) => {
    let btnClass = cx({
        [`${styles}`]: true,
        [`w-${width}`]: true,
        [`${btnColor}`]: true,
        [`text-${color}`]: true,
        "opacity-50 cursor-not-allowed pointer-events-none": disabled === true,
        [`rounded-${rounded}`]: true,
        "outline-none focus:outline-none ease-linear transition-all duration-150 min-w-60": true,
    });
    let iconClass = cx({
        [`${iconStyles}`]: true,
    });
    // w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none
    return (
        <button
            ref={passRef}
            className={btnClass}
            type={type}
            onClick={action}
            disabled={disabled}
        >
            {img.length > 0 && <Image imgClass={`${!updateStyle && `h-5 w-5`}`} img={img} />}
            {Object.keys(icon).length > 0 && (
                <span className={iconClass}>
                    <FontAwesomeIcon icon={icon} />
                </span>
            )}
            {text.length > 0 && (
                <Text
                    tag="span"
                    scale={true}
                    styles={
                        img.length > 0 || icon.length > 0
                            ? "ml-2"
                            : `font-${fweight}`
                    }
                    text={text}
                    alignment="center"
                />
            )}
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.string,
    img: PropTypes.string,
    btnColor: PropTypes.string,
    fweight: PropTypes.string,
    color: PropTypes.string,
    styles: PropTypes.string,
    icon: PropTypes.object,
    rounded: PropTypes.string,
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    disabled: PropTypes.bool,
    passRef: PropTypes.object,
    iconStyles: PropTypes.string,
    updateStyle: PropTypes.bool,
};

export default Button;
