import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Text from "../../commonComponent/Text";

const Field = ({
    eletype = "input",
    label = "",
    errorText = "",
    inputProps = {},
    hasIcon = "",
    errorStyle = "",
    disabled = false,
    bgColor = "",
    readOnly = false,
    options = [],
    render = (text) => text,
    styles = "",
    labelStyles = "",
    prefixValue = "",
    containerfull = false,
    handleChange,
    handleBlur,
    value = "",
    width = 'full',
    autoComplete='off'
}) => {
    let inputClass = cx(
        `p-3 text-black-900 font-semibold relative text-md outline-none w-${width}`,
        {
            "pl-10": hasIcon === "true",
            "border-red-600": errorText,
            "focus:ring-blue-100": errorText,
            "focus:ring-red-100": errorText,
            "opacity-40 cursor-not-allowed pointer-events-none":
                disabled === true,
            [`${styles}`]: true,
            "left-2/5": prefixValue,
        }
    );

    let labelClass = cx("text-gray-500 mb-2 pb-5", {
        [`${labelStyles}`]: true,
    });

    function renderInput() {
        if (inputProps.type === "date") {
            return <div></div>;
        }

        return (
            <div className="relative pb-1 text-center">
                <p className="absolute top-2">{prefixValue}</p>
                <input
                    className={inputClass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                    {...inputProps}
                    autoComplete={autoComplete}
                />
            </div>
        );
    }

    const renderText=()=>{
        return(
            <p>Household</p>
        )
    }

    function renderSelect() {
        return (
            <select
                className={inputClass}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                {...inputProps}
            >
                {options.map((option) => {
                    if (option.isDisabled) {
                        return (
                            <option
                                key={option.key}
                                className="bg-white text-black hover:bg-sidebarActive"
                                disabled
                                value=""
                                selected={option.isSelected}
                            >
                                {option.label}
                            </option>
                        );
                    }
                    return (
                        <option
                            className="bg-white text-black hover:bg-sidebarActive"
                            key={option.key}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    );
                })}
            </select>
        );
    }

    function renderTextarea() {
        return (
            <textarea
                row="70"
                col="50"
                className={inputClass}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                autoComplete={autoComplete}
                {...inputProps}
            ></textarea>
        );
    }

    function renderAutocomplete() {
        return (
            <select
                className={inputClass}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                {...inputProps}
            >
                {options.map((option) => {
                    return (
                        <option
                            className="bg-white text-black hover:bg-sidebarActive"
                            key={option.key}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    );
                })}
            </select>
        );
    }

    function renderFileInput() {}

    function renderElement() {
        switch (eletype) {
            case "select":
                return renderSelect();
            case "input":
                return renderInput();
            case "autocomplete":
                return renderAutocomplete();
            case "textarea":
                return renderTextarea();
            case "fileinput":
                return renderFileInput();
            case "text":
                return renderText();
        }
    }

    return (
        <div
            className={`text-left relative pb-4 ${
                containerfull ? "w-full" : ""
            }`}
        >
            {label && (
                <Text
                    tag="label"
                    scale={true}
                    styles={labelClass}
                    text={label}
                    autoComplete={autoComplete}
                    alignment="left"
                />
            )}
            {renderElement()}
            {errorText && (
                <Text
                    autoComplete={autoComplete}
                    tag="small"
                    scale={true}
                    styles={`bottom-0 text-red-600 absolute w-full pt-3  ${errorStyle}`}
                    text={errorText}
                />
            )}
        </div>
    );
};

Field.propTypes = {
    eletype: PropTypes.string,
    hasIcon: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    bgColor: PropTypes.string,
    readOnly: PropTypes.bool,
    inputProps: PropTypes.object,
    errorStyle: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    render: PropTypes.func,
    styles: PropTypes.string,
    labelStyles: PropTypes.string,
    prefixValue: PropTypes.string,
    containerfull: PropTypes.bool,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    value: PropTypes.string,
    width: PropTypes.string,
    autoComplete: PropTypes.string
};

export default Field;
