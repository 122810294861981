import React, {useContext} from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router'
import { UserContext } from '../contextAPI';
import PropTypes from "prop-types";

const SESSION_IDLE_MINUTES = process.env.REACT_APP_LOGOUT_IDLE_MINUTES;

const AutoLogoutTimer = ({ComposedClass}) => {
    const history = useHistory()
   const {clearUser} = useContext(UserContext);

    const handleOnIdle = (event) => {
        // SHOW YOUR MODAL HERE AND LOGOUT
        clearUser();
        localStorage.clear();
        //history.push("/");
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    return <ComposedClass/>
}
AutoLogoutTimer.propTypes = {
    ComposedClass: PropTypes.elementType,
    path: PropTypes.string,
    permission: PropTypes.array
  };

  function areEqual(prevProps, nextProps) {
    if(prevProps.location.pathname === nextProps.location.pathname)
    {
        return true;
    }
    return false;
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  }
  
  
export default React.memo(AutoLogoutTimer, areEqual);