import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import AccessCheck from "./AccessCheck";

const OptionsMenu = ({
    dropdownRef,
    searchResults,
    selectClick,
    compactView = false,
    inputRef = null,
    searchTerm = "",
    autoComplete = "off",
    handleSearchChange = () => { },
    dropdownInputClass = "",
    height = '48',
    disabled,
    deletePermissionRequired = false,
    checkPermissionRequired = false,
    permissionFeature = null,
}) => {

    let dropdownClass = classNames({
        "absolute shadow bg-white top-14 z-40 w-full lef-0 rounded max-h-select overflow-y-auto ": true,
        "h-48": compactView,
        [`h-${height}`]: true
    });

    const inputTag = (item) => {
        return <input
            type="checkbox"
            checked={item.checked}
            id={item.value}
            value={item.value}
            disabled={disabled}
            onChange={(e) => {
                selectClick(e, item);
            }}
            className={`${compactView ? "mt-2" : ""}`}
        />
    }

    const renderCheckbox = (item) => {
        if (deletePermissionRequired && permissionFeature && item.checked) {
            return (
                <AccessCheck feature={permissionFeature} permissionType="delete" elementType="FormElement" noDiv>
                    {inputTag(item)}
                </AccessCheck>
            )
        }
        if (checkPermissionRequired && permissionFeature && !item.checked) {
            return (
                <AccessCheck feature={permissionFeature} permissionType="write" elementType="FormElement" noDiv>
                    {inputTag(item)}
                </AccessCheck>
            )
        }
        return inputTag(item)
    }

    return (
        <div id="dropdown" ref={dropdownRef} className={dropdownClass}>
            <div className="flex flex-col w-full py-3">
                {compactView && <div className="flex">
                    <input
                        ref={inputRef}
                        placeholder="Search By Name"
                        readOnly={false}
                        value={searchTerm}
                        autoComplete={autoComplete}
                        onChange={handleSearchChange}
                        className={dropdownInputClass}
                        onMouseLeave={() => {
                            inputRef?.current?.blur();
                        }}
                    />
                </div>}
                {searchResults && searchResults.length === 0 && (
                    <div className="text-center text-sm mb-4">
                        No Data Available
                    </div>
                )}
                {searchResults && searchResults.map((item, key) => {
                    return (
                        <div
                            key={key}
                            className={`cursor-pointer w-full rounded-t ${compactView && "px-2"} `}
                        >
                            {compactView && key !== 0 && <hr />}
                            <div className={`flex w-full items-center justify-between px-5 py-2 relative ${item.className}`}>
                                <label className="w-full cursor-pointer" htmlFor={item.value}>{item.label ? item.label : item.title}</label>
                                {renderCheckbox(item)}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

OptionsMenu.propTypes = {
    selectClick: PropTypes.func,
    searchResults: PropTypes.array,
    dropdownRef: PropTypes.node,
    compactView: PropTypes.bool,
    inputRef: PropTypes.object,
    searchTerm: PropTypes.string,
    autoComplete: PropTypes.string,
    handleSearchChange: PropTypes.func,
    dropdownInputClass: PropTypes.string,
    height: PropTypes.string,
    disabled: PropTypes.bool,
    deletePermissionRequired: PropTypes.bool,
    permissionFeature: PropTypes.string,
    checkPermissionRequired: PropTypes.bool,
};

export default OptionsMenu;
