import React from "react";
import PropTypes from "prop-types";

function MarkButton({ icon, handler, isVisible, styles = "" }) {
    return (
        isVisible && (
            <img
                src={icon}
                size="xs"
                fontWeight="normal"
                onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(".editor").focus();
                    setTimeout(() => {
                        handler();
                    }, 10);
                }}
                className={styles}
            />
        )
    );
}

MarkButton.propTypes = {
    icon: PropTypes.string,
    handler: PropTypes.func,
    isVisible: PropTypes.bool,
    styles: PropTypes.string,
};

export default MarkButton;
