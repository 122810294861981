import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import Button from "../../../commonComponent/Button";
import Element from "./Element";

import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccessCheck from '../../../commonComponent/AccessCheck'
import useUserContext from "../../../hooks/useUserContext";
import { UserContext } from "../../../contextAPI";
import SubForm from "../SubForm";
import ProfileImageComponent from "../../Pages/Prospects/ProfileImageComponent";
import Notes from "../../Pages/Prospects/Notes";
import CustomFieldArray from "../CustomFieldArray";
import { replaceAllObjValues } from "../../../utils/UtilsFunc";
import { isFunction } from "lodash";
import Loader from "../../../commonComponent/Loader";

/* 

let initialData = [
    { 
        key: 'key',
        initialValue: '', 
        eletype: 'input'   // select, autocomplete, fileinput, input
        inputProps: {
            type: 'text',
            placeholder: 'Enter Name',
            name: 'name'
            styles: ''
        }
    },
    { 
        key: 'key',
        initialValue: '', 
        eletype: 'select'   // select, autocomplete, fileinput,
        inputProps: {
            type: 'text',
            placeholder: 'Select Name',
            name: 'name'
            styles: ''
        },
        options: [{ 
            label: 'Label 1',
            value: 'value 1'
        }]
    }
];

*/

function Form({
    initialData,
    validationSchema = {},
    handleSubmit = null,
    handleCancel = null,
    handleUpdate = () => { },
    handleDelete = () => { },
    submitButton,
    cancelButton,
    updateButton,
    deleteButton,
    formStyles = "",
    header = null,
    bodyStyles = "",
    actionStyles = "",
    editInitialData = {},
    columnWidthArray = [],
    rowButtonRender = false,
    validationRef = null,
    trackSaveBtnClick = false,
    detectChangeHandler = null,
    extraButton = null,
}) {

    const { setFormApiState, formApiState } = useUserContext(UserContext)
    const formikRef = useRef()

    const initialValues = useMemo(() => {
        editInitialData = editInitialData ? editInitialData : {};
        return initialData.filter(e => !(e.eletype === "section")).reduce((acc, val) => {
            if (val.eletype === "sub-form") {
                let name = val.name;
                return {
                    ...acc,
                    [name]: editInitialData[name]
                }
            }
            else if (val.eletype === "fileinput_profile") {
                val.subForm.forEach(element => {
                    let name = element.inputProps.name;

                    if (name.split(".").length === 1) {
                        acc = {
                            ...acc,
                            [name]: editInitialData[name]
                                ? editInitialData[name]
                                : element.initialValue,
                        };
                    }

                    const [main, subMain] = name.split(".");
                    acc = {
                        ...acc,
                        [main]: {
                            ...acc[main],
                            [subMain]: editInitialData[main][subMain]
                                ? editInitialData[main][subMain]
                                : element.initialValue,
                        },
                    };
                })

                let name = val.inputProps.name;

                if (name.split(".").length === 1) {
                    acc = {
                        ...acc,
                        [name]: editInitialData[name]
                            ? editInitialData[name]
                            : val.initialValue,
                    };
                }

                const [main, subMain] = name.split(".");
                acc = {
                    ...acc,
                    [main]: {
                        ...acc[main],
                        [subMain]: editInitialData[main][subMain]
                            ? editInitialData[main][subMain]
                            : val.initialValue,
                    },
                };
                return acc;
            }
            let name = val.inputProps.name;
            if (name?.split(".").length === 1) {
                return {
                    ...acc,
                    [val.inputProps.name]: editInitialData[val.inputProps.name]
                        ? editInitialData[val.inputProps.name]
                        : val.initialValue,
                };
            }

            var [main, subMain] = name.split(".");
            return {
                ...acc,
                [main]: {
                    ...acc[main],
                    [subMain]: editInitialData[main][subMain]
                        ? editInitialData[main][subMain]
                        : val.initialValue,
                },
            };
        }, {});
    }, [editInitialData]);

    function getValueFromName(values, name, checkType = false) {
        if (name?.split(".").length === 1) {
            if (checkType) {
                return typeof values[name] === "string" ? values[name] : "";
            }
            return values[name];
        }
        var [main, subMain] = name.split(".");
        if (checkType) {
            return values[main] ? (typeof values[main][subMain] === "string" ? values[main][subMain] : "") : "";
        }
        return values[main] ? values[main][subMain] : "";
    }

    const buttonRenderer = (isSubmitting) => (
        <>
            {/* Button Div */}
            <div className={actionStyles}>
                {cancelButton.isVisible ? cancelButton.accessCheck ?
                    <AccessCheck feature={cancelButton.feature} permissionType={cancelButton.permission} elementType="Button">
                        <Button
                            {...cancelButton.props}
                            text={cancelButton.text}
                            img={cancelButton.img}
                            action={handleCancel}
                            disabled={isSubmitting || cancelButton.isDisabled}
                        />
                    </AccessCheck> :
                    <Button
                        {...cancelButton.props}
                        text={cancelButton.text}
                        img={cancelButton.img}
                        action={handleCancel}
                        disabled={isSubmitting || cancelButton.isDisabled}
                    />
                    : null}
                {extraButton && <Button
                    {...extraButton.props}
                    text={extraButton.text}
                    img={extraButton.img}
                    disabled={isSubmitting || extraButton.isDisabled}
                />}
                {submitButton.isVisible ? submitButton.accessCheck ?
                    <AccessCheck feature={submitButton.feature} permissionType={submitButton.permission} elementType="Button">
                        <Button
                            {...submitButton.props}
                            text={submitButton.text}
                            img={submitButton.img}
                            disabled={isSubmitting || submitButton.isDisabled}
                        />
                    </AccessCheck> :
                    <>
                    {
                        submitButton.props?.loading?<div><Loader height="5"/></div>:
                        <Button
                        {...submitButton.props}
                        text={submitButton.text}
                        img={submitButton.img}
                        disabled={isSubmitting || submitButton.isDisabled}
                    />
                    }
                    </>
                    : null}
            </div>
        </>
    )

    const updateDeleteButtonRenderer = () => (
        <>
            {/* Update and Delete Button Div */}
            <div className={actionStyles}>
                {updateButton?.isVisible && (
                    <span className="w-5 h-5 mr-3">
                        <FontAwesomeIcon
                            icon={faPencilAlt}
                            className="w-3 h-3 cursor-pointer"
                            onClick={handleUpdate}
                        />
                    </span>


                )}
                {deleteButton?.isVisible && (
                    <span className="w-5 h-5">
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="w-3 h-3 cursor-pointer text-red-600"
                            onClick={handleDelete} />
                    </span>
                )}
            </div>
        </>
    )

    useEffect(() => {
        if (formApiState === false) {
            formikRef.current?.setSubmitting(false);
        }
    }, [formApiState, formikRef])

    const customValidateForm = useCallback(() => {
        const { validateForm, setTouched, submitForm, setFieldValue } = formikRef.current
        return validateForm().then((res) => {
            const transformedError = replaceAllObjValues(res, true)
            setTouched(transformedError)
            setFieldValue("saveButtonClicked", false)
            submitForm()
            return Object.keys(transformedError).length > 0
        })
    }, [formikRef])

    if (validationRef) {
        validationRef.current = customValidateForm;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={r => {
                formikRef.current = r
            }}
        >
            {({
                errors,
                touched,
                values,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => {
                if (isSubmitting === true && formApiState === false) {
                    setFormApiState(true)
                }
                if (detectChangeHandler && isFunction(detectChangeHandler)) {
                    detectChangeHandler(values, initialValues)
                }
                return (
                    <form onSubmit={(e) => {
                        if (trackSaveBtnClick) {
                            setFieldValue("saveButtonClicked", true)
                        }
                        handleSubmit(e)
                    }} className={formStyles}>
                        {header}
                        {/* Form Div */}
                        <div className={bodyStyles}>
                            {initialData.map(({ key, ...rest }, idx) => {
                                if (rest.eletype === "section") {
                                    return <React.Fragment key={key}>
                                        {rest.component(values, touched)}
                                    </React.Fragment>
                                }
                                else if (rest.eletype === "sub-form") {
                                    return <SubForm key={key} {...rest} isSubmitting={isSubmitting} />
                                }
                                else if (rest.eletype === "notes") {
                                    return <div key={key} className={rest.wrapperStyles}>
                                        <Notes
                                            inputProps={{
                                                placeholder: "Add Notes and Attachments",
                                                ...rest.inputProps,
                                            }}
                                            values={values.notes}
                                            handleChange={setFieldValue}
                                            {...rest}
                                        /></div>
                                }
                                else if (rest.eletype === "fileinput_profile") {
                                    return <ProfileImageComponent
                                        errors={errors}
                                        key={key}
                                        {...rest}
                                        name={rest.inputProps.name}
                                        value={{
                                            profileImageUrl: getValueFromName(
                                                values,
                                                rest.inputProps.name
                                            )
                                        }}
                                        profileImage={getValueFromName(
                                            values,
                                            rest.inputProps.name
                                        )}
                                    />
                                }
                                else if (rest.eletype === "fieldArray") {
                                    return (
                                        <CustomFieldArray
                                            key={key}
                                            {...rest}
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    )
                                }
                                return (
                                    <Element
                                        key={key}
                                        columWidth={
                                            columnWidthArray.length > 0
                                                ? columnWidthArray[idx].width
                                                : ""
                                        }
                                        {...rest}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        // onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                        value={getValueFromName(
                                            values,
                                            rest.inputProps.name
                                        )}
                                        errorText={
                                            getValueFromName(
                                                touched,
                                                rest.inputProps.name
                                            ) &&
                                            getValueFromName(
                                                errors,
                                                rest.inputProps.name,
                                                true
                                            )
                                        }
                                        errorStyle={rest.errorStyle ? rest.errorStyle : ""}
                                    />
                                );
                            })}
                            {rowButtonRender && (submitButton.isVisible ? buttonRenderer(isSubmitting) : updateDeleteButtonRenderer())}
                        </div>
                        {!rowButtonRender && buttonRenderer(isSubmitting)}
                    </form>
                );
            }}
        </Formik>
    );
}

Form.propTypes = {
    initialData: PropTypes.array,
    validationSchema: PropTypes.object,
    handleSubmit: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    handleCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    handleUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    handleDelete: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    updateButton: PropTypes.object,
    deleteButton: PropTypes.object,
    submitButton: PropTypes.object,
    cancelButton: PropTypes.object,
    formStyles: PropTypes.string,
    header: PropTypes.element,
    bodyStyles: PropTypes.string,
    actionStyles: PropTypes.string,
    editInitialData: PropTypes.object,
    columnWidthArray: PropTypes.array,
    rowButtonRender: PropTypes.bool,
    validationRef: PropTypes.object,
    trackSaveBtnClick: PropTypes.bool,
    detectChangeHandler: PropTypes.arrayOf(PropTypes.func, null),
    extraButton: PropTypes.arrayOf(PropTypes.object, null),
};

export default Form;
