import { DateTime } from "luxon";

export const getTimeFromJSDate = (date, format = "MM/dd/yyyy") => {
    return date && DateTime.fromJSDate(date).toFormat(format);
};

export const getFormattedTimeFromDate = (date, format = "MM/dd/yyyy") => {
    if (date) {
        const selectedDate = (typeof (date) === 'string') ? formatFromISOtoJSDate(date) : new Date(date); 
    return DateTime.fromJSDate(selectedDate).toFormat(format);
    }
};

export const getAge = (dob) => {
    if (dob) {
        const selectedDate = (typeof (dob) === 'string') ? formatFromISOtoJSDate(dob) : new Date(dob); 
        var currentTime = DateTime.fromJSDate(DateTime.now().toJSDate());
        var dobTime = DateTime.fromJSDate(selectedDate);
        var age = currentTime.diff(dobTime, ["years"]).years;
        return Math.floor(age);
    }
    
};

export const getFormatTimeFromTimestamp = (
    timestamp,
    format = "MM/dd/yyyy"
) => {
    return timestamp && (DateTime.fromISO(timestamp).isValid
        ? DateTime.fromISO(timestamp).toFormat(format)
        : "");
};

export const getDateAndTime = (timestamp, format = "MM/dd HH:mm a") => {
    if (DateTime.fromISO(timestamp).isValid) {
        return DateTime.fromISO(timestamp).toFormat(format);
    }
    return "";
};

export const addMonthsToDate = (date, months, format = "yyyy-MM-dd") => {
    return date && DateTime.fromJSDate(date).plus({ months: months }).toFormat(format);
};

export const addDaysToDate = (date, days, format = "yyyy-MM-dd") => {
    return date && DateTime.fromJSDate(date).plus({ days: days }).toFormat(format);
};

export const isLessOrEqualThan = (startDate, endDate) => {
    return DateTime.fromISO(startDate) <= DateTime.fromISO(endDate);
};

export const format = (date, format = "MM/dd/yyyy") => {
    return (
        DateTime.fromJSDate(date).weekdayShort +
        " " +
        DateTime.fromJSDate(date).toFormat(format)
    );
};

export const displayDateLabel = (date, checkThisWeek = true) => {
    const currentFormattedDate = getTimeFromJSDate(DateTime.now().toJSDate(), "yyyy-MM-dd");
    const tomorrowDate = addDaysToDate(DateTime.now().toJSDate(), 1, "yyyy-MM-dd");
    const currentWeekDay = getCurrentWeekday();
    const diffWeekDay = 7 - currentWeekDay;
    const thisSunday = addDaysToDate(DateTime.now().toJSDate(), diffWeekDay, "yyyy-MM-dd");
    const nextSunday = addDaysToDate(formatFromISOtoJSDate(thisSunday), 7, "yyyy-MM-dd");

    if (date === currentFormattedDate) {
        return "Today";
    } else if (date === tomorrowDate) {
        return "Tomorrow";
    } else if (checkThisWeek) {
        if (
            isLessOrEqualThan(date, thisSunday) &&
            isLessOrEqualThan(currentFormattedDate, date)
        ) {
            return "This week";
        } else if (
            isLessOrEqualThan(date, nextSunday) &&
            !isLessOrEqualThan(date, thisSunday)
        ) {
            return "Next week";
        } else {
            return format(formatFromISOtoJSDate(date));
        }
    } else {
        return format(formatFromISOtoJSDate(date));
    }
};

export const isFutureDate = (date) => {
    if (date) {
        const currentDate = DateTime.now().toFormat("yyyy-MM-dd");
        var date1 = DateTime.fromISO(currentDate);
        var date2 = DateTime.fromISO(date).isValid
            ? DateTime.fromISO(date)
            : DateTime.fromJSDate(new Date(date));
        return date2.diff(date1)?.values?.milliseconds > 0;
    }
};

export const getFormatTime = (date, format = "MM/dd/yyyy") => {
    if (DateTime.fromISO(date).isValid) {
        return DateTime.fromISO(date).toFormat(format);
    }
    return DateTime.fromJSDate(new Date(date)).toFormat(format);
};

export const getCurrentWeekday = () => {
    return DateTime.now().weekday;
};

//Get format of date with only month and year where month is long and year numeric
export const getMonthYear = (date, month = "long") => {
    return date && DateTime.fromJSDate(date).toLocaleString({
        month: month,
        year: "numeric",
    });
};

export const currentSystemDate = DateTime.fromJSDate(DateTime.now().toJSDate()).toFormat("yyyy-MM-dd");

export const formatDateForTable = (value) => value && DateTime.fromISO(value).toFormat("MM/dd/yyyy");

export const formatDateForTableToUTC = (value) => value && DateTime.fromISO(value).toUTC().toFormat("MM/dd/yyyy");

export const formatFromISOtoJSDate= (value) => value && DateTime.fromISO(value).toJSDate();

export const convertDashedToSlashes= (value) => value && DateTime.fromFormat(value, 'yyyy-MM-dd').toFormat("MM/dd/yyyy");

export const getFormattedTimeFromSeconds = (
    seconds,
    format = "MM/dd/yyyy"
) => {
    return seconds && (DateTime.fromSeconds(seconds).isValid
        ? DateTime.fromSeconds(seconds).toFormat(format)
        : "");
};

export const subtractSecondsToDate = (date, seconds) => {
    return date && DateTime.fromJSDate(date).minus({ seconds: seconds })
};

export const includeSelectedEndDate = (date) => {
    const updatedEndDate = addDaysToDate(date, 1)
    return subtractSecondsToDate(new Date(updatedEndDate), 1).toString()
}

export const getRelativeDate = (dt, format = "MM/dd/yyyy") => {
    const date = DateTime.fromJSDate(new Date(dt))
    const diff = date.diffNow(['day', "hours", "minutes"]).toObject()
    const today = DateTime.now()
    const currHr = DateTime.now().get("hour")
    const time = date.toFormat("hh:mm a")

    if (today.day === date.day && today.month === date.month && today.year === date.year) {
        return "Today " + time
    } else if (diff.days === 0 && (currHr + diff.hours) < 0) {
        return "Yesterday " + time
    } else {
        return getFormatTimeFromTimestamp(date, format) + " " + time
    }
}
