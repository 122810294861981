import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
const Popup = ({
    title = "",
    children,
    closeIcon = false,
    closeAction = null,
    size = "normal",
    padding_x = "0",
    height = "",
    width = ""
}) => {
    const popupContainer = cx(
        ` m-2 px-${padding_x} rounded-sm sm:py-0 md:py-2 lg:py-2 xl:py-2 2xl:py-0 bg-white items-start shadow-xl m-auto relative overflow-y-scroll`,
        {
            "w-80": size === "normal",
            "w-96": size === "large",
            "w-72": size === "small",
            "w-3/5": size === "extraLarge",
            "w-151": size === "addMember",
            "w-4/6":size==='xl',
            "w-5/6":size === 'xxl',
            "w-full": size === "full",
            [`w-${width}`]: !!width,
            [`h-${height}`]: height,
        }
    );
    const closeModal = () => {
        closeAction(false);
    };
    return (
        <div className="leading-loose fixed left-0 right-0 top-0 bottom-0 flex items-center z-50 bg-black bg-opacity-40 overflow-y-scroll">
            <div className={popupContainer}>
                {closeIcon && (
                    <span className="absolute right-1 top-0">
                        <FontAwesomeIcon
                            size="lg"
                            icon={faTimesCircle}
                            className="cursor-pointer bg-white rounded-full text-gray-500"
                            onClick={closeModal}
                        />
                    </span>
                )}
                {_.size(title) > 0 && (
                    <div className="border-b-2 border-gray-200">
                        <p className="text-base text-black-900 font-bold px-4 py-2">
                            {title}
                        </p>
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};

Popup.propTypes = {
    children: PropTypes.element.isRequired,
    title: PropTypes.string,
    closeIcon: PropTypes.bool,
    closeAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    size: PropTypes.string,
    padding_x: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Popup;
