export const SessionStorage = {
    set: (key, value) => sessionStorage.setItem(key, value),
    get: (key) => sessionStorage.getItem(key),
    setObj: (key, value) => sessionStorage.setItem(key, JSON.stringify(value)),
    getObj: (key) =>  JSON.parse(sessionStorage.getItem(key)),
    remove: (key)  => sessionStorage.removeItem(key),
    clear: () => sessionStorage.clear()
};

export const LocalStorage = {
    set: (key, value) => {localStorage.setItem(key, value)},
    get: (key) => localStorage.getItem(key),
    setObj: (key, value) => {localStorage.setItem(key, JSON.stringify(value))},
    getObj: (key) =>  JSON.parse(localStorage.getItem(key)),
    remove: (key)  => {localStorage.removeItem(key)},
    clear: () => {localStorage.clear()}
};