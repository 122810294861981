import { useRef } from 'react'

function useDatePickerRef() {
  const datepickerRef = useRef(null);
  // OPENS UP THE DATEPICKER WHEN THE CALENDAR ICON IS CLICKED FOR THE INPUT FIELD
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }
  return { datepickerRef, handleClickDatepickerIcon }
}

export default useDatePickerRef