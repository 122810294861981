import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { SessionStorage } from "../utils/Storage";
const UserContext = createContext();

function UserContextProvider(props) {
    const [userDetails, setUserDetails] = useState(
        SessionStorage.getObj("user") || {}
    );
    const [fromValid, setFromValid] = useState([]);
    const [showToast, SetShowToast] = useState({
        status: false,
        message: "",
        alignment: "rt",
        type: "success",
        icon: null,
    });
    const [profileUser, setProfileUser] = useState();
    const [prospectFilters, setProspectFilters] = useState({
        financialLifeManagement: true,
        riskManagement: true,
        assetManagement: true,
        legacyPlanning: true,
        privateFund: true,
    });

    const [dropdownScrollLeftPos, setdropdownScrollLeftPos] = useState(0);
    const [dropdownScrollTopPos, setdropdownScrollTopPos] = useState(0);

    const [formApiState, setFormApiState] = useState(false)

    const setScrollLeftPos=(leftPos)=>{
        setdropdownScrollLeftPos(leftPos)
    }
    const setScrollTopPos=(topPos)=>{
        setdropdownScrollTopPos(topPos)
    }
    const updateProspectFilters = (key) => {
        setProspectFilters((prev) => {
            const params = {
                ...prev,
            };
            params[key] = !params[key];
            return params;
        });
    };

    const setUserForProfileView = (user) => {
        setProfileUser(user);
    };

    const clearProfileUser = () => {
        setProfileUser(undefined);
    };

    const setUser = (uDetails = {}) => {
        !_.isEmpty(uDetails)
            ? setUserDetails(uDetails)
            : setUserDetails(SessionStorage.getObj("user"));
    };

    const updateTotalAlerts = (total) => {
        let prevData = SessionStorage.getObj("user");
        if(_.has(prevData, "alertCount")) {
            prevData.alertCount = total;
            SessionStorage.setObj('user', prevData);
        }
    }

    const formValidStatus = (status) => {
        setFromValid((prev) => ({ ...prev, status }));
    };

    const clearUser = () => {
        setUserDetails({});
        clearProfileUser();
        SessionStorage.clear();
    };

    const dHToast = (status = false, message = "", alignment = "rt", type, icon) => {
        SetShowToast((prev) => {
            return { ...prev, status, message, alignment, type, icon };
        });
    };

    useEffect(() => {
        let timer, timerId;
        if (showToast) {
            timer = () =>
                setTimeout(() => {
                    SetShowToast(false);
                }, 3000);
            timerId = timer();
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [showToast]);

    useEffect(() => {
        SessionStorage.setObj("user", userDetails);
    }, [userDetails]);
    return (
        <UserContext.Provider
            value={{
                userDetails,
                setUser,
                clearUser,
                fromValid,
                formValidStatus,
                showToast,
                dHToast,
                setUserForProfileView,
                clearProfileUser,
                profileUser,
                prospectFilters,
                updateProspectFilters,
                updateTotalAlerts,
                setScrollLeftPos,
                setScrollTopPos,
                dropdownScrollLeftPos,
                dropdownScrollTopPos,
                setFormApiState,
                formApiState,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
}

UserContextProvider.propTypes = {
    children: PropTypes.element,
};

export { UserContextProvider, UserContext };
