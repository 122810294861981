import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import _, { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { getVirtualElement } from "../utils/UtilsFunc";
import MultiSelectOptionMenu from "./MultiSelectOptionsMenu";

function MultiSelect({
  option = [],
  handleChange = () => { },
  handleBlur = () => { },
  inputProps,
  value = '',
  transformOptions,
  userIcon = userIcon,
  disabled = false,
  errorText = "",
  width = "",
  hasIcon = "false",
  margin = "false",
  readOnly = false,
  height = 'auto',
  values = {},
  showLists,
  autoComplete = 'off',
  dropDownHeight,
  materialLabel,
  selectLabel,
  optionHasMoreInfo,
  hideDropdownOnClick = true,
}) {
  const [dropdown, setDropdown] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItemsArray, setSelectedItemsArray] = useState([]);
  const inputRef = useRef();
  const dropdownRef = useRef();
  const wrapperDivRef = useRef()
  const [inputWidth, setInputwidth] = useState(0)
  const [labelActive, setLabelActive] = useState(false);

  const { handleChange: onChange, ...rest } = inputProps;

  var refElement = useMemo(() => {
    return getVirtualElement({
      name: inputProps.name,
      type: "multiselect",
      value: value,
      handleChange: function (e) {
        handleChange(e);
      },
      handleBlur,
    });
  }, []);


  useEffect(() => {
    if (!isEmpty(selectedItemsArray)) {
      setSelectedItemsArray(selectedItemsArray);
      setSelectedItems(selectedItemsArray)
      refElement.setValue(selectedItemsArray);
    }
  }, [selectedItemsArray]);

  useEffect(() => {
    if (_.isFunction(inputProps.defaultValue)) {
      let defaultValue = inputProps.defaultValue(value);
      refElement.setValue(defaultValue);
    }

    return () => {
      setSelectedItems([])
      setSelectedItemsArray([])
      refElement.setValue("")
    }
  }, []);

  const removeTag = (ele) => {
    const filteredItem = selectedItems.filter((tag) => tag.value !== ele.value);
    // setSelectedItemsArray(selectedItemsArray.map(item => ({ ...item, checked: false }))
    //   .filter((tag) => tag.value !== ele.value));
    setSelectedItemsArray(selectedItemsArray.filter((tag) => tag.value !== ele.value));
    setSelectedItems(filteredItem);
    refElement.setValue(filteredItem);
  };

  useOnClickOutside(dropdownRef, () => {
    setDropdown(false);
    setSearchTerm("")
    if (selectedItems.length === 0) {
      setLabelActive(false)
    }
  });

  useOnClickOutside(wrapperDivRef, () => {
    if (selectedItems.length === 0) {
      setLabelActive(false)
    }
  });

  useEffect(() => {
    if (!dropdown && selectedItems.length === 0) {
      setLabelActive(false)
    }
    else {
      setLabelActive(true)
    }
  }, [dropdown, selectedItems])

  let inputClass = classNames({
    "bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800": true,
    [`${inputProps.styles}`]: true,
    "pl-10": hasIcon === "true",
    "mb-3": margin === "true",
    "border-red-600": errorText.length > 0,
    "opacity-40 cursor-not-allowed pointer-events-none": disabled === true,
    [`w-${width}`]: true,
  });

  useEffect(() => {
    (async function () {
      if (_.isArray(option)) {
        return setItems(option);
      }
      let data = await option();
      setItems(transformOptions(data));
    })();
  }, [option]);

  function selectClick(e, item) {
    const { checked, value } = e.target;
    let updatedSelectedItemsArray = []
    if (checked) {
      updatedSelectedItemsArray = [...selectedItems, { ...item, checked: item.value === value }];
    } else if (!checked) {
      updatedSelectedItemsArray = selectedItemsArray.filter(filteredItem => filteredItem.value !== value);
      const filteredItem = selectedItems.filter((tag) => tag.value !== value);
      setSelectedItems(filteredItem);
    }
    inputRef.current.focus();
    setSelectedItemsArray(updatedSelectedItemsArray)
    setSearchTerm("");
    setSearchResults(prevList => prevList.map(el => ({ ...el, checked: updatedSelectedItemsArray.some(it => it.id === el.id) })))
    if (hideDropdownOnClick) {
      setDropdown(false);
    }
  }

  useEffect(() => {
    setSelectedItemsArray(value);
  }, [])

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    if (!isEmpty(searchTerm)) {
      const results = items.filter((ele) => ele.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((item) => ({ ...item, checked: false }));
      setDropdown(true);
      if (!isEmpty(selectedItemsArray)) {
        setSearchResults(results.map(item => ({ ...item, checked: selectedItemsArray.some(selected => selected.value === item.value) })));
      } else {
        setSearchResults(results);
      }
    }
  }, [searchTerm]);

  const selectedItemFilter = (id) => {
    return selectedItems.every(item => item.id !== id)
  }

  const handleOnClick = () => {
    setLabelActive(true)
    setDropdown(true);
    const list = selectedItems.length ? [...selectedItems, ...items.filter(item => selectedItemFilter(item.id))] : items;
    setSearchResults(list.map(el => ({ ...el, checked: !!el.checked })));
  }

  useEffect(() => {
    if (value) {
      setSelectedItemsArray(value.map(el => ({ ...el, checked: true })))
    }
  }, [])

  return (
    <div ref={wrapperDivRef}>
      <div className="flex flex-col items-center relative w-full">
        {showLists && selectedItems.length > 0 && (
          <div className="">
            <div className="px-6 py-4 w-1/2 absolute right-0 top-0 bg-white z-50 shadow rounded max-h-select">
              {selectedItems.map((item) => {
                return <div key={item.id} className="pb-2">{item.title}</div>;
              })}
            </div>
          </div>
        )}
        <div className="w-full">
          <div className={`p-1 flex ${materialLabel ? "bg-white" : "bg-gray-100"} h-${height} flex-col`}>
            <div className="flex flex-auto flex-col no-scrollbar overflow-x-auto overflow-y-hidden">

              <div className='flex flex-wrap'>
                {selectedItems && selectedItems.map((tag) => {
                  return (
                    <React.Fragment key={tag.id}  >
                      <div
                        className={`flex flex-row justify-center items-center m-1 font-medium ${materialLabel ? "bg-gray-200" : "bg-white"} rounded-full text-teal-700 bg-teal-100 w-2/5`}
                      >
                        <span className="rounded-full text-black-400 bg-white-200 font-normal text-sm flex items-center cursor-pointer active:bg-gray-300 transition duration-300 ease w-max">
                          <span className="flex items-center px-3 py-2">
                            {tag.title}
                          </span>
                        </span>
                        <button
                          onClick={() => removeTag(tag)}
                          className="bg-transparent hover focus:outline-none"
                          type="button"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={`w-4 h-4 mr-2 ${materialLabel ? "text-gray-500" : "text-gray-300"}`}
                          />
                        </button>
                      </div>
                    </React.Fragment>

                  );
                })}
              </div>


              <div style={{ minWidth: '6rem' }} className={`flex-1 overflow-auto ${materialLabel && "material-field input-field"}`}>
                <input
                  ref={inputRef}
                  placeholder=""
                  readOnly={readOnly}
                  value={searchTerm}
                  autoComplete={autoComplete}
                  onClick={handleOnClick}
                  onChange={handleSearchChange}
                  {...rest}
                  className={inputClass}
                  onMouseLeave={() => {
                    inputRef.current.blur();
                    refElement.blurElement();
                  }}
                />

                {materialLabel && (
                  <>
                    <i className="select-icon">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </i>
                    <label
                      htmlFor={inputProps.id}
                      className={`renderAutocompletelabel ${labelActive ? "active" : ""
                        }`}
                    >
                      {materialLabel}
                    </label>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {dropdown ? (
        <MultiSelectOptionMenu
          dropdownRef={dropdownRef}
          searchResults={searchResults}
          selectClick={selectClick}
          text={"A/c"}
          autoComplete={autoComplete}
          height={dropDownHeight}
          selectLabel={selectLabel}
          optionHasMoreInfo={optionHasMoreInfo}
          checkBoxStyle={inputProps.listCheckboxStyle}
        />
      ) : null}
    </div>
  );
}

MultiSelect.propTypes = {
  option: PropTypes.array,
  handleChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  handleBlur: PropTypes.func,
  disabled: PropTypes.string,
  inputProps: PropTypes.object,
  value: PropTypes.array,
  transformOptions: PropTypes.func,
  userIcon: PropTypes.string,
  errorText: PropTypes.string,
  width: PropTypes.string,
  hasIcon: PropTypes.string,
  margin: PropTypes.string,
  readOnly: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  values: PropTypes.object,
  showLists: PropTypes.bool,
  autoComplete: PropTypes.string,
  dropDownHeight: PropTypes.string,
  materialLabel: PropTypes.string,
  selectLabel: PropTypes.string,
  optionHasMoreInfo: PropTypes.bool,
  hideDropdownOnClick: PropTypes.bool,
};

export default MultiSelect;
