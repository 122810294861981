
import {useState, useEffect} from 'react';
import debounce from "lodash/debounce";

export const useResize = (ref)=> {
    const [resizeState, setResizeState] = useState(null);
    useEffect(() => {
      const getSize = debounce(() => {
        if (!ref || !ref.current) {
          return;
        }
  
        const width = ref.current.offsetWidth;
        const height = ref.current.offsetHeight;
        setResizeState({
          width,
          height
        });
      }, 1000);
  
      window.addEventListener("resize", getSize);
      getSize();
      return () => window.removeEventListener("resize", getSize);
    }, [ref]);
    return resizeState;
  }
  