export const CHANGE_ACCOUNTS_SELECTED_TAB = "CHANGE_ACCOUNTS_SELECTED_TAB";
export const CREATE_PAYOUT_BLOCK_INFO = "CREATE_PAYOUT_BLOCK_INFO";
export const ADD_NEW_PAYOUT_BLOCK_INFO = "ADD_NEW_PAYOUT_BLOCK_INFO";
export const DELETE_NEW_PAYOUT_BLOCK_INFO = "DELETE_NEW_PAYOUT_BLOCK_INFO";
export const GET_GENERAL_ACCOUNT = 'GET_GENERAL_ACCOUNT';
export const GET_BILLING_ACCOUNT = 'GET_BILLING_ACCOUNT';
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_ACCOUNT_PAY_FOR = 'GET_ACCOUNT_PAY_FOR';
export const CLEAR_STATE = 'CLEAR_STATE';
export const ADD_PAY_FOR = 'ADD_PAY_FOR';
export const UPDATE_SELECTED_STATE = 'UPDATE_SELECTED_STATE';
export const REMOVE_SELECTED_STATE = 'REMOVE_SELECTED_STATE';
export const GET_INVESTMENT_FEE = 'GET_INVESTMENT_FEE';