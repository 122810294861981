import React from "react";
import PropTypes from "prop-types";

function Modal({ children }) {
    return (
        <div className="leading-loose fixed left-0 right-0 top-0 bottom-0 flex items-center w-full h-full z-50 bg-black bg-opacity-40 overflow-y-scroll">
            {children}
        </div>
    );
}

Modal.propTypes = {
    children: PropTypes.element,
};

export default Modal;
