import _ from "lodash";
import { UPDATE_MASTER_DATA, UPDATE_SPECIFIC_MASTER_DATA } from "../../actions/masterData/master-data.constant";


function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case UPDATE_MASTER_DATA:
      return {
        ...state,
        ...action.data,
      }

    case UPDATE_SPECIFIC_MASTER_DATA: {
      let updatedArr = _.cloneDeep(state[action.key])
      if (action.data.type === "delete") {
        updatedArr = updatedArr.filter(el => el.id !== action.data.id)
      }
      else {
        const idToSearch = action.data.data.id
        const idx = updatedArr.findIndex(el => el.id === idToSearch)
        if (idx !== -1) {
          updatedArr[idx] = action.data.data
        }
        else {
          updatedArr.push(action.data.data)
        }
      }
      return {
        ...state,
        [action.key]: updatedArr
      }

    }

    default:
      return state;
  }
}

var INIT_STATE = {
  gender: [],
  sexualOrientation: [],
  marketingSegments: [],
  ageRange: [],
  politicalParty: [],
  state: [],
  sources: [],
  specificInterests: [],
  religiousAffiliations: [],
  marriageStatus: [],
  employmentStatus: [],
  industry: [],
  sysconfig: [],
  providers: []
};

export default reducer;
