import React from "react";
import PropTypes from "prop-types";

function Section({ title, action, styles }) {
    return (
        <div
            className={`flex mt-4 py-4 pt-0 justify-between items-center ${styles}`}
        >
            <div
                className="text-blue-900 underline font-bold"
                style={{ textUnderlineOffset: "10px" }}
            >
                {title}
            </div>
            <div>{action}</div>
        </div>
    );
}

Section.propTypes = {
    title: PropTypes.string,
    action: PropTypes.element,
    styles: PropTypes.string,
};

export default Section;
