import { filterActions } from "redux-ignore";

// import * as householdActions from "../actions/billing/household/household.constant";
import * as accountsActions from "../actions/billing/accounts/accounts.constant";
import * as specialDayActions from "../actions/clients/specialDays/specialDays.constant";
import * as dashoboardActions from "../actions/dashboard/dashboard.constant";
import * as userActions from "../actions/user/user.constant";
import * as clientsAction from '../actions/clients/clients.constant';
import * as instanceAction from '../actions/billing/instances/instance.constant';
// import * as feeScheduleStructureActions from "../actions/billing/feeschedules/feeschedulestructure.constant";

import receivablesReducer from "./billing/receivables.reducer";
import householdReducer from "./billing/household.reducer";
import specialDaysReducer from "./clients/specialDays.reducer";
import accountReducer from "./billing/account.reducer";
import instanceReducer from "./billing/instance.reducer";
import dashboardReducer from "./dashboard/dashboard-update.reducer";
import userReducer from "./user.reducer";
import clientReducer from "./clients/clients.reducer";
import memberReducer from "./clients/members.reducer";
import loggedInUserReducer from "./loggedInUser.reducer";
import feeScheduleStructureReducer from "./billing/feeScheduleStructure.reducer"
import strategySetupReducer from "./clients/strategySetup.reducer"
import sidebarReducer from "./sidebar.reducer"
import masterDataReducer from "./masterData/master-data.reducer"

const reducers = {
    loggedInUser: loggedInUserReducer,
    receivables: receivablesReducer,
    strategySetup: strategySetupReducer,
    household: householdReducer,
    feeScheduleStructure: feeScheduleStructureReducer,
    accounts: filterActions(
        accountReducer,
        Object.values({ ...accountsActions })
    ),
    specialDays: filterActions(
        specialDaysReducer,
        Object.values({ ...specialDayActions })
    ),
    dashboard: filterActions(
        dashboardReducer,
        Object.values({ ...dashoboardActions })
    ),
    user: filterActions(userReducer, Object.values({ ...userActions })),
    client: filterActions(clientReducer, Object.values({ ...clientsAction })),
    instance: filterActions(instanceReducer,Object.values({...instanceAction})),
    sidebar: sidebarReducer,
    member: memberReducer,
    masterData: masterDataReducer,
};

export default reducers;
