import React, { useRef, useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Text from "../../../commonComponent/Text";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getInputElement, nullCheck, setSelectionNotAvailable} from "../../../utils/UtilsFunc";
import { formatFromISOtoJSDate, getTimeFromJSDate } from "../../../utils/luxon";
import FileInput from "../../../commonComponent/FileInput";
import NewDropdown from "../../../commonComponent/NewDropdown";
import MultiSelectChips from "../../../commonComponent/MultiSelectChips";
import MultiSelect from "../../../commonComponent/MultiSelect";
import Autocomplete from "../../../commonComponent/Autocomplete";
import { useFileUpload } from "../../../hooks/useFileUpload";
import DynamicFields from "../../../commonComponent/DynamicFields";
import { isEmpty, isFunction, omit } from "lodash";
import calendar from "../../../assets/img/icons/calendar.svg";
import { useFormikContext } from "formik";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import MaterialInput from "../MaterialField";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import useDatePickerRef from "../../../hooks/useDatePickerRef";
import { Tooltip } from "react-tippy";
import getRequest from "../../../network/getRequest";
import syncIcon from "../../../assets/img/icons/sync.svg"

const { fetchRequest } = getRequest();

const Element = ({
    eletype = "input",
    label = "",
    errorText = "",
    inputProps = {},
    hasIcon = false,
    hasInfoIcon = false,
    errorStyle = "bottom-0",
    disabled = false,
    bgColor = "",
    readOnly = false,
    options = [],
    allOptions = [],
    render = null,
    styles = "",
    labelStyles = "",
    prefixValue = "",
    containerfull = false,
    handleChange,
    handleBlur,
    value = "",
    fileInputMeta = {},
    filterOptions,
    transformOptions,
    icon = null,
    inputIcon = null,
    iconAction,
    intermediateValue,
    errorWrapperStyles = "",
    borderFlag = true,
    columWidth = "",
    wrapperStyles = "",
    conditionalChange = null,
    rounded = true,
    withScroll = false,
    helperText = "",
    isHelperText = false,
    dropdownStyles = "",
    managerIcon = false,
    memberIcon = false,
    rightIconAction,
    userIcon = "",
    placeholder = "",
    showLists,
    accordianDropdown = false,
    conditionChangeValue = "",
    handleCancel,
    isUpward = false,
    menuListHeight = "-240px",
    conditionalChangeCheckForArr = [],
    selectLabel = "",
    optionHasMoreInfo,
    uiType = "",
    renderOnListEmpty,
    fieldClass,
    dropDownHeight = undefined,
    materialLabel = null,
    beforeChange,
    fetchBeforeFocus,
    isClearable,
    hideDropdownOnClick,
    inputRefHandler,
    isDraggable = false,
    uploadToS3 = true,
    optionStyles = "",
    showChipsImg=true,
    inputWrapperClassName,
    crossIconStyle=null
}) => {
    const { values, setFieldValue,setFieldError } = useFormikContext(); 
    const [lists, setLists] = useState(false);
    const rightIconRef = useRef();
    const [cursor, setCursor] = useState(null);
    const [keyEvent,setKeyEvent] = useState(false)
    const [labelActive, setLabelActive] = useState(false);
    const ref = useRef(null);
    const { datepickerRef, handleClickDatepickerIcon } = useDatePickerRef()

    var [date, setDate] = useState(() => {
        if (inputProps.type === "date") {
            return value ? (
                typeof(value) === 'string' ? formatFromISOtoJSDate(value) : new Date(value) 
            ) : '';
        }
        return "";
    });
    var { upload, isLoading, imageMeta, fileName, error } = useFileUpload(
        fileInputMeta,
        value?.attachmentUrl
    );

    let inputClass = cx(
        "p-3 text-black-900 font-normal relative text-md outline-none w-full",
        {
            "pl-10": hasIcon === true,
            "border-red-600": errorText,
            "focus:ring-blue-100": errorText,
            "focus:ring-red-100": errorText,
            "opacity-40 cursor-not-allowed pointer-events-none":
                disabled === true,
            [`${styles}`]: true,
            "left-2/5": prefixValue,
            [`bg-${bgColor}`]: bgColor.length > 0,
            "pr-10": isClearable === true,
        }
    );

    let labelClass = cx("text-gray-500 text-sm inline-block mb-2", {
        [`${labelStyles}`]: true,
    });

    useEffect(() => {
        if (!imageMeta) return;
        var event = {};
        event.target = {
            type: "fileinput",
            name: inputProps.name,
            value: imageMeta,
        };
        handleChange(event);
    }, [imageMeta]);

    var refElement = useMemo(() => {
        if (inputProps.type === "date") {
            return getInputElement({
                value: value,
                name: inputProps.name,
                onChange: handleChange,
                onBlur: handleBlur,
            });
        }

        return null;
    }, []);

    useEffect(() => {
        if (isFunction(render) && value) {
            if(uiType === "material") return;
            var formattedValue = render(value);
            var e = {
                target: {
                    name: inputProps.name,
                    type: "text",
                    value: formattedValue,
                },
            };
            handleChange(e);
        }
    }, []);

    useEffect(() => {
        if (inputProps.name === "identityNumber") {
            var e = {
                target: {
                    value: intermediateValue ? intermediateValue : "",
                    name: inputProps.name,
                    type: "text",
                },
            };
            handleChange(e);
        }
    }, [intermediateValue]);

    function handleDateChange(date) {
        if (date) {
            setDate(date);
            var formattedDate = getTimeFromJSDate(date, "yyyy-MM-dd");
            refElement.setValue(inputProps.withoutFormatted ? date?.toISOString() : formattedDate);
        } else {
            setDate("");
            refElement.setValue("");
        }
    }

    function handleFileChange(e) {
        // We have got the file now we need to upload this file to the server.
        let file = e.target.files[0];
        const fileName = file.name

        if (uploadToS3) {
            upload(file);
        } else {
            setFieldValue(inputProps.name, file)
        }
    }

    function handleFileBlur() {
        var event = {};
        event.target = {
            name: inputProps.name,
            type: "fileinput",
        };
        handleBlur(event);
    }
    useEffect(() => {
        const input = ref.current;
        if (input && nullCheck(cursor)=== false && isFunction(input.setSelectionRange) && setSelectionNotAvailable(input.type)=== false) {
            
            if(inputProps.phoneNumber){
                if(Number(cursor) <= 4 && keyEvent && value.length >= 4)
                {
                    //input.setSelectionRange(4, 4);
                    setCursor(4)
                    setKeyEvent(false)
                    //return
                }
                let compareCursor = Number(cursor) + 3
                let changedCursor ;
                if(keyEvent || compareCursor<value.length){
                   changedCursor = Number(cursor);
                   setKeyEvent(false)
                 }else{
                    if(!keyEvent && compareCursor>value.length)
                    {
                        changedCursor = Number(cursor) + 1
                    }else{
                        changedCursor = Number(cursor) + 3
                    }
                }
                
                input.setSelectionRange(changedCursor, changedCursor);
    
            }else if(inputProps.ssn){
                let compareCursor = Number(cursor) + 2
                let changedCursor ;
                if(keyEvent || compareCursor<value.length){
                changedCursor = Number(cursor);
                setKeyEvent(false)
                }else{
                    changedCursor = Number(cursor) +2
                }
                
                input.setSelectionRange(changedCursor, changedCursor);
            }
            else{
                let compareCursor = Number(cursor) + 1
                let changedCursor ;
                if(keyEvent || compareCursor<value.length){
                changedCursor = Number(cursor);
                setKeyEvent(false)
                }else{
                    changedCursor = Number(cursor) +1
                }
                
                input.setSelectionRange(changedCursor, changedCursor);
            }
        } 
     }, [ref, cursor, value]);

    function handleInputChange(e) {
        setCursor(e?.target?.selectionStart);
        var val = e?.target?.value;
        if (isFunction(render)) {
            var formattedValue = render(val);
            e.target.value = formattedValue        
        }
        handleChange(e);
    }

    useOnClickOutside(rightIconRef, () => {
        setLists(false);
    }); 

    useOnClickOutside(ref, () => {
        if (!ref.current.value) {
            setLabelActive(false);
        }
    }); 
    useEffect(() => {
        if (value?.length > 0) {
            setLabelActive(true);
        }
    }, [value])
    if(inputProps.disableCondition && isFunction(inputProps.disableCondition)){
        inputProps.disabled=inputProps.disableCondition(values)
    }
    function renderInput() {
        if (inputProps.type === "date") {
            return (
                <React.Fragment>
                    <div className="py-0 text-left relative border-b-2 border-gray-400">
                        <DatePicker
                            selected={date}
                            {...inputProps}
                            onBlur={(e) => refElement.blurElement()}
                            onChange={handleDateChange}
                            autoComplete="off"
                            showMonthDropdown
                            showYearDropdown
                            peekNextMonth
                            adjustDateOnChange
                            excludeDates={[new Date("12/31/1899")]}
                            dropdownMode="select"
                            style={{ zIndex: 51 }}
                            ref={datepickerRef}
                            // onKeyDown={(e) => {
                            //     e.preventDefault();
                            // }}
                        />
                        {inputIcon ? (
                            <span className="absolute bottom-3 right-4">
                                <img
                                    className="text-black-100"
                                    src={inputIcon}
                                    alt=""
                                />
                            </span>
                        ) : (
                            <span className="absolute bottom-2.5 right-4" onClick={() => handleClickDatepickerIcon()}>
                                <img
                                    src={calendar}
                                    className="w-7 h-7 m-auto"
                                />
                            </span>
                        )}
                        {isClearable && date ? (
                            <div
                                className="absolute cursor-pointer bottom-3 right-12"
                                onClick={() => handleDateChange("")}
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    size="lg"
                                    className="w-4 h-4 m-auto "
                                />
                            </div>
                        ) : null}
                    </div>
                </React.Fragment>
            );
        }

        const onIconAction = () => {
            iconAction();
        };
        return (
            <div
                className={`relative mb-1 text-center ${
                    !borderFlag ? "border-b-2 border-gray-400" : ""
                } ${materialLabel && "material-field input-field prospect-field"}`}
                style={{ opacity: inputProps.disabled ? 0.5 : 1 }}
            >
                <p className="absolute top-2">{prefixValue}</p>
                <input
                    ref={ref}
                    className={inputClass}
                    onKeyDown={(e) => {
                        
                        if (
                            inputProps.type === "number" &&
                            (e.key === "e"
                            || (!inputProps.acceptdecimal && e.key === ".")
                            || (e.key === 39 || e.key === 37))
                        ) {
                            e.preventDefault();
                        }
                        if (inputProps.type === "number") {
                            if (
                                !(
                                    (e.key >= 0 && e.key <= 9) ||
                                    e.key === "Backspace" ||
                                    e.key === "Tab" ||
                                    (inputProps.acceptdecimal && e.key === ".")
                                    || (e.key !== 39 || e.key !== 37)
                                )
                            )
                                e.preventDefault();
                        }

                        if(inputProps.type === "email")
                        {
                            if ( e.key === " ") {
                                e.preventDefault(); //The value with a space now differs from what is displayed in the state. Issue for input type = email
                            }
                        }
                        if(inputProps.type === "text" && inputProps.keyDownReq)
                        {   
                            if (
                                isNaN(Number(e.key)) && e.key !== "Backspace" && e.key !== "Tab" && e.key !=="ArrowLeft" && e.key !== "ArrowRight"
                            ){
                                e.preventDefault();
                            }
                                const input = ref.current;
                                input.setSelectionRange(e.target?.selectionStart, e.target?.selectionStart)
                                if(e.key === "Backspace" || e.key === 37 || e.key === 39 || e.key === "Delete"){
                                    if ((e.key === "Backspace" && inputProps.phoneNumber && e.target?.selectionStart !== value.length) || e.key === "Delete")
                                    {
                                        e.preventDefault();
                                    }else{
                                        setKeyEvent(true)
                                    }
                                }
                    }}}
                    onChange={handleInputChange}
                    onBlur={(e) => {
                        inputProps.onHandleBlur ? inputProps.onHandleBlur(e, setFieldError, inputProps.name, fetchRequest, inputProps.index) : handleBlur(e)
                    }}
                    value={value}
                    {...omit(inputProps, ["keyDownReq", "phoneNumber"])}
                    autoComplete="off"
                    disabled={inputProps.disabled}
                    onFocus={(e) => {
                        if (inputProps.onFocus) {
                            inputProps.onFocus(e)
                        }
                        setLabelActive(true)
                    }}
                    // isHelperText={isHelperText}
                />
                {
                    materialLabel && (
                        <label
                            htmlFor=""
                            className={`${labelActive ? "active" : ""}`}
                            style={{ left: "0" }}
                        >
                            {materialLabel}
                        </label>
                    )
                }
                {isClearable && value ? (
                    <div
                        className="absolute cursor-pointer bottom-3 right-4"
                        onClick={() =>  setFieldValue(inputProps.name, "")}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            size="lg"
                            className="w-4 h-4 m-auto "
                        />
                    </div>
                ) : null}
                {hasIcon && (
                    <button onClick={onIconAction} type="button">
                        <span className="absolute bottom-3 right-4">
                            <FontAwesomeIcon
                                icon={icon}
                                size="lg"
                                className="w-3 h-3 m-auto "
                            />
                        </span>
                    </button>
                )}
                {inputIcon && (
                    <span className="absolute bottom-3 right-4">
                        <img
                            className="text-black-100 m-auto"
                            src={inputIcon}
                            alt=""
                        />
                    </span>
                )}
                {hasInfoIcon && (
                    <span className="absolute bottom-3 right-4">
                        <Tooltip
                            position="top"
                            trigger="mouseenter"
                            arrow
                            html={
                                <p className="w-full break-all px-2 pb-2 pt-0.5 " style={{ maxWidth: 'full' }}>
                                    Once you fetch your LinkedIn profile image, you can refresh the image again
                                    only<br />after a period of 15 mins.
                                </p>
                            }
                        >
                            <FontAwesomeIcon
                                icon={icon}
                                size="lg"
                                className="w-3 h-3 m-auto "
                            />
                        </Tooltip>
                    </span>
                )}
            </div>
        );
    }

    function renderSelect() {
        return (
            <div className={`py-0 text-left relative ${materialLabel && "mt-2 mb-4"}`}>
                <NewDropdown
                    autoComplete='off'
                    option={options}
                    allOptions={allOptions}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={value}
                    inputProps={inputProps}
                    bgColor={bgColor}
                    transformOptions={transformOptions}
                    borderFlag={borderFlag}
                    icon={icon}
                    values={values}
                    setFieldValue={setFieldValue}
                    conditionChange={conditionalChange}
                    roundedStyle={"rounded-none"}
                    wrapperClass={"border-b-2"}
                    withScroll={withScroll}
                    accordianDropdown={accordianDropdown}
                    isUpward={isUpward}
                    menuListHeight={menuListHeight}
                    conditionalChangeCheckForArr={conditionalChangeCheckForArr}
                    materialLabel={materialLabel}
                    beforeChange={beforeChange}
                    disabled={disabled}
                />
            </div>
        );
    }

    function renderTextarea() {
        return (
            <textarea
                row="70"
                col="50"
                className={inputClass}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                autoComplete='off'
                {...inputProps}
            ></textarea>
        );
    }

    function renderChipInput() {
        return (
            <div className="py-0 text-left relative">
                <MultiSelectChips
                    option={options}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={value}
                    inputProps={inputProps}
                    transformOptions={transformOptions}
                    borderFlag={borderFlag}
                    rounded={rounded}
                    withScroll={withScroll}
                    userIcon={userIcon}
                    placeholder={placeholder}
                    showLists={lists}
                    autocomplete='off'
                    showChipsImg={showChipsImg}
                    inputWrapperClassName={inputWrapperClassName}
                    crossIconStyle={crossIconStyle}
                    materialLabel={materialLabel}
                />
            </div>
        );
    }

    function renderAutocomplete() {
        return (
            <div className="py-0 text-left relative">
                <Autocomplete
                    autoComplete="off"
                    option={options}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={value}
                    disabled={inputProps.disableCondition && isFunction(inputProps.disableCondition)?inputProps.disableCondition(values):disabled}
                    inputProps={inputProps}
                    filterOptions={filterOptions}
                    borderFlag={borderFlag}
                    rounded={rounded}
                    values={values}
                    withScroll={withScroll}
                    dropdownStyles={dropdownStyles}
                    setFieldValue={setFieldValue}
                    conditionChange={conditionalChange}
                    conditionChangeValue={conditionChangeValue}
                    inputRefHandler={inputRefHandler}
                    hasIcon={hasIcon}
                    optionStyles={optionStyles}
                />
            </div>
        );
    }

    function renderFileInput() {
        if(isFunction(conditionalChange)){
            conditionalChange(isLoading)
        }        
        return (
            <FileInput
                {...inputProps}
                onChange={handleFileChange}
                errorText={error}
                onBlur={handleFileBlur}
                imageMeta={imageMeta}
                value={value}
                setFieldValue={setFieldValue}
                handleCancel={handleCancel}
                placeholder={
                    isLoading
                        ? "Uploading..."
                        : !(isEmpty(value)) && fileName
                        ? fileName
                        : "Click to Upload Image"
                }
            />
        );
    }

    function renderDynamicInputField() {
        return (
            <div className="py-0 text-left relative">
                <DynamicFields
                    inputProps={inputProps}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorText={error}
                    value={value}
                    autoComplete= 'off'
                    isDraggable={isDraggable}
                />
            </div>
        );
    }


    function renderMultiSelect() {
        return (
            <div className="py-0 text-left relative">
                <MultiSelect
                    option={options}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={value}
                    inputProps={inputProps}
                    transformOptions={transformOptions}
                    borderFlag={borderFlag}
                    rounded={rounded}
                    withScroll={withScroll}
                    userIcon={userIcon}
                    placeholder={placeholder}
                    values={values}
                    setFieldValue={setFieldValue}
                    conditionChange={conditionalChange}
                    showLists={lists}
                    autoComplete='off'
                    dropDownHeight={dropDownHeight}
                    materialLabel={materialLabel}
                    selectLabel={selectLabel}
                    optionHasMoreInfo={optionHasMoreInfo}
                    hideDropdownOnClick={hideDropdownOnClick}
                />
            </div>
        );
    }
    function renderElement() {
        if (uiType === "material") {
            return <MaterialInput
                label={label}
                eletype={eletype}
                options={options}
                allOptions={allOptions}
                containerClass="mr-4"
                customClasses="prospect-field"
                fieldClass={fieldClass}
                inputProps={{
                    ...inputProps,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: value,
                }}
                errorText={errorText}
                renderOnListEmpty={renderOnListEmpty}
                hasIcon={hasIcon}
                icon={icon}
                autoComplete='off'
                filterOptions={filterOptions}
                values={values}
                setFieldValue={setFieldValue}
                fetchBeforeFocus={fetchBeforeFocus}
                {...(isFunction(render) ? { render: render } : {})}
            />
        }
        switch (eletype) {
            case "select":
                return renderSelect();
            case "input":
                return renderInput();
            case "autocomplete":
                return renderAutocomplete();
            case "textarea":
                return renderTextarea();
            case "fileinput":
                return renderFileInput();
            case "dynamic":
                return renderDynamicInputField();
            case "checkbox":
                return renderCheckbox();
            case "chipsinput":
                return renderChipInput();
            case "multiselect":
                return renderMultiSelect();
        }
    }

    function renderCheckbox() {
         return <div className={`flex items-center ${inputProps.className}`}>
                <input
                    name={inputProps.name}
                    type="checkbox"
                    value={value}
                    checked={value}
                    onChange={(e) => {
                        if (inputProps.onInputChange && isFunction(inputProps.onInputChange)) {
                            inputProps.onInputChange(e, values, setFieldValue)
                        }
                        handleChange(e)

                    }}
                    onBlur={handleBlur}
                    disabled={disabled}
                    style={inputProps.style ? inputProps.style : {}}
                    // className="cursor-pointer h-5 w-5 rounded-full checked:shadow-xl focus:ring-0"
                />
                <label className={`${labelStyles ? labelStyles : "ml-4"}`} htmlFor="qualifiedInvestor">
                 {inputProps.hover ?
                <Tooltip
                 position="top"
                 trigger="mouseenter"
                 arrow
                 delay={100}      
                 html={
                    <p className="w-64 break-all p-1">
                        {`Centric Asset Management`}
                    </p>
                }>
                  {label}     
               </Tooltip>:label}
                </label>
            </div>
    }

    const onRightIconAction = () => {
        rightIconAction();
        setLists(!lists);
    };

    return (
        <div
            className={`text-left relative pb-4 ${wrapperStyles} ${
                columWidth.length > 1 ? "mx-2" : ""
            } ${containerfull ? "w-full" : ""}`}
            style={{ width: columWidth }}
        >
            <div className="flex items-center justify-between">
                {uiType !== "material" && label && !["checkbox"].includes(eletype) && (
                    <Text
                        autoComplete='off'
                        tag="label"
                        scale={true}
                        styles={`${labelClass} leading-4`}
                        text={label}
                        alignment="left"
                    />
                )}
                {
                    inputProps.syncIcon && <div onClick={() => !errorText && inputProps.getClientLinkedin(value, setFieldValue)}>
                            <Tooltip
                                position="top"
                                trigger="mouseenter"
                                arrow={true}
                                arrowSize="regular"
                                html={
                                    <p className="w-full break-all px-2 pb-2 pt-0.5">
                                    Sync your linkedIn profile
                                    </p>
                                }
                                >
                                {inputProps.syncIcon && (
                                    <div>
                                    <img className="text-black-100 m-auto" src={syncIcon} alt="" />
                                    </div>
                                )}
                            </Tooltip>
                        </div>
                }
            </div>
            {values?.managers?.length > 0 &&
            managerIcon &&
            !["checkbox"].includes(eletype) ? (
                <button
                    ref={rightIconRef}
                    className="action-menu"
                    onClick={onRightIconAction}
                    type="button"
                >
                    <span className="absolute top-1 right-0">
                        <img src={icon} className="w-6 h-6" />
                    </span>
                </button>
            ) : values?.members?.length > 0 &&
              memberIcon &&
              !["checkbox"].includes(eletype) ? (
                <button
                    ref={rightIconRef}
                    className="action-menu"
                    onClick={onRightIconAction}
                    type="button"
                >
                    <span className="absolute top-1 right-0">
                        <img src={icon} className="w-6 h-6" />
                    </span>
                </button>
            ) : null}
            {renderElement()}
            {errorText && uiType !== "material" && (
                <div className={errorWrapperStyles}>
                    <Text
                        tag="small"
                        scale={true}
                        styles={`text-red-600 absolute ${errorStyle}`}
                        text={errorText}
                        alignment="left"
                    />
                </div>
            )}
            {isHelperText && (
                <div className="mt-2">
                    <Text
                        tag="small"
                        scale={true}
                        styles={`text-gray-600`}
                        text={helperText}
                        alignment="left"
                    />
                </div>
            )}
        </div>
    );
};

Element.propTypes = {
    eletype: PropTypes.string,
    hasIcon: PropTypes.bool,
    label: PropTypes.string,
    errorText: PropTypes.string,
    bgColor: PropTypes.string,
    readOnly: PropTypes.bool,
    inputProps: PropTypes.object,
    errorStyle: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    render: PropTypes.func,
    styles: PropTypes.string,
    labelStyles: PropTypes.string,
    prefixValue: PropTypes.string,
    containerfull: PropTypes.bool,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
    fileInputMeta: PropTypes.object,
    filterOptions: PropTypes.func,
    transformOptions: PropTypes.func,
    icon: PropTypes.string,
    inputIcon: PropTypes.string,
    iconAction: PropTypes.func,
    intermediateValue: PropTypes.string,
    errorWrapperStyles: PropTypes.string,
    borderFlag: PropTypes.bool,
    columWidth: PropTypes.string,
    wrapperStyles: PropTypes.string,
    conditionalChange: PropTypes.func,
    rounded: PropTypes.bool,
    withScroll: PropTypes.bool,
    helperText: PropTypes.string,
    dropdownStyles: PropTypes.string,
    isHelperText: PropTypes.bool,
    managerIcon: PropTypes.bool,
    rightIconAction: PropTypes.func,
    userIcon: PropTypes.string,
    placeholder: PropTypes.string,
    showLists: PropTypes.bool,
    allOptions: PropTypes.array,
    accordianDropdown: PropTypes.bool,
    conditionChangeValue: PropTypes.string,
    memberIcon: PropTypes.bool,
    handleCancel: PropTypes.func,
    isUpward:PropTypes.bool,
    menuListHeight:PropTypes.string,
    conditionalChangeCheckForArr: PropTypes.array,
    uiType: PropTypes.string,
    renderOnListEmpty: PropTypes.element,
    fieldClass: PropTypes.string,
    dropDownHeight: PropTypes.oneOfType(PropTypes.string, undefined),
    materialLabel: PropTypes.string,
    selectLabel: PropTypes.string,
    optionHasMoreInfo: PropTypes.bool,
    beforeChange: PropTypes.func,
    fetchBeforeFocus: PropTypes.bool,
    isClearable: PropTypes.bool,
    hideDropdownOnClick: PropTypes.bool,
    inputRefHandler: PropTypes.func,
    isDraggable: PropTypes.bool,
    uploadToS3: PropTypes.bool,
    optionStyles: PropTypes.string,
    showChipsImg: PropTypes.bool,
    inputWrapperClassName: PropTypes.string,
    crossIconStyle: PropTypes.oneOf(PropTypes.object, null),
    hasInfoIcon:PropTypes.bool
};

export default Element;
