import {
  GET_BILLING_STATS,
  CLEAR_STATE,
  GET_BILLINGS,
  SELECTED_FILTER
} from "../../actions/billing/instances/instance.constant";

function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_BILLING_STATS: {
      return {
        ...state,
        billingStats: action.data
      }
    }
    case GET_BILLINGS: {
      return {
        ...state,
        billings: action.data,
        totalResults: action.totalResults
      }
    }
    case CLEAR_STATE: {
      return {
        ...state,
        billingStats: {}
      }
    }
    case SELECTED_FILTER: {
      return {
        ...state,
        selectedFilter: action.data
      }
    }
    default:
      return state;
  }
}

var INIT_STATE = {
  billingStats: {},
  billings: [],
  selectedFilter: 'ALL',
  totalResults: 0,
};

export default reducer;
