import {
    CREATE_SPECIAL_DAY,
    FETCH_SPEACIAL_DAYS,
} from "../../actions/clients/specialDays/specialDays.constant";


const INIT_STATE = {
    items: [],
};

function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case FETCH_SPEACIAL_DAYS:
            return { ...state, items: action.items.map(item => ({ ...item, dateType: item.dateType })) };
        case CREATE_SPECIAL_DAY:
            return { ...state, items: [action.data, ...state.items] };
        default:
            return state;
    }
}

export default reducer;
