import {
  CREATE_STRATEGY_SETUP_INFO,
  ADD_NEW_STRATEGY_SETUP_INFO,
  DELETE_NEW_STRATEGY_SETUP_INFO,
  RESET_STRATEGY_SETUP_INFO
} from "../../actions/clients/strategySetup/strategySetup.constant";


function reducer(state = INIT_STATE, action) {
  switch (action.type) {
      case CREATE_STRATEGY_SETUP_INFO:
          return {
              ...state,
              strategySetup: {
                  strategySetupList: action.data,
                  //totalCreditTo: action.data.creditToTotal,
                  //payoutPercentageTotal: action.data.payoutPercentageTotal
              },
          };
      case ADD_NEW_STRATEGY_SETUP_INFO:
          return {
              ...state,
              strategySetup: {
                strategySetupList: [
                      ...state.strategySetup.strategySetupList,
                      action.data,
                  ],
                  //totalCreditTo: state.payoutBlock.totalCreditTo,
                  //payoutPercentageTotal: state.payoutBlock.payoutPercentageTotal
              },
          };
      case DELETE_NEW_STRATEGY_SETUP_INFO:
          return {
              ...state,
              strategySetup: {
                strategySetupList: state.strategySetup.strategySetupList.filter(
                      (item) => item.strategyType !== ""
                  ),
                  //totalCreditTo: state.payoutBlock.totalCreditTo,
                  //payoutPercentageTotal: state.payoutBlock.payoutPercentageTotal
              },
          };
          case RESET_STRATEGY_SETUP_INFO:
            return {
                ...state,
                strategySetup: {
                  strategySetupList: null
                },
            };
      default:
          return state;
  }
}

var INIT_STATE = {
  strategySetup: {
      strategySetupList: null,
      //totalCreditTo: 0,
      //payoutPercentageTotal: 0,

  },
};

export default reducer;
