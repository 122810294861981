import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import cx from "classnames";
import centricLogo from "../assets/img/icons/centric_partners_svg.svg"; //centric_partners_svg.svg //centric_partners.svg
import userIcon from "../assets/img/icons/account-circle.svg";
import menuDown from "../assets/img/icons/menu-down.svg";
import searchIcon from "../assets/img/icons/search_icon.svg";
import Image from "../commonComponent/Image";
import InputText from "../commonComponent/InputText";
import Anchor from "../commonComponent/Anchor";
import ChangePasswordModal from "./ChangePasswordModal";
import ResetMfaModal from "./ResetMfaModal";
import { UserContext } from "../contextAPI";
import { useHistory } from "react-router-dom";
import { SessionStorage } from "../utils/Storage";
import { useDispatch } from "react-redux";
import { setLoggedOutUser } from '../store/actions/loggedOutUser/loggedOutUser.action';
import { resetAction } from '../store/actions/loggedInUser/loggedInUser.action';
import TruncatedText from "../commonComponent/TruncatedText";
import useOnClickOutside from "../hooks/useOnClickOutside";
import globalUserIcon from "../assets/img/icons/clients.svg";
import globalRecieptIcon from "../assets/img/icons/billing.svg";
import Text from "../commonComponent/Text";
import Loader from "../commonComponent/Loader";
import { globalSeachIcon } from "../utils/enum";
import { generateAccountDetailURL, generateClientDetailURL, generateUnitsDetailURL } from "../utils/UtilsFunc";
import { debounce } from "lodash";
import getRequest from "../network/getRequest";

function searchAPI(searchText,succesFn,errorFn,fetchFunc) {
    fetchFunc({searchText},"post","globalSearch")
      .then(res => {
        succesFn(res.result)
      })
      .catch(error => {
        errorFn(error)
      });
  }
const Header = () => {
    const history = useHistory();
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showResetMfaModal, setShowResetMfaModal] = useState(false);
    const [searchText, setSearchText] = useState()
    const [isDropOpen, setIsDropOpen] = useState(false);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const { fetchRequest } = getRequest();
    const dropdownRef = useRef();
    const { userDetails, clearUser, clearProfileUser } = useContext(UserContext);
    let reference = useRef();
    let classes = "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100";
   
    const [user, setUserData] = useState(userDetails || {});

    const dispatch = useDispatch();
    
    useEffect(() => {
        setUserData(userDetails);
    }, [userDetails]);

    useOnClickOutside(dropdownRef, () => {
        setIsDropOpen(false);
      });

    const handleSignOutClick = () => {
        // window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost:3000';
        // window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?client-request-id=93d06f7d-e6ad-4496-bc7b-ef26291434fb&post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F'

        let loginType = SessionStorage.getObj("loginType");
        if (loginType === 'msSignIn') {
            if (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
                window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fdev-api.centricria.com%2F%23%2F'
            } else if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
                window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fqa-api.centricria.com%2F%23%2F'
            } else if (process.env.REACT_APP_ENVIRONMENT === 'UAT') {
                window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fuat-api.centricria.com%2F%23%2F'
            } else if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
                window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%centec.io%2F%23%2F'
            } else {
                window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F'
            }
            clearUser();
            localStorage.clear()
            dispatch(resetAction())

        } else {
            clearUser();
            localStorage.clear();
            dispatch(setLoggedOutUser())
            history.push("/")
        }
        
    };

    const handleClickOutside = (event) => {
        if (reference.current && !reference.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    const handleChangePasswordClick = () => {
        setShowMenu(false);
        setShowModal(true);
    };

    const handleRestMfaClick =()=>{
        setShowMenu(false);   
        setShowResetMfaModal(true);     
    }   

    const closeModalHandler = () => {
        setShowModal(false);
    };

    const closeResetMfaModalHandler = () => {
        setShowResetMfaModal(false);
    };

    useEffect(() => {
        if (setShowMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMenu]);

    const toggleMenu = () => setShowMenu((prev) => !prev);
    let toggleMenuClass = cx(
        "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
        {
            hidden: showMenu === false,
        }
    );

    const redirectProfile = () => {
        clearProfileUser();
        //history.push("/");
        history.push("/dashboard");
    };
  
    const searchNewNames = useMemo(() => debounce((value, successFn, errorFn) => {
        searchAPI(value, successFn, errorFn, fetchRequest)
    }, 500), []);
      
    const handleChange = (name, value) => {
        setSearchText(value)
        if(value?.length>=1){
            setLoading(true)
            setIsDropOpen(true)
            searchNewNames(value,data => {
                setResults(data)
                setLoading(false)
              },error => {
                setLoading(false)
              })
        }
    };
    const handleClick = () => {
        setShowMenu(false);
        clearProfileUser()
        history.push({
            pathname: `/waprofile/${userDetails.id}`,
            search: "tab=personal",
        });
    };
const renderIcon=(iconType)=>{
    if(iconType===globalSeachIcon.client){
        return globalUserIcon
    }
    return globalRecieptIcon
}
const goToClientProfile = (data) => {
    if (data.resultType==globalSeachIcon.client) {history.push(generateClientDetailURL({ clientId:data.clientId }));}
    else if(data.resultType==globalSeachIcon.unit){
        history.push(generateUnitsDetailURL({ clientId:data.clientId }));
    }
    else if(data.resultType==globalSeachIcon.account){
        history.push(generateAccountDetailURL({clientId:data.clientId,tabe:"0",accountId:data.accountId }));
    }
    setIsDropOpen(false)
    setSearchText('')
};

    return (
        <>
            {showModal && (
                <ChangePasswordModal
                    userDetails={userDetails}
                    closeModal={closeModalHandler}
                />
            )}
            {showResetMfaModal && (
                <ResetMfaModal
                    userDetails={userDetails}
                    closeModal={closeResetMfaModalHandler}
                />
            )}
            <nav className="fixed w-full flex items-center bg-white justify-between flex-wrap bg-teal-500 p-3 shadow-md z-50">
                <div
                    className="flex items-center flex-shrink-0 text-white mr-12 cursor-pointer"
                    onClick={redirectProfile}
                >
                    <Image
                        img={centricLogo}
                        alt="logo"
                        imgClass="object-contain h-10 w-52"
                    />
                </div>
                <div className="block flex-grow flex items-center w-auto">
                    <div className="flex-grow">
                        <form className="w-1/2">
                            <div className="relative text-gray-600">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                    <button
                                        type="submit"
                                        className="p-1 focus:outline-none focus:shadow-outline z-10"
                                    >
                                        <Image
                                            img={searchIcon}
                                            alt="search"
                                            imgClass="object-contain w-4"
                                        />
                                    </button>
                                </span>
                                <InputText
                                    name="search"
                                    placeholder="Search"
                                    type="search"
                                    value={searchText}
                                    hasIcon="true"
                                    classStyle="pl-10"
                                    bgColor="gray-100"
                                    rounded="false"
                                    handleChange={handleChange}
                                    roundedStyles={"rounded-lg"}
                                />
                                {isDropOpen&&<div className="w-11/12 py-2  px-5 absolute bg-white  right-0.5 flex flex-col " style={{ boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)' }} ref={dropdownRef}>
                                    {
                                       loading?
                                       <Loader height="auto"/>:
                                       results?.length>0?
                                       results?.map(el=>(
                                        <div className="flex items-center my-1.5 cursor-pointer" onClick={() => goToClientProfile(el)} key={el.memberId}>
                                            
                                        <Image
                                        img={renderIcon(el.resultType)}
                                        alt={"Icon"}
                                        imgClass={"w-4 h-4 mr-3"}
                                    />
                                        <TruncatedText
                                            tag="h6"
                                            scale={true}
                                            text={el.title}
                                            fontWeight="bold"
                                            alignment="left"
                                        />
                                    </div>
                                       ))
                                       
                                        :
                                        <Text
                                        tag="small"
                                        scale={true}
                                        styles="text-100 text-left"
                                        text={'Sorry, we could not find anything for the entered text.'}
                                    />
                                    }
                                </div>}
                            </div>
                        </form>
                    </div>
                    <div className="relative" ref={reference}>
                        <button
                            className="flex focus:outline-none hover:bg-gray-100 items-center pl-2 pr-2 pt-1 pb-1"
                            id="menu-button"
                            aria-expanded="true"
                            aria-haspopup="true"
                            onClick={toggleMenu}
                        >
                            <span className="mr-2">
                                {user?.fullName || ""}
                            </span>
                            {userDetails?.profileImageUrl ? (
                                <Image
                                    img={userDetails?.profileImageUrl}
                                    alt="logo"
                                    imgClass="w-10 h-10 rounded-full object-cover"
                                />
                            ) : (
                                <Image
                                    img={userIcon}
                                    alt="logo"
                                    imgClass="object-contain w-10"
                                />
                            )}
                            <Image
                                img={menuDown}
                                alt="menu"
                                imgClass="object-contain w-4"
                            />
                        </button>
                        <div
                            className={toggleMenuClass}
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabIndex="-1"
                        >
                            <div className="py-1" role="none">
                                <Anchor
                                    redirectPath=''
                                    item={{ title: "My Profile" }}
                                    showTitle={true}
                                    classes={classes}
                                    clickEvent={handleClick}
                                />
                                <Anchor
                                    redirectPath=""
                                    item={{ title: "Change password" }}
                                    showTitle={true}
                                    classes={classes}
                                    clickEvent={handleChangePasswordClick}
                                />
                                <Anchor
                                    redirectPath=""
                                    item={{ title: "MFA Settings" }}
                                    showTitle={true}
                                    classes={classes}
                                    clickEvent={handleRestMfaClick}
                                />
                                <Anchor
                                    redirectPath=""
                                    item={{ title: "Sign Out" }}
                                    showTitle={true}
                                    classes={classes}
                                    clickEvent={handleSignOutClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
