import {
  SET_USER_PERMISSIONS,
  SET_USER_DETAILS,
  SET_ROLES_LIST,
  SET_LOADING_STATE,
  SET_MFA_USER_DETAILS,
  RESET
} from "../actions/loggedInUser/loggedInUser.constant";

function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_USER_PERMISSIONS:
      return { ...state, loggedInUser: { ...state.loggedInUser, userPermission: [...action.payload] } };
    case SET_USER_DETAILS:
      return { ...state, loggedInUser: { ...action.payload } };
    case SET_ROLES_LIST:
      return { ...state, rolesList: [...action.payload] }
    case SET_LOADING_STATE: {
      return { ...state, isLoading: action.payload }
    }
    case SET_MFA_USER_DETAILS:
      return { ...state, mfaUserDetails: { ...action.payload } };
    case RESET:
      return INIT_STATE
    default:
      return state;
  }
}

var INIT_STATE = {
  loggedInUser: {
    //userPermission: []
  },
  rolesList: [],
  isLoading: false,
  mfaUserDetails:{}
};

export default reducer;
