import React from "react";
import PropTypes from "prop-types";
import user from "../assets/img/icons/user_icon.svg";

function DefaultUser({ styles, wrapperClass,gender='',showByGender=false,style={} }) {
    let icon=user;
  if(showByGender){
        if(gender?.toLocaleLowerCase()=='male'){
            icon=require(`../assets/img/icons/male.svg`).default;
        }
        else if(gender?.toLocaleLowerCase()=='female'){
            icon=require(`../assets/img/icons/female.svg`).default;
        }
        else{
            icon=require(`../assets/img/icons/neutral.svg`).default;
        }
    }
    return (
        <div className={wrapperClass}>
            <img src={icon} className={styles} style={style}/>
        </div>
    );
}

DefaultUser.propTypes = {
    styles: PropTypes.string,
    wrapperClass: PropTypes.string,
    gender: PropTypes.string,
    showByGender: PropTypes.bool,
    style: PropTypes.object,
};

export default DefaultUser;
