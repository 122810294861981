import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";

import thunk from "./middelwares/thunk";
import reducers from "./reducers";
import getRequest from "../network/getRequest";
import useUserContext from "../hooks/useUserContext";
import callbackEnhancer from "./enhancer/callbackEnhancer";
import { loadState, saveState } from "./LocalStorage";
import { combineReducers } from 'redux'
import { DESTROY_SESSION    } from "../store/actions/loggedOutUser/loggedOutUser.constant";
function StoreProvider({ children }) {
    const { fetchRequest } = getRequest();
    const { dHToast } = useUserContext();
    const persistedState = loadState();

    const combinedReducer = combineReducers({
        ...reducers
    });
    
    const rootReducer = (state, action) => {
        if (action.type === DESTROY_SESSION) { // check for action type 
        state = undefined;
        }
        return combinedReducer(state, action);
    };
    
    const store = useMemo(() => {
        
        return configureStore({
            reducer: rootReducer,
            middleware: [
                thunk.withExtraArgument([fetchRequest, dHToast]),
                logger,
            ],
            enhancers: [callbackEnhancer()],
            preloadedState: persistedState,
            devTools: process.env.NODE_ENV !== "production",
        });
    }, []);

    store.subscribe(()=>{
        saveState({loggedInUser: store.getState().loggedInUser})
    })

    return <Provider store={store}>{children}</Provider>;
}

StoreProvider.propTypes = {
    children: PropTypes.element,
};

export default React.memo(StoreProvider);
