import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./app/App";
// import IdelAppState from "./app/IdelAppState";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import { UserContextProvider } from "./app/contextAPI";
import StoreProvider from "./app/store";
ReactDOM.render(
    <React.StrictMode>
        <UserContextProvider>
            <StoreProvider>
                <Router>
                    <App />
                </Router>
            </StoreProvider>
        </UserContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
