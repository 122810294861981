import config from "../config/Config";
import { SessionStorage } from "../utils/Storage";
import _ from "lodash";
import { stringInject, urlToQuery } from "../utils/UtilsFunc";

const getRequest = () => {
    const fetchRequest = (
        body = {},
        type = "post",
        requestURI = "",
        vars = [],
        isObj = null,
        concatString = null,
        headersFile = null,
        fileMethod=null,
    ) => {

        let header = config.get("headers");
        const user = SessionStorage.getObj("user");
        const errorCodes = config.get("errorCode");
        if (user && _.has(user, "accessToken") && user.accessToken.length > 0) {
            header = { Authorization: `Bearer ${user.accessToken}`, ...header, ...(headersFile && typeof headersFile === 'object' ? headersFile : {}) };
        }
        let url = config.get("base") + config.get(requestURI);
        if (vars) {
            url = stringInject(url, vars);
        }
        if (_.isObject(isObj) && !_.isEmpty(isObj)) {
            url = urlToQuery(url, isObj);
        }
        if (vars?.length === 0 && concatString) {
            url = url.concat(concatString)
        }


        let options = {};
        if (type === "post") {
            options = {
                method: "POST",
                headers: header,
                body: JSON.stringify(body),
            };
        } else if (type === "file") {
            delete header["Content-Type"];
            options = {
                method: fileMethod?fileMethod:headersFile && vars[0] ? "PUT" : "POST",
                headers: header,
                body: body,
            };
        } else if (type === "put") {
            options = {
                method: "PUT",
                headers: header,
                body: JSON.stringify(body),
            };
        } else if (type === "delete") {
            options = {
                method: "DELETE",
                headers: header
                //body: JSON.stringify(body),
            };
        } else if (type === "patch") {
            options = {
                method: "PATCH",
                headers: header,
                body: JSON.stringify(body),
            };
        } else {
            options = {
                method: "GET",
                headers: header,
            };
        }

        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then(async (response) => {
                    const data = await response.json();
                    if (_.has(data, "statusCode") && _.indexOf(errorCodes, data?.statusCode) > -1) {
                        throw new Error(data.message);
                    } else {
                        resolve({ ...data, status: response.statusCode || response.status });
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    return { fetchRequest };
};

export default getRequest;
