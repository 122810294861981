import {
  SET_USER_PERMISSIONS,
  SET_USER_DETAILS,
  SET_ROLES_LIST,
  SET_LOADING_STATE,
  SET_MFA_USER_DETAILS
} from "./loggedInUser.constant";
import { has } from "lodash";

export const setLoggedInUserPermissions = (permissions) => {
  return {
      type: SET_USER_PERMISSIONS,
      payload: permissions,
  };
};

export const setLoggedInUserDetails = (details) => {
  return {
    type: SET_USER_DETAILS,
    payload: details,
  };
};

export const setMfaUserDetails = (details) => {
  return {
    type: SET_MFA_USER_DETAILS,
    payload: details,
  };
};


export const fetchAllRoles = () => {
  return async (dispatch, args) => {
      var [fetchRequest] = args;
        
      const res = await fetchRequest({}, "get", "role");
      if (has(res, "result") && res.result.length > 0) {
        let modifiedRolesList = res.result.map((role) => ({
            name: role.displayName,
            id: role.id,
            role:role.role,
            active:role.active
        }));
        dispatch(setAllRoles(modifiedRolesList));
      }
    }
}

export const setAllRoles = (rolesList) => {
  return {
    type: SET_ROLES_LIST,
    payload: rolesList,
};
};

export const setLoadingAction = (data) => {
  return {
      type: SET_LOADING_STATE,
      payload: data
  }
}

export const resetAction = ()=> {
  return {
    type: "RESET"
  }
}
