import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatDateForTable } from '../../../../utils/luxon';
import { formatDollar, nullCheck } from "../../../../utils/UtilsFunc";

import {
    CHANGE_HOUSEHOLD_SELECTED_TAB,
    CREATE_BILLING_INFORMATION_SUCCESS,
    CREATE_FINANCIAL_PLANNING_INFO,
    FETCH_BILLING_INFO_SUCCESS,
    FETCH_FINANCIAL_PLANNING_INFO_SUCCESS,
    FETCH_HOUSEHOLD_GENERAL_INFO_SUCCESS,
    FETCH_INITIAL_REQUEST_START,
    UPDATE_BILLING_INFORMATION_SUCCESS,
    UPDATE_FINANCIAL_PLANNING_INFO,
    CHANGE_HOUSEHOLD_STATUS,
    FETCH_CLIENT_HOUSEHOLD_BILLING_SUCCESS,
    CREATE_PAYOUT_BLOCK_INFO,
    ADD_NEW_PAYOUT_BLOCK_INFO,
    DELETE_NEW_PAYOUT_BLOCK_INFO,
    CHANGE_REFERENCE,
    GET_EXCLUDED_ASSETS,
    ADD_EXCLUDED_ASSETS,
    RESET_PAY_BLOCK_LIST,
    RESET_EXCLUDED_ASSETS,
    LIVE_BILL_LIST,
    CLEAR_HOUSEHOLD_GENERAL_INFO,
    CLEAR_HOUSEHOLD_BILLING_INFO,
    CLEAR_HOUSEHOLD_BILLING_INFORMATION
} from "./household.constant";
import {  PRIMARY, SECONDARY } from '../../../../constants/constant'
import { fetchFinanceInfoThunk } from "./household.thunk";
import { memberSubType } from "../../../../utils/enum";

export const changeActiveTab = (index) => {
    return {
        type: CHANGE_HOUSEHOLD_SELECTED_TAB,
        index: index,
    };
};

export const changeGeneralReference = () => {
    return {
        type: CHANGE_REFERENCE,
    };
};

export const clearGeneralInfo = () => {
    return {
        type : CLEAR_HOUSEHOLD_GENERAL_INFO
    }
}
export const clearBillingInfo = () => {
    return {
        type : CLEAR_HOUSEHOLD_BILLING_INFO
    }
}

export const fetchGeneralInfo = (id,states) => {
        return async (dispatch, args) => {
        var [fetchRequest] = args;
        dispatch(fetechInitalRequestStart());
        const response = await fetchRequest({}, "get", "householdGeneral", [
            id,
        ]);
        let address=response.result.members.find(i=>i.subType===memberSubType.Primary)?.addresses?.find(i=>i.isPrimary)
        if(address){
            response.result.contact={
                address:address.addressLine1??'',
                addressLine2: address.addressLine2??'',
                city:address.city??'',
                state: {title:address.state.name,value:address.state.id,abbrevation:address.state.abbrevation},
                zip: address.zip??'',
                usResident: address.country.abbrevation==='US'?true:false,
            }
        }
         if (!address) {
            response.result.contact = {
                address: "",
                addressLine2: "",
                city: "",
                state: "",
                zip: "",
                usResident: false,
            }
        }
        dispatch(fetchGeneralInfoSuccess(response.result));
    };
};

export const fetchFinanceInfoAsyncThunk = createAsyncThunk(
    "/billing/financialPlanning",
    fetchFinanceInfoThunk
);

export const fetchBillingInfo = (id,states,setLoading) => {
    return async (dispatch, args) => {
        var [fetchRequest] = args;
        const response = await Promise.all([
            fetchRequest({}, "get", "householdFinance", [id]),
            fetchRequest({}, "get", "householdBilling", [id]),
        ]);
        const [financeInfo, billingInfo] = response;
        if (billingInfo.result && Object.keys(billingInfo.result).length) {
            const selectedState=states.filter((state) => {
                return state.id === billingInfo.result.state.id
            })[0];
            billingInfo.result.state = {...selectedState,value:selectedState?.id,title:selectedState?.name}
        }
        dispatch(fetchBillingInfoSuccess(billingInfo.result));
        dispatch(fetchFinanceInfoSuccess(financeInfo.result));
        setLoading(false)
    };
};

export const updateGeneralInfo = (id, data) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        try {
            const response = await fetchRequest(data, "put", "householdGeneral", [
                id,
            ]);
            dispatch(fetchGeneralInfo(data.client));
            showToast(true, response.message, "rt", "success", faCheckCircle);
        } catch (err) {
            showToast(true, err.message, "rt", "error", faExclamationCircle);
        }
    };
};

const modifyResponse = (res) => ({
    ...res,
    feePayingAccount: res?.feePayingAccount?.id
})

export const createFinancialPlanning = (data) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        const response = await fetchRequest(data, "post", "householdFinance");
        dispatch(createFinancialPlanningSuccess(modifyResponse(response.result)));
        showToast(true, response.message, "rt", "success", faCheckCircle);
    };
};

export const updateFinancialPlanning = (id, data) => {
    return async (dispatch, args) => {
        const { modifiedBy, ...rest } = data;
        const [fetchRequest, showToast] = args;
        const response = await fetchRequest(rest, "put", "householdFinance", [
            id,
        ]);
        dispatch(updateFinancialPlanningInfo(data));
        showToast(true, response.message, "rt", "success", faCheckCircle);
    };
};

export const createBillingInformation = (data) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        const response = await fetchRequest(data, "post", "householdBilling");
        dispatch(createBillingInformationSuccess(response.result));
        showToast(true, response.message, "rt", "success", faCheckCircle);
    };
};

export const updateBillingInformation = (id, data,states) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        const response = await fetchRequest(data, "put", "householdBilling", [
            id,
        ]);
        let filteredStateValue = states.filter((state) => {
            return state.id === data.state
        })
        data.state = {...filteredStateValue[0],title:filteredStateValue[0].name,value:filteredStateValue[0].id};
        dispatch(updateBillingInformationSuccess(data));
        showToast(true, response.message, "rt", "success", faCheckCircle);
    };
};

function updateBillingInformationSuccess(data) {
    return {
        type: UPDATE_BILLING_INFORMATION_SUCCESS,
        data,
    };
}

function createBillingInformationSuccess(data) {
    return {
        type: CREATE_BILLING_INFORMATION_SUCCESS,
        data,
    };
}

function createFinancialPlanningSuccess(data) {
    return {
        type: CREATE_FINANCIAL_PLANNING_INFO,
        data,
    };
}

function updateFinancialPlanningInfo(data) {
    return {
        type: UPDATE_FINANCIAL_PLANNING_INFO,
        data,
    };
}

function fetchGeneralInfoSuccess(data) {
    return {
        type: FETCH_HOUSEHOLD_GENERAL_INFO_SUCCESS,
        data,
    };
}

function fetchFinanceInfoSuccess(data) {
    return {
        type: FETCH_FINANCIAL_PLANNING_INFO_SUCCESS,
        data,
    };
}

function fetchBillingInfoSuccess(data) {
    return {
        type: FETCH_BILLING_INFO_SUCCESS,
        data,
    };
}
export function clearBillingInformation(){
    return {
        type:CLEAR_HOUSEHOLD_BILLING_INFORMATION
    }
}
function fetechInitalRequestStart() {
    return {
        type: FETCH_INITIAL_REQUEST_START,
    };
}

export const fetchHouseHoldBilling = (
    status,
    queryFilter,
    pageNo,
    numberOfRecords,
    sortBy,
    selectedTab,
    setLoading
) => {
    return function (dispatch, args) {
        var [fetchRequest] = args;
        const params = {
            query: {
                ...queryFilter,
                householdStatus: status,
                ...((sortBy.sortBy === "member" || sortBy.sortBy === "unitId") ? { subType: PRIMARY } : {})
            },
            paginate: {},
            sorting: { ...sortBy },
        };
        if (numberOfRecords) {
            params.paginate.limit = numberOfRecords;
        }
        if (pageNo) {
            params.paginate.page = pageNo;
        }
       
        if(selectedTab === "readyToBill")
        {
            params.query.billingStatus = "READY";
        }else if(selectedTab === "notReadyToBill")
        {
            params.query.billingStatus = "REQUIRES_ATTENTION";
        }else if (selectedTab === "missingFeeSchedules")
        {
            params.query.missingFeeSchedule = true;
        }else if (selectedTab === "missingPayoutBlocks")
        {
            params.query.missingPayoutBlocks = true;
        }
        fetchRequest(params, "post", "getHouseholdBilling").then((response) => {
            dispatch(getClientHouseholdBilling(mapData(response)));
            if(setLoading){
                setLoading(false)
            }
        });
    };
};

const mapData = (response) => {
    const { billingDetails,  missingFeeSchedule,  missingPayoutBlocks, readyToBill, requiresAttention,
        totalHouseholds } = response.result || {};
    const {totalResults } = response
    const stats = {
        missingFeeSchedule,
        missingPayoutBlocks,
        readyToBill,
        requiresAttention,
        totalHouseholds
    }
    const res = billingDetails?.filter(item => item.members?.some(member => (member?.subType === PRIMARY
        || nullCheck(member?.subType) || member?.subType === SECONDARY))) || [];
    const billingDetail = res.map(item => ({
        ...item,
        members: item?.members.filter(member => member?.subType === PRIMARY)
    }))
    return  {
        billingDetail,
        totalResults,
        stats
    }
}    

export const changeHouseholdStatus = (status) => {
    return {
        type: CHANGE_HOUSEHOLD_STATUS,
        status,
    };
};

function getClientHouseholdBilling(data) {
    return {
        type: FETCH_CLIENT_HOUSEHOLD_BILLING_SUCCESS,
        householdBilling: data,
    };
}

export const resetPayBlock = () => {
    return {
        type: RESET_PAY_BLOCK_LIST,
    };
}

export const fetchPayoutBlock = (clientId) => {
    return async (dispatch, args) => {
        var [fetchRequest] = args;
        const response = await fetchRequest({}, "get", "payoutBlock", [
            clientId,
        ]);
        //if(response.result.payoutBlock)
        dispatch(createPayoutBlockSuccess(response.result));
    };
};

export const createPayoutBlock = (data) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        try {
            const response = await fetchRequest(data, "post", "payoutBlock");
            dispatch(createPayoutBlockSuccess(response.result));
            showToast(true, response.message, "rt", "success", faCheckCircle);
        } catch (err) {
            showToast(true, err.message, "rt", "error", faExclamationCircle);
        }
    };
};

export const updatePayoutBlock = (id, data) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        try {
            const response = await fetchRequest(data, "put", "payoutBlock", [
                id,
            ]);
            dispatch(createPayoutBlockSuccess(response.result));
            showToast(true, response.message, "rt", "success", faCheckCircle);
        } catch (err) {
            showToast(true, err.message, "rt", "error", faExclamationCircle);
        }
    };
};

export const deletePayoutBlock = (id, clientId, data) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        const response = await fetchRequest(data, "delete", "payoutBlock", [
            id,
            clientId,
        ]);
        dispatch(createPayoutBlockSuccess(response.result));
        showToast(true, response.message, "rt", "success", faCheckCircle);
    };
};

function createPayoutBlockSuccess(data) {
    data.payoutBlock.map((item, idx) => {
        let payeeObj = { ...item.payee };
        item.payee = { title: payeeObj.fullName, value: payeeObj.id };
        if (item.payForever) {
            //item.numberOfPayments = "Forever";
            item.payoutBlockPaymentType = "Forever";
        } else {
            //item.numberOfPayments = null
            item.payoutBlockPaymentType = "Number_Of_Payments";
        }
        return item;
    });

    return {
        type: CREATE_PAYOUT_BLOCK_INFO,
        data,
    };
}

export const addNewPayoutBlock = (data) => {
    return {
        type: ADD_NEW_PAYOUT_BLOCK_INFO,
        data,
    };
};

export const deleteNewPayoutBlock = (data) => {
    return {
        type: DELETE_NEW_PAYOUT_BLOCK_INFO,
        data,
    };
};


//EXCLUDED ASSETS

export const getExcludedAssets = (data) => {
    return {
        type: GET_EXCLUDED_ASSETS,
        data
    }
}

export const addExcludedAssets = (data) => {
    return {
        type: ADD_EXCLUDED_ASSETS,
        data
    }
}
export const resetExcludedAssets = () => {
    return {
        type:RESET_EXCLUDED_ASSETS
    }
    
}
export const getExcludedAssetsService = (clientId) => {
    return async (dispatch, args) => {
        const [fetchRequest] = args;
        const response = await fetchRequest(
            null,
            "get",
            "getExcludedAssets",
            [clientId]
        );
        dispatch(getExcludedAssets(response?.result || []));
    };
}

export const addExcludedAssetsService = (data,formSubmit) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        try {
            const response = await fetchRequest(
                data,
                'post',
                'addExcludedAssets'
            );
            showToast(true, response.message, "rt", "success", faCheckCircle);
            formSubmit(true)
        } catch (error) {
            showToast(true, error.message, "rt", "error", faExclamationCircle);
        }
        
    }
}

export const updateExcludedAssetsService = (data,id) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        try {
            const response = await fetchRequest(
                data,
                'put',
                'updateExcludedAssets',
                [id]
            );
            showToast(true, response.message, "rt", "success", faCheckCircle);
        } catch (error) {
            showToast(true, error.message, "rt", "error", faExclamationCircle);
        }
        
    }
}

export const deleteExcludedAssetsService = (id, clientId) => {
    return async (dispatch, args) => {
        const [fetchRequest, showToast] = args;
        try {
            const response = await fetchRequest(
                null,
                'delete',
                'deleteExcludedAssets',
                [id, clientId]
            );
            dispatch(getExcludedAssets(response?.result || []));
            showToast(true, response?.message, "rt", "success", faCheckCircle);
        } catch (error) {
            showToast(true, error.message, "rt", "error", faExclamationCircle);
        }
        
    }
}

// LiveBill

const getLiveBillAssets = (data) => {
    return {
        type: LIVE_BILL_LIST,
        data
    }
}

const modifyLiveBillResponse = (data) => {
    return data?.map((item) => ({
        ...item,
        billId:item?.liveBillSeq,
        liveBillSeq: item?.billNo?.billNo,
        netFeeAmt : formatDollar(Number(item?.balance)),
        generatedBy: item?.createdBy?.fullName,
        dateRange: `${formatDateForTable(item?.billNo?.periodStart)} - ${formatDateForTable(item?.billNo?.periodEnd)}`,
        createdAt: formatDateForTable(item?.createdAt),
        processedDate: formatDateForTable(item?.billNo?.createdAt)
    }))

}
export const getLiveBillService = (params) => {
    return async (dispatch, args) => {
        const [fetchRequest] = args;
        const response = await fetchRequest(
            params,
            "post",
            "getLiveBills"
        );
        dispatch(getLiveBillAssets(modifyLiveBillResponse(response?.result)));
    };
}

