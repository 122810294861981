import React from "react";
import PropTypes from "prop-types";

const Loader = ({ height = 'screen' }) => {
  return (
    <div className={`flex h-${height} w-full items-center justify-center`}>
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  height: PropTypes.string,
};

export default Loader;
