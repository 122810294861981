import _ from "lodash";
import { EDIT_MEMBER_DATA, START_MEMBER_FORM, UPDATE_BASIC_INFO, UPDATE_CONTACT_INFO, UPDATE_EMPLOYMENT_INFO, UPDATE_FORM_TAB_IDX, UPDATE_IDENTITY_INFO, UPDATE_PREFERENCE_INFO } from "../../actions/clients/members/members.constant";

function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case START_MEMBER_FORM: {
      return {
        ...state,
        ...INIT_STATE,
        memberType: action.memberType,
        currFormIdx: state.isEditing ? state.currFormIdx : 0,
      }
    }
    case UPDATE_BASIC_INFO: {
      return {
        ...state,
        basic: {
          ...state.basic,
          ...action.data
        },
        currFormIdx: state.isEditing ? state.currFormIdx : Math.max(state.currFormIdx, 1),
      }
    }
    case UPDATE_PREFERENCE_INFO: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.data
        },
        currFormIdx: state.isEditing ? state.currFormIdx : Math.max(state.currFormIdx, 2),
      }
    }
    case UPDATE_CONTACT_INFO: {
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          ...action.data
        },
        currFormIdx: state.isEditing ? state.currFormIdx : Math.max(state.currFormIdx, 3),
      }
    }
    case UPDATE_IDENTITY_INFO: {
      return {
        ...state,
        identity: {
          ...state.identity,
          ...action.data
        },
        currFormIdx: state.isEditing ? state.currFormIdx : Math.max(state.currFormIdx, 4),
      }
    }
    case UPDATE_EMPLOYMENT_INFO: {
      return {
        ...state,
        employment: {
          ...state.employment,
          ...action.data
        },
        currFormIdx: state.isEditing ? state.currFormIdx : Math.max(state.currFormIdx, 5),
      }
    }
    case UPDATE_FORM_TAB_IDX: {
      return {
        ...state,
        currFormIdx: state.currFormIdx + 1,
      }
    }
    case EDIT_MEMBER_DATA: {
      const updatedPreferences = action.data.preferences
      Object.keys(updatedPreferences).forEach(k => {
        if (updatedPreferences[k] && updatedPreferences[k].id) {
          updatedPreferences[k] = updatedPreferences[k].id
        }
      })

      const updatedAddress = _.cloneDeep(action.data.contactInfo.addresses).map(addr => {
        const updatedAddr = addr
        Object.keys(updatedAddr).forEach(k => {
          if (updatedAddr[k] && updatedAddr[k].id) {
            updatedAddr[k] = updatedAddr[k].id
          }
        })
        return updatedAddr
      })

      const updatedEmployment = action.data.employment
      Object.keys(updatedEmployment).forEach(k => {
        if (updatedEmployment[k] && updatedEmployment[k].id) {
          updatedEmployment[k] = updatedEmployment[k].id
        }
      })

      const updatedIdentity = action.data.identity
      Object.keys(updatedIdentity).forEach(k => {
        if (updatedIdentity[k] && updatedIdentity[k].id) {
          updatedIdentity[k] = updatedIdentity[k].id
        }
      })

      return {
        ...state,
        ...action.data,
        basic: {
          // ...state.basic,
          ...action.data.basic,
        },
        preferences: {
          // ...state.preferences,
          ...updatedPreferences,
        },
        contactInfo: {
          // ...state.contactInfo,
          ...action.data.contactInfo,
          addresses: updatedAddress
        },
        identity: {
          // ...state.identity,
          ...updatedIdentity,
        },
        employment: {
          // ...state.employment,
          ...updatedEmployment,
        },
        currFormIdx: 4,
        isEditing: true,
      }
    }
    default:
      return state;
  }
}

var INIT_STATE = {
  currFormIdx: 0,
  memberType: "",
  isEditing: false,
  basic: {
    firstName: "",
    middleName: "",
    lastName: "",
    nickName: "",
    suffix: "",
    subType: "",
    annualIncome: "",
    profileImageUrl: "",
  },
  preferences: {
    gender: "",
    sexualOrientation: "",
    marriageStatus: "",
    politicalParty: "",
    religion: "",
    parentReligion: "",
    interests: "",
    riskScore: "",
  },
  contactInfo: {
    addresses: [{
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      type: "",
      isPrimary: false,
      linkedToPrimary:false
    }],
    phoneNumbers: [{
      phone: "",
      type: "",
      isPrimary: false,
      linkedToPrimary:false
    }],
    emailAddresses: [{
      email: "",
      type: "",
      isPrimary: false,
      linkedToPrimary:false
    }],
  },
  identity: {
    date: "",
    identityNumber: "",
    driverLicenseNum: "",
    driverLicenseState: ""
  },
  employment: {
    industry: "",
    employer: "",
    jobTitle: "",
    employmentStatus: "",
    parentIndustry: ""
  },
  deceased: false,
  deceasedDate: "",
};

export default reducer;
