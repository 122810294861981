import {
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_START,
  FETCH_CLIENT_SUCCESS,
} from "../../actions/clients/clients.constant";

function reducer(state = INIT_STATE, action) {
  switch (action.type) {
      case FETCH_CLIENT_START:
          return { ...state, isLoading: true };
      case FETCH_CLIENT_SUCCESS:
          return { ...state, clientList: action.clients, isLoading: false };
      case FETCH_CLIENT_FAILURE:
          return { ...state, isLoading: false };
      default:
          return state;
  }
}

var INIT_STATE = {
  clientList: [],
  isLoading: false,
};

export default reducer;
