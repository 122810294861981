import React from 'react'
import { addMemberType, memberSubType } from '../utils/enum'
import Image from './Image'
import PropTypes from "prop-types";
import { Tooltip } from 'react-tippy';
import buildingImage from "../assets/img/image/building.jpg"
import Text from './Text';

function MembersDefaultImages({
  members = [],
  showImageByGender = false,
  showNameOnHover = false,
  showRemaining = false,
  countToShow = 0,
  tooltipHtml,
  className = "",
  primaryImageClassName = "object-contain w-10 h-10 rounded-md object-cover drop-shadow-xl z-10",
  secondaryImageClassName = "object-contain w-8 h-8 rounded-md object-cover drop-shadow-xl ",
  secondaryLeft = "1.85rem",
}) {
  const primaryMember = members?.find(el => el.subType === memberSubType.Primary)
  const secondaryMember = members?.find(el => el.subType === memberSubType.Secondary)
  let neutralImage =
    require(`../assets/img/icons/neutral.svg`).default;
  let maleImage =
    require(`../assets/img/icons/male.svg`).default;
  let femaleImage =
    require(`../assets/img/icons/female.svg`).default;
  const getProfileImage = (member) => {
    if (member.profileImageUrl) {
      return member.profileImageUrl
    }
    else if (member.type === addMemberType.BUSINESS || member.type === addMemberType.TRUST) {
      return buildingImage
    }
    else if (!showImageByGender) {
      return neutralImage
    }
    else if (member.gender && member.gender.name.toLowerCase() === "male") {
      return maleImage
    }
    else if (member.gender && member.gender.name.toLowerCase() === "female") {
      return femaleImage
    }

    else {
      return neutralImage
    }
  }


  return (
    <div className={`flex items-center relative ${className} ${showRemaining ? (secondaryMember ? "mr-12" : "mr-6") : (primaryMember ? (secondaryMember ? "mr-6" : "") : "")}`}>
      {primaryMember && (showNameOnHover ?
        <Tooltip
          position="top"
          trigger="mouseenter"
          className='z-30'
          arrow
          html={<p className="w-auto break-all capitalize p-1  z-20">{primaryMember.firstName + ' ' + primaryMember.lastName}</p>}
        >
          <Image
            img={getProfileImage(primaryMember)}
            imgClass={primaryImageClassName} style={{ border: '1px solid white', boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)" }}
          />
        </Tooltip>
        :
        <Image
          img={getProfileImage(primaryMember)}
          imgClass={primaryImageClassName} style={{ border: '1px solid white', boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)" }}
        />
      )

      }
      {
        secondaryMember && (showNameOnHover ?
          <Tooltip
            position="top"
            trigger="mouseenter"
            className={`${primaryMember && "absolute"} w-8 h-8 z-20`}
            arrow
            style={{ left: '1.85rem' }}
            html={<p className="w-auto break-all capitalize p-1">{secondaryMember.firstName + ' ' + secondaryMember.lastName}</p>}
          >
            <Image
              img={getProfileImage(secondaryMember)}
              imgClass={secondaryImageClassName} style={{ left: '1.85rem', border: '1px solid white' }}
            />
          </Tooltip>
          : (
            <Image
              img={getProfileImage(secondaryMember)}
              imgClass={`${secondaryImageClassName} absolute`}
              style={{ left: secondaryLeft, border: '1px solid white' }}
            />
          ))}
      {showRemaining && <div onClick={e => e.stopPropagation()} className="bg-gray-300 z-10 w-full h-7 flex justify-end items-center absolute rounded-md" style={{ left: secondaryMember ? '2.85rem' : "1.40rem", border: '1px solid white' }}>
        <Tooltip
          position="top"
          trigger="mouseenter"
          className='flex justify-center items-center'
          arrow
          html={tooltipHtml}
          interactive
          style={{
            textDecoration: "none"
          }}
        >
          <Text
            tag="p"
            scale={false}
            text={"+" + countToShow}
            fontweight="normal"
            styles={`text-sm ${countToShow < 10 ? "pr-1" : ""} `}
            alignment="right"
          />
        </Tooltip ></div>}
    </div>
  )
}

MembersDefaultImages.propTypes = {
  members: PropTypes.array,
  showImageByGender: PropTypes.bool,
  showNameOnHover: PropTypes.bool,
  showRemaining: PropTypes.bool,
  countToShow: PropTypes.number,
  tooltipHtml: PropTypes.element,
  className: PropTypes.string,
  primaryImageClassName: PropTypes.string,
  secondaryImageClassName: PropTypes.string,
  secondaryLeft: PropTypes.string,
};
export default MembersDefaultImages