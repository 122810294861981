import {
    FETCH_USER_FAILURE,
    FETCH_USER_START,
    FETCH_USER_SUCCESS,
} from "../actions/user/user.constant";

function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case FETCH_USER_START:
            return { ...state, isLoading: true };
        case FETCH_USER_SUCCESS:
            return { ...state, userList: action.users, isLoading: false };
        case FETCH_USER_FAILURE:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}

var INIT_STATE = {
    userList: [],
    isLoading: false,
};

export default reducer;
