import {
  CREATE_REC_PAYOUT_BLOCK_INFO,
  ADD_NEW_REC_PAYOUT_BLOCK_INFO,
  DELETE_NEW_REC_PAYOUT_BLOCK_INFO,
  UPDATE_DRILL_DOWN_CHART_STATE,
} from "../../actions/billing/receivables/receivables.constant";

function reducer(state = INIT_STATE, action) {
  switch (action.type) {
      case CREATE_REC_PAYOUT_BLOCK_INFO:
          return {  
              ...state,
              payoutBlock: {
                  payoutBlockList: action.data.receivablePayout,
                  totalCreditTo: action.data.creditToTotal,
                  payoutAppPercentageTotal: action.data.payoutAppPercentageTotal
              },
          };
      case ADD_NEW_REC_PAYOUT_BLOCK_INFO:
          return {
              ...state,
              payoutBlock: {
                  payoutBlockList: [
                      ...state.payoutBlock.payoutBlockList,
                      action.data,
                  ],
                  totalCreditTo: state.payoutBlock.totalCreditTo,
                  payoutAppPercentageTotal: state.payoutBlock.payoutAppPercentageTotal
              },
          };
      case DELETE_NEW_REC_PAYOUT_BLOCK_INFO:
          return {
              ...state,
              payoutBlock: {
                  payoutBlockList: state.payoutBlock.payoutBlockList.filter(
                      (item) => item.payee !== ""
                  ),
                  totalCreditTo: state.payoutBlock.totalCreditTo,
                  payoutAppPercentageTotal: state.payoutBlock.payoutAppPercentageTotal
              },
          };
      case UPDATE_DRILL_DOWN_CHART_STATE:
          return {
              ...state,
              drillDownChartState: action.data
          }
      default:
          return state;
  }
}

var INIT_STATE = {
  payoutBlock: {
      payoutBlockList: [],
      totalCreditTo: 0,
      payoutAppPercentageTotal: 0,
  },
  drillDownChartState: {},
};

export default reducer;
