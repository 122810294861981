import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { UserContext } from "../contextAPI";
import getRequest from "../network/getRequest";
import useUserContext from "./useUserContext";

/**
 *  options here will take more configurable options such as mime type alllowed or max file size needed
 *  {
 *      mimeTypeAllowed: ['png', 'jpg'],
 *      sizeLimit: 500 (in KB)
 *  }
 *
 * */

export const useFileUpload = ({ pathToUpload, validation, showErrorInToast }, value) => {
    const { dHToast } = useUserContext(UserContext);
    var [state, setState] = useState(() => {
        let meta = !value
            ? null
            : {
                  attachmentUrl: value,
                  attachmentName: value.substring(value.lastIndexOf("/") + 1),
              };
        return {
            imageMeta: meta,
            isLoading: false,
            error: null,
            fileName: null,
        };
    });

    function upload(file) {
        const res = validation(file);
        if (res === true) {
            // Means validation are passed and we are good to upload the files
            var formData = new FormData();
            formData.append("file", file);
            var { fetchRequest } = getRequest();
            setState({
                ...state,
                isLoading: true,
                fileName: file.name,
                error: null,
            });
            fetchRequest(formData, "file", pathToUpload)
                .then((response) => {
                    setState({
                        ...state,
                        isLoading: false,
                        imageMeta: response.result,
                        fileName: file.name,
                        error: null,
                    });
                })
                .catch((err) => {
                    setState({
                        ...state,
                        isLoading: false,
                        error: "Internal Server Error",
                        fileName: file.name,
                    });
                });
        } else {
            if (showErrorInToast) {
                dHToast(true, res, "rt", "error", faExclamationCircle);
                setState({
                    ...state,
                    fileName: file.name,
                });
            }
            else {
                setState({
                    ...state,
                    error: res,
                    fileName: file.name,
                });
            }
        }
    }

    return {
        ...state,
        upload,
        setState
    };
};
