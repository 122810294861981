export function validateEmail(data, name, errorText, len) {
    const re = /^\S[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/;
    if (len) {
        return data && data.length > 0 && data.length > len
            ? "Email can be up to 255 characters in length." : (!re.test(data)
                ? errorText || `Please enter valid ${name}`
                : "");
    }
    return data && data.length > 0 && !re.test(data)
        ? errorText || `Please enter valid ${name}`
        : "";
}

export function validateText(data, name) {
    const re = /^[a-zA-Z\s]*$/g;
    return data && data.length > 0 && !re.test(data)
        ? `Please enter valid ${name}`
        : "";
}

export function validateNumber(data, name) {
    const re = /([0-9])\w+/g;
    return data && data.length > 0 && !re.test(data)
        ? `Please enter valid ${name}`
        : "";
}

export function validatePassword(data, name, errorText) {
    const re =
        /^(?=.*\d)(?=.*[a-z])(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,14}$/g;
    return data && data.length > 0 && !re.test(data)
        ? errorText || `Please enter valid ${name}`
        : "";
}

export function validateDate(data, name) {
    const re = /^"([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}"$/i;
    return data && data.length > 0 && !re.test(data)
        ? `Please enter valid ${name}`
        : "";
}

export function validateAddress(data, name) {
    //eslint-disable-next-line
    const re = /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    return data && data.length > 0 && !re.test(data)
        ? `Please enter valid ${name}`
        : "";
}

export function validateSearch(data, name) {
    //eslint-disable-next-line
    const re = /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    return data && data.length > 0 && !re.test(data)
        ? `Please enter valid ${name}`
        : "";
}

export function validateTextWithSpecialCharacters(data, name) {
    const re = /^[^0-9]*$/;
    return data && data.length > 0 && !re.test(data)
        ? `Please enter valid ${name}`
        : "";
}

export const allowAll = (data, name) => "";

export function getError(type, data, name, errorText, len = null) {
    switch (type) {
        case "email":
            return validateEmail(data, name, errorText, len);
        case "text":
            return validateText(data, name);
        case "number":
            return validateNumber(data, name);
        case "password":
            return validatePassword(data, name, errorText);
        case "date":
            return validateDate(data, name);
        case "address":
            return validateAddress(data, name);
        case "search":
            return validateSearch(data, name);
        case "textWithSpecial":
            return validateTextWithSpecialCharacters(data, name);
        case "all":
            return allowAll(data, name);
        default:
            return validateText(data, name);
    }
}
