import React from 'react';
import PropTypes from "prop-types";
//import permission from "../assets/database/permission.json"
import { useSelector } from "react-redux";
import UnAuthorized from '../components/Unauthorized';

const labelMap = {
  'Main': ['Dashboard', 'Clients', 'Prospects', 'Alert', "Other Professional"],
  'Clients':["Other Professional"],
  'Workflows':['Open Request', 'Manage Workflows'],
  'Products & Billing':['Products', 'Units','Fee_Schedules','Accounts','CashFlows','Billing_Instances','Receivables',],
  'Identity access management':['Users', 'Roles', 'Teams'],
  'Billing':['Units','Fee Schedules','Accounts','CashFlows','Billing Instances','Receivables',]
}

const AccessCheck = ({
  module=null,
  feature=null, 
  permissionType=null,
  elementType,
  children,
  checkStyles="flex items-center", 
  labelName=null,
  noDiv=false
}) => {

  const permission = useSelector(({ loggedInUser }) => loggedInUser.loggedInUser.userPermission);
  
    const checkAuth = () => {
      let moduleVal;
      let featureVal;
      let labelArray = [];
      if(module)
      {
        //exception for clients sidebar item
        if(module === 'General')
        {
          moduleVal = permission?.find(obj => {
            return obj.permission.feature.toUpperCase() === module.toUpperCase();
          });
  
        }else{
          moduleVal = permission?.find(obj => {
            return obj.permission.module.toUpperCase() === module.toUpperCase();
          });
        }
         if(moduleVal)
         { 
           return moduleVal[permissionType]
         }
      }
      
      if(feature)
      {
        featureVal = permission?.find(obj => {
          return obj.permission.feature.toUpperCase() === feature.toUpperCase();
        });
        if(featureVal) return featureVal[permissionType]
      }


      if(labelName) // name of label main etc
      {
        labelArray = labelMap[labelName];        
        if(labelArray?.length >0)
        {
          let values = [];
          let valid = true;
          labelArray.map((subItems)=>{
            let moduleVal = permission?.find(obj => {
              
            if(subItems === 'Units'||subItems === 'Fee Schedules' ||subItems === 'Fee_Schedules' || subItems === 'CashFlows' || subItems === 'Accounts' || subItems === 'Billing_Instances' ||subItems === 'Billing Instances'|| subItems === 'Receivables'|| subItems === 'Other Professional'){
                if(subItems === 'Fee Schedules' || subItems === 'Billing Instances' || subItems === 'Other Professional')
                {
                  let moduleName = subItems.replaceAll(' ', '_')
                  return obj.permission.feature.toUpperCase() === moduleName.toUpperCase(); 

                }else{
                    return obj.permission.feature.toUpperCase() === subItems.toUpperCase();
                }
              }
              else{
                return obj.permission.module.toUpperCase() === subItems.toUpperCase();
              }
            });
            if(moduleVal) {
              values.push(moduleVal['read'])  
            }            
          })
          if (values.length > 0) {
            valid = values.every((item) => item === false);
          }
          if(valid)
          {
            return false
          }else{
            return true
          }
        }
      }

      return true;
            
    }
  
    const renderElement=()=>{
      switch (elementType) {
        case "Button":
          return React.cloneElement(children, {styles:'hidden ' + children.props.styles})
        case "ButtonDisable":
            return React.cloneElement(children, {styles:'pointer-events-none ' + children.props.styles})  
        case "Anchor":
          return React.cloneElement(children, {classes:'hidden ' + children.props.classes})          
        case "TableRowClick":
          return React.cloneElement(children, {rowClass:'pointer-events-none'})
        case "Img":
          return React.cloneElement(children, {className:'hidden ' + children.props.className })
        case "Tab":
          return <div className='flex items-center justify-center w-full'> <UnAuthorized message='You do not have permission to access this section.' className='flex-1 bg-white' /> </div>;
        case "Label":
          return React.cloneElement(children, {className:'hidden ' + children.props.className});
        case "FormElement":
          return React.cloneElement(children, { disabled: true });
        default:
          return React.cloneElement(children, {styles:'hidden ' + children.props.styles})
      }

    }

    return (
      noDiv?<>
          {
          checkAuth() ? 
              children : 
              renderElement()
        }
      </>:
      
      <div className={checkStyles}>
        {
          checkAuth() ? 
              children : 
              renderElement()
        }
      </div>
    );
};

AccessCheck.propTypes = {
  module: PropTypes.string,
  feature: PropTypes.string,
  permissionType: PropTypes.string,
  children: PropTypes.element.isRequired,
  elementType:PropTypes.string,
  checkStyles:PropTypes.string,
  labelName: PropTypes.string,
  noDiv:PropTypes.bool
};

export default AccessCheck;