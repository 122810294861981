import classNames from "classnames";
import { isFunction, size } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import searchIcon from "../assets/img/icons/search_icon.svg";
import Image from "../commonComponent/Image";
import Text from "../commonComponent/Text";
import { UserContext } from "../contextAPI";
import { getError } from "../utils/Validators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const InputText = ({
    name = "",
    value = "",
    icon,
    placeholder = "",
    type = "text",
    autocomplete = "off",
    hasIcon = "false",
    forTable = "false",
    placeholderColor = "true",
    margin = "false",
    handleChange = null,
    required = false,
    label = "",
    errorText = "",
    emptyErrorText = "",
    styles = "",
    errorStyle = "",
    validatorType = "",
    disabled = false,
    bgColor = "",
    placeholderFontNormal = "false",
    rounded = "true",
    roundedStyles = "",
    height="",
    border=true,
    borderBottom="",
    validationRequired = true,
    maxLength = "",
    textCenter = false,
    showNumberOnly=false,
    style={},
    clearable = false,
}) => {
    const [inputError, setInputError] = useState("");
    const { formValidStatus } = useContext(UserContext);
    const [readOnly, setReadOnly] = useState(true);
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        if(showNumberOnly){
            if(!(/^\d*$/.test(value)))
            {
                e.preventDefault();
                return;
            }
        }
        if(validationRequired) {
            let result = getError(
                validatorType || type,
                value,
                placeholder,
                errorText,
                type === "email" ? 255 : null
            );
            if (value?.trim() === "" && emptyErrorText !== "") {
                result = emptyErrorText;
            }
    
            if (result.length > 0) {
                formValidStatus(status);
            }
            setInputError(result);
            if (result.length > 0) {
                formValidStatus(status);
                handleChange(name, value, false);
            } else {
                handleChange(name, value, true);
            }
        } else {
            if(type === 'checkbox')
            {
                handleChange(name, e.target.checked, true);
            }else{
                handleChange(name, value, true);
            }
        }
    };

    const handleOnfocus = () => {
        setReadOnly(false);
    };

    useEffect(() => {
        if (size(value) === 0) {
            setInputError("");
        }
    }, [value]);

    let inputClass = classNames(
        `px-3 py-3 ${borderBottom} ${roundedStyles? roundedStyles: ""} text-black-900 relative text-sm focus:ring outline-none w-full ${border  ? 'border' : '' }`,
        {
            "font-bold": forTable === "false",
            "focus:ring":forTable === "false",
            "border": forTable === "false",
            "border-b-2":forTable === "true",
            "pl-10": hasIcon === "true",
            "mb-3": margin === "true",
            "placeholder-placeholderText": placeholderColor === "true",
            "rounded": rounded === "true",
            placeholderFontNormal: placeholderFontNormal === "true",
            "border-red-600": inputError.length > 0,
            "focus:ring-blue-100": inputError.length === 0,
            "focus:ring-red-100": inputError.length > 0,
            [`bg-${bgColor.length > 0 ? bgColor : "white"}`]: true,
            "opacity-40 cursor-not-allowed pointer-events-none":
                disabled === true,
        }
    );

    return (
        <div className={`${styles || "py-1"} ${textCenter?"text-center":"text-left"} relative ${height}`}>
            {type === "search" && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button
                        type="submit"
                        className="p-1 focus:outline-none focus:shadow-outline"
                        style={{ zIndex: 5 }}
                    >
                        <Image
                            img={searchIcon}
                            alt="search"
                            imgClass="object-contain w-4"
                        />
                    </button>
                </span>
            )}
           
            {label.length > 0 && (
                <Text
                    tag="label"
                    scale={true}
                    styles={"mb-1 text-gray-500"}
                    text={label}
                    alignment="left"
                />
            )}
            <input
                readOnly={readOnly}
                type={type}
                name={name}
                placeholder={placeholder}
                className={type === 'checkbox'?"cursor-pointer bg-red-200":inputClass}
                autoComplete={autocomplete}
                onChange={handleChangeInput}
                required={required}
                value={value}
                checked={value}
                onFocus={handleOnfocus}
                disabled={disabled}
                maxLength={maxLength}
                onKeyDown ={ (e) => {
                    if ( e.key === " " && type === 'email' ) {
                      e.preventDefault(); //The value with a space now differs from what is displayed in the state. Issue for input type = email
                    }
                   
                  }}
                  style={style}
            />
             {icon&&(
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    
                    <Image
                        img={icon}
                        alt="search"
                        imgClass="object-contain w-4"
                    />
            </span>
            )}
            {clearable && <i className={`absolute inset-y-0 px-1 mx-1 flex items-center cursor-pointer ${icon ? "right-5" : "right-0"} `} onClick={() => {
                handleChange(name, "", true);
            }}>
                <FontAwesomeIcon icon={faTimes} size="xs" />
            </i>}
            {inputError.length > 0 && (
                <Text
                    tag="small"
                    scale={true}
                    styles={`${
                        errorStyle || "bottom-0"
                    } text-red-600 absolute left-0 pt-1`}
                    text={inputError}
                    alignment="left"
                />
            )}
        </div>
    );
};

InputText.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder: PropTypes.string,
    type: PropTypes.string,
    autocomplete: PropTypes.string,
    hasIcon: PropTypes.string,
    forTable: PropTypes.string,
    placeholderColor: PropTypes.string,
    placeholderFontNormal: PropTypes.string,
    margin: PropTypes.string,
    handleChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    required: PropTypes.bool,
    label: PropTypes.string,
    errorText: PropTypes.string,
    emptyErrorText: PropTypes.string,
    styles: PropTypes.string,
    errorStyle: PropTypes.string,
    validatorType: PropTypes.string,
    disabled: PropTypes.bool,
    bgColor: PropTypes.string,
    rounded: PropTypes.string,
    height: PropTypes.string,
    border: PropTypes.bool,
    icon: PropTypes.any,
    borderBottom: PropTypes.string,
    validationRequired: PropTypes.bool,
    maxLength: PropTypes.string,
    roundedStyles: PropTypes.string,
    textCenter: PropTypes.bool,
    showNumberOnly:PropTypes.bool,
    style: PropTypes.object,
    clearable: PropTypes.bool,
};

export default InputText;
