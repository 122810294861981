import { faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { UserContext } from "../contextAPI";
const Toast = ({
    text = "",
    icon = faInfoCircle,
    styles = "",
    bgColor = "white",
    rounded = "sm",
    textColor = "gray",
    truncate = false,
    alignment = "rt",
    fontweight = "normal",
    type = "info", // success /info / error
}) => {
    const { dHToast } = useContext(UserContext);
    const textClass = cx(styles, {
        "flex px-3 py-2": true,
        [`text-${textColor}`]: true,
        [`bg-${bgColor}`]: true,
        [`rounded-${rounded}`]: true,
        "overflow-ellipsis": truncate === true,
        [`text-${alignment}`]: true,
        "2xs:text-3xs xs:text-2xs sm:text-2xs md:text-2xs lg:text-xs xl:text-xs 2xl:text-sm small": true,
        [`font-${fontweight}`]: true,
    });
    const toastClass = cx({
        "fixed w-96 z-50 flex items-center bg-white border-l-4 py-3 px-3 shadow-md mb-2 rounded-md": true,
        "right-5 top-14": alignment === "rt",
        "left-5 top-14": alignment === "lt",
        "left-5 bottom-32": alignment === "lb",
        "right-5 bottom-32": alignment === "rb",
        "border-green-700": type === "success",
        "border-red-700": type === "error",
        "border-blue-700": type === "info",
    });
    const fontClass = cx("m-auto w-5 h-5", {
        "text-green-400": type === "success",
        "text-red-400": type === "error",
        "text-blue-400": type === "info",
    });
    const closeIcon = cx("text-gray-600")
    return (
        <div className={toastClass}>
            <div className="text-red-500 flex items-center rounded-full bg-white">
                <FontAwesomeIcon icon={icon} className={fontClass} />
            </div>
            <div className="max-w-xs">
                <span className={textClass}>{text}</span>
            </div>
            <div className="text-red-500 flex items-center rounded-full bg-white ml-auto cursor-pointer">
                <FontAwesomeIcon
                    icon={faTimesCircle}
                    className={closeIcon}
                    onClick={() => dHToast(false)}
                />
            </div>
        </div>
    );
};

Toast.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    styles: PropTypes.string,
    bgColor: PropTypes.string,
    rounded: PropTypes.string,
    textColor: PropTypes.string,
    truncate: PropTypes.bool,
    alignment: PropTypes.string,
    fontweight: PropTypes.string,
    type: PropTypes.string,
};

export default Toast;
