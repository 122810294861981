import React, { useState, useContext } from 'react';
import Text from '../commonComponent/Text';
import appConfig from '../assets/database/appConfig.json';
import PropTypes from "prop-types";
import getRequest from '../network/getRequest';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from '../contextAPI';
import { mfaVerificationCode } from '../utils/form/mfa';
import Form from "../NewComponents/UI/Form";
import { mfaVerificationCodeSchema } from "../utils/validationSchema";
import { useDispatch } from "react-redux";
import { setLoggedOutUser } from '../store/actions/loggedOutUser/loggedOutUser.action';
import {  setMfaUserDetails } from '../store/actions/loggedInUser/loggedInUser.action';
import AlertConfirmation from "../commonComponent/AlertConfirmation";


const ResetMfaModal = ({ userDetails = {}, closeModal = null }) => {
    const [formData, setFormData] = useState({});
    const [errorText, setErrorText] = useState(false);
    const { dHToast } = useContext(UserContext);
    const { clearUser, clearProfileUser } = useContext(UserContext);
    const { fetchRequest } = getRequest();
    const history = useHistory();
    const { img, changepass } = appConfig;
    let { title } = changepass;
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [vCodeData, setvCodeData] = useState("");
    

    const closeModalHandler = () => {
        closeModal();
    }

    const validateCode = async()=>{
        setShowAlert(false)
        try {
            const body = {
                validationToken: vCodeData?.vcode,
            };
            const response = await fetchRequest(body, 'put', 'updateMfa',[userDetails?.id]);
            //display messages
            if (!response.error) {

                try{
                    let bodyData = {"email":userDetails?.email}
                    const res = await fetchRequest(bodyData, "post", "setupMfa");
                    if(res?.result){
                        setTimeout(()=>{closeModal();
                        let fromData = {email:userDetails.email,/*password:userDetails.password*/}
                        
                        localStorage.clear();
                        dispatch(setLoggedOutUser())
                        dispatch(setMfaUserDetails({formData:fromData, mfaActive: false, qrImg: res?.result}))
                        clearUser();
                        
                        history.push({pathname:'/Mfa'})},3000)
                        dHToast(true, 'MFA has been reset successfully. You will be redirected to Setup MFA', "rt", "success", faCheckCircle);

                    }
                }catch (err) {
                    dHToast(true, err.message, "rt", "error", faExclamationCircle);
                }

            } else {
                dHToast(true, response.message, "rt", "error", faExclamationCircle);
            }
        }
        catch (err) {
            dHToast(true, err.message, "rt", "error", faExclamationCircle);
        }
    }

    const handleSubmit = (data) => {
        setvCodeData(data)
        setShowAlert(true)
    }
  
    return (
        <>
            <div className="fixed left-0 right-0 top-0 bottom-0 flex items-center w-full h-full z-30 bg-black bg-opacity-40">
                <div className="p-4 bg-white rounded-lg shadow-xl m-auto w-84 reset-mfa-height sm:w-1/3">
                    <>
                      <Form
                          key={"mfa_general_form"}
                          initialData={mfaVerificationCode}
                          header={
                              <>
                            <div className="mb-5 border-b-2 border-gray-200 flex justify-between">
                              <div className="">
                                  <p className="text-base text-left text-black-900 font-bold pb-4">
                                      MFA Settings
                                  </p>                                
                              </div>
                              <div className="float-right text-gray-500 cursor-pointer" onClick={closeModalHandler}>
                                <FontAwesomeIcon icon={faTimes} />
                              </div>
                            </div>
                            <Text
                                tag="p"
                                scale={true}
                                styles="mt-1 mb-3 text-lg"
                                text={"Enter the MFA code from the authenticator app and proceed to reset your MFA"}
                                alignment="center"
                            />    
                            </>                    
                        }    
                          validationSchema={mfaVerificationCodeSchema}
                          //bodyStyles={"flex items-center "}
                          handleSubmit={handleSubmit}
                          submitButton={{
                              isVisible: true,
                              text: "Reset MFA",
                              props: {
                                  btnColor: "text-white",
                                  rounded: "rounded",
                                  color: "black",
                                  type: "Submit",
                                  styles: "bg-red-600 rounded w-full p-3",
                              },
                          }}
                          cancelButton={{
                            isVisible: false,
                            text: "Cancel",
                            props: {
                                btnColor: "",
                                rounded: "rounded",
                                color: "black",
                                styles: "active:bg-blue-900 rounded flex items-center justify-around p-2",
                            },
                          }}
                          actionStyles="w-full"
                      />
                      </>
                </div>
                <AlertConfirmation
                    showConfirm={showAlert}
                    title="Reset MFA"
                    message="Are you sure you want to reset MFA?"
                    firstButtonText="Cancel"
                    secondButtonText="Yes"
                    firstButtonHandler={() => {
                        setShowAlert(false);
                        setvCodeData('');
                    }}
                    secondButtonHandler={validateCode}
                />
            </div>
        </>
    );
}

ResetMfaModal.propTypes = {
    userDetails: PropTypes.object,
    closeModal: PropTypes.func
};

export default ResetMfaModal;
