import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from "prop-types";
import Text from './Text'
import { Tooltip } from 'react-tippy';
import { useState } from 'react';
import { useResize } from '../hooks/useResize';

const TruncatedText = ({
  text,
  tag = "small",
  styles = "",
  fontWeight = "normal",
  alignment = "center",
  scale = true,
  toolTipStyle = "",
  tooltipHtmlStyle=''
}) => {
  const textRef = useRef()
  const [showTooltip, setShowTooltip] = useState(false)
  const [textHidden, setTextHidden] = useState(false)

  const currWidth = useResize(useMemo(() => ({ current: textRef.current?.tooltipDOM }), [textRef.current]))?.width

  useEffect(() => {
    if (textRef.current) {
      const offsetWidth = textRef.current.tooltipDOM?.firstChild?.offsetWidth
      const scrollWidth = textRef.current.tooltipDOM?.firstChild?.scrollWidth

      if (offsetWidth && scrollWidth && (scrollWidth > offsetWidth)) {
        setTextHidden(true)
      }
      else {
        setTextHidden(false)
      }
    }
  }, [textRef, currWidth, text])

  return (
    <>
      <Tooltip
        trigger='manual'
        className={`truncate w-full ${toolTipStyle} `}
        html={
          <div className={`px-2 py-1 ${tooltipHtmlStyle}`}>{text}</div>
        }
        open={textHidden && showTooltip}
        arrow
        ref={textRef}
      >
        <Text
          tag={tag}
          scale={scale}
          text={text}
          fontweight={fontWeight}
          alignment={alignment}
          styles={`${styles} w-full block`}
          truncate={true}
          onMouseOver={() => {
            setShowTooltip(true)
          }}
          onMouseLeave={() => {
            setShowTooltip(false)
          }}
        />
      </Tooltip>
    </>
  )
}

TruncatedText.propTypes = {
  text: PropTypes.string,
  tag: PropTypes.string,
  styles: PropTypes.string,
  fontWeight: PropTypes.string,
  alignment: PropTypes.string,
  scale: PropTypes.bool,
  toolTipStyle: PropTypes.string,
  tooltipHtmlStyle: PropTypes.string,
};

export default TruncatedText