import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Anchor from "./Anchor";
import { getVirtualElement } from "../utils/UtilsFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Image from "./Image";

function NewDropdown({
    hasIcon = "false",
    margin = "false",
    handleChange = null,
    borderFlag = true,
    handleBlur = () => { },
    inputProps,
    label = "",
    option = [],
    allOptions = [],
    dataLable = "name",
    bgColor = "",
    disabled = false,
    errorText = "",
    value = "",
    width = "",
    wrapperClass = "",
    transformOptions,
    icon = null,
    values = {},
    setFieldValue = null,
    conditionChange = null,
    roundedStyle = "rounded",
    withScroll = false,
    marginRight = true,
    accordianDropdown = false,
    isUpward = false,
    menuListHeight = "-240px",
    borderSize = "border-gray-400",
    borderBottom = true,
    autoComplete= 'off',
    conditionalChangeCheckForArr = [],
    materialLabel,
    beforeChange,
    clearable = false,
    dropDownHeight
}) {
    const [showAddMenu, setShowAddMenu] = useState(false);
    const [options, setOptions] = useState([]);
    var inputRef = useRef();
    var dropdownRef = useRef();
    const mainElement = useRef();
    const [topPos, setTopPos] = useState(null);
    const [labelActive, setLabelActive] = useState(false);

    const [selectedValue, setSelectedValue] = useState(value)

    const { handleChange: onChange, ...rest } = inputProps;

    var refElement = useMemo(() => {
        return getVirtualElement({
            name: inputProps.name,
            type: "dropdown",
            value: value,
            handleChange: function(e){
                handleChange(e);
            },
            handleBlur,
        });
    }, []);

   useOnClickOutside(dropdownRef, () => {setShowAddMenu(false); setSelected(null)});

   useEffect(()=>{
    if(allOptions.length > 0 && _.isFunction(transformOptions)) 
    {
        setOptions(transformOptions(allOptions, values));
    }
   },[selectedValue, values])


    useEffect(() => {
        
        if (_.isFunction(conditionChange)){ 

            conditionChange(values, setFieldValue);
        }
        
    }, [values]);

    const { productType } = values;
    useEffect(() => {
        if (productType &&
            conditionalChangeCheckForArr.length > 0 &&
            conditionalChangeCheckForArr.includes(productType) &&
            _.isFunction(conditionChange)
        ) {
            conditionChange(productType, setFieldValue, true);
        }
    }, [productType]);

    useEffect(() => {
        if (value && value.length > 0) {
            setLabelActive(true)
        }
        if (_.isFunction(inputProps.defaultValue)) {
            let defaultValue = inputProps.defaultValue(value);
            refElement.setValue(defaultValue);
        }
    }, []);

    useEffect(() => {
        var width = inputRef.current.getBoundingClientRect().width;
        if (showAddMenu) {
            dropdownRef.current.style.width = width + 18 + "px";
            if(isUpward){ dropdownRef.current.style.top = -Math.abs(dropdownRef.current.getBoundingClientRect().height+10)+'px' }
        }
        else {
            if (!selectedValue) {
                setLabelActive(false)
            }
        }
    }, [showAddMenu]);

    useEffect(() => {
        (async function () {
            if (_.isArray(option)) {
                return setOptions(option);
            }
            let data = await option();
            setOptions(transformOptions(data, values));
        })();
    }, [option]);

    let wrapperBlockClass = classNames({
        [`${wrapperClass}`]: true,
        [`bg-${bgColor.length > 0 ? bgColor : "white"}`]: true,
    });

    let inputClass = classNames({
        "placeholder-blueGray-300 bg-transparent relative leading-5 text-base outline-none ml-0 p-2 cursor-pointer border-none": true,
        [`${inputProps.styles}`]: true,
        "pl-10": hasIcon === "true",
        "mb-3": margin === "true",
        "border-red-600": errorText.length > 0,
        "opacity-40 cursor-not-allowed pointer-events-none": disabled === true,
        [`w-${width}`]: true,
    });

    const toggleRenameMenuClass = classNames(
        ` origin-top-right rounded-md shadow-lg bg-white ring-1 w-10 pb-2 ring-black ring-opacity-5 focus:outline-none z-50 overflow-y-scroll ${dropDownHeight ? `max-h-${dropDownHeight}` : 'max-h-56'}`,        
        {
            invisible: showAddMenu === false,
            fixed: withScroll === true,
            absolute: withScroll === false
        }
    );

    const menuItemClasses = classNames(
        `text-gray-700 block py-2 ${materialLabel ?  "py-4 px-1 hover:bg-gray-100" : "hover:bg-blue-100 hover:text-blue-800 rounded-md my-1"} text-xs text-left`
    );

    const menuItemClassesForDisabledOption = classNames(
        "text-gray-300 block py-2 pointer-events-none cursor-not-allowed my-1 rounded-md text-xs text-left"
    );

    function selectClick(item) {
        let value = item.value;
        if (_.isFunction(beforeChange)) {
            value = beforeChange(values, value)
        }
        setSelectedValue(value)
        refElement.setValue(value);
        setShowAddMenu(false);
        if (onChange) {
            onChange(item, setFieldValue)
        }
    }

    function renderMenu(item, level=0) {
        const nesteditems = (item.submenu || []).map((data, index) => {
            level = level +1
            return renderMenu(data, level)
          })
        if (item.menuTitle) {
            return (
                <Anchor key={item.value ?? item.menuTitle} classes={menuItemClasses} menuTitle={item.menuTitle} />
            );
        } else if (item.separator) {
            return <Anchor key={item.value ?? item.menuTitle} classes={menuItemClasses} separator={true} />;
        } else {           
            return (
                <div className={`${item.disable ? "cursor-not-allowed" : ""} ${materialLabel && item.type !== "label" ? "border-b border-gray-300" : ""}`} key={item.value ?? item.menuTitle}>
                    {item.type !== "label" ?
                        <Anchor
                            item={item}
                            showTitle={true}
                            classes={item.disable? menuItemClassesForDisabledOption: menuItemClasses}
                            clickEvent={() => {
                                selectClick(item);
                            }}
                            imageStyle={item.imageStyle}
                        /> :
                        <div className={`py-2 my-1 rounded-md text-xs text-left ${item.labelStyle ? item.labelStyle : "font-bold text-gray-700 block truncate"}`}>
                            {
                                _.has(item, "icon") && item.icon!== null && (
                                    <span className="inline-flex justify-center items-center mx-4">
                                        {_.has(item, "icon") && (
                                            <Image
                                                img={require(`../assets/img/icons/${item.icon}`).default}
                                                alt={item.title}
                                                imgClass= {item.imageStyle}
                                            />
                                        )}
                                    </span>)
                            }
                            {item.title}
                        </div>}
                    
                    <div style={{"marginLeft": 4 * level}}>
                        {nesteditems}
                    </div>
                </div>
            );
        }
    }
    const [leftPos, setScrollLeft] = useState(0);
    const [topPos1, setScrollTop1] = useState(0);
    function handleClick() {
        setLabelActive(true)
        if (inputProps.disabled || disabled) return;
        var main = mainElement.current.getBoundingClientRect();
        var ele = dropdownRef.current.getBoundingClientRect();
        let pos = 0;
        if (main.bottom + ele.height > window.innerHeight) {
            pos = -1 * ele.height - 25 + "px";
        }
        // if(isUpward) {
        //     setTopPos(pos);
        // } else {
        //     setTopPos(pos + 55);
        // }
        setTopPos(pos + 55 + (inputProps.topPosition ? inputProps.topPosition : 0));
        if(withScroll)
        {
            setScrollTop1(mainElement.current.getBoundingClientRect().top + 50 );
            setScrollLeft(mainElement.current.getBoundingClientRect().left )
        }
        setShowAddMenu((val) => !val);
    }

    useEffect(() => {
        if(withScroll)
        {
            
            const onScroll = e => {
                setScrollTop1(mainElement?.current?.getBoundingClientRect().top + 50) ;
                setScrollLeft(mainElement?.current?.getBoundingClientRect().left );                        
            };
        
            window.addEventListener("scroll", onScroll);
        
            return () => window.removeEventListener("scroll", onScroll);
        }
      }, []);
    
    useEffect(()=>{
          if(withScroll)
          {
            //setScrollLeft(dropdownScrollLeftPos)
            //setScrollTop1(dropdownScrollTopPos)
          }
      },[])

    function renderMenuList() {
        return (
            <div
                className={toggleRenameMenuClass}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
                onMouseLeave={() => {
                    inputRef.current.blur();
                    setShowAddMenu(false);
                    refElement.blurElement();
                }}
                ref={dropdownRef}
                id={'selectDropdown'}
                //style={{ top: topPos}}
                style={{top:withScroll? topPos1 : isUpward ? -Math.abs(dropdownRef?.current?.getBoundingClientRect().height + 10)+'px' : topPos, left:withScroll? leftPos:0}}
            >
                <div className={`px-2`} role="none">
                    {options.map((item, index) => {
                        if(accordianDropdown)
                        {
                            return renderAccordianMenu(item, index)
                        }else{
                            return renderMenu(item);
                        }
                    })}
                </div>
            </div>
        );
    }
    const [selected, setSelected] = useState(null);
    const myRefs = useRef([]);

    const renderAccordianMenu = (item, index)=>{
        return (
            <ul className="shadow-box" key={index}>
            <li key={index} className="relative my-4" onClick={() =>setSelected(selected !== index ? index : null)}>
                <div className="cursor-pointer  flex items-center bg-white px-2 justify-between hover:bg-blue-100 rounded-md">
                    <div key={index} className="basis-52 px-1 py-3 text-left leading-3">
                       <div className="flex items-center">
                            {item.title}
                     </div>
                    </div>
                    <div className="icon w-2 h-2 rounded-full cursor-pointer flex items-center justify-center hover:bg-gray-100">
                        <FontAwesomeIcon size="xs" icon={selected !== index ? faChevronRight : faChevronDown} />
                    </div>
                </div>
                <div
                    key={index}
                    className={`relative overflow-hidden transition-all max-h-0 duration-700`}
                    style={{
                        maxHeight: `${
                            selected == index
                                ? myRefs.current[index].scrollHeight + "px"
                                : ""
                        }`,
                    }}
                    ref={(el) => (myRefs.current[index] = el)}>
                    {
                        item.submenu.length >0? item.submenu.map((subMenuItem, index)=>
                            (
                            <div key={index} className="pl-3">                                                  
                                <Anchor
                                    item={{ title: subMenuItem.title }}
                                    showTitle={true}
                                    classes={menuItemClasses}
                                    clickEvent={() => selectClick(subMenuItem)}
                                />    
                            </div>                       
                            ))
                        :<div key={index} className="pl-3 mt-3 text-xs font-bold">No Templates Available</div>
                  }
                </div>
            </li>
            </ul>
            )
    }


    function deepFlattenArray(array) {
        var result = [];
        array.forEach(function (a) {
            result.push(a);
            if (Array.isArray(a.submenu)) {
                result = result.concat(deepFlattenArray(a.submenu));
            }
        });
        return result;
      }
    function getLabelFromValue(value) {
        var val ;
        var check = options.some(obj => Object.keys(obj).includes("submenu"));
        if(check === true)
        {
            let newArray = deepFlattenArray(options);
            val = newArray.find((op) => op.value === value);
        }else{
           val = options.find((op) => op.value === value);
           if(!val && value !== "" && allOptions.length >0)
           {
            val = allOptions.find((op) => op.value === value);
           }
        }
        return val?.title ? val.title : "";
    }

    return (
        <div
            className="relative"
            ref={mainElement}
            id={'selectDropdownDiv'}
            style={{ opacity: inputProps.disabled ? 0.5 : 1 }}
        >
            <div
                className={`bg-${bgColor ? bgColor : "white"} ${
                    borderFlag ? "border" : `${borderBottom ? "border-b-2" :""} ${borderSize}`
                } ${roundedStyle ? roundedStyle:"rounded"} flex justify-between items-center pr-2 ${marginRight ? 'mr-1' : ''} ${wrapperBlockClass} ${materialLabel && "material-field input-field prospect-field"}`}
            >
                <input
                    ref={inputRef}
                    readOnly={true}
                    className={inputClass}
                    onClick={handleClick}
                    value={getLabelFromValue(value)}
                    {..._.omit(rest, "bgColor")}
                    autoComplete={autoComplete}
                />

                {icon ? (
                    <img className="text-black-100" src={icon} alt="" />
                ) : (
                    materialLabel ? (
                        <>
                            <i className="select-icon">
                                <FontAwesomeIcon icon={faChevronDown} />
                            </i>
                            <label
                                htmlFor={inputProps.id}
                                className={`renderAutocompletelabel ${labelActive ? "active" : ""
                                    }`}
                            >
                                {materialLabel}
                            </label>
                        </>
                    ) :
                    <>
                        {clearable && <i className="select-icon-newDropDown px-1 mx-1 cursor-pointer" onClick={() => {
                            setSelectedValue("")
                            refElement.setValue("");
                        }}>
                            <FontAwesomeIcon icon={faTimes} size="xs" />
                        </i>}
                        <i className="select-icon-newDropDown" onClick={handleClick}>
                            <FontAwesomeIcon icon={faChevronDown} size="xs" />
                        </i>
                    </>
                )}
            </div>
            {renderMenuList()}
        </div>
    );
}

NewDropdown.propTypes = {
    hasIcon: PropTypes.string,
    margin: PropTypes.string,
    handleChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    handleBlur: PropTypes.func,
    label: PropTypes.string,
    option: PropTypes.array,
    allOptions: PropTypes.array,
    dataLable: PropTypes.string,
    bgColor: PropTypes.string,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    errorText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.string,
    wrapperClass: PropTypes.string,
    transformOptions: PropTypes.func,
    borderFlag: PropTypes.bool,
    icon: PropTypes.string,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    conditionChange: PropTypes.func,
    roundedStyle: PropTypes.string,
    withScroll: PropTypes.bool,
    marginRight: PropTypes.bool,
    accordianDropdown: PropTypes.bool,
    isUpward: PropTypes.bool,
    autoComplete: PropTypes.string,
    borderSize: PropTypes.string,
    menuListHeight: PropTypes.string,
    borderBottom: PropTypes.bool,
    conditionalChangeCheckForArr: PropTypes.array,
    materialLabel: PropTypes.string,
    beforeChange: PropTypes.func,
    clearable: PropTypes.bool,
    dropDownHeight: PropTypes.string,
};

export default React.memo(NewDropdown);
