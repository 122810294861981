import {
    ADD_NEW_FEE_SCHEDULE_STRUCTURE,
    DELETE_NEW_FEE_SCHEDULE_STRUCTURE,
    CREATE_FEE_SCHEDULE_STRUCTURE,
    GET_FEE_SCHEDULE_STRUCTURE_LIST,
    UPDATE_FEE_SCHEDULE_STRUCTURE_LIST,
    DELETE_NEW_FEE_SCHEDULE_STRUCTURE_LIST,
    UPDATE_NEW_FEE_SCHEDULE_STRUCTURE_LIST,
    REMOVE_STATE
} from "../../actions/billing/feeschedules/feeschedulestructure.constant";

const INIT_STATE = {
    feeScheduleList: {
        structureList: null
    }
};

function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case CREATE_FEE_SCHEDULE_STRUCTURE:
            return {
                ...state,
                feeScheduleList: {
                    structureList: [
                        action.data
                    ]
                }
            };
        case ADD_NEW_FEE_SCHEDULE_STRUCTURE:
            return {
                ...state,
                feeScheduleList: {
                    structureList: (state.feeScheduleList.structureList.length > 0 ?
                        [
                            action.data,
                            ...state.feeScheduleList.structureList.filter(item => item.id),
                        ] :
                        [
                            ...state.feeScheduleList.structureList.filter(item => item.id),
                            action.data
                        ]
                    ).sort((a, b) => a.minimum - b.minimum)
                }
            };
        case DELETE_NEW_FEE_SCHEDULE_STRUCTURE:
            return {
                ...state,
                feeScheduleList: {
                    structureList: [
                        ...state.feeScheduleList.structureList.filter(i => (i.id !== action.data.id) || (i.isDeleted = action.data.isDeleted))
                    ].filter(i => !i.isDeleted)
                }
            };
        case GET_FEE_SCHEDULE_STRUCTURE_LIST:
            return {
                ...state,
                feeScheduleList: {
                    structureList: action.data
                }
            }
        case UPDATE_FEE_SCHEDULE_STRUCTURE_LIST:
            return {
                ...state,
                feeScheduleList: {
                    structureList: [
                        ...state.feeScheduleList.structureList.filter(item => item.id),
                        action.data
                    ]
                }
            }
        case DELETE_NEW_FEE_SCHEDULE_STRUCTURE_LIST: {
            return {
                ...state,
                feeScheduleList: {
                    structureList: [
                        ...state.feeScheduleList.structureList.filter(i => (i.id !== action.data.id) || (i.isDeleted = action.data.isDeleted))
                    ].filter(i => !i.isDeleted)
                }
            }
        }
        case UPDATE_NEW_FEE_SCHEDULE_STRUCTURE_LIST: {
            return {
                ...state,
                feeScheduleList: {
                    structureList: [
                        ...state.feeScheduleList.structureList.map(item => (item.id === action.data.id) ? action.data : item).sort((a, b) => a.minimum - b.minimum)
                    ]
                }
            }
        }
        case REMOVE_STATE: {
            return {
                ...state,
                feeScheduleList: {
                    structureList: []
                }
            }
        }
        default:
            return state;
    }
}


export default reducer;
