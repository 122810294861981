import React from "react";
import PropTypes from "prop-types";
import { useSelected, useFocused } from "slate-react";

const Mention = ({ attributes, children, element }) => {
    const selected = useSelected();
    const focused = useFocused();
    return (
        <span
            {...attributes}
            contentEditable={false}
            data-cy={`mention-${element.character.replace(" ", "-")}`}
            style={{
                padding: "3px 3px 2px",
                margin: "0 1px",
                verticalAlign: "baseline",
                display: "inline-block",
                borderRadius: "4px",
                backgroundColor: "#eee",
                fontSize: "0.9em",
                boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
            }}
        >
            @{element.character}
            {children}
        </span>
    );
};

Mention.propTypes = {
    attributes: PropTypes.string,
    children: PropTypes.element,
    element: PropTypes.element,
};

export default Mention;
