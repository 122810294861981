import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useContext } from "react";
import { UserContext } from "../../../contextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faUser } from "@fortawesome/free-solid-svg-icons";
import Item from "../../../commonComponent/Editor/Item";
import { capitalizeFirstCharOfEveryWord, fetchUsers, uuidv4 } from "../../../utils/UtilsFunc";
import "./Notes.css";
import { getRelativeCalenderDate } from "../../../utils/UtilsFunc";
import useEditor from "../../../hooks/useEditor";
import Editor from "../../../commonComponent/Editor";
import { DateTime } from "luxon";
import _ from "lodash";
import { apiResponseType, notesTypes } from "../../../utils/enum";
import getRequest from "../../../network/getRequest";

function Notes({ values, handleChange, selectedProspectId }) {
    var { userDetails } = useContext(UserContext);
    const [usersData, setUsersData] = useState([])
    const [apiResponse, setApiResponse] = useState(apiResponseType.IDLE)

    const [notes, setNotes] = useState(values.map(el => ({
        ...el,
        ...(el.clients ? { clients: el.clients.map(client => typeof client === "object" ? client.id : client) } : {}),
        ...(el.users ? { users: el.users.map(user => typeof user === "object" ? user.id : user) } : {}),
    })));
    const [editId, setEditId] = useState();

    let optionUsers = useMemo(() => {
        return usersData.map(
            (user) =>
                capitalizeFirstCharOfEveryWord(user.fullName) +
                " | " +
                user.email
        );
    }, [usersData]);

    var data = useEditor({
        atOptions: optionUsers,
        currentUserId: userDetails.id,
        onSubmit: handleSubmit,
        trackTaggedUser: true,
        userOptions: usersData,
        inline: {
            bold: true,
            italic: true,
            underline: true,
            at: true,
            link: true,
        },
        tab: "",
        type: notesTypes.prospects,
    });
    const { fetchRequest } = getRequest();

    const searchNewUsers = useMemo(() => _.debounce((value, successFn, errorFn) => {
        fetchUsers(value, successFn, errorFn, fetchRequest)
    }, 500), []);

    useEffect(() => {
        const taggedUsers = data.userIdList.filter(el => el.userId).map(el => el.userId)
        if (data.search.length > 0) {
            setApiResponse(apiResponseType.FETCHING)
            searchNewUsers(
                {
                    fullName: data.search,
                    ...(taggedUsers.length > 0 ? { exceptUsersId: JSON.stringify(taggedUsers) } : {})
                },
                res => {
                    setApiResponse(apiResponseType.SUCCESS)
                    setUsersData(res.result)
                    data.setSearch("")
                },
                () => {
                    setApiResponse(apiResponseType.ERROR)
                    setUsersData([])
                    data.setSearch("")
                })
        }
    }, [data.search])

    function handleSubmit(notesData) {
        let newNotes = [];
        const taggedUsers = data.userIdList.filter(el => el.userId).map(el => el.userId)
        const taggedClients = data.userIdList.filter(el => el.clientId).map(el => el.clientId)
        if (selectedProspectId) {
            taggedClients.push(selectedProspectId)
        }

        if (data.editMode) {
            const selectedNoteId = notes.find((note) => note.id === editId)?.id;
            const selectedNoteIndex = notes.findIndex((note) => note.id === editId);
            const notesMap = notes.map(item =>
                (item.id === selectedNoteId) ? {
                    ...notesData,
                    id: item.id,
                    createdAt: item.createdAt,
                    createdBy: item.createdBy,
                    ownerId: item.ownerId,
                    users: taggedUsers,
                    ...(taggedClients.length > 0 ? { clients: taggedClients } : {})
                } :
                    item
            );
            setNotes(() => {
                const updatedNote = notesMap[selectedNoteIndex];
                // values.splice(selectedNoteIndex, 1, updatedNote)
                return notesMap;
            })
            return;
        }
        setNotes((notes) => {
            const updatedNotes = _.cloneDeep(notes)
            let newNote = {
                ...notesData,
                createdAt: DateTime.now(),
                createdBy: {
                    id: userDetails?.id,
                    fullName: userDetails?.fullName,
                    profileImageUrl: userDetails?.profileImageUrl
                },
                id: uuidv4(),
                users: taggedUsers,
            };
            newNotes = [newNote, ...updatedNotes];
            // values.unshift(_.omit(newNote, "createdAt"));
            return newNotes;
        });
    }

    useEffect(() => {
        // console.log("NOTESSSS:", notes)
        // const firstIdx = notes.findIndex(el => (typeof el.id === "string"))
        // const secondIdx = notes.findIndex((el, idx) => (idx !== firstIdx && typeof el.id === "string" && _.isEqual(_.omit(notes[firstIdx], ["createdAt", "id"]), _.omit(el, ["createdAt", "id"]))))
        // console.log("firstIdx:", firstIdx)
        // console.log("secondIdx:", secondIdx)
        // if (firstIdx >= 0 && secondIdx >= 0) {
        //     setNotes(prevState => {
        //         const updatedState = _.cloneDeep(prevState)
        //         updatedState.splice(secondIdx, 1)
        //         return updatedState
        //     })
        // }

        // const prevNotes = notes.filter(el => !isNaN(el.id))
        // const newNotes = notes.filter(el => isNaN(el.id)).reverse()
        // const notesToUpload = [...newNotes, ...prevNotes]
        handleChange("notes", notes.map(({ createdAt, ...el }) => el), false)
    }, [notes])

    // useEffect(() => {
    //     return () => {
    //         values.length = 0;
    //     };
    // }, []);


    const handleEdit = ({ id, ...state }) => {
        setEditId(id);
        data.enableEdit(state);
    }
    function renderNote({ note, id, createdAt, attachments }) {
        if (!note) return null;
        return (
            <div className="min-h-8 flex items-start mt-2 bg-myGrayLight rounded p-2 justify-start"
                onClick={() => {
                    handleEdit({
                        note,
                        attachments,
                        id
                    });
                }}>
                <div className="rounded text-gray-400 flex justify-center items-center border h-12 w-12">
                    {userDetails.profileImageUrl ? (
                        <img
                            src={userDetails.profileImageUrl}
                            className="w-12 h-12 object-cover rounded-md"
                        />
                    ) : (
                        <FontAwesomeIcon icon={faUser} size="3x" />
                    )}
                </div>
                <div className="flex flex-col ml-3 mt-0">
                    <div className="flex items-center">
                        <p className="font-bold mr-2 capitalize text-sm">
                            {userDetails.fullName}
                        </p>
                        <p className="text-sm">
                            {getRelativeCalenderDate(createdAt)}
                        </p>
                    </div>
                    <p
                        className="text-sm break-all"
                        dangerouslySetInnerHTML={{ __html: note }}
                    ></p>
                    <div className="flex items-center">
                        <React.Fragment>
                            {attachments.map((attachment) => {
                                return (
                                    <React.Fragment
                                        key={attachment?.attachmentName}
                                    >
                                        <FontAwesomeIcon
                                            icon={faLink}
                                            className="mr-2"
                                            size="sm"
                                        />
                                        <a
                                            href={attachment?.attachmentUrl}
                                            className="text-sm cursor-pointer"
                                        >
                                            {attachment?.attachmentName}
                                        </a>
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Editor {...data} handleEdit={handleEdit} fetchState={apiResponse} />
            {notes.map((note) => (
                <Item
                    key={note.id}
                    isPinnedAllowed={false}
                    handleEdit={handleEdit}
                    parentType={notesTypes.prospects}
                    {...note}
                    viewableAttachment
                />
            ))}
        </React.Fragment>
    );
}

Notes.propTypes = {
    values: PropTypes.array,
    handleChange: PropTypes.func,
    selectedProspectId: PropTypes.string,
};

export default Notes;
