import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { generateClientDetailURL } from "../../utils/UtilsFunc";
import pin from "../../assets/img/icons/pin.svg";
import cx from "classnames";
import tick from "../../assets/img/icons/tick.svg";
import snooze from "../../assets/img/icons/snooze.svg";
import DefaultUser from "../../commonComponent/DefaultUser";
import "react-tippy/dist/tippy.css";
import FilePreview from "../../components/WAProfile/Compliance/components/FilePreview";
import deleteIcon from "../../assets/img/icons/delete.svg";
import albumIcon from "../../assets/img/icons/album_blue.svg";

import "./Editor.css";
import Popup from "../Popup";
import AlertConfirmation from "../AlertConfirmation";
import { UserContext } from "../../contextAPI";
import AccessCheck from "../AccessCheck";
import { notesTypes } from "../../utils/enum";
import _ from "lodash";
import ClientsDefaultImages from "../ClientsDefaultImages";
import suitabilityBlue from '../../assets/img/icons/suitabilityBlue.svg'
import { useHistory } from "react-router-dom";
import { getRelativeDate } from "../../utils/luxon";
import navigationIcon from "../../assets/img/icons/navigation.svg"

function Item({
    note,
    attachments = [],
    createdAt,
    createdBy,
    togglePin = null,
    id,
    isPinned = false,
    isSystemGenerated = false,
    subType = "REVIEW_DONE",
    type = "NONE",
    handleEdit = null,
    isPinnedAllowed = true,
    iconsqare = true,
    leftStyles = "",
    clients,
    showClient,
    viewableAttachment,
    showDeleteIcon = false,
    deleteNote,
    permissionCheckFor = "NOTES",
    openWorkflowRequest = null,
    showWorkflowUI = false,
    parentType = notesTypes.dashboard,
}) {
    const { userDetails } = useContext(UserContext);
    const [showPin, setShowPin] = useState(false);
    const [showDelete, setShowDelete] = useState(false)
    const [isFilePreview, setIsFilePreview] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [isDeleteing, setIsDeleting] = useState(false)
    const wrapperClass = cx(
        "min-h-8 h-auto flex items-start mt-2 bg-myGrayLight rounded-lg p-1 overflow-hidden justify-start mb-2",
        {
            "bg-blue-50": isPinned === true,
        }
    );
    let icon = null;
    if (type === notesTypes.workflow) {
        icon = albumIcon
    } else if (type === notesTypes.suitability) {
        icon = suitabilityBlue
    } else if (subType === "SNOOZE") {
        icon = snooze
    } else if (subType === "REVIEW_DONE") {
        icon = tick
    }

    const designIcon = cx({
        "w-12 h-12 rounded-md object-cover": iconsqare,
        "w-12 h-12 rounded-full object-cover": !iconsqare,
    });

    const history = useHistory();
    const navigateToOpenRequest = () => {
        history.push(`/open-requests?filterBy=IN_PROGRESS%2CCOMPLETED%2CREJECTED&clientId=${clients[0]?.id}&requestId=${openWorkflowRequest?.id}&redirect=true`);
    }

    const pathName = history.location.pathname

    return (
        <>
            <div
                className="relative cursor-pointer"
                onMouseOver={() => { setShowPin(true); setShowDelete(true) }}
                onMouseLeave={() => { setShowPin(false); setShowDelete(false) }}
                onClick={(e) => {
                    if (e.target.classList.contains("attachment-to-view")) {
                        return
                    }
                    if (((parentType === notesTypes.dashboard || parentType === notesTypes.highlights) && type === notesTypes.workflow)) {
                        return
                    }
                    if (!isSystemGenerated) {
                        handleEdit({
                            note,
                            attachments,
                            id,
                            ownerId: createdBy?.id,
                        });
                    }
                }}
            >
                <div className="flex absolute top-2 right-2 cursor-pointer z-10">
                    {(showPin || isPinned) &&
                        !isSystemGenerated &&
                        isPinnedAllowed && (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    togglePin(id);
                                }}
                            >
                                <img src={pin} />
                            </div>
                        )}
                    {(showDeleteIcon && showDelete && !isSystemGenerated && type != null && type !== notesTypes.workflow && (createdBy?.id === userDetails.id || userDetails.role.id === 1)) &&
                        <AccessCheck feature={permissionCheckFor} permissionType="delete" elementType="Img">
                            <div style={{}} onClick={(e) => {
                                e.stopPropagation()
                                setShowConfirmationDialog(true)
                            }}>
                                <img src={deleteIcon} className={"w-5 h-5 rounded-xl mx-1"} />
                            </div>
                        </AccessCheck>
                    }
                </div>
                {isSystemGenerated ? (
                    <div
                        className={`bg-gray-100 relative rounded mb-4 text-sm ${showWorkflowUI ? "pt-1 pl-1" : ""} `}
                        onClick={e => {
                            e.stopPropagation();
                            if (type === "SUITABILITY") {
                                history.push(generateClientDetailURL({ clientId: clients[0].id, tab: "suitability" }))
                            }
                        }}
                    >
                        <div className="w-4 h-4 absolute flex-shrink-0">
                            {showWorkflowUI && <img src={icon} />}
                        </div>
                        <div className={`px-4 pt-1 pb-3 flex ${!showWorkflowUI ? "ml-1" : ""}`}>
                            <div className={`rounded text-gray-400 flex py-3 pl-2 justify-center flex-shrink-0 ${leftStyles}`}>
                                {createdBy?.profileImageUrl ? (
                                    <img
                                        src={createdBy?.profileImageUrl}
                                        className={designIcon}
                                    />
                                ) : (
                                    <DefaultUser wrapperClass="h-12 w-12" />
                                )}
                            </div>
                            <div className="ml-3 flex flex-col justify-center flex-1 py-2">
                                <div className="flex items-center">
                                    <p className="font-bold mr-2 capitalize text-sm">
                                        {createdBy?.fullName}
                                    </p>
                                    <p className="text-sm">
                                        {getRelativeDate(createdAt)}
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex flex-1 flex-col" style={{ wordBreak: "break-word" }}>
                                        {(showWorkflowUI && type === notesTypes.workflow && openWorkflowRequest) &&
                                            <>
                                                <p className="font-bold">{_.upperFirst(openWorkflowRequest.workflowTemplate.type.name)}</p>
                                                <p>{_.upperFirst(openWorkflowRequest.name)}</p>
                                            </>
                                        }
                                        <div>{note}</div>
                                    </div>
                                    {pathName === '/dashboard' && type === notesTypes.workflow && (
                                        <img src={navigationIcon} onClick={navigateToOpenRequest} className="pr-4" />
                                    )}
                                    {showWorkflowUI && <ClientsDefaultImages clients={clients} />}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={wrapperClass}>
                        <div className="w-4 h-4 flex-shrink-0">
                            {showWorkflowUI && type === notesTypes.workflow && <img src={albumIcon} />}
                        </div>
                        <div
                            className={`rounded text-gray-400 flex py-3 pl-2 justify-center items-center flex-shrink-0 ${leftStyles}`}
                        >
                            {createdBy?.profileImageUrl ? (
                                <img
                                    src={createdBy?.profileImageUrl}
                                    className={designIcon}
                                />
                            ) : (
                                <DefaultUser wrapperClass="h-12 w-12" />
                            )}
                        </div>
                        <div className="flex flex-col ml-3 mt-0 w-full py-3 text-sm">
                            <div className="flex items-center">
                                <p className="font-bold mr-2 capitalize text-sm">
                                    {createdBy?.fullName}
                                </p>
                                <p className="text-sm">
                                    {getRelativeDate(createdAt)}
                                </p>
                            </div>
                            <div className="flex items-center pr-3">
                                <div className="flex flex-col flex-1" style={{ wordBreak: "break-word" }}>
                                    {(showWorkflowUI && type === notesTypes.workflow && openWorkflowRequest) &&
                                        <>
                                            <p className="text-sm break-words font-bold">{_.upperFirst(openWorkflowRequest.workflowTemplate.type.name)}</p>
                                            <p>{_.upperFirst(openWorkflowRequest.name)}</p>
                                        </>
                                    }
                                    <p
                                        className="text-sm break-words mr-3 flex-1"
                                        dangerouslySetInnerHTML={{ __html: note }}
                                    ></p>
                                </div>
                                {pathName === '/dashboard' && type === notesTypes.workflow && (
                                    <img src={navigationIcon} onClick={navigateToOpenRequest} className="pr-4" />
                                )}
                                {showWorkflowUI && <ClientsDefaultImages clients={clients} />}
                            </div>
                            {attachments.length > 0 && (
                                <div className="flex flex-col mt-4">
                                    {attachments.map((attach) => {
                                        return (
                                            <div
                                                className="flex mr-2 mb-1"
                                                key={attach.id}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faLink}
                                                    className="mr-2 mt-1"
                                                    size="sm"
                                                />
                                                {
                                                    viewableAttachment ?
                                                        <p
                                                            className="attachment-to-view"
                                                            onClick={() => {
                                                                let idx = attach.attachmentName.lastIndexOf(".")
                                                                let extension = attach.attachmentName.substring(idx + 1).trim()
                                                                if (!extension) {
                                                                    idx = attach.attachmentUrl.lastIndexOf(".")
                                                                    extension = attach.attachmentUrl.substring(idx + 1).trim()
                                                                }
                                                                setSelectedFile({
                                                                    signedUrl: attach.attachmentUrl,
                                                                    documentType: extension,
                                                                    documentName: attach.attachmentName
                                                                })
                                                                setIsFilePreview(true)
                                                            }}>
                                                            {attach.attachmentName}
                                                        </p> :
                                                        <a
                                                            href={attach.attachmentUrl}
                                                            className="break-all text-sm cursor-pointer"
                                                        >
                                                            {attach.attachmentName}
                                                        </a>
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {
                isFilePreview && (
                    <Popup
                        {...{
                            closeAction: setIsFilePreview,
                            size: "extraLarge",
                            closeIcon: true,
                        }}
                    >
                        <FilePreview
                            file={selectedFile}
                            close={setIsFilePreview}
                            hasFileUrl
                        />
                    </Popup>
                )
            }
            <AlertConfirmation
                showConfirm={showConfirmationDialog}
                title="Please Confirm?"
                message="Are you sure you want to delete the note?"
                firstButtonText="Cancel"
                secondButtonText="Yes"
                firstButtonHandler={() => {
                    setShowConfirmationDialog(false);
                }}
                secondButtonHandler={() => {
                    setIsDeleting(true);
                    deleteNote(id).then(() => {
                        setIsDeleting(false)
                        setShowConfirmationDialog(false)
                    })
                }}
                disableSecondButton={isDeleteing}
            />
        </>
    );
}

Item.propTypes = {
    note: PropTypes.string,
    attachments: PropTypes.array,
    createdAt: PropTypes.string,
    createdBy: PropTypes.object,
    togglePin: PropTypes.func,
    id: PropTypes.number,
    isPinned: PropTypes.bool,
    isSystemGenerated: PropTypes.bool,
    subType: PropTypes.string,
    type: PropTypes.string,
    handleEdit: PropTypes.func,
    isPinnedAllowed: PropTypes.bool,
    iconsqare: PropTypes.bool,
    leftStyles: PropTypes.string,
    clients: PropTypes.array,
    showClient: PropTypes.bool,
    viewableAttachment: PropTypes.bool,
    showDeleteIcon: PropTypes.bool,
    deleteNote: PropTypes.func,
    permissionCheckFor: PropTypes.string,
    openWorkflowRequest: PropTypes.object,
    showWorkflowUI: PropTypes.bool,
    parentType: PropTypes.string,
};

export default Item;
