import React from "react";
import Modal from "../NewComponents/UI/Modal";
import PropTypes from "prop-types";

/****
 * Usage Example:
 * 
 *  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
 * 
 *  const showConfirmDialogFunc = () =>{
         setShowConfirmDialog(true)
    }

    <button className="cursor-pointer" onClick={showConfirmDialogFunc}>click for popup</button>
            
    <AlertConfirmation
        showConfirm = {showConfirmDialog}
        title={"This is the title"}
        message={"This is is the message to be displayed"}
    />

*********/

const AlertConfirmation = ({
    showConfirm = false,
    title,
    message,
    firstButtonText = "No",
    secondButtonText = "Yes",
    firstButtonHandler = null,
    secondButtonHandler = null,
    disableSecondButton = false,
    thirdButtonText = null,
    thirdButtonHandler = null,
    disableThirdButton = false
}) => {
    const handleFirstButton = () => {
        firstButtonHandler();
    };

    const handleSecondButton = () => {
        secondButtonHandler();
    };

    return showConfirm ? (
        <Modal>
            <div className="flex flex-col max-w-2xl m-2 p-4 w-auto bg-white rounded shadow-xl m-auto h-auto">
                <p className="border-b-2 font-bold">{title}</p>

                <p className="py-5">{message}</p>

                <div className="flex justify-end items-center">
                    <button
                        onClick={thirdButtonHandler}
                        className={`text-white px-5 rounded mr-4 ${disableThirdButton ? "bg-blue-400 cursor-not-allowed" : "bg-blue-700"}`}
                    >
                        {thirdButtonText}
                    </button>
                    <button
                        onClick={handleSecondButton}
                        disabled={disableSecondButton}
                        className={`text-white px-5 rounded mr-4 ${disableSecondButton ? "bg-blue-400 cursor-not-allowed" : "bg-blue-700"}`}
                    >
                        {secondButtonText}
                    </button>
                    <button
                        onClick={handleFirstButton}
                        className="bg-blue-700 text-white px-5 rounded mr-4"
                    >
                        {firstButtonText}
                    </button>
                </div>
            </div>
        </Modal>
    ) : null;
};

AlertConfirmation.propTypes = {
    showConfirm: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    firstButtonText: PropTypes.string,
    secondButtonText: PropTypes.string,
    firstButtonHandler: PropTypes.func,
    secondButtonHandler: PropTypes.func,
    disableSecondButton: PropTypes.bool,
    thirdButtonText: PropTypes.string,
    thirdButtonHandler: PropTypes.func,
    disableThirdButton: PropTypes.bool,
};

export default AlertConfirmation;
