import { FORCE_UPDATE } from "../actions/sidebar/sidebar.constant";

function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case FORCE_UPDATE:
      return {
        count: state.count + 1
      };
    default:
      return state;
  }
}

var INIT_STATE = {
  count: 0
};

export default reducer;
