export const CHANGE_HOUSEHOLD_SELECTED_TAB = "CHANGE_HOUSEHOLD_SELECTED_TAB";
export const FETCH_INITIAL_REQUEST_START = "FETCH_HOUSEHOLD_GENERAL_INFO_START";
export const FETCH_HOUSEHOLD_GENERAL_INFO_SUCCESS =
    "FETCH_HOUSEHOLD_GENERAL_INFO_SUCCESS";
export const FETCH_FINANCIAL_PLANNING_INFO_SUCCESS =
    "FETCH_FINANCIAL_PLANNING_INFO_SUCCESS";
export const FETCH_BILLING_INFO_SUCCESS = "FETCH_BILLING_INFO_SUCCESS";
export const CREATE_FINANCIAL_PLANNING_INFO = "CREATE_FINANCIAL_PLANNING_INFO";
export const UPDATE_FINANCIAL_PLANNING_INFO = "UPDATE_FINANCIAL_PLANNING_INFO";
export const CREATE_BILLING_INFORMATION_SUCCESS =
    "CREATE_BILLING_INFORMATION_SUCCESS";
export const UPDATE_BILLING_INFORMATION_SUCCESS =
    "UPDATE_BILLING_INFORMATION_SUCCESS";
export const FETCH_CLIENT_HOUSEHOLD_BILLING_SUCCESS =
    "FETCH_CLIENT_HOUSEHOLD_BILLING_SUCCESS";
export const CHANGE_HOUSEHOLD_STATUS = "CHANGE_HOUSEHOLD_STATUS";
export const CREATE_PAYOUT_BLOCK_INFO = "CREATE_PAYOUT_BLOCK_INFO";
export const ADD_NEW_PAYOUT_BLOCK_INFO = "ADD_NEW_PAYOUT_BLOCK_INFO";
export const DELETE_NEW_PAYOUT_BLOCK_INFO = "DELETE_NEW_PAYOUT_BLOCK_INFO";
export const CHANGE_REFERENCE = "CHANGE_REFERENCE";
export const GET_EXCLUDED_ASSETS = 'GET_EXCLUDED_ASSETS';
export const RESET_PAY_BLOCK_LIST = 'RESET_PAY_BLOCK_LIST';
export const RESET_EXCLUDED_ASSETS = 'RESET_EXCLUDED_ASSETS'
export const ADD_EXCLUDED_ASSETS = 'ADD_EXCLUDED_ASSETS';
export const LIVE_BILL_LIST = 'LIVE_BILL_LIST';
export const CLEAR_HOUSEHOLD_GENERAL_INFO ='CLEAR_HOUSEHOLD_GENERAL_INFO'
export const CLEAR_HOUSEHOLD_BILLING_INFO ='CLEAR_HOUSEHOLD_BILLING_INFO'
export const CLEAR_HOUSEHOLD_BILLING_INFORMATION ='CLEAR_HOUSEHOLD_BILLING_INFORMATION'