import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Text from "../../commonComponent/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import "./MaterialField.css";
import { getInputElement } from "../../utils/UtilsFunc";
import _, { isFunction } from "lodash";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Possible values for type 'input' || 'select' || 'autocomplete'
// render function will take label and value as a input and return an array [newLabel, newValue]
function MaterialInput({
    eletype = "input",
    label = "",
    errorText = "",
    inputProps = {},
    hasIcon = "",
    errorStyle = "",
    disabled = false,
    bgColor = "",
    readOnly = false,
    options = [],
    allOptions = [],
    fieldClass = "w-full",
    icon = {},
    mainLabel = null,
    selectMargin = true,
    containerClass = "",
    prefixValue = "",
    activeOnMount = false,
    padding = "",
    onListBlur = (e) => {},
    render = (...args) => args,
    renderOnListEmpty = null,
    errorWrapperStyles,
    customClasses = "",
    autoComplete= 'off',
    filterOptions,
    values,
    setFieldValue,
    fetchBeforeFocus = false,
}) {
    var [active, setActive] = useState(false);
    var [selected, setSelected] = useState(0);
    const [subSelected, setSubSelected] = useState(-1)
    var [labelActive, setLabelActive] = useState(false);
    var [autocompleteOptions, setAutocompleteOptions] = useState(options);
    const [updateState, setUpdateState] = useState(0)
    const [selectedImageLabel, setSelectedImageLabel] = useState(null)

    var { value, name, onChange, onBlur, handleOnChange, ...restInputProps } = inputProps;

    var refElement = useMemo(() => {
        return getInputElement({
            value,
            name,
            onChange,
            onBlur,
        });
    }, []);

    var getOptions = useMemo(() => {
        if (_.isFunction(filterOptions)) {
            return filterOptions(options, values);
        }
        return null;
    }, [values]);

    useEffect(() => {
        if(options.length) {
            setAutocompleteOptions(options);
        }
    }, [options]);

    useEffect(() => {
        if (name === "info.businessDeveloper" && values.info.wealthAdvisor) {
            setAutocompleteOptions(prev => prev.filter(p => p.value !== values.info.wealthAdvisor))
        }
        if (name === "info.wealthAdvisor" && values.info.businessDeveloper) {
            setAutocompleteOptions(prev => prev.filter(p => p.value !== values.info.businessDeveloper))
        }
    }, [values])

    useEffect(() => {
        if (activeOnMount) {
            ele.current.focus();
            setActive(true);
            setLabelActive(true);
        }
    }, []);

    var ele = useRef();
    const prevValue = useRef()
    const containerRef = useRef()

    function handleBlur() {
        refElement.blurElement();
    }

    function handleAutocompleteBlur() {
        refElement.blurElement();
    }

    function getSelectedUser(id) {
        if (!id) return;
        if(allOptions.length > 0){
            return allOptions.find((option) => option.id === id)?.profileImageUrl;
        }
        return options.find((option) => option.id === id)?.profileImageUrl;
    }

    useEffect(() => {
        if (inputProps.value) {
            if (["select", "autocomplete"].includes(eletype)) {
                let val = options.find(
                    (option) => option.value === inputProps.value
                );
                if (val) {
                    ele.current.value = val.label ? val.label : val.title;
                    if (val.imgLabel) {
                        setSelectedImageLabel(val.imgLabel)
                    }
                }
            } else {
                var [labelData, valueData] = render(
                    inputProps.value,
                    inputProps.value
                );
                ele.current.value = labelData;
                refElement.setValue(valueData);
            }
            setLabelActive(true);
        } else if (inputProps.value === null) {
            if (selectedImageLabel) {
                setSelectedImageLabel(null)
            }
            refElement.setValue("");
        }
    }, []);

    function handleClick() {
        ele.current.focus();
        setActive(true);
        setLabelActive(true);
    }

    function handleBlurActive(e) {
        if (!ele.current.value?.trim()) {
            setActive(false);
            setLabelActive(false);
        }
    }

    function handleAutocompleteItemClick(idx, valueD, labelD, imgLabel = null) {
        var item = options.find((option) => option.id === idx);
        if (item && item.isDisabled) return;
        ele.current.value = labelD;
        refElement.setValue(valueD);
        if (imgLabel) {
            setSelectedImageLabel(imgLabel);
        }
        setSelected(idx);
        setTimeout(() => {
            setActive(false);
        }, 0);
    }

    function handleSelectItemClick(idx, valueD, labelD) {
        if (options[idx].isDisabled) return;
        ele.current.value = labelD;
        refElement.setValue(valueD);
        setSelected(idx);
        setTimeout(() => {
            setActive(false);
        }, 0);
    }

    function handleFocus() {
        setActive(true);
        setLabelActive(true);
        if (_.isFunction(getOptions) && autocompleteOptions.length === 0) {
            getOptions("", options, function (options) {
                setAutocompleteOptions(options);
            });
        }
    }

    useEffect(()=>{
        if(fetchBeforeFocus && _.isFunction(getOptions) && autocompleteOptions.length===0){
            getOptions("", options, function (options) {
                setAutocompleteOptions(options);
            });
        }
    },[getOptions])

    useEffect(() => {
        if (autocompleteOptions.length > 0) {
            if (inputProps.value) {
                if ("autocomplete" === eletype) {
                    let val = autocompleteOptions.find(
                        (option) => option.value === inputProps.value
                    );
                    if (val) {
                        ele.current.value = val.label ? val.label : val.title;
                    }
                }
            }
        }
    }, [autocompleteOptions])

    useEffect(() => {
        prevValue.current = ele.current.value
    }, [updateState])

    function handleChange(e) {
        var [labelD, currentValue] = render(e.target.value, e.target.value, prevValue.current);
        ele.current.value = labelD;
        refElement.setValue(currentValue);
        setUpdateState(prevState => prevState + 1)
    }

    useOnClickOutside(containerRef, () => setActive(false));

    useEffect(() => {
        if (!active && !ele.current.value?.trim()) {
            setLabelActive(false);
        }
    }, [active])

    function handleInputChange(e) {
        // var elValue = e.target.value;
        // if (!elValue) {
        //     refElement.setValue("");
        //     return setAutocompleteOptions(options);
        // }
        // var newOptions = options.filter((option) => {
        //     return option.label?.toLowerCase().includes(elValue?.toLowerCase());
        // });
        // setAutocompleteOptions(newOptions);
        // refElement.setValue("");


        var value = e.target.value;
        if (!value) {
            if (isFunction(getOptions)) {
                getOptions(value, options, function (options) {
                    setAutocompleteOptions(options);
                    if (selectedImageLabel) {
                        setSelectedImageLabel(null)
                    }
                    refElement.setValue("");
                });
            }
            if (selectedImageLabel) {
                setSelectedImageLabel(null)
            }
            return refElement.setValue("");
        }
        if (_.isFunction(getOptions)) {
            return getOptions(value, options, function (options) {
                setAutocompleteOptions(options);
                if (selectedImageLabel) {
                    setSelectedImageLabel(null)
                }
                refElement.setValue("");
            });
        } else {
            var [firstObj, ...rest] = options;
            var newOptions = rest.filter((item) => item.title.includes(value));
            setAutocompleteOptions([firstObj, ...newOptions]);
        }
        if (selectedImageLabel) {
            setSelectedImageLabel(null)
        }
        refElement.setValue("");
    }

    function renderInput() {
        return (
            <div
                className="material-field input overflow-hidden"
                onClick={handleClick}
                onBlur={handleBlurActive}
                onFocus={() => {
                    setActive(true);
                    setLabelActive(true);
                }}
            >
                <div className="input-field overflow-hidden">
                    {hasIcon && (
                        <i className="absolute bottom-2 w-9 h-9">{icon}</i>
                    )}
                    {inputProps.value && (
                        <p className="absolute top-3 left-12">{prefixValue}</p>
                    )}
                    <input

                        // value={value}
                        ref={ele}
                        readOnly={readOnly}
                        className={`material-input ${fieldClass} ${
                            hasIcon ? "input-icon" : ""
                        }`}
                        disabled={disabled}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        {...restInputProps}
                        autoComplete={autoComplete}
                        onKeyDown ={ (e) => {
                            if ( e.key === " " && inputProps.touchedname === 'email' ) {
                              e.preventDefault(); //The value with a space now differs from what is displayed in the state. Issue for input type = email
                            }
                          }}
        
                    />
                    {label && (
                        <label
                            htmlFor=""
                            className={labelActive ? "active" : ""}
                            style={{ left: "0" }}
                        >
                            {label}
                        </label>
                    )}
                </div>
            </div>
        );
    }
    function renderAutocomplete() {
        return (
            <div
                className={`material-field ${customClasses}`}
                onClick={handleClick}
                onMouseLeave={(e) => {
                    // setActive(false);
                    // handleBlurActive(e);
                    ele.current.blur();
                }}
            >
                <div className="input-field relative">
                    {hasIcon && <i className="icon">{icon}</i>}
                    {selectedImageLabel? <div className="absolute top-2">{selectedImageLabel}</div>:null}
                    <input
                        onFocus={handleFocus}
                        ref={ele}
                        className={`cursor-pointer ${
                            inputProps.value && hasIcon ? "pl-8" : ""
                        } select-autocomplete ${fieldClass} ${
                            inputProps.value && hasIcon
                                ? "input-icon w-Select"
                                : "input-icon"
                        } ${selectedImageLabel ? "pl-14" : ""}`}
                        disabled={disabled}
                        onChange={handleInputChange}
                        onBlur={handleAutocompleteBlur}
                        {...restInputProps}
                        autoComplete={autoComplete}
                    />
                    {inputProps.value && hasIcon && (
                        <div className="absolute h-6 w-6 rounded-full top-3 border">
                            <span className="rounded-full w-full h-full flex items-center justify-center">
                                {inputProps.value &&
                                    (getSelectedUser(inputProps.value) ? (
                                        <img
                                            src={getSelectedUser(
                                                inputProps.value
                                            )}
                                            alt=""
                                            className="rounded-full h-6 w-6"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faUser}
                                            className="text-gray-500"
                                        />
                                    ))}
                            </span>
                        </div>
                    )}
                    <ul
                        className={`autocomplete-list dropdown-content w-48 overflow-x-auto overflow-y-auto ${
                            active ? "autocomplete-active" : "display-none"
                        }`}
                    >
                        {autocompleteOptions.length === 0
                            ? renderOnListEmpty
                            : autocompleteOptions.map((option) => {
                                  return (
                                      <li
                                          key={option.id}
                                          onClick={() => {
                                              handleAutocompleteItemClick(
                                                  option.id,
                                                  option.value,
                                                  option.label,
                                                  option.imgLabel,
                                              )
                                              if (handleOnChange) {
                                                  handleOnChange(option, setFieldValue)
                                              }
                                          }}
                                          tabIndex="0"
                                          className={`${
                                              selected == option.id
                                                  ? "selected"
                                                  : ""
                                          } ${
                                              option.isDisabled
                                                  ? "disabled"
                                                  : ""
                                          } border-b border-gray-300`}
                                          style={{cursor:option.isDisabled?'not-allowed':'pointer'}}
                                      >
                                          <span className="text-xs">
                                              {option.mainLabel
                                                  ? option.mainLabel
                                                  : option.label}
                                          </span>
                                      </li>
                                  );
                              })}
                    </ul>
                    <i className="select-icon cursor-pointer">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </i>
                    {label && (
                        <label
                            htmlFor=""
                            className={`renderAutocompletelabel ${
                                labelActive ? "active" : ""
                            }`}
                        >
                            {label}
                        </label>
                    )}
                </div>
            </div>
        );
    }

    function renderSelect() {
        return (
            <div className="material-field" onMouseLeave={(e) => {
                setActive(false)
                if (!ele.current.value?.trim()) {
                    setLabelActive(false)
                }
            }}>
                <div className={`input-field ${bgColor}`}>
                    {hasIcon && <i className="icon">{icon}</i>}
                    <input
                        onClick={handleClick}
                        onFocus={handleFocus}
                        ref={ele}
                        readOnly={true}
                        className={`${padding} ring-transparent select-dropdown dropdown-trigger text-gray-500 ${fieldClass} ${
                            hasIcon ? "input-icon" : ""
                        }`}
                        disabled={disabled}
                        onBlur={handleBlur}
                        {...restInputProps}
                        autoComplete={autoComplete}

                    />

                    <ul
                        onMouseLeave={(e) => {
                            onListBlur();
                            setActive(false);
                            handleBlurActive(e);
                        }}
                        className={`select-dropdown dropdown-content ${
                            active ? "select-active" : "display-none"
                        }`}
                    >
                        {options.map((option, idx) => {
                            return (
                                <li
                                    key={option.key}
                                    onClick={() =>
                                        handleSelectItemClick(
                                            idx,
                                            option.value,
                                            option.label
                                        )
                                    }
                                    tabIndex="0"
                                    className={`${
                                        selected == idx ? "selected" : ""
                                    } ${option.isDisabled ? "disabled" : ""}`}
                                >
                                    <span>{option.label}</span>
                                </li>
                            );
                        })}
                    </ul>
                    <i className="select-icon">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </i>
                    {label && (
                        <label
                            htmlFor=""
                            className={`${
                                labelActive ? "active" : ""
                            } ${padding}`}
                        >
                            {label}
                        </label>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            className={`text-left relative pt-0.5 pb-2 ${
                selectMargin ? "mt-2 mb-4" : ""
            } w-full ${containerClass}`}
            onMouseLeave={e => {
                e.preventDefault()
                e.stopPropagation()
                if (e.target == containerRef.current) {
                    setActive(false);
                    handleBlurActive(e);
                }
            }}
            ref={containerRef}
        >
            <div className="flex flex-col">
                {eletype === "input" && renderInput()}
                {eletype === "autocomplete" && renderAutocomplete()}
                {eletype === "select" && renderSelect()}
            </div>
            {errorText && (
                <div className={errorWrapperStyles}>
                    <Text
                        tag="small"
                        scale={true}
                        styles={`left-0 ${
                            errorStyle
                                ? errorStyle
                                : eletype === "autocomplete"
                                ? ""
                                : "-bottom-3"
                        } text-red-600 absolute pt-1`}
                        text={errorText}
                        alignment="left"
                        autoComplete={autoComplete}

                    />
                </div>
            )}
        </div>
    );
}

MaterialInput.propTypes = {
    eletype: PropTypes.string,
    hasIcon: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    bgColor: PropTypes.string,
    readOnly: PropTypes.bool,
    inputProps: PropTypes.object,
    errorStyle: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    allOptions:PropTypes.array,
    fieldClass: PropTypes.string,
    icon: PropTypes.object,
    mainLabel: PropTypes.any,
    containerClass: PropTypes.string,
    prefixValue: PropTypes.string,
    padding: PropTypes.string,
    activeOnMount: PropTypes.bool,
    selectMargin: PropTypes.bool,
    onListBlur: PropTypes.func,
    render: PropTypes.func,
    renderOnListEmpty: PropTypes.element,
    errorWrapperStyles: PropTypes.string,
    customClasses: PropTypes.string,
    autoComplete: PropTypes.string,
    filterOptions: PropTypes.func,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    fetchBeforeFocus: PropTypes.bool,
};

export default MaterialInput;
