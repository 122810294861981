import { faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Button from "./Button";
import Text from "./Text";

const FileInput = ({
    label = "",
    info = "",
    folder = "",
    placeholder = "",
    width = "",
    onChange = null,
    accept = "*.*",
    onBlur = () => {},
    name = "file",
    errorText = "",
    fontweight = "bold",
    height = 20,
    styles = "",
    imageMeta = "",
    isPreview = false,
    value= '',
    setFieldValue,
    // handleCancel = null,
}) => {
    const [img,setImg] = useState({})
    let condition = isPreview && ((_.size(img) > 0) || (!_.isEmpty(value)))
    let splitUrl =  isPreview && ((_.isEmpty(img) && !(_.isEmpty(value) )) &&  value.split('/'));
    let decodeUrl = decodeURIComponent(splitUrl[5]?.slice(splitUrl[5].indexOf('-') + 1))
    if(decodeUrl=='undefined'){
        decodeUrl=decodeURIComponent(splitUrl[splitUrl.length-1])
    }

    const placeholderName = value?.name && value.name
    
    function handleCancel() {
        if(_.isFunction(setFieldValue))
        {
            setFieldValue(name, {})
        }
        setImg({})
    }

    useEffect(() =>{
        if(_.isFunction(setFieldValue))
        {
            setFieldValue(name,value)
        } 
    },[value])

    useEffect(() =>{
        setImg(imageMeta)
    },[imageMeta])

    return (
        <div className={`flex ${width} flex-col`}>
            {_.size(label) > 0 && (
                <Text
                    tag="label"
                    scale={true}
                    text={label}
                    alignment="left"
                    fontweight={fontweight}
                    styles={styles}
                />
            )}
            {_.size(info) > 0 && (
                <Text
                    tag="span"
                    scale={true}
                    text={info}
                    alignment="left"
                    styles="my-2 text-oldProspectsFont"
                />
            )}
            <div className={`relative cursor-pointer h-${height} ${width}`}>
                <div className={`flex items-center cursor-pointer justify-center ${condition ? 'file-team-container' : 'file-container'}`}>
                    <div className={`w-full break-all leading-3 text-center ${condition ? 'flex items-center justify-items-start px-3' : ''}`}>
                    {condition && (
                        <>
                            <img className="w-8 h-8 object-cover rounded-full mr-3 border border-solid border-gray-400" src={img?.logo || img?.attachmentUrl || value} alt={placeholder} />
                        </>
                    )}
                        <Text
                            tag={placeholder?.length > 30 ? "small" : "span"}
                            scale={true}
                            text={splitUrl.length > 0 ? decodeUrl : placeholder?.length > 30 ? placeholder?.substring(0, 30) + '...' : placeholderName ? placeholderName.length > 30 ? `${placeholderName.substring(0, 30)}...` : placeholderName : placeholder}
                            alignment="center"
                            styles="my-2 text-sidebarActive cursor-pointer pr-5 break-all truncate"
                        />
                    </div>
                    {condition && (
                    <Button
                            action={handleCancel}
                            icon={faTimes}
                            styles="absolute right-3 z-20"
                        />
                    )}
                    <input
                        type="file"
                        name={name}
                        className="cursor-pointer"
                        onChange={onChange}
                        accept={accept}
                        onBlur={onBlur}
                        title={(img?.logo || img?.attachmentUrl || Object.keys(value).length > 0) ? placeholder : "No file chosen"}
                    />
                </div>
            </div>
            {_.size(folder) > 0 && (
                <div>
                    <Text
                        tag="span"
                        scale={true}
                        text={"Uploading to"}
                        alignment="left"
                    />
                    <Text
                        tag="span"
                        scale={true}
                        text={folder}
                        alignment="left"
                        styles="text-sidebarActive block max-w-xs truncate"
                    />
                </div>
            )}
            {errorText && (
                <p className="text-sm text-red-500 2xs:text-3xs xs:text-2xs sm:text-2xs md:text-2xs lg:text-xs xl:text-xs 2xl:text-xs small mt-2">
                    {errorText}
                </p>
            )}
        </div>
    );
};

FileInput.propTypes = {
    value: PropTypes.string,
    info: PropTypes.string,
    label: PropTypes.string,
    folder: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    onChange: PropTypes.func,
    accept: PropTypes.string,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    errorText: PropTypes.string,
    fontweight: PropTypes.string,
    height: PropTypes.number,
    styles: PropTypes.string,
    imageMeta: PropTypes.string,
    isPreview: PropTypes.bool,
    handleCancel: PropTypes.func,
    setFieldValue: PropTypes.func,
};

export default FileInput;
