import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export const Portal = ({ children }) => {
    return typeof document === "object"
        ? ReactDOM.createPortal(children, document.body)
        : null;
};

Portal.propTypes = {
    children: PropTypes.element.isRequired,
  };