import React, { useState, useEffect, useContext } from 'react';
import Image from '../commonComponent/Image';
import Text from '../commonComponent/Text';
import InputText from '../commonComponent/InputText';
import Button from '../commonComponent/Button';
import appConfig from '../assets/database/appConfig.json';
import _ from "lodash";
import PropTypes from "prop-types";
import getRequest from '../network/getRequest';
import getMessage from '../constants/Message';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from '../contextAPI';
import { messageString } from '../utils/UtilsFunc';
import Tooltip from '../commonComponent/Tooltip';


const ChangePasswordModal = ({ userDetails = {}, closeModal = null }) => {
    const [formData, setFormData] = useState({});
    const [errorText, setErrorText] = useState(false);
    const { dHToast } = useContext(UserContext);
    const { fetchRequest } = getRequest();
    const history = useHistory();
    const { img, changepass } = appConfig;
    let { title } = changepass;
    
    const isFormValid = _.isEmpty(formData) === false &&
        formData.oldPasswordValid &&
        formData.newPasswordValid &&
        formData.confirmPasswordValid &&
        formData.newPassword === formData.confirmPassword;

    const submitForm = async (e) => {
        e.preventDefault();
        if (isFormValid) {
            try {
                const body = {
                    oldPassword: formData.oldPassword,
                    newPassword: formData.newPassword
                };
                const response = await fetchRequest(body, 'post', 'changePassword');
                //display messages
                if (!response.error) {
                    closeModal();
                    dHToast(true, 'Password updated successfully', "rt", "success", faCheckCircle);
                    history.push(`/waprofile/${userDetails?.id}`);
                } else {
                    setErrorText(messageString(response.message));
                }
            }
            catch (err) {
                setErrorText(err.message);
            }
        } else {
            setErrorText(getMessage('changepass.formValidation'));
        }
    }

    const closeModalHandler = () => {
        closeModal();
    }

    //handle input change
    const handleChange = (name, value, isValid) => {
        if (errorText.length > 1) {
            setErrorText('');
        }
        setFormData((prev) => {
            const inputValidName = name + 'Valid';
            const hasKey = _.has(formData, name);
            if (prev.length > 0 && hasKey) {
                prev[name] = value;
                if (value.length === 0) {
                    prev[inputValidName] = false;
                } else {
                    prev[inputValidName] = isValid;
                }
                return prev;
            } else {
                if (value.length === 0) {
                    return { ...prev, [name]: value, [inputValidName]: false };
                } else {
                    return { ...prev, [name]: value, [inputValidName]: isValid };
                }
            }
        });
    }

    useEffect(() => {
        const { newPassword, confirmPassword } = formData;
        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            setErrorText(getMessage("changepass").passwordnotmatch);
        } else {
            setErrorText("");
        }
    }, [formData]);

    return (
        <>
            <div className="fixed left-0 right-0 top-0 bottom-0 flex items-center w-full h-full z-30 bg-black bg-opacity-40">
                <div className="p-4 bg-white rounded-lg shadow-xl m-auto w-84 change-password-height sm:w-1/3">
                    <span
                        className="float-right text-gray-500 cursor-pointer"
                        onClick={closeModalHandler}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                    {Object.entries(img).map(([key, value], i) => (
                        <Image
                            key={i}
                            img={require(`../assets/img/icons/${value}`).default}
                            alt={key}
                            imgClass="object-contain m-auto w-36"
                        />
                    ))}
                    <div className="text-center my-3">
                        <Text
                            tag="span"
                            scale={true}
                            styles="my-4"
                            text={title}
                            alignment="center"
                        />
                        <Text
                            tag="span"
                            scale={true}
                            fontweight="bold"
                            styles="my-4"
                            text={userDetails.email}
                            alignment="center"
                        />
                    </div>
                    <form
                        autoComplete="off"
                        className="py-4"
                        name="changepass"
                        onSubmit={submitForm}
                    >
                        <InputText
                            name="oldPassword"
                            placeholder="Current Password"
                            type="password"
                            required="required"
                            margin="true"
                            handleChange={handleChange}
                            value={formData['oldPassword']}
                        />
                        <InputText
                            name="newPassword"
                            placeholder="New Password"
                            type="password"
                            required="required"
                            margin="true"
                            handleChange={handleChange}
                            value={formData['newPassword']}
                        />
                        <InputText
                            name="confirmPassword"
                            placeholder="Confirm New Password"
                            type="password"
                            required="required"
                            margin="true"
                            handleChange={handleChange}
                            value={formData['confirmPassword']}
                            validationRequired={false}
                        />
                        <div className="text-left mb-2 leading-none">
                            <Text
                                tag="small"
                                scale={true}
                                styles="mt-4 mb-4 mr-2"
                                text={"Check the password match condition"}
                                alignment="left"
                            />
                            <Tooltip
								content={getMessage("tooltips").passwordInfo}
								icon={faInfoCircle}
								styles="w-72"
							/>
                        </div>
                        <Button
                            text="Set Password"
                            type="submit"
                            width="full"
                            btnColor="bg-blue-700"
                            color="white"
                            rounded="rounded"
                            styles="active:bg-blue-900 text-base rounded shadow-md hover:shadow-lg py-3 px-1"
                            disabled={!isFormValid}
                        />
                        {errorText.length > 0 && (
                            <Text
                                tag="small"
                                scale={true}
                                styles={"text-red-600 pt-1"}
                                text={errorText}
                                alignment="left"
                            />
                        )}
                    </form>
                </div>
            </div>

        </>
    );
}

ChangePasswordModal.propTypes = {
    userDetails: PropTypes.object,
    closeModal: PropTypes.func
};

export default ChangePasswordModal;
