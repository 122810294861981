import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Anchor from "./Anchor";
import { getVirtualElement, uuidv4 } from "../utils/UtilsFunc";
import { has, isEqual, isFunction, isObject } from "lodash";
import useOnClickOutside from "../hooks/useOnClickOutside";
import usePrevious from "../hooks/usePrevious";
import Image from "./Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes} from "@fortawesome/free-solid-svg-icons";
// import { UserContext } from "../contextAPI";

function deepFlattenArray(array) {
    var result = [];
    array.forEach(function (a) {
        result.push(a);
        if (Array.isArray(a.submenu)) {
            result = result.concat(deepFlattenArray(a.submenu));
        }
    });
    return result;
}

function Autocomplete({
    hasIcon = false,
    margin = "false",
    handleChange = null,
    handleBlur = () => { },
    inputProps,
    // label = "",
    option = [],
    // dataLable = "name",
    bgColor = "",
    disabled = false,
    errorText = "",
    value = "",
    filterOptions,
    borderFlag = true,
    rounded = true,
    withScroll = false,
    dropdownStyles = "",
    values = {},
    setFieldValue = null,
    conditionChange = null,
    conditionChangeValue = '',
    autoComplete = 'off',
    inputRefHandler = null,
    optionStyles = ""
}) {
    // const { dropdownScrollLeftPos, dropdownScrollTopPos  } = useContext(UserContext);
    var [showAddMenu, setShowAddMenu] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    var inputRef = useRef();
    var [autoCompleteOptions, setAutocompleteOptions] = useState(option);
    const [selectedIcon, setSelectedIcon] = useState(null)
    const [isIconUrl, setIsIconUrl] = useState(false)

    var dropdownRef = useRef();

    const myPreviousState = usePrevious(values);

    // const { handleChange: onChange, ...rest } = inputProps;

    var refElement = useMemo(() => {
        return getVirtualElement({
            name: inputProps.name,
            type: "dropdown",
            value: value,
            handleChange,
            handleBlur,
        });
    }, []);

    var getOptions = useMemo(() => {
        if (isFunction(filterOptions)) {
            return filterOptions(option, values);
        }
        return null;
    }, [values]);

    useOnClickOutside(dropdownRef, () => setShowAddMenu(false));

    useEffect(() => {
        if (option.length) {
            setAutocompleteOptions(option);
        }
    }, [option]);

    useEffect(() => {
        var element = inputRef.current;
        if (value) {
            if (typeof value === "string" || typeof value === "number") {
                setIsLoading(true)
                getOptions("", option, function (options) {
                    setAutocompleteOptions(options);
                    const selectedVal = options.find(op => op.value === value)
                    if (selectedVal&&inputRef.current) {
                        inputRef.current.value = selectedVal.title;
                        refElement.setValue(selectedVal.value);
                    }
                    setIsLoading(false)
                }, function () {
                    setIsLoading(false)
                });
            }
            else {
                inputRef.current.value = value.title;
                refElement.setValue(value.value);
            }
        } else {
            inputRef.current.value = "";
        }
        return function () {
            element.value = "";
        };
    }, []);

    useEffect(() => {
        if (value && autoCompleteOptions.length > 0) {
            const selectedVal = autoCompleteOptions.find(op => op.value === value)
            if (selectedVal && inputRef.current.value !== selectedVal.title) {
                inputRef.current.value = selectedVal.title;
                if (hasIcon) {
                    setSelectedIcon(selectedVal.icon ?? null)
                    setIsIconUrl(selectedVal.iconUrl)
                }
            }
        }
    }, [value, autoCompleteOptions])

    /*useEffect(() => {
        var width = inputRef.current.getBoundingClientRect().width;
        if (showAddMenu) {
            dropdownRef.current.style.width = width + "px";
            //dropdownRef.current.style.marginTop = 50 + "px"
        }
    }, [showAddMenu]);*/

    let inputClass = classNames({
        [`placeholder-blueGray-300 font-${inputProps.weight ? inputProps.weight : "semibold"} relative bg-white leading-5 text-${inputProps.size ? inputProps.size : "base"} focus:ring outline-none ml-0 mr-3`]: true,
        [`${inputProps.styles}`]: true,
        "pl-10": hasIcon && selectedIcon !== null,
        "mb-3": margin === "true",
        "border": borderFlag,
        "rounded": rounded,
        "border-red-600": errorText.length > 0,
        "focus:ring-blue-100": errorText.length === 0,
        "focus:ring-red-100": errorText.length > 0,
        [`bg-${bgColor.length > 0 ? bgColor : "white"}`]: true,
        "opacity-40 cursor-not-allowed pointer-events-none": disabled === true,
    });

    const toggleRenameMenuClass = classNames(
        `origin-top-right mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 ${dropdownStyles}`,
        {
            hidden: showAddMenu === false,
            fixed: withScroll === true,
            absolute: withScroll === false
        }
    );

    const menuItemClasses =
        `truncate text-gray-700 block px-1 py-2 hover:bg-blue-100 hover:text-blue-800 rounded-md text-xs text-left ${optionStyles} `;

    const menuItemClassesForDisabledOption = classNames(
        "text-gray-300 block py-2 pointer-events-none cursor-not-allowed my-1 rounded-md text-xs text-left"
    );

    const [leftPos, setScrollLeft] = useState(0);
    const [topPos1, setScrollTop1] = useState(0);
    const mainElement = useRef();
    useEffect(() => {
        if (withScroll) {
            const onScroll = e => {
                setScrollTop1(mainElement?.current?.getBoundingClientRect().top);
                setScrollLeft(mainElement?.current?.getBoundingClientRect().left);
            };

            window.addEventListener("scroll", onScroll);

            return () => window.removeEventListener("scroll", onScroll);
        }
    }, []);

    useEffect(() => {
        if (isFunction(conditionChange)) {
            const modifiedState = isObject(myPreviousState?.[`${conditionChangeValue}`]) ?
                { ...myPreviousState, [`${conditionChangeValue}`]: myPreviousState?.[`${conditionChangeValue}`]?.value } : { ...myPreviousState, [`${conditionChangeValue}`]: myPreviousState?.[`${conditionChangeValue}`] };


            const modifiedValue = isObject(value) ?
                { ...myPreviousState, [`${conditionChangeValue}`]: value.value } : { ...myPreviousState, [`${conditionChangeValue}`]: value || '' };

            if (!isEqual(modifiedState[`${conditionChangeValue}`], modifiedValue[`${conditionChangeValue}`])) {
                conditionChange(values, setFieldValue, autoCompleteOptions);
            }
        }
    }, [values]);

    useEffect(() => {
        if (withScroll) {
            //setScrollLeft(dropdownScrollLeftPos)
            //setScrollTop1(dropdownScrollTopPos)
        }
    }, [])

    useEffect(() => {
        if (isFunction(inputProps.defaultValue)) {
            let defaultValue = inputProps.defaultValue(value);
            refElement.setValue(defaultValue);
        }
    }, []);
    // function getLabelFromValue(value) {
    //     var val = autoCompleteOptions.find((op) => op.value == value);
    //     return val?.title ? val.title : "";
    // }
    function getLabelFromValue(value) {
        var val;
        var check = autoCompleteOptions.some(obj => Object.keys(obj).includes("submenu"));
        if (check === true) {
            let newArray = deepFlattenArray(autoCompleteOptions);
            val = newArray.find((op) => op.value === value);
        } else {
            val = autoCompleteOptions.find((op) => op.value === value);
        }
        return val?.title ? val.title : "";
    }

    function selectClick(item) {
        if (hasIcon) {
            setSelectedIcon(item.icon ?? null)
            setIsIconUrl(item.iconUrl)
        }
        const value = item.value;
        inputRef.current.value = getLabelFromValue(value);
        refElement.setValue(value);
        setShowAddMenu(false);
    }

    async function handleInputChange(e) {
        var value = e.target.value;
        if (!value) {
            setIsLoading(true)
            getOptions(value, option, function (options) {
                setAutocompleteOptions(options);
                refElement.setValue("");
                setIsLoading(false)
            }, function () {
                setIsLoading(false)
            });
            setSelectedIcon(null)
            setIsIconUrl(false)
            return refElement.setValue("");
        }
        if (isFunction(getOptions)) {
            setIsLoading(true)
            getOptions(value, option, function (options) {
                setAutocompleteOptions(options);
                refElement.setValue("");
                setSelectedIcon(null)
                setIsIconUrl(false)
                setIsLoading(false)
            }, function () {
                setIsLoading(false)
            });
        } else {
            var [firstObj, ...rest] = option;
            var newOptions = rest.filter((item) => item.title.includes(value));
            setAutocompleteOptions([firstObj, ...newOptions]);
        }
        refElement.setValue("");
        setSelectedIcon(null)
        setIsIconUrl(false)
    }

    // function renderMenu(item) {
    //     if (item.menuTitle) {
    //         return (
    //             <Anchor key={item.menuTitle} classes={menuItemClasses} menuTitle={item.menuTitle} />
    //         );
    //     } else if (item.separator) {
    //         return <Anchor key={item} classes={menuItemClasses} separator={true} />;
    //     } else {
    //         return (
    //             <Anchor
    //                 key={item.title}
    //                 item={item}
    //                 showTitle={true}
    //                 classes={menuItemClasses}
    //                 clickEvent={() => selectClick(item)}
    //                 disabled={item.disabled}
    //             />
    //         );
    //     }
    // }
    function renderMenu(item, level = 0) {
        const nesteditems = (item.submenu || []).map((data, index) => {
            level = level + 1
            return renderMenu(data, level)
        })
        if (item.menuTitle) {
            return (
                <Anchor key={item.value ?? item.menuTitle} classes={menuItemClasses} menuTitle={item.menuTitle} />
            );
        } else if (item.separator) {
            return <Anchor key={item.value ?? item.menuTitle} classes={menuItemClasses} separator={true} />;
        } else {
            return (
                <div className={`${item.disable ? "cursor-not-allowed" : ""}`} key={item.value ?? item.menuTitle}>
                    {item.type !== "label" ?
                        <Anchor
                            item={item}
                            showTitle={true}
                            classes={item.disable ? menuItemClassesForDisabledOption : menuItemClasses}
                            clickEvent={() => {
                                selectClick(item);
                            }}
                            imageStyle={item.imageStyle}
                        /> :
                        <div className={`py-2 my-1 rounded-md text-xs text-left ${item.labelStyle ? item.labelStyle : "font-bold text-gray-700 block truncate"}`}>
                            {
                                has(item, "icon") && item.icon !== null && (
                                    <span className="inline-flex justify-center items-center mx-4">
                                        {has(item, "icon") && (
                                            <Image
                                                img={require(`../assets/img/icons/${item.icon}`).default}
                                                alt={item.title}
                                                imgClass={item.imageStyle}
                                            />
                                        )}
                                    </span>)
                            }
                            {item.title}
                        </div>}

                    <div style={{ "marginLeft": 4 * level }}>
                        {nesteditems}
                    </div>
                </div>
            );
        }
    }

    function renderMenuList() {
        return (
            <React.Fragment key={uuidv4()}>
                <div
                    className={toggleRenameMenuClass}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                    onMouseMove={(e) => {
                        e.target.focus()
                    }}
                    onKeyDown={() => {
                        inputRef.current?.focus()
                    }}
                    onMouseLeave={() => {
                        inputRef.current.blur();
                        setShowAddMenu(false);
                        refElement.blurElement();
                    }}
                    ref={dropdownRef}
                    id={'selectDropdown'}
                    style={{ top: withScroll ? topPos1 : "40px", left: withScroll ? leftPos : "", width: showAddMenu ? inputRef?.current?.getBoundingClientRect().width : "" }}
                >
                    <div className="p-2" role="none">
                        {isLoading ?
                            <Anchor classes={menuItemClasses} menuTitle={"Loading..."} /> :
                            getAutoCompleteLength() === 0 ?
                                <Anchor classes={menuItemClasses} menuTitle={"No data available"} /> :
                                autoCompleteOptions.map((item) => {
                                    return renderMenu(item);
                                })
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function handleInputBlur() {
        // if (!value) {
        //     inputRef.current.value = "";
        //     //     return getOptions("", option, function (options) {
        //     //         setAutocompleteOptions(options);
        //     //     });
        // }
        refElement.blurElement();
        setIsFocused(false)
    }

    function handleInputFocus() {
        if (autoCompleteOptions.length < 10) {
            setIsLoading(true)
            getOptions("", option, function (options) {
                setAutocompleteOptions(options);
                setIsLoading(false)
            }, function () {
                setIsLoading(false)
            });
        }
    }

    if (inputRefHandler) {
        inputRefHandler(inputRef)
    }

    const getAutoCompleteLength = () => {
        return (autoCompleteOptions.length - autoCompleteOptions.filter(el => (el.menuTitle || el.separator)).length)
    }

    return (
        <div ref={mainElement} className="relative group" id={'selectDropdownDiv'} onMouseLeave={() => {
            if (!value) {
                inputRef.current.value = "";
            }
            if (!isFocused) {
                setShowAddMenu(false)
            }
        }}>
            {hasIcon && selectedIcon && <Image
                img={isIconUrl ? selectedIcon : require(`../assets/img/icons/${selectedIcon}`).default}
                alt={"Icon"}
                imgClass={"h-4 w-4 absolute z-10 bottom-4 left-3"}
            />}
            <input
                ref={inputRef}
                className={inputClass}
                onFocus={(e) => {
                    handleInputFocus();
                    if (withScroll) {
                        setScrollTop1(mainElement.current.getBoundingClientRect().top + 50);
                        setScrollLeft(mainElement.current.getBoundingClientRect().left)
                    }
                    setShowAddMenu(true);
                    setIsFocused(true)
                }}
                {...inputProps}
                autoComplete={autoComplete}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onClick={() => {
                    if (isFocused) {
                        setShowAddMenu(true)
                    }
                }}
            />
            {inputProps.crossDownAngleIcon && <div>
                <i className="select-icon absolute bottom-2 right-3 ">
                    {inputRef?.current?.value && <FontAwesomeIcon icon={faTimes} className='mr-2 text-gray-600 cursor-pointer text-xs'
                        onClick={() => {
                            inputRef.current.value = "";
                            refElement.setValue("");
                        }}
                    />}<FontAwesomeIcon icon={faChevronDown} className=' text-gray-600 text-xs' />
                </i>
            </div>}
            {(autoCompleteOptions.length > 0 ||
                ((
                    (("showLoader" in inputProps) && inputProps.showLoader) ||
                    !("showLoader" in inputProps)
                ) && isLoading)
            ) && renderMenuList()}
        </div>
    );
}

Autocomplete.propTypes = {
    hasIcon: PropTypes.string,
    margin: PropTypes.string,
    handleChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    handleBlur: PropTypes.func,
    // label: PropTypes.string,
    option: PropTypes.array,
    // dataLable: PropTypes.string,
    bgColor: PropTypes.string,
    disabled: PropTypes.string,
    inputProps: PropTypes.object,
    errorText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filterOptions: PropTypes.func,
    borderFlag: PropTypes.bool,
    rounded: PropTypes.bool,
    withScroll: PropTypes.bool,
    autoComplete: PropTypes.string,
    dropdownStyles: PropTypes.string,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    conditionChange: PropTypes.func,
    conditionChangeValue: PropTypes.string,
    inputRefHandler: PropTypes.func,
    optionStyles: PropTypes.string,
};

export default Autocomplete;